var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translater } from "../../../../utils/localization/localization";
export var GreenMessage = function (_a) {
    var _b = _a.message, message = _b === void 0 ? '' : _b;
    return (_jsxs("div", __assign({ style: { backgroundColor: 'rgba(228, 248, 240, 0.7)' }, className: "p-message-success flex align-items-center gap-1 border-round-sm p-2 px-2" }, { children: [_jsx("i", { style: { color: '#1ea97c' }, className: "pi pi-check text-sm" }), _jsx("span", __assign({ style: { color: '#1ea97c' }, className: "text-sm cursor-default" }, { children: translater(message) }))] })));
};
