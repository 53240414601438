var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from "react-hook-form";
import { Button } from 'primereact/button';
import { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Input } from "./components/Input";
import { Textarea } from "./components/Textarea";
import { Multiselect } from "./components/Multiselect";
import { getDefaultValues } from "./utils/form";
import { List } from "./components/List";
import { Radio } from "./components/Radio";
import { Audio } from "./components/audio/Audio";
import { translater } from "../../../../utils/localization/localization";
import { ExpertTab, preparedExpertOptions } from "./components/ExpertTab";
import { Password } from "./components/Password";
export var getListItemKeyword = function (listItem) {
    return listItem ? "".concat(translater(listItem.keyword)) || "".concat(listItem.keyword) : '';
};
export var translate = function (content) { return translater(content); };
export var getFormSettings = function (form) {
    var tabs = form.tabs;
    var settings = {};
    var getDefaultValue = function (type, formField) {
        if (['multiselect'].includes(type))
            return formField.defaultValue || [];
        if (['radio'].includes(type))
            return formField.defaultValue ? formField.defaultValue.content : null;
        if (['list', 'audio', 'radio'].includes(type))
            return formField.defaultValue || null;
        // textarea, input
        return formField.defaultValue || '';
    };
    tabs.forEach(function (tab) {
        if (tab.fields)
            tab.fields.forEach(function (formField) {
                var type = formField.type, field = formField.field;
                settings[field] = getDefaultValue(type, formField);
            });
    });
    return settings;
};
export var FormGenerator = function (_a) {
    var json = _a.json, onSend = _a.onSend, defaultFormValues = _a.defaultFormValues, expertOptions = _a.expertOptions, _b = _a.sendButtonLoading, sendButtonLoading = _b === void 0 ? false : _b;
    var _c = __read(useState(expertOptions ? preparedExpertOptions(expertOptions) : ''), 2), expertCode = _c[0], onExpertCode = _c[1];
    var formData = JSON.parse(json);
    var defaultValues = defaultFormValues || getDefaultValues(json);
    var form = useForm({
        mode: 'all',
        defaultValues: defaultValues
    });
    // Преобразование элементов формы по условию
    formData.formTitleVisible = (typeof formData.formTitleVisible === 'boolean' && !formData.formTitleVisible) ? false : true;
    var handleSubmit = form.handleSubmit, _d = form.formState, submitCount = _d.submitCount, isValid = _d.isValid, errors = _d.errors;
    var onSubmit = function (data) { return onSend({ data: data, expertCode: expertCode }); };
    var fields = [];
    for (var i = 0; i < formData.tabs.length; i++) {
        var tab = formData.tabs[i];
        if (tab.fields) {
            for (var i_1 = 0; i_1 < tab.fields.length; i_1++) {
                var field = tab.fields[i_1];
                var settings = { name: field.field };
                // Скрываем поля с isHidden = true
                if (field.isHidden)
                    continue;
                if (field.type)
                    switch (field.type) {
                        case 'input':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(Input, { form: form, field: field }) }));
                            break;
                        case 'password':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(Password, { form: form, field: field }) }));
                            break;
                        case 'textarea':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(Textarea, { form: form, field: field }) }));
                            break;
                        case 'list':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(List, { form: form, field: field }) }));
                            break;
                        case 'multiselect':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(Multiselect, { form: form, field: field }) }));
                            break;
                        case 'radio':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(Radio, { form: form, field: field }) }));
                            break;
                        case 'audio':
                            fields.push(__assign(__assign({}, settings), { structure: _jsx(Audio, { form: form, field: field }) }));
                            break;
                        default:
                            continue;
                    }
            }
        }
    }
    var expertTab = formData.tabs.find(function (tab) { return (tab === null || tab === void 0 ? void 0 : tab.expertMode) === true; }) || '';
    var tabError = function (tab) {
        var errorKeys = Object.keys(errors);
        var errorField = tab.fields.find(function (field) { return errorKeys.includes(field.field); });
        return errorField ? 'pi pi-circle-fill mr-2 text-red-600 text-xs' : '';
    };
    return (_jsx("div", __assign({ className: "flex flex-column mx-auto w-full" }, { children: _jsxs("form", __assign({ className: "py-2 px-4 border-round w-full flex flex-column align-items-start", onSubmit: handleSubmit(onSubmit) }, { children: [formData.titleTranslaterKey && formData.formTitleVisible
                    ? _jsx("h3", { children: formData.titleTranslaterKey })
                    : '', _jsx("div", __assign({ className: "w-full flex flex-column gap-3" }, { children: _jsxs(TabView, { children: [formData.tabs.map(function (tab, index) {
                                // Если вкладка не для режима эксперта
                                return !tab.expertMode && (_jsx(TabPanel, __assign({ leftIcon: tabError(tab), header: tab.titleTranslaterKey, className: "flex flex-column gap-2" }, { children: tab.fields
                                        .filter(function (field) { return !field.isHidden; })
                                        .map(function (field, index) {
                                        var _a;
                                        return (_jsx("div", __assign({ className: "w-full" }, { children: ((_a = fields.find(function (f) { return f.name === field.field; })) === null || _a === void 0 ? void 0 : _a.structure) || '' }), index));
                                    }) }), index));
                            }), expertTab && (_jsx(TabPanel, __assign({ header: expertTab.titleTranslaterKey }, { children: _jsx(ExpertTab, { expertCode: expertCode, onExpertCode: onExpertCode, form: form, tabs: formData.tabs }) })))] }) })), _jsx("div", __assign({ className: "w-full flex justify-content-end my-3" }, { children: _jsx(Button, { type: "submit", className: "w-full mx-3 full-button p-button-success", icon: "pi pi-check", disabled: submitCount > 0 && !isValid, loading: sendButtonLoading, label: formData.buttonLabelTranslaterKey || translater("Form Generator Send Button Default").toString() }) }))] })) })));
};
