var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var template = function (_a) {
    var groups = _a.groups;
    return (_jsx("div", __assign({ className: "flex flex-wrap align-items-center justify-content-center gap-1" }, { children: groups.map(function (group) {
            return (_jsx("span", __assign({ className: "mr-1 px-2 py-1 border-indigo-200 border-round-xs border border-1" }, { children: group.title }), group.title));
        }) })));
};
export var groupsColumn = {
    columnHeader: 'usersPageGroupsColumn',
    field: 'groups',
    style: {
        minWidth: "300px"
    },
    body: template
};
