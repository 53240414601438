var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { classNames } from "primereact/utils";
import { translater } from "../../../../../utils/localization/localization";
export var StationsAnalytics = function (_a) {
    var analytics = _a.analytics, json = _a.json;
    var stations = Object.keys(analytics);
    var _b = __read(useState(false), 2), logsVisible = _b[0], setLogsVisible = _b[1];
    return (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsxs("table", __assign({ className: "w-25rem station-analytics" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("td", { children: _jsx("b", { children: translater('Station') }) }), _jsx("td", __assign({ className: "w-7rem text-center" }, { children: _jsx("b", { children: translater('ApplySettingsModalTableSuccessColumnName') }) })), _jsx("td", __assign({ className: "w-7rem text-center" }, { children: _jsx("b", { children: translater('ApplySettingsModalTableErrorColumnName') }) }))] }) }), _jsx("tbody", { children: stations.map(function (station) {
                            return (_jsxs("tr", { children: [_jsx("td", { children: station }), _jsx("td", __assign({ className: "w-2rem text-center" }, { children: _jsx("i", { className: classNames({
                                                'text-green-400 font-bold': analytics[station].ok
                                            }, 'pi pi-check text-300') }) })), _jsx("td", __assign({ className: "w-2rem text-center" }, { children: _jsx("i", { className: classNames({
                                                'text-red-500 font-bold': analytics[station].failures
                                            }, 'pi pi-times text-300 text-center') }) }))] }));
                        }) })] })), !logsVisible ? (_jsx("span", __assign({ onClick: function () { return setLogsVisible(true); }, className: "text-sm text-500 cursor-pointer" }, { children: translater('ApplySettingsModalTableShowLogsButton') }))) : (_jsx("div", __assign({ className: "flex w-25rem flex-wrap" }, { children: _jsx("pre", __assign({ className: "text-sm apply-btn-logs" }, { children: JSON.stringify(JSON.parse(json), null, 2) })) })))] })));
};
