var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { FilterMatchMode } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import { translater } from "../../../utils/localization/localization";
import { API } from "../../../api";
import { successNotification } from "../../../redux/actions/notificationsActions";
import '../.././../assets/scss/pages/lists.scss';
import { dynamicColumns } from "./components/columns/columns";
import { Header } from "./components/header/Header";
import { UpdateExtension } from "./components/header/components/update-modal/UpdateExtension";
import { PreviewDialog } from "./components/PreviewDialog";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { recognizeConfigField } from "../../components/particals/form-generator/utils/form";
import { EntityDependencyErrorArea } from "../../components/particals/error/EntityDependencyErrorArea";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
import { TooltipRevealer } from "../../components/particals/tooltip/TooltipRevealer";
var defaultLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var Extensions = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/extensions' }]);
    }, []);
    var _b = __read(useState([]), 2), extensions = _b[0], setExtensions = _b[1];
    var _c = __read(useState(50), 2), limit = _c[0], setLimit = _c[1];
    var _d = __read(useState(0), 2), extensionsAmount = _d[0], setExtensionsAmount = _d[1];
    var _e = __read(useState([]), 2), extensionsColumnNames = _e[0], setExtensionsColumnNames = _e[1];
    var _f = __read(useState([]), 2), selectedExtensions = _f[0], setSelectedExtensions = _f[1];
    var _g = __read(useState(false), 2), loading = _g[0], setLoading = _g[1];
    var _h = __read(useState(''), 2), globalFinderValue = _h[0], setGlobalFinderValue = _h[1];
    var _j = __read(useState(false), 2), dialogVisible = _j[0], setDialogVisible = _j[1];
    var _k = __read(useState(false), 2), previewDialogVisible = _k[0], setPreviewDialogVisible = _k[1];
    var _l = __read(useState(null), 2), modifiedExtension = _l[0], setModifiedExtension = _l[1];
    var _m = __read(useState(defaultLazyState), 2), lazyState = _m[0], setlazyState = _m[1];
    var _o = __read(useState([]), 2), dependencyErrors = _o[0], setDependencyErrors = _o[1];
    var _p = __read(useState(false), 2), dependencyModalVisible = _p[0], setDependencyModalVisible = _p[1];
    var _q = __read(useState([]), 2), removableCandidates = _q[0], setRemovableCandidates = _q[1];
    var loadData = function () {
        setLoading(true);
        var query = globalFinderValue.trim();
        API.extensionsFinder(query, lazyState)
            .then(function (response) {
            var _a = response.data, extensions = _a.extensions, limit = _a.limit, amount = _a.amount;
            setLimit(limit);
            setExtensions(extensions);
            setExtensionsAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var onFilter = function (event) {
        event['first'] = 0;
        setlazyState(event);
    };
    var defaultVisibleColumns = dynamicColumns
        .filter(function (col) { return ['name'].includes(col.field || ''); })
        .map(function (col) { return col.field || ''; });
    var _r = __read(useState(defaultVisibleColumns), 2), visibleColumns = _r[0], setVisibleColumns = _r[1];
    var changeVisibleColumns = function (columns) {
        setVisibleColumns(columns);
    };
    // Грузим название имен колонок для exntesions
    useEffect(function () {
        var load = function () {
            API.getExtensionsColumnNames()
                .then(function (response) {
                setExtensionsColumnNames(response.data);
                setLoading(false);
            })
                .finally(function () { return setLoading(false); });
        };
        load();
    }, []);
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadData(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    var onPage = function (event) { setlazyState(event); };
    var onChangeGlobalFinderValue = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
    };
    var accept = function (ids) {
        setLoading(true);
        API.removeExtensions(ids)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            setSelectedExtensions([]);
            // Перезагружаем данные после удаления
            loadData();
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var confirm = function (row) {
        var extension = row.name;
        var ids = [row._id];
        setRemovableCandidates(ids);
        confirmDialog({
            message: translater("extensionsPageDeleteTrunkConfirmMessage").toString() +
                extension +
                "?",
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(ids); },
            reject: function () { }
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkExtensionsBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeExtensions(removableCandidates)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                setSelectedExtensions([]);
                // Перезагружаем данные после удаления
                loadData();
                setDependencyErrors([]);
                setDependencyModalVisible(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { loadData: loadData, globalFinderValue: globalFinderValue, setGlobalFinderValue: onChangeGlobalFinderValue, visibleColumns: visibleColumns, dynamicColumns: dynamicColumns, setVisibleColumns: changeVisibleColumns, extensionsColumnNames: extensionsColumnNames }) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: extensions, loading: loading, rows: limit, first: lazyState.first, totalRecords: extensionsAmount, responsiveLayout: "scroll", scrollable: true, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, selection: selectedExtensions, onSelectionChange: function (e) { return setSelectedExtensions(e.value); }, dataKey: "_id", style: { minWidth: "200px" } }, { children: [dynamicColumns
                            .filter(function (column) {
                            return visibleColumns.find(function (visibleColumn) { return column.field === visibleColumn; });
                        })
                            .map(function (column) {
                            var header = translater(column.columnHeader);
                            return (_jsx(Column, __assign({ className: "justify-content-center px-5", style: column.style || { minWidth: "150px" }, header: header }, column), column.field));
                        }), extensionsColumnNames
                            .filter(function (column) {
                            return visibleColumns.find(function (visibleColumn) { return column === visibleColumn; });
                        })
                            .map(function (name) {
                            return (_jsx(Column, { className: "justify-content-center", header: name, style: { minWidth: "150px" }, body: function (row) {
                                    return (_jsx("p", { children: row.config[name]
                                            ? recognizeConfigField(row.config[name])
                                            : '' }));
                                } }, "column-" + name));
                        }), _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), body: function (row) {
                                return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(TooltipRevealer, __assign({ hint: translater('viewBtn').toString() }, { children: _jsx(Button, { icon: "pi pi-eye", className: "p-button p-component p-button-icon-only p-button-outlined border-circle p-button-warning", style: { width: "50px", height: "50px" }, onClick: function () {
                                                    setPreviewDialogVisible(true);
                                                    setModifiedExtension(row);
                                                } }) })), _jsx(TooltipRevealer, __assign({ hint: translater('updateBtn').toString() }, { children: _jsx(Button, { icon: "pi pi-pencil", className: "p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () {
                                                    setDialogVisible(true);
                                                    setModifiedExtension(row);
                                                } }) })), _jsx(TooltipRevealer, __assign({ hint: translater('deleteBtn').toString() }, { children: _jsx(Button, { icon: "pi pi-trash", className: "p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }) }))] })));
                            } }, 'actions')] })) })), previewDialogVisible && (_jsx(PreviewDialog, { modifiedExtension: modifiedExtension, hideDialog: function () { return setPreviewDialogVisible(false); } })), _jsx(UpdateExtension, { modifiedExtension: modifiedExtension, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible, loadData: loadData }), _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] })));
};
