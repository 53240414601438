var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { API } from "../../../../../../../../../api";
import { translater } from "../../../../../../../../../utils/localization/localization";
import { dynamicColumns } from './children-columns/columns';
export var ChildrenField = function (_a) {
    var id = _a.id, selectedChildren = _a.selectedChildren, setSelectedChildren = _a.setSelectedChildren;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState([]), 2), children = _c[0], setChildren = _c[1];
    var _d = __read(useState(""), 2), globalFilter = _d[0], setGlobalFilter = _d[1];
    var childrenFinder = function () {
        setLoading(true);
        API.getAllChildren(id)
            .then(function (response) {
            var data = response.data;
            setChildren(data);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var filterByName = function (name, query) {
        return name.toLowerCase().includes(query.toLowerCase());
    };
    useEffect(function () {
        childrenFinder();
    }, []);
    var columns = dynamicColumns.map(function (column) {
        var header = translater(column.columnHeader);
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "elements", className: "block text-900 font-medium mb-2" }, { children: translater("contextPageHeaderDialogElements") })), _jsxs("span", __assign({ className: "w-full p-input-icon-left mb-2" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { id: "elements", value: globalFilter, onChange: function (event) { return setGlobalFilter(event.target.value); }, className: "w-full", placeholder: translater('search').toString() })] })), _jsxs(DataTable, __assign({ value: children.filter(function (child) { return filterByName(child.name, globalFilter); }), loading: loading, responsiveLayout: "scroll", scrollable: true, size: 'small', selectionMode: "multiple", selection: selectedChildren, onSelectionChange: function (e) { return setSelectedChildren(e.value); }, dataKey: "_id" }, { children: [_jsx(Column, { className: "flex justify-content-center", selectionMode: "multiple", style: { maxWidth: "90px" }, headerStyle: { width: '6rem', height: "65px" } }, 'selecting'), columns] }))] }));
};
