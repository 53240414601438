var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { translater } from '../../../../utils/localization/localization';
import { TooltipRevealer } from '../../../components/particals/tooltip/TooltipRevealer';
export var Header = function (_a) {
    var selectedPhoneNumbers = _a.selectedPhoneNumbers, setSelectedPhoneNumbers = _a.setSelectedPhoneNumbers, phoneNumbers = _a.phoneNumbers, clearFilter = _a.clearFilter, globalFinderValue = _a.globalFinderValue, setGlobalFinderValue = _a.setGlobalFinderValue, setModalVisible = _a.setModalVisible;
    return (_jsxs("div", __assign({ className: "calls-header w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsx("div", __assign({ className: "w-full" }, { children: phoneNumbers.length > 0 ? (_jsx(MultiSelect, { value: selectedPhoneNumbers, onChange: function (event) { return setSelectedPhoneNumbers(event.value); }, options: phoneNumbers, disabled: !phoneNumbers || phoneNumbers.length === 0, placeholder: translater('callsHeaderPhoneNumberPickerPlaceholder').toString(), display: "chip", className: "w-full md:w-20rem" })) : (_jsx(InputText, { disabled: true, placeholder: translater('callsHeaderEmptyPhoneNumberPickerPlaceholder').toString(), className: "w-full md:w-20rem" })) })), _jsxs("div", __assign({ className: 'w-full flex gap-2' }, { children: [_jsx(Button, { className: 'min-w-max', type: "button", icon: "pi pi-filter-slash", label: translater('callsClearButton').toString(), onClick: function () { return clearFilter(); } }), _jsxs("span", __assign({ className: "p-input-icon-left w-full" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: globalFinderValue, onChange: function (event) { return setGlobalFinderValue(event); }, placeholder: translater('callFinderPlaceholder').toString(), className: 'w-full' })] })), _jsx(TooltipRevealer, __assign({ hint: translater("calendarBtn").toString() }, { children: _jsx("div", __assign({ onClick: function () { return setModalVisible(true); }, style: { padding: '0.75rem 1.25rem' }, className: "cursor-pointer flex align-items-center justify-content-center border-1 border-400 text-700 border-round-md" }, { children: _jsx("i", { className: "pi pi-calendar" }) })) }))] }))] })));
};
