var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { FilterMatchMode } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import { translater } from "../../../utils/localization/localization";
import { dynamicColumns } from "./components/columns/columns";
import { Header } from "./components/header/Header";
import { API } from "../../../api";
import { successNotification } from "../../../redux/actions/notificationsActions";
import { UpdateList } from "./components/header/components/update-modal/UpdateList";
import '../.././../assets/scss/pages/lists.scss';
import { localStorageVariables } from "../../../utils/localStorage/variables";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
var defaultLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var Lists = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/lists' }]);
    }, []);
    var _b = __read(useState([]), 2), lists = _b[0], setLists = _b[1];
    var _c = __read(useState(50), 2), limit = _c[0], setLimit = _c[1];
    var _d = __read(useState(0), 2), listsAmount = _d[0], setListsAmount = _d[1];
    var _e = __read(useState([]), 2), selectedLists = _e[0], setSelectedLists = _e[1];
    var _f = __read(useState(false), 2), loading = _f[0], setLoading = _f[1];
    var _g = __read(useState(''), 2), globalFinderValue = _g[0], setGlobalFinderValue = _g[1];
    var _h = __read(useState(false), 2), dialogVisible = _h[0], setDialogVisible = _h[1];
    var _j = __read(useState(null), 2), modifiedList = _j[0], setModifiedList = _j[1];
    var _k = __read(useState(defaultLazyState), 2), lazyState = _k[0], setlazyState = _k[1];
    var defaultVisibleColumns = dynamicColumns
        .filter(function (col) { return ['items', 'name'].includes(col.field || ''); })
        .map(function (col) { return col.field || ''; });
    var _l = __read(useState(defaultVisibleColumns), 2), visibleColumns = _l[0], setVisibleColumns = _l[1];
    var changeVisibleColumns = function (columns) {
        setVisibleColumns(columns);
        localStorage.setItem(localStorageVariables.defaultTableColumns.list, JSON.stringify(columns));
    };
    // Получение имен колонок
    useEffect(function () {
        var columnNames = localStorage.getItem(localStorageVariables.defaultTableColumns.list) || '';
        if (columnNames) {
            setVisibleColumns(JSON.parse(columnNames));
        }
        else {
            localStorage.setItem(localStorageVariables.defaultTableColumns.list, JSON.stringify(defaultVisibleColumns));
        }
    }, []);
    var loadData = function () {
        setLoading(true);
        var query = globalFinderValue.trim();
        API.listsFinder(query, lazyState)
            .then(function (response) {
            var _a = response.data, lists = _a.lists, limit = _a.limit, amount = _a.amount;
            setLimit(limit);
            setLists(lists);
            setListsAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var onFilter = function (event) {
        event['first'] = 0;
        setlazyState(event);
    };
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadData(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    var onPage = function (event) { setlazyState(event); };
    var onChangeGlobalFinderValue = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
    };
    var accept = function (id) {
        API.removeList(id)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadData();
        });
    };
    var confirm = function (row) {
        var title = row.name;
        var id = row._id;
        confirmDialog({
            message: translater("listsPageConfirmDialogMessage").toString()
                + " ".concat(title, " ?"),
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(id); },
            reject: function () { }
        });
    };
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { loadData: loadData, globalFinderValue: globalFinderValue, setGlobalFinderValue: onChangeGlobalFinderValue, selectedLists: selectedLists, clearSelectedLists: function () { return setSelectedLists([]); }, visibleColumns: visibleColumns, setVisibleColumns: changeVisibleColumns, dynamicColumns: dynamicColumns }) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: lists, loading: loading, rows: limit, first: lazyState.first, totalRecords: listsAmount, responsiveLayout: "scroll", scrollable: true, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, dataKey: "_id", style: { minWidth: "200px" } }, { children: [dynamicColumns
                            .filter(function (column) {
                            return visibleColumns.find(function (visibleColumn) { return column.field === visibleColumn; });
                        })
                            .map(function (column) {
                            var header = translater(column.columnHeader);
                            return (_jsx(Column, __assign({ className: "justify-content-center px-5", style: column.style || { minWidth: "150px" }, header: header }, column), column.field));
                        }), _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), style: { maxWidth: "300px" }, body: function (row) {
                                return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(Button, { icon: "pi pi-pencil", className: "p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () {
                                                setDialogVisible(true);
                                                setModifiedList(row);
                                            } }), _jsx(Button, { icon: "pi pi-trash", className: "p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } })] })));
                            } }, 'actions')] })) })), _jsx(UpdateList, { modifiedList: modifiedList, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible, loadData: loadData })] })));
};
