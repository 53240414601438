var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { confirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";
import { removeNodeById, updateTree } from "../../../../../../../redux/actions/builderAction";
import { clone, findParentElementById, replaceElementById } from "../../../../utils/tree";
export var RemoveButton = function (_a) {
    var node = _a.node, setModalSettings = _a.setModalSettings;
    var currentTree = useSelector(function (state) { return state.builder; }).tree;
    var removeNode = function () {
        var parent = findParentElementById(currentTree, node.id);
        // addDeletedBlockId()
        if (node.children.length === 0) {
            // Если у элемента нет children
            if (parent.isChildrenList) {
                var filteredParentChildren = parent.children
                    .filter(function (child) { return child.id !== node.id; });
                parent.children = __spreadArray([], __read(filteredParentChildren), false);
                var tree = replaceElementById(currentTree, parent.id, clone(parent));
                updateTree(clone(tree));
            }
            else {
                return removeNodeById(node.id);
            }
        }
        else {
            // Если у элемента есть children
            if (node.isChildrenList) {
                // Удаляем множественный элемент
                if (parent.isChildrenList) {
                    // Если родитель множесвенный
                    var remove_1 = function (branch) {
                        // Оставить одну из веток
                        if (['Select a branch', 'Stay a branch'].includes(branch)) {
                            // Если ветка только одна, то не спрашивать
                            if (node.children.length === 1) {
                                var _a = __read(node.children, 1), childElement = _a[0];
                                var child = clone(childElement);
                                if (child) {
                                    child.branch = node.branch;
                                    parent.children = __spreadArray(__spreadArray([], __read(parent.children
                                        .filter(function (child) { return child.branch !== node.branch; })
                                        .sort(function (a, b) { return a.branch - b.branch; })), false), [child], false);
                                    var tree = replaceElementById(currentTree, parent.id, parent);
                                    updateTree(clone(tree));
                                }
                            }
                            else {
                                // Если веток несколько, то спросить какую оставить
                                var branches_1 = node.children.map(function (child) { return child.branch; });
                                var selectBranch_1 = function (branch) {
                                    if (branch) {
                                        var child = clone(node.children.find(function (child) { return child.branch === branch; }));
                                        child.branch = node.branch;
                                        parent.children = __spreadArray(__spreadArray([], __read(parent.children
                                            .filter(function (child) { return child.branch !== node.branch; })
                                            .sort(function (a, b) { return a.branch - b.branch; })), false), [child], false);
                                        var tree = replaceElementById(currentTree, parent.id, parent);
                                        updateTree(clone(tree));
                                    }
                                };
                                setTimeout(function () {
                                    setModalSettings({
                                        header: 'Select a branch',
                                        visible: true,
                                        branches: branches_1,
                                        selectedBranches: [],
                                        callback: function (branch) { return selectBranch_1(branch); }
                                    });
                                }, 200);
                            }
                        }
                        // Удаляем элемент со всеми children
                        if (branch === 'Remove with children') {
                            parent.children = __spreadArray([], __read(parent.children
                                .filter(function (child) { return child.branch !== node.branch; })), false);
                            var tree = replaceElementById(currentTree, parent.id, parent);
                            updateTree(clone(tree));
                        }
                    };
                    setModalSettings({
                        header: 'Items deleting',
                        description: 'Please choose the type of items deleting',
                        visible: true,
                        branches: [node.children.length > 1 ? 'Select a branch' : 'Stay a branch', 'Remove with children'],
                        selectedBranches: [],
                        callback: function (branch) { return remove_1(branch); }
                    });
                }
                else {
                    // Если родитель единичный (имеет только одного child)
                    var remove_2 = function (branch) {
                        // Оставить одну из веток
                        if (['Select a branch', 'Stay a branch'].includes(branch)) {
                            // Если ветка только одна, то не спрашивать
                            if (node.children.length === 1) {
                                var _a = __read(node.children, 1), childElement = _a[0];
                                var child = clone(childElement);
                                if (child) {
                                    delete child.branch;
                                    parent.children = [child];
                                    var tree = replaceElementById(currentTree, parent.id, parent);
                                    updateTree(clone(tree));
                                }
                            }
                            else {
                                // Если веток несколько, то спросить какую оставить
                                var branches_2 = node.children.map(function (child) { return child.branch; });
                                var selectBranch_2 = function (branch) {
                                    if (branch) {
                                        var child = clone(node.children.find(function (child) { return child.branch === branch; }));
                                        delete child.branch;
                                        parent.children = [child];
                                        var tree = replaceElementById(currentTree, parent.id, parent);
                                        updateTree(clone(tree));
                                    }
                                };
                                setTimeout(function () {
                                    setModalSettings({
                                        header: 'Select a branch',
                                        visible: true,
                                        branches: branches_2,
                                        selectedBranches: [],
                                        callback: function (branch) { return selectBranch_2(branch); }
                                    });
                                }, 200);
                            }
                        }
                        // Удаляем элемент со всеми children
                        if (branch === 'Remove with children') {
                            parent.children = [];
                            var tree = replaceElementById(currentTree, parent.id, parent);
                            updateTree(clone(tree));
                        }
                    };
                    setModalSettings({
                        header: 'Items deleting',
                        description: 'Please choose the type of items deleting',
                        visible: true,
                        branches: [node.children.length > 1 ? 'Select a branch' : 'Stay a branch', 'Remove with children'],
                        selectedBranches: [],
                        callback: function (branch) { return remove_2(branch); }
                    });
                }
            }
            else {
                // Удаляем единичный элемент
                if (parent.isChildrenList) {
                    // Случай, когда родитель множественный
                    var remove_3 = function (branch) {
                        var _a = __read(node.children, 1), childElement = _a[0];
                        var child = clone(childElement);
                        if (child) {
                            // Удаляем только элемент
                            if (branch === 'Remove only one') {
                                child.branch = node.branch;
                                var tree = replaceElementById(currentTree, node.id, child);
                                updateTree(clone(tree));
                            }
                            // Удаляем элемент со всеми children
                            if (branch === 'Remove with children') {
                                parent.children = __spreadArray([], __read(parent.children
                                    .filter(function (child) { return child.branch !== node.branch; })), false);
                                var tree = replaceElementById(currentTree, parent.id, parent);
                                updateTree(clone(tree));
                            }
                        }
                    };
                    setModalSettings({
                        header: 'Items deleting',
                        description: 'Please choose the type of items deleting',
                        visible: true,
                        branches: ['Remove only one', 'Remove with children'],
                        selectedBranches: [],
                        callback: function (branch) { return remove_3(branch); }
                    });
                }
                else {
                    // Случай, когда родитель единичный (имеет только одного child)
                    var remove_4 = function (branch) {
                        var _a = __read(node.children, 1), childElement = _a[0];
                        var child = clone(childElement);
                        if (child) {
                            // Удаляем только элемент
                            if (branch === 'Remove only one') {
                                var tree = replaceElementById(currentTree, node.id, child);
                                updateTree(clone(tree));
                            }
                            // Удаляем элемент со всеми children
                            if (branch === 'Remove with children') {
                                parent.children = [];
                                var tree = replaceElementById(currentTree, parent.id, parent);
                                updateTree(clone(tree));
                            }
                        }
                    };
                    setModalSettings({
                        header: 'Items deleting',
                        description: 'Please choose the type of items deleting',
                        visible: true,
                        branches: ['Remove only one', 'Remove with children'],
                        selectedBranches: [],
                        callback: function (branch) { return remove_4(branch); }
                    });
                }
            }
        }
    };
    var confirm = function (event) {
        event.preventDefault();
        event.stopPropagation();
        confirmDialog({
            message: "Are you sure you want to delete the element?",
            header: node.data.label,
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return removeNode(); },
            reject: function () { }
        });
    };
    return (_jsx("i", { onClick: function (event) { return confirm(event); }, className: clsx("pi pi-trash", "node-button"), style: { fontSize: '0.6rem', color: '#787878' } }));
};
