export function showCustomOverlay() {
  // Поверх экрана рисуем невидимую область
  const overlay = document.createElement('div')
  overlay.id = 'js-overlay'
  overlay.style.position = 'fixed'
  overlay.style.top = '0'
  overlay.style.left = '0'
  overlay.style.width = '100vw'
  overlay.style.height = '100vh'
  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.1)'
  overlay.style.display = 'flex'
  overlay.style.alignItems = 'center'
  overlay.style.justifyContent = 'center'
  overlay.style.color = 'white'
  overlay.style.fontSize = '2em'
  overlay.style.zIndex = '10002'
  document.body.appendChild(overlay)

  // Выключаем сообщение о потери сети
  document.querySelector('.custom-notification').classList.add('hidden-mode')
}

export function hideCustomOverlay() {
  const overlay = document.getElementById('js-overlay')
  if (overlay) overlay.remove()

  // Выключаем сообщение о потери сети
  document.querySelector('.custom-notification').classList.remove('hidden-mode')
}