var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { ConfirmModal } from "./components/ConfirmModal";
import { translater } from "../../../../../../../../utils/localization/localization";
export var ImportADButton = function (_a) {
    var loadData = _a.loadData;
    var _b = __read(useState(false), 2), modalVisible = _b[0], setModalVisible = _b[1];
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-upload", label: translater("usersPageImportButton").toString(), className: "w-full bg-white border-green-600 text-green-600", onClick: function () { return setModalVisible(true); } }), _jsx(ConfirmModal, { updSettings: function () { }, loadData: loadData, hideModal: function () { return setModalVisible(false); }, visible: modalVisible })] })));
};
