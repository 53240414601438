var template = function (_a) {
    var description = _a.description;
    var strLength = 150;
    return description.length > strLength
        ? description.slice(0, strLength) + '...'
        : description;
};
export var descriptionColumn = {
    columnHeader: 'groupsPageDescriptionColumn',
    field: 'description',
    style: {
        minWidth: "370px",
        textAlign: "justify"
    },
    body: template,
};
