var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { localStorageVariables } from "../../utils/localStorage/variables";
var defaultSidebarTabs = {
    isSettingsOpen: !false,
    isSystemSettingsOpen: false,
    isPbxSettingsOpen: false,
    isUserSettingsOpen: false,
    isSecuritySettingsOpen: false,
};
var initialState = {
    lastVisitUrl: '',
    sidebarVisible: true,
    headerVisible: true,
    applyLogsVisible: false,
    companyName: '',
    appVersion: '',
    breadCrumbs: [],
    homeData: {
        phones: [],
        mainPhone: null,
        data: null,
        userInfo: {
            // missingCallsExistence: false,
            messagesAmount: 0,
            faxAmount: 0
        }
    },
    sidebarItems: [
        {
            order: 1,
            name: 'sideBarCalls',
            path: "/calls",
            classes: "pi pi-phone",
            access: ['Administrator', 'Security', 'Supervisor', 'User']
        },
        // {
        //   order: 88,
        //   name: 'currentCallsSideBar',
        //   path: "/current-calls",
        //   classes: "pi pi-book",
        //   access: ['Supervisor']
        // },
        // {
        //   order: 90,
        //   name: 'instructionSideBar',
        //   path: "/instruction",
        //   classes: "pi pi-desktop",
        //   access: ['Administrator', 'Security', 'Supervisor', 'User']
        // },
    ],
    adminSettingsItems: [
    // {
    //   order: 16,
    //   name: 'queuesSideBar',
    //   path: "/queues",
    //   classes: "pi pi-share-alt",
    //   access: ['Administrator']
    // },
    // {
    //   order: 17,
    //   name: 'currentCallsSideBar',
    //   path: "/current-calls",
    //   classes: "pi pi-book",
    //   access: ['Administrator']
    // },
    ],
    systemSettingsItems: [
        {
            order: 100,
            name: 'settingsPageApplicationInfoHeader',
            path: "/settings/application-info",
            classes: "pi pi-info-circle",
            access: ['Administrator']
        },
        {
            order: 101,
            name: 'settingsPageCompanyNameHeader',
            path: "/settings/company-name",
            classes: "pi pi-star",
            access: ['Administrator']
        },
        {
            order: 102,
            name: 'settingsPageActiveDirectoryHeader',
            path: "/settings/active-directory",
            classes: "pi pi-users",
            access: ['Administrator']
        },
        // {
        //   order: 103,
        //   name: 'settingsPageEncryptionKeyHeader',
        //   path: "/settings/encryption-key",
        //   classes: "pi pi-key",
        //   access: ['Administrator']
        // },
        {
            order: 104,
            name: 'settingsPageCDRHeader',
            path: "/settings/cdr",
            classes: "pi pi-phone",
            access: ['Administrator']
        },
        {
            order: 105,
            name: 'settingsPageAppDataHeader',
            path: "/settings/database",
            classes: "pi pi-database",
            access: ['Administrator']
        },
        {
            order: 106,
            name: 'groupsSideBar',
            path: "/groups",
            classes: "pi pi-users",
            access: ['Administrator']
        },
        {
            order: 107,
            name: 'usersSideBar',
            path: "/users",
            classes: "pi pi-user",
            access: ['Administrator']
        },
        {
            order: 108,
            name: 'stationsSideBar',
            path: "/stations",
            classes: "pi pi-desktop",
            access: ['Administrator']
        },
        {
            order: 109,
            name: 'listsSideBar',
            path: "/lists",
            classes: "pi pi-list",
            access: ['Administrator']
        },
        {
            order: 110,
            name: 'interpriseSupportSideBar',
            path: "/interprise-support",
            classes: "pi pi-globe",
            access: ['Administrator']
        },
        {
            order: 111,
            name: 'audioSideBar',
            path: "/audio",
            classes: "pi pi-volume-off",
            access: ['Administrator']
        },
        {
            order: 112,
            name: 'AWSSideBar',
            path: "/automated-notification-system",
            classes: "pi pi-bell",
            access: ['Administrator']
        },
        // {
        //   order: 110,
        //   name: 'certificatesSideBar',
        //   path: "/certificates",
        //   classes: "pi pi-shield",
        //   access: ['Administrator']
        // },
    ],
    pbxSettingsItems: [
        {
            order: 200,
            name: 'rulesSideBar',
            path: "/rules",
            classes: "pi pi-chart-bar",
            access: ['Administrator']
        },
        {
            order: 201,
            name: 'contextsSideBar',
            path: "/contexts",
            classes: "pi pi-sitemap",
            access: ['Administrator']
        },
        {
            order: 202,
            name: 'templatesSideBar',
            path: "/templates",
            classes: "pi pi-box",
            access: ['Administrator']
        },
        {
            order: 203,
            name: 'extensionsSideBar',
            path: "/extensions",
            classes: "pi pi-credit-card",
            access: ['Administrator']
        },
        {
            order: 204,
            name: 'trunksSideBar',
            path: "/trunks",
            classes: "pi pi-eject",
            access: ['Administrator']
        },
        {
            order: 205,
            name: 'redirectsSideBar',
            path: "/redirects",
            classes: "pi pi-history",
            access: ['Administrator']
        },
        {
            order: 206,
            name: 'filesSideBar',
            path: "/pbx-configuration",
            classes: "pi pi-book",
            access: ['Administrator']
        },
        {
            order: 207,
            name: 'queuesSideBar',
            path: "/queues",
            classes: "pi pi-share-alt",
            access: ['Administrator']
        },
    ],
    securitySettingsItems: [
        {
            order: 301,
            name: 'certificatesSideBar',
            path: "/certificates",
            classes: "pi pi-shield",
            access: ['Administrator']
        },
    ],
    userSettingsItems: [
        {
            order: 4,
            name: 'redirectsSideBar',
            path: "/redirects",
            classes: "pi pi-history",
            access: ['Supervisor', 'User']
        },
        // {
        //   order: 100,
        //   name: 'languagesSideBar',
        //   path: "/settings",
        //   classes: "pi pi-comments",
        //   access: ['Administrator', 'Security', 'Supervisor', 'User']
        // },
    ],
    sidebarTabs: defaultSidebarTabs
};
// Устанавливаем sidebar tabs по умолчанию
var tabsJSON = localStorage.getItem(localStorageVariables.sidebarTabs) || '';
if (tabsJSON) {
    var tabs = JSON.parse(tabsJSON);
    tabs.isSettingsOpen = true;
    initialState.sidebarTabs = tabs;
}
else
    localStorage.setItem(localStorageVariables.sidebarTabs, JSON.stringify(defaultSidebarTabs));
var lastPath = localStorage.getItem(localStorageVariables.lastVisitUrl);
if (lastPath)
    initialState.lastVisitUrl = lastPath;
else
    localStorage.setItem(localStorageVariables.lastVisitUrl, JSON.stringify(initialState.lastVisitUrl));
var applyVisible = localStorage.getItem(localStorageVariables.app.applyLogsVisible);
initialState.applyLogsVisible = Boolean(applyVisible === 'true');
export var SHOW_SIDEBAR = "SHOW_SIDEBAR";
export var HIDE_SIDEBAR = "HIDE_SIDEBAR";
export var SHOW_HEADER = "SHOW_HEADER";
export var HIDE_HEADER = "HIDE_HEADER";
export var SET_HOME_DATA = "SET_HOME_DATA";
export var SET_SIDEBAR_TABS = "SET_SIDEBAR_TABS";
export var SET_LAST_VISIT_URL = 'SET_LAST_VISIT_URL';
export var SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export var SET_APPLICATION_VERSION = 'SET_APPLICATION_VERSION';
export var SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export var SET_APPLY_LOGS_VISIBLE = 'SET_APPLY_LOGS_VISIBLE';
export var appReducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_COMPANY_NAME:
            return __assign(__assign({}, state), { companyName: action.payload });
        case SET_APPLICATION_VERSION:
            return __assign(__assign({}, state), { appVersion: action.payload });
        case SET_APPLY_LOGS_VISIBLE:
            return __assign(__assign({}, state), { applyLogsVisible: action.payload });
        case SET_LAST_VISIT_URL:
            return __assign(__assign({}, state), { lastVisitUrl: action.payload });
        case SET_BREADCRUMBS:
            state.breadCrumbs.length = 0;
            (_a = state.breadCrumbs).push.apply(_a, __spreadArray([], __read(action.payload), false));
            return __assign({}, state);
        case SHOW_SIDEBAR:
            return __assign(__assign({}, state), { sidebarVisible: true });
        case HIDE_SIDEBAR:
            return __assign(__assign({}, state), { sidebarVisible: false });
        case SHOW_HEADER:
            return __assign(__assign({}, state), { headerVisible: true });
        case SET_SIDEBAR_TABS:
            return __assign(__assign({}, state), { sidebarTabs: action.payload });
        case SET_HOME_DATA:
            return __assign(__assign({}, state), { homeData: action.payload });
        case HIDE_HEADER:
            return __assign(__assign({}, state), { headerVisible: false });
        default:
            return state;
    }
};
