import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { audioPlayerReducer } from './audioPlayerReducer';
import { authReducer } from './authReducer';
import { languagesReducer } from './languageReducer';
import { notificationsReducer } from './notificationsReducer';
import { themesReducer } from './themeReducer';
import { builderReducer } from './builderReducer';
import { editorReducer } from './editorReducer';
export var rootReducer = combineReducers({
    language: languagesReducer,
    theme: themesReducer,
    notifications: notificationsReducer,
    auth: authReducer,
    audioPlayer: audioPlayerReducer,
    builder: builderReducer,
    app: appReducer,
    editor: editorReducer
});
