var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from "primereact/divider";
import { Message } from "primereact/message";
import { Skeleton } from "primereact/skeleton";
import { translater } from "../../../../../utils/localization/localization";
import { infoNotification } from "../../../../../redux/actions/notificationsActions";
import { ApplicationAutoUpdate } from "./components/ApplicationAutoUpdate";
import { PagesHidder } from "./components/pages-hidder/PagesHidder";
export var Information = function (_a) {
    var settings = _a.settings;
    var copyToClipboard = function (content) {
        navigator.clipboard.writeText(content);
        infoNotification(translater("successfullyCopied").toString());
    };
    return (_jsxs("div", __assign({ className: "flex flex-column gap-2 w-full" }, { children: [_jsxs("div", __assign({ className: "w-full card  flex flex-column align-items-start gap-2 surface-card shadow-2 border-round p-3" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: translater('settingsPageApplicationInfoHeader') })), _jsx(Divider, { className: "my-1" }), _jsx("label", { children: translater('settingsPageApplicationInfoVersionLabel') }), settings.version ? (_jsx(Message, { onClick: function () { return copyToClipboard(settings.version || ''); }, severity: "warn", text: settings.version, className: "app-version-settings-info cursor-pointer", icon: null })) : (_jsx(Skeleton, { width: "10rem", height: "3rem" })), _jsx("label", __assign({ className: "mt-2" }, { children: translater('settingsPageApplicationInfoDeveloperLabel') })), settings.version ? (_jsx(Message, { onClick: function () { return copyToClipboard(settings.developer || ''); }, severity: "info", text: settings.developer, className: "app-version-settings-info cursor-pointer", icon: null })) : (_jsx(Skeleton, { width: "10rem", height: "3rem" })), _jsx(ApplicationAutoUpdate, {})] })), _jsxs("div", __assign({ className: "w-full card  flex flex-column align-items-start gap-2 surface-card shadow-2 border-round p-3" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: translater('settingsPageApplicationInfoHeader') })), _jsx(Divider, { className: "my-1" }), _jsx(PagesHidder, {})] }))] })));
};
