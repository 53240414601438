var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { translater } from "../../../../utils/localization/localization";
export var Preview = function (_a) {
    var showCreateInterface = _a.showCreateInterface, selectedPhone = _a.selectedPhone;
    return (_jsx("div", __assign({ className: "card-position-center h-20rem surface-card p-4 shadow-2 border-round flex align-items-center justify-content-center" }, { children: _jsxs("div", __assign({ className: 'max-w-20rem h-12rem border-dashed border-400 border-round p-5 flex flex-column align-items-center justify-content-center gap-2' }, { children: [_jsx(Button, { className: 'p-button-outlined text-green-600 border-green-600', onClick: showCreateInterface, label: translater('redirectsAddRedirectButton').toString() }), _jsxs("span", __assign({ className: 'text-center' }, { children: [translater('redirectsAddRedirectMessage'), _jsx("br", {}), _jsx("b", { children: selectedPhone })] }))] })) })));
};
