var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { translate } from "../FormGenerator";
import { useController } from "react-hook-form";
import { RequiredFieldDesignation } from "../utils/RequiredFieldDesignation";
import { translater } from "../../../../../utils/localization/localization";
import { ErrorBlock } from "../../error/ErrorBlock";
import { Hint } from "../utils/Hint";
import { RadioButton } from "primereact/radiobutton";
import { API } from "../../../../../api";
import { Preloader } from "../../preloader/Preloader";
export var Radio = function (_a) {
    var _b = _a.form, control = _b.control, register = _b.register, setValue = _b.setValue, trigger = _b.trigger, getValues = _b.getValues, errors = _b.formState.errors, _c = _a.field, titleTranslaterKey = _c.titleTranslaterKey, fieldName = _c.field, placeholderTranslaterKey = _c.placeholderTranslaterKey, _d = _c.apiDataUrl, apiDataUrl = _d === void 0 ? 'no-key' : _d, required = _c.required, staticList = _c.staticList, hintTranslaterKey = _c.hintTranslaterKey;
    var ref = useRef(null);
    var _e = __read(useState([]), 2), list = _e[0], setList = _e[1];
    var _f = __read(useState(false), 2), loading = _f[0], setLoading = _f[1];
    var settings = {
        control: control,
        name: fieldName,
        rules: {}
    };
    var rules = {};
    if (required)
        rules.required = translater("Form Generator Required Field").toString();
    var field = useController(settings).field;
    var params = {};
    if (placeholderTranslaterKey)
        params.placeholder = translate(placeholderTranslaterKey).toString();
    var loadList = function () {
        setLoading(true);
        API.getServerData(apiDataUrl)
            .then(function (response) {
            var data = response.data;
            setList(data);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        if (apiDataUrl !== 'no-key')
            loadList();
        if (staticList)
            setList(staticList);
    }, []);
    var changeItem = function (event, item) {
        setValue(fieldName, item);
        setTimeout(function () { trigger(fieldName); }, 0);
    };
    return (_jsxs("div", __assign({ className: "w-full flex flex-column align-items-start gap-2" }, { children: [_jsxs("div", __assign({ className: "w-full flex flex-column gap-1" }, { children: [_jsxs("span", __assign({ className: "mb-1" }, { children: [hintTranslaterKey && (_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "text-primary p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" })), translate(titleTranslaterKey), required && _jsx(RequiredFieldDesignation, {})] })), loading
                        ? _jsx(Preloader, {})
                        : (_jsx("div", __assign({ className: "flex flex-wrap gap-4" }, { children: list.map(function (item) {
                                return (_jsxs("div", __assign({ className: "flex gap-1" }, { children: [_jsx(RadioButton, __assign({}, params, { inputId: "".concat(fieldName, "_").concat(item.keyword), value: item.content, className: "w-full", name: fieldName, onChange: function (event) { return changeItem(event, item); }, checked: field.value.content === item.content })), _jsx("label", __assign({ htmlFor: "".concat(fieldName, "_").concat(item.keyword) }, { children: translater(item.keyword) || item.keyword }))] }), item.keyword));
                            }) })))] })), _jsx(ErrorBlock, { errors: errors, keyword: fieldName }), _jsx(Hint, { hint: hintTranslaterKey, reference: ref })] })));
};
