var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { hideForm, setFormData, setFormLoaderMode, updateTree } from "../../../../../../redux/actions/builderAction";
import { FormGenerator } from "../../../../../components/particals/form-generator/FormGenerator";
import { Preloader } from "../../../../../components/particals/preloader/Preloader";
import { findTreeElementById, replaceElementById } from "../../../utils/tree";
import { translater } from "../../../../../../utils/localization/localization";
export var Form = function () {
    var _a = useSelector(function (state) { return state.builder; }), formVisible = _a.formVisible, formData = _a.formData, formLoader = _a.formLoader, currentTree = _a.tree;
    var sendData = function (_a) {
        var data = _a.data;
        delete data['undefined'];
        var id = formData.nodeId;
        var node = findTreeElementById(currentTree, id);
        node.settings = data;
        // Обновляем настройки блока в дереве
        var tree = replaceElementById(currentTree, id, node);
        updateTree(tree);
        hideForm();
        setFormLoaderMode(false);
        setFormData(null);
    };
    var defaultFormValues = formData === null || formData === void 0 ? void 0 : formData.settings;
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx(Dialog, __assign({ visible: formVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", contentStyle: { minWidth: '550px' }, onHide: function () { return hideForm(); } }, { children: formLoader
                ? (_jsxs("div", __assign({ className: "flex flex-column gap-3" }, { children: [_jsx(Preloader, {}), _jsx(Preloader, {}), _jsx(Preloader, {}), _jsx(Preloader, {})] }))) : (_jsx("div", __assign({ className: "surface-card mt-2 p-4 shadow-2 border-round w-full" }, { children: formData
                    ? (_jsx(FormGenerator, { defaultFormValues: defaultFormValues, onSend: sendData, json: JSON.stringify(formData) }))
                    : (_jsx("span", __assign({ className: 'block text-center' }, { children: translater("formDialogNoData") }))) }))) })) })));
};
