var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import clsx from "clsx";
import { images } from "../../../../../../assets/images";
import { translater } from "../../../../../../utils/localization/localization";
import { TooltipRevealer } from "../../../../../components/particals/tooltip/TooltipRevealer";
import { translate } from "../../../../../components/particals/form-generator/FormGenerator";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
var typeCalls = ['incoming', 'outcoming', 'missing', 'unanswered', 'viewed'];
var typeItemTemplate = function (option) {
    return (_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("img", { className: "type-calls", src: images.pages.calls[option], style: { height: 'auto', maxWidth: '30px' }, alt: 'DRF' }), _jsx("span", { children: translater(option) })] })));
};
var typeFilterTemplate = function (options) {
    return (_jsx(MultiSelect, { value: options.value, options: typeCalls, itemTemplate: typeItemTemplate, onChange: function (e) { return options.filterCallback(e.value); }, placeholder: translater("callsPageSelectType").toString(), className: "p-column-filter w-16rem" }));
};
var typeBodyTemplate = function (setRecords) { return function (_a) {
    var type = _a.type, id = _a.id;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var changeType = function (newType) {
        if (!loading) {
            setLoading(true);
            API.changeCallType(id)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                setRecords(function (prevAudioObjects) {
                    var data = JSON.parse(JSON.stringify(prevAudioObjects));
                    return data.map(function (obj) {
                        if (obj.id === id)
                            obj.type = newType;
                        return obj;
                    });
                });
            })
                .finally(function () { return setLoading(false); })
                .catch(function () { return setLoading(false); });
        }
    };
    return (_jsxs("div", __assign({ className: "relative" }, { children: [_jsx("img", { className: "type-calls", src: images.pages.calls[type], style: { height: 'auto', maxWidth: '30px' }, alt: 'DRF' }), type === 'missing' ? (_jsx(TooltipRevealer, __assign({ className: "absolute calls-missing-watch-icon", hint: translate('viewBtn').toString() }, { children: _jsx("i", { className: clsx("pi pi-eye", {
                        'opacity-50 cursor-default': loading,
                    }), onClick: function () { return changeType('viewed'); } }) }))) : '', type === 'viewed' ? (_jsx(TooltipRevealer, __assign({ className: "absolute calls-missing-watch-icon", hint: translate('cancelViewBtn').toString() }, { children: _jsx("i", { className: clsx("pi pi-eye-slash", {
                        'opacity-50 cursor-default': loading,
                    }), onClick: function () { return changeType('missing'); } }) }))) : ''] })));
}; };
export var typeColumn = function (setRecords) { return ({
    columnHeader: 'callsType',
    field: 'type',
    filter: true,
    filterElement: typeFilterTemplate,
    showFilterMatchModes: false,
    style: { maxWidth: "120px" },
    body: typeBodyTemplate(setRecords)
}); };
