var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { translater } from "../../../../utils/localization/localization";
export var LoginByPassword = function (_a) {
    var register = _a.register;
    var _b = __read(useState(false), 2), hintVisible = _b[0], setHintVisible = _b[1];
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex align-items-center gap-2 mb-2" }, { children: [_jsx("label", __assign({ htmlFor: "email", className: "block text-900 font-medium" }, { children: translater("login") })), _jsx("i", { onClick: function () { return setHintVisible(true); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer" })] })), _jsx(InputText, __assign({ id: "email", type: "text" }, register("login"), { placeholder: "".concat(translater("loginEnterData"), " Username"), className: "w-full mb-3" })), _jsx("label", __assign({ htmlFor: "password", className: "block text-900 font-medium mb-2" }, { children: translater("password") })), _jsx(InputText, __assign({ id: "password", type: "password" }, register("password"), { placeholder: "".concat(translater("loginEnterData"), " ").concat(translater("password")), className: "w-full mb-3" })), _jsxs(Dialog, __assign({ header: translater("loginDataTypes"), visible: hintVisible, resizable: false, onHide: function () { return setHintVisible(false); }, style: { width: '50vw' }, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: [_jsxs("p", __assign({ className: "m-0" }, { children: [" ", translater("loginADHint"), " "] })), _jsx("br", {}), _jsxs("p", __assign({ className: "m-0" }, { children: [" ", translater("loginHint"), " "] }))] }))] }));
};
