var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 as uuidv4 } from 'uuid';
var TREE_ELEMENTS_HISTORY_LIMIT = 15;
var defaultTree = {
    id: uuidv4(),
    data: { label: 'Root' },
    isChildrenList: false,
    canHaveChildren: true,
    children: [],
};
var initialState = {
    formVisible: false,
    formData: null,
    formLoader: false,
    minimapVisible: false,
    isDragging: false,
    isMoving: false,
    movingNode: null,
    tree: defaultTree,
    treeHistory: [],
    marginY: 40,
    marginX: 190,
    blocks: []
};
export var SHOW_FORM = "SHOW_FORM";
export var HIDE_FORM = "HIDE_FORM";
export var SHOW_MINIMAP = "SHOW_MINIMAP";
export var HIDE_MINIMAP = "HIDE_MINIMAP";
export var UPDATE_TREE = "UPDATE_TREE";
export var SET_DRAGGING_MODE = "SET_DRAGGING_MODE";
export var SET_MOVING_MODE = "SET_MOVING_MODE";
export var SET_MOVING_NODE = "SET_MOVING_NODE";
export var ADD_TREE_HISTORY = "ADD_TREE_HISTORY";
export var RETURN_PREVIOUS_TREE = "RETURN_PREVIOUS_TREE";
export var SET_TREE_BLOCKS = "SET_TREE_BLOCKS";
export var SET_FORM_LOADER = "SET_FORM_LOADER";
export var SET_FORM_DATA = "SET_FORM_DATA";
export var CLEAN_TREE_HISTORY = "CLEAN_TREE_HISTORY";
export var builderReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SHOW_FORM:
            return __assign(__assign({}, state), { formVisible: true });
        case HIDE_FORM:
            return __assign(__assign({}, state), { formVisible: false });
        case SHOW_MINIMAP:
            return __assign(__assign({}, state), { minimapVisible: true });
        case SET_FORM_DATA:
            return __assign(__assign({}, state), { formData: action.payload });
        case SET_FORM_LOADER:
            return __assign(__assign({}, state), { formLoader: action.payload });
        case HIDE_MINIMAP:
            return __assign(__assign({}, state), { minimapVisible: false });
        case UPDATE_TREE:
            return __assign(__assign({}, state), { tree: action.payload });
        case SET_TREE_BLOCKS:
            return __assign(__assign({}, state), { blocks: action.payload });
        case SET_DRAGGING_MODE:
            return __assign(__assign({}, state), { isDragging: action.payload });
        case SET_MOVING_MODE:
            return __assign(__assign({}, state), { isMoving: action.payload });
        case SET_MOVING_NODE:
            return __assign(__assign({}, state), { movingNode: action.payload });
        case ADD_TREE_HISTORY:
            var updatedTreeHistory = __spreadArray(__spreadArray([], __read(state.treeHistory), false), [action.payload], false);
            // Проверяем длину массива treeHistory
            if (updatedTreeHistory.length > TREE_ELEMENTS_HISTORY_LIMIT) {
                updatedTreeHistory = updatedTreeHistory.slice(1);
            }
            return __assign(__assign({}, state), { treeHistory: updatedTreeHistory });
        case RETURN_PREVIOUS_TREE:
            return __assign(__assign({}, state), { treeHistory: __spreadArray([], __read((state.treeHistory.slice(0, state.treeHistory.length - 1))), false), tree: action.payload });
        case CLEAN_TREE_HISTORY:
            return __assign(__assign({}, state), { treeHistory: [] });
        default:
            return state;
    }
};
