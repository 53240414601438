var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { classNames } from "primereact/utils";
import { translater } from "../../../../../utils/localization/localization";
import { TooltipRevealer } from "../../tooltip/TooltipRevealer";
import { EditorSettingsDialog } from "../../../../pages/editor/components/header/components/second-header/EditorSettingsDialog";
export var FontSizeArea = function (_a) {
    var _b = _a.styling, styling = _b === void 0 ? '' : _b, _c = _a.hiddenFields, hiddenFields = _c === void 0 ? [] : _c;
    var _d = __read(useState(false), 2), visible = _d[0], setVisible = _d[1];
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex" }, { children: _jsx(TooltipRevealer, __assign({ hint: translater("editorSettingsButtonTooltip").toString() }, { children: _jsx("i", { onClick: function () { return setVisible(true); }, className: classNames("pi pi-cog cursor-pointer ".concat(styling)) }) })) })), _jsx(EditorSettingsDialog, { hiddenFields: hiddenFields, dialogVisible: visible, setDialogVisible: setVisible })] }));
};
