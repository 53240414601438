var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { store } from '../../../../../../../redux/store';
import { API } from '../../../../../../../api';
export var DialplanButton = function () {
    var _a = __read(useState(''), 2), dialplan = _a[0], setDiaplan = _a[1];
    var _b = __read(useState(false), 2), modalVisible = _b[0], setModalVisible = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var getDialplan = function () {
        setLoading(true);
        var tree = store.getState().builder.tree;
        API.prepareDialplan({ form: JSON.stringify(tree) })
            .then(function (response) {
            var dialplan = response.data.dialplan;
            setModalVisible(true);
            setDiaplan(dialplan);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return getDialplan(); }, disabled: loading, className: 'p-button p-component p-button-outlined p-button-secondary', label: "Dialplan" }), _jsx(Dialog, __assign({ visible: modalVisible, onHide: function () { return setModalVisible(false); }, style: { width: '50vw' }, header: "Dialplan (demo)", resizable: false, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: _jsx("pre", { children: dialplan }) }))] }));
};
