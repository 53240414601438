var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from 'primereact/button';
import clsx from 'clsx';
import { Tooltip } from 'primereact/tooltip';
import { hideHeader, hideSidebar, showHeader, showSidebar } from '../../../../../../../redux/actions/appActions';
import { translater } from '../../../../../../../utils/localization/localization';
export var FullScreenToggle = function () {
    var _a = __read(useState(false), 2), fullScreenMode = _a[0], setFullScreenMode = _a[1];
    var toggle = function () {
        if (fullScreenMode) {
            showSidebar();
            showHeader();
        }
        else {
            hideSidebar();
            hideHeader();
        }
        setFullScreenMode(!fullScreenMode);
    };
    return (_jsx("div", __assign({ className: "min-w-max" }, { children: _jsxs("span", __assign({ "data-pr-tooltip": fullScreenMode ? translater("collapseButton") : translater("expandButton"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-full-screen" }, { children: [_jsx(Button, { onClick: function () { return toggle(); }, className: clsx('p-button-outlined p-button-icon-only p-button-secondary'), icon: clsx('pi', {
                        'pi-window-maximize': !fullScreenMode,
                        'pi-window-minimize': fullScreenMode
                    }) }), _jsx(Tooltip, { target: ".custom-target-icon-full-screen" })] })) })));
};
