var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Preloader } from "../../../components/particals/preloader/Preloader";
export var RedirectsPreloader = function () {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'flex flex-column align-items-start gap-3' }, { children: [_jsx("div", __assign({ className: "w-full flex gap-4 w-full" }, { children: _jsx(Preloader, { height: "100px" }) })), _jsx("div", __assign({ className: "w-full flex gap-4 w-full" }, { children: _jsx(Preloader, { height: "350px" }) }))] })) }));
};
