var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../../utils/localization/localization";
import { CreateContextButton } from "./components/CreateContextButton";
export var Header = function (_a) {
    var globalFinderValue = _a.globalFinderValue, setGlobalFinderValue = _a.setGlobalFinderValue, loadData = _a.loadData;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(CreateContextButton, { loadData: loadData }) })), _jsxs("span", __assign({ className: "w-full p-input-icon-left" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: globalFinderValue, onChange: function (event) { return setGlobalFinderValue(event); }, className: "w-full", placeholder: translater('contextPageSearchPlaceholder').toString() })] }))] })) }));
};
