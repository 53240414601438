var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translater } from "../../../../utils/localization/localization";
export var NoData = function () {
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "surface-section p-3 shadow-2 border-round flex gap-3 align-items-center" }, { children: _jsxs("div", __assign({ className: "flex flex-column align-items-center justify-content-center gap-2 mx-auto" }, { children: [_jsx("div", __assign({ className: "w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle flex-shrink-0" }, { children: _jsx("i", { className: "pi pi-info text-xl text-orange-500" }) })), _jsx("span", __assign({ className: "text-700 text-center font-normal" }, { children: translater("editorPageNoDataContent") }))] })) })) }));
};
