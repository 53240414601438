var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { hideAudioPlayer } from "../../../../../redux/actions/audioPlayerActions";
export var InfoAudio = function (_a) {
    var title = _a.title;
    return (_jsxs("div", __assign({ className: "info-audio" }, { children: [_jsx("span", { children: title }), _jsx("button", __assign({ className: "close-audio p-0 flex align-items-center", onClick: hideAudioPlayer }, { children: _jsx("i", { className: "pi pi-times text-sm" }) }))] })));
};
