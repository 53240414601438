var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import { Tooltip } from "primereact/tooltip";
import { translater } from "../../../utils/localization/localization";
import { API } from "../../../api";
import "../.././../assets/scss/pages/lists.scss";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { dynamicColumns } from "./columns/columns";
import { Header } from "./header/Header";
import { successNotification } from "../../../redux/actions/notificationsActions";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
import { EntityDependencyErrorArea } from "../../components/particals/error/EntityDependencyErrorArea";
var defaultLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN },
    },
};
export var Stations = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    var navigate = useNavigate();
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/stations' }]);
    }, []);
    var _b = __read(useState([]), 2), stations = _b[0], setStations = _b[1];
    var _c = __read(useState(50), 2), limit = _c[0], setLimit = _c[1];
    var _d = __read(useState(0), 2), stationsAmount = _d[0], setStationsAmount = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(useState(""), 2), globalFinderValue = _f[0], setGlobalFinderValue = _f[1];
    var _g = __read(useState(defaultLazyState), 2), lazyState = _g[0], setlazyState = _g[1];
    var _h = __read(useState([]), 2), stationColumnNames = _h[0], setStationColumnNames = _h[1];
    var _j = __read(useState(false), 2), modalLoading = _j[0], setModalLoading = _j[1];
    var _k = __read(useState(false), 2), modalVisible = _k[0], setModalVisible = _k[1];
    var _l = __read(useState(''), 2), sshResult = _l[0], setSshResult = _l[1];
    var _m = __read(useState(null), 2), stationData = _m[0], setStationData = _m[1];
    var _o = __read(useState([]), 2), dependencyErrors = _o[0], setDependencyErrors = _o[1];
    var _p = __read(useState(false), 2), dependencyModalVisible = _p[0], setDependencyModalVisible = _p[1];
    var _q = __read(useState([]), 2), removableCandidates = _q[0], setRemovableCandidates = _q[1];
    var _r = __read(useState([]), 2), selectedStations = _r[0], setSelectedStations = _r[1];
    var loadData = function () {
        setLoading(true);
        var query = globalFinderValue.trim();
        API.stationsFinder(query, lazyState)
            .then(function (response) {
            var _a = response.data, stations = _a.stations, limit = _a.limit, amount = _a.amount;
            setLimit(limit);
            setStations(stations);
            setStationsAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var onFilter = function (event) {
        event["first"] = 0;
        setlazyState(event);
    };
    var defaultVisibleColumns = dynamicColumns
        .filter(function (col) { return ['hostname', 'location', 'name', 'status'].includes(col.field || ''); })
        .map(function (col) { return col.field || ''; });
    var _s = __read(useState(defaultVisibleColumns), 2), visibleColumns = _s[0], setVisibleColumns = _s[1];
    var changeVisibleColumns = function (columns) {
        setVisibleColumns(columns);
    };
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadData(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    var onPage = function (event) {
        setlazyState(event);
    };
    var onChangeGlobalFinderValue = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
    };
    var accept = function (ids) {
        setLoading(true);
        API.removeStations(ids)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            setSelectedStations([]);
            // Перезагружаем данные после удаления
            loadData();
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var confirm = function (row) {
        var name = row.name;
        var id = row._id;
        setRemovableCandidates([id]);
        confirmDialog({
            message: translater("stationsPageDeleteStationConfirmMessage").toString() +
                name +
                "?",
            header: translater("confirmDialogConfirmDeletion"),
            icon: "pi pi-info-circle",
            className: "max-w-30rem",
            position: "top",
            accept: function () { return accept([id]); },
            reject: function () { },
        });
    };
    var checkSSHData = function (data) {
        setModalLoading(true);
        setModalVisible(true);
        API.sshCopyId(data)
            .then(function (response) {
            var result = response.data.result;
            setSshResult(result);
            setModalLoading(false);
        })
            .catch(function () {
            setModalLoading(false);
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkStationsBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeStations(removableCandidates)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                setSelectedStations([]);
                // Перезагружаем данные после удаления
                loadData();
                setDependencyErrors([]);
                setDependencyModalVisible(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { visibleColumns: visibleColumns, dynamicColumns: dynamicColumns, setVisibleColumns: changeVisibleColumns, loadData: loadData, globalFinderValue: globalFinderValue, stationColumnNames: stationColumnNames, setGlobalFinderValue: onChangeGlobalFinderValue }) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: stations, loading: loading, rows: limit, first: lazyState.first, totalRecords: stationsAmount, responsiveLayout: "scroll", scrollable: true, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, dataKey: "_id", style: { minWidth: "200px" } }, { children: [dynamicColumns
                            .filter(function (column) {
                            return visibleColumns.find(function (visibleColumn) { return column.field === visibleColumn; });
                        })
                            .map(function (column) {
                            var header = translater(column.columnHeader);
                            return (_jsx(Column, __assign({ className: "justify-content-center px-5 text-center", style: column.style || { minWidth: "150px" }, header: header }, column), column.field));
                        }), stationColumnNames
                            .filter(function (column) {
                            return visibleColumns.find(function (visibleColumn) { return column === visibleColumn; });
                        })
                            .map(function (name) {
                            return (_jsx(Column, { className: "justify-content-center", header: name, style: { minWidth: "150px" }, body: function (row) {
                                    return (_jsx("p", {}));
                                } }, "column-" + name));
                        }), _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), style: { minWidth: "200px" }, body: function (row) {
                                return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(Button, { "data-pr-tooltip": translater("stationsTableOpenServerButton"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-code", className: "custom-target-icon-open-station-server p-button p-component  p-button-icon-only p-button-outlined p-button-secondary border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return window.open("/terminal?user=".concat(row.login, "&hostname=").concat(row.hostname, "&name=").concat(row.name)); } }), _jsx(Button, { "data-pr-tooltip": translater("modalExecCommandModalReconnect"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-refresh", className: "custom-target-icon-open-station-server p-button p-component  p-button-icon-only p-button-outlined p-button-secondary border-circle", style: { width: "50px", height: "50px" }, onClick: function () { checkSSHData({ password: '', hostname: row.hostname, login: row.login || '' }); } }), _jsx(Tooltip, { target: ".custom-target-icon-open-station-server" }), _jsx(Button, { "data-pr-tooltip": translater("deleteBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-trash", className: "custom-target-icon-delete-stations p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }), _jsx(Tooltip, { target: ".custom-target-icon-delete-stations" })] })));
                            } }, "actions")] })) })), _jsxs(Dialog, __assign({ visible: modalVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window max-w-25rem flex flex-column', style: { minWidth: '600px' }, onHide: function () { setModalVisible(false); setSshResult(''); setStationData(null); } }, { children: [_jsx("b", { children: translater('modalExecCommandHeaderMessage') }), _jsxs("p", __assign({ style: { fontFamily: 'monospace' }, className: "text-sm" }, { children: [translater('modalExecCommandText'), stationData ? stationData.hostname : ''] })), modalLoading ? (_jsxs("div", __assign({ className: "flex flex-column gap-1" }, { children: [_jsx(Skeleton, {}), _jsx(Skeleton, {}), _jsx(Skeleton, {})] }))) : (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsx("b", { children: translater('modalExecCommandResultMessage') }), _jsx("pre", __assign({ style: {
                                    minWidth: '500px',
                                    overflow: 'auto',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    margin: 0
                                }, className: "text-sm" }, { children: sshResult })), _jsx(Link, __assign({ to: '/terminal', target: "_blank", className: "app-link-color no-underline" }, { children: translater('modalOpenTerminalMessage') }))] })))] })), _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] })));
};
