var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from "react";
import { Button } from "primereact/button";
import { StyleClass } from "primereact/styleclass";
import { TabPanel, TabView } from "primereact/tabview";
import { translater } from "../../../../../../utils/localization/localization";
import { NavigationTabPanel } from "./components/NavigationTabPanel";
import { SettingsTabPanel } from "./components/SettingsTabPanel";
import { InformationTabPanel } from "./components/InformationTabPanel";
export var SlideOver = function () {
    var btn = useRef(null);
    var hideHeaderMenu = function () {
        btn.current.click();
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ id: "slideover-10", className: "hidden min-h-screen min-w-4 surface-card shadow-2 py-3 px-4 border-round absolute top-0 right-0 z-10002" }, { children: _jsxs("div", __assign({ className: "flex flex-column px-4 py-2 w-full h-full" }, { children: [_jsxs("div", __assign({ className: "flex align-items-start justify-content-between" }, { children: [_jsx(InformationTabPanel, {}), _jsx(StyleClass, __assign({ nodeRef: btn, selector: "#slideover-10", leaveToClassName: "hidden", leaveActiveClassName: "fadeoutright" }, { children: _jsx(Button, { ref: btn, icon: "pi pi-times", className: "p-button-rounded p-button-text p-button-plain mb-1" }) }))] })), _jsxs(TabView, { children: [_jsx(TabPanel, __assign({ header: translater("slideOverTabPanelHeaderNavigation").toString() }, { children: _jsx(NavigationTabPanel, { hideHeaderMenu: hideHeaderMenu }) })), _jsx(TabPanel, __assign({ header: translater("slideOverTabPanelHeaderSettings").toString() }, { children: _jsx(SettingsTabPanel, {}) }))] })] })) })) }));
};
