var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
export var JSONButton = function () {
    var tree = useSelector(function (state) { return state.builder; }).tree;
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return setModalVisible(true); }, className: 'p-button p-component p-button-outlined p-button-secondary', label: "JSON" }), _jsx(Dialog, __assign({ visible: modalVisible, onHide: function () { return setModalVisible(false); }, style: { width: '50vw' }, resizable: false, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: _jsx("pre", { children: JSON.stringify(tree, function (key, value) {
                        if (key === 'formJSON')
                            return undefined;
                        return value;
                    }, 4) }) }))] }));
};
