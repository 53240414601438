var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { IPField } from "./fields/IPField";
import { LoginField } from "./fields/LoginField";
import { PasswordField } from "./fields/PasswordField";
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { API } from "../../../../../../api";
import { translater } from "../../../../../../utils/localization/localization";
export var Form = function (_a) {
    var reloadTabs = _a.reloadTabs;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var cancelToken = useRef(null);
    var testConnection = function (data) {
        setLoading(true);
        cancelToken.current = axios.CancelToken.source();
        if (cancelToken)
            API.serverConnection(data, cancelToken)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                reloadTabs();
            })
                .catch(function () { return setLoading(false); });
    };
    var abortRequest = function (event) {
        var _a;
        event.preventDefault();
        (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.cancel();
        setLoading(false);
    };
    var _c = useForm({ mode: 'all' }), register = _c.register, handleSubmit = _c.handleSubmit, _d = _c.formState, errors = _d.errors, isValid = _d.isValid, submitCount = _d.submitCount;
    var onSubmit = function (data) {
        testConnection(data);
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsxs("div", __assign({ className: "surface-card p-4 shadow-2 border-round w-full" }, { children: [_jsx(IPField, { register: register, errors: errors }), _jsx(LoginField, { register: register, errors: errors }), _jsx(PasswordField, { register: register, errors: errors })] })), _jsx("div", __assign({ className: "flex justify-content-end gap-2 mt-4 mb-4" }, { children: loading ? (_jsx(Button, { label: translater("cancel").toString(), icon: "pi pi-times", type: "button", onClick: abortRequest, className: "p-button p-button-warning" })) : (_jsx(Button, { label: translater("ipTabPageTestConnectButton").toString(), icon: "pi pi-check", className: "p-button-success", loading: loading, disabled: submitCount > 0 && !isValid })) }))] })));
};
