var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { translater } from "./localization";
var today = function (now) {
    return {
        start: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)
    };
};
var yesterday = function (now) {
    // Создание даты для начала вчерашнего дня
    var start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    start.setDate(start.getDate() - 1); // Переместиться на один день назад
    // Создание даты для конца вчерашнего дня
    var end = new Date(start);
    end.setHours(23, 59, 59, 999);
    return { start: start, end: end };
};
var thisWeek = function (now) {
    // Получить день недели, где 0 - воскресенье, 1 - понедельник, и т.д.
    var dayOfWeek = now.getDay();
    var diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    // Создание даты для начала недели (понедельника)
    var start = new Date(now.getFullYear(), now.getMonth(), now.getDate() + diffToMonday);
    start.setHours(0, 0, 0, 0); // Установить начало дня
    // Создание даты для конца недели (воскресенья)
    // Добавляем 6 дней к дате начала недели, чтобы получить воскресенье
    var end = new Date(start);
    end.setDate(end.getDate() + 6);
    end.setHours(23, 59, 59, 999); // Установить конец дня
    return { start: start, end: end };
};
var lastWeek = function (now) {
    var dayOfWeek = now.getDay();
    // Вычисляем смещение до предыдущего понедельника
    var diffToLastMonday = dayOfWeek === 0 ? -13 : -dayOfWeek - 6;
    // Вычисляем смещение до предыдущего воскресенья
    var diffToLastSunday = dayOfWeek === 0 ? -7 : -dayOfWeek;
    // Создание даты для начала прошлой недели (понедельник)
    var start = new Date(now);
    start.setDate(start.getDate() + diffToLastMonday);
    start.setHours(0, 0, 0, 0); // Установить начало дня
    // Создание даты для конца прошлой недели (воскресенье)
    var end = new Date(now);
    end.setDate(end.getDate() + diffToLastSunday + 6); // Перемещаемся на воскресенье прошлой недели
    end.setHours(23, 59, 59, 999); // Установить конец дня
    return { start: start, end: end };
};
var last7Days = function (now) {
    // Создание даты для начала периода (7 дней назад от текущей даты)
    var start = new Date(now);
    start.setDate(start.getDate() - 6); // Учтено, что текущий день включается в период
    start.setHours(0, 0, 0, 0); // Установить начало дня
    // Создание даты для конца периода (текущий день)
    var end = new Date(now);
    end.setHours(23, 59, 59, 999); // Установить конец дня
    return { start: start, end: end };
};
var last14Days = function (now) {
    var start = new Date(now);
    start.setDate(start.getDate() - 13);
    start.setHours(0, 0, 0, 0); // Установить начало дня
    // Создание даты для конца периода (текущий день)
    var end = new Date(now);
    end.setHours(23, 59, 59, 999); // Установить конец дня
    return { start: start, end: end };
};
var last30Days = function (now) {
    // Создание даты для начала периода (30 дней назад от текущей даты)
    var start = new Date(now);
    start.setDate(start.getDate() - 29);
    start.setHours(0, 0, 0, 0); // Установить начало дня
    // Создание даты для конца периода (текущий день)
    var end = new Date(now);
    end.setHours(23, 59, 59, 999); // Установить конец дня
    return { start: start, end: end };
};
var last60Days = function (now) {
    // Создание даты для начала периода (60 дней назад от текущей даты)
    var start = new Date(now);
    start.setDate(start.getDate() - 59);
    start.setHours(0, 0, 0, 0); // Установить начало дня
    // Создание даты для конца периода (текущий день)
    var end = new Date(now);
    end.setHours(23, 59, 59, 999); // Установить конец дня
    return { start: start, end: end };
};
export var getPeriods = function () {
    var now = new Date();
    return [
        {
            keyword: translater("userDashboardDropdownPeriodToday"),
            content: today(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodYesterday"),
            content: yesterday(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodThisWeek"),
            content: thisWeek(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodLastWeek"),
            content: lastWeek(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodLastDays7"),
            content: last7Days(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodLastDays14"),
            content: last14Days(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodLastDays30"),
            content: last30Days(now)
        },
        {
            keyword: translater("userDashboardDropdownPeriodLastDays60"),
            content: last60Days(now)
        }
    ];
};
export var dateAdapter = function (date) {
    var start = date.start, end = date.end;
    var _a = __read(start.toLocaleString().split(','), 2), startDate = _a[0], startTime = _a[1];
    var formattedStart = "".concat(startDate.split('.').reverse().join('-')).concat(startTime);
    var _b = __read(end.toLocaleString().split(','), 2), endDate = _b[0], endTime = _b[1];
    var formattedEnd = "".concat(endDate.split('.').reverse().join('-')).concat(endTime);
    // Преобразование в формат YYYY-MM-DD HH:MM:SS
    return { start: formattedStart, end: formattedEnd };
};
