var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Ripple } from "primereact/ripple";
import { useSelector } from "react-redux";
import { setDarkMode } from "../../../../../../../redux/actions/themeActions";
import { translater } from "../../../../../../../utils/localization/localization";
export var ToggleModeButton = function () {
    var darkMode = useSelector(function (state) { return state.theme; }).isDarkMode;
    // useEffect(() => {
    //   const element: any = document.getElementById('theme-link')
    //   if (element) {
    //     const { href: path } = element
    //     // Меняем значение подключаемого скрипта стилей
    //     const mode = darkMode ? path.replace('light', 'dark') : path.replace('dark', 'light')
    //     element.href = mode
    //   }
    // }, [darkMode])
    return (_jsxs("a", __assign({ onClick: function () { return setDarkMode(!darkMode); }, className: "p-ripple flex p-3 align-items-center text-800 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: "pi pi-".concat(darkMode ? "sun" : "moon", " text-base mr-2") }), _jsx("span", __assign({ className: "block font-medium" }, { children: translater("modeButtonLightDark") })), _jsx(Ripple, {})] })));
};
