var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { translater } from "../../../../../../../utils/localization/localization";
import { changeEditorFileContent } from "../../../../../../../redux/actions/editorAction";
import { useSelector } from "react-redux";
export var CancelChangesButton = function (_a) {
    var loading = _a.loading;
    var _b = useSelector(function (state) { return state.editor; }), currentFileName = _b.currentFileName, files = _b.files;
    var currentFile = files.find(function (file) { return file.name === currentFileName; });
    var selectedFile = files.find(function (file) { return file.name === currentFileName; });
    var returnFileDefaultContent = function (currentFile) {
        if (currentFile)
            changeEditorFileContent(currentFile.name, currentFile.prevContent);
    };
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsxs("span", __assign({ "data-pr-tooltip": translater("editorPageCancelChanges"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-cancel-changes button-mini" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-undo", className: "p-button-outlined p-button-secondary", disabled: ((selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.content) === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.prevContent)), onClick: function () { return returnFileDefaultContent(currentFile); } }), _jsx(Tooltip, { target: ".custom-target-icon-cancel-changes" })] })), _jsx(Button, { type: "button", icon: "pi pi-undo", label: translater("editorPageCancelChanges").toString(), className: "w-full full-button p-button-outlined p-button-secondary", disabled: ((selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.content) === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.prevContent)), onClick: function () { return returnFileDefaultContent(currentFile); } })] })));
};
