var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { JSONButton } from './components/buttons/JSONButton';
import { MiniMapToggle } from './components/buttons/MiniMapToggle';
import { ReturnPreviousTree } from './components/buttons/ReturnPreviousTree';
import { FullScreenToggle } from './components/buttons/FullScreenToggle';
import { DialplanButton } from './components/buttons/DialplanButton';
import { translater } from '../../../../../utils/localization/localization';
import { ReturnBackButton } from './components/buttons/ReturnBackButton';
import { TooltipRevealer } from '../../../../components/particals/tooltip/TooltipRevealer';
export var Header = function (_a) {
    var settings = _a.settings;
    return (_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsxs("div", __assign({ className: "w-full flex flex-wrap justify-content-between align-items-center gap-2" }, { children: [_jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx(ReturnBackButton, {}), _jsxs(_Fragment, { children: [_jsx(TooltipRevealer, __assign({ hint: translater(settings.saveButtonTranslaterKey).toString() }, { children: _jsx("span", __assign({ className: " button-mini" }, { children: _jsx(Button, { className: 'p-button-success', icon: "pi pi-save", onClick: function () { return settings.callback(); } }) })) })), _jsx(Button, { className: 'full-button p-button-success', icon: "pi pi-save", onClick: function () { return settings.callback(); }, label: translater(settings.saveButtonTranslaterKey).toString() })] }), _jsx(JSONButton, {}), _jsx(DialplanButton, {})] })), _jsxs("div", __assign({ className: 'flex gap-2' }, { children: [_jsx(ReturnPreviousTree, {}), _jsx(MiniMapToggle, {}), _jsx(FullScreenToggle, {})] }))] })) })));
};
