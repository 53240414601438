var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import axios from "axios";
import { ControllersField } from "./fields/СontrollersField";
import { LoginField } from "./fields/LoginField";
import { PasswordField } from "./fields/PasswordField";
import { UserGroupField } from "./fields/groups/UserGroupField";
import { SupervisorGroupField } from "./fields/groups/SupervisorGroupField";
import { AdminGroupField } from "./fields/groups/AdminGroupField";
import { BaseDNField } from "./fields/BaseDNField";
import { GroupInfoWindow } from "./GroupInfoWindow";
import { API } from "../../../../../../api";
import { translater } from "../../../../../../utils/localization/localization";
import { useForm } from "react-hook-form";
import { DialogWindow } from "./DialogWindow";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import { UserImportModal } from "./UserImportModal";
export var Form = function (_a) {
    var setSettings = _a.setSettings;
    var _b = __read(useState(false), 2), hintVisible = _b[0], setHintVisible = _b[1];
    var _c = __read(useState(false), 2), visibleUsersModal = _c[0], setVisibleUsersModal = _c[1];
    var _d = __read(useState(false), 2), userImportModalVisible = _d[0], setUserImportModalVisible = _d[1];
    var _e = __read(useState(null), 2), savedData = _e[0], setSavedData = _e[1];
    var cancelToken = useRef(null);
    var _f = __read(useState({
        users: 0,
        admins: 0,
        supervisors: 0
    }), 2), amount = _f[0], setAmount = _f[1];
    var _g = __read(useState([]), 2), failed = _g[0], setFailed = _g[1];
    var _h = __read(useState([]), 2), controllers = _h[0], setControllers = _h[1];
    var _j = __read(useState(false), 2), loading = _j[0], setLoading = _j[1];
    var _k = __read(useState([]), 2), groups = _k[0], setGroups = _k[1];
    var groupsAvailability = function () { return !!(groups.length > 0); };
    var updSettings = function () {
        setSettings(function (prev) { return (__assign(__assign({}, prev), { activeDirectory: savedData })); });
    };
    function sendRequest(data) {
        return __awaiter(this, void 0, void 0, function () {
            var ad;
            return __generator(this, function (_a) {
                setLoading(true);
                ad = __assign(__assign({}, data), { user: data.user ? data.user.name : '', supervisor: data.supervisor ? data.supervisor.name : '', admin: data.admin ? data.admin.name : '' });
                cancelToken.current = axios.CancelToken.source();
                // Если групппы догружены
                if (groupsAvailability()) {
                    API.saveActiveDirectory(ad, cancelToken)
                        .then(function (response) {
                        var responseData = response.data;
                        setAmount(responseData);
                        setVisibleUsersModal(true);
                        var AD = {
                            urls: data.controllers.join(','),
                            baseDN: data.baseDN,
                            login: data.login,
                            password: '********',
                            user: data.user.name,
                            supervisor: data.supervisor.name,
                            admin: data.admin.name,
                        };
                        setSavedData(AD);
                        if (responseData.message)
                            successNotification(responseData.message);
                        setLoading(false);
                    })
                        .catch(function (error) {
                        var _a;
                        var failed = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.failed;
                        if (failed)
                            setFailed(failed);
                    })
                        .finally(function () { return setLoading(false); });
                }
                else {
                    // Если групп нет
                    API.checkActiveDirectoryConnection(data, cancelToken)
                        .then(function (response) {
                        var _a = response.data, _b = _a.groups, groups = _b === void 0 ? [] : _b, isActiveDirectoryCreated = _a.isActiveDirectoryCreated;
                        setGroups(groups.map(function (group) { return ({ name: group }); }));
                        setLoading(false);
                    })
                        .catch(function (error) {
                        var _a;
                        var failed = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.failed;
                        if (failed)
                            setFailed(failed);
                    })
                        .finally(function () { return setLoading(false); });
                }
                return [2 /*return*/];
            });
        });
    }
    var _l = useForm({ mode: 'all' }), register = _l.register, handleSubmit = _l.handleSubmit, setValue = _l.setValue, getValues = _l.getValues, trigger = _l.trigger, reset = _l.reset, _m = _l.formState, errors = _m.errors, isValid = _m.isValid, submitCount = _m.submitCount;
    var onSubmit = function (data) {
        var values = Object.assign({}, data, { controllers: controllers });
        sendRequest(values);
    };
    var clearData = function () {
        setGroups([]);
        setControllers([]);
        reset();
        // Открываем окно для импорта пользователей
        setUserImportModalVisible(true);
    };
    var addController = function (value) {
        // Поле не может быть пустым
        if (!value.trim())
            return false;
        // Проверяем совпадение име контроллеров
        var diff = controllers
            .find(function (c) { return c.toLocaleLowerCase() === value.trim().toLocaleLowerCase(); });
        if (diff)
            return false;
        setControllers(__spreadArray(__spreadArray([], __read(controllers), false), [value], false));
        // Чистим поле
        setValue('controller', '');
        // Принудительно валидируем
        setTimeout(function () { return trigger('controller'); }, 0);
    };
    var onKeyDownHandler = function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            var value = event.target.value;
            addController(value);
            setTimeout(function () { return trigger('controller'); }, 0);
        }
    };
    var onClickHandler = function () {
        var controller = getValues('controller');
        addController(controller);
        setTimeout(function () { return trigger('controller'); }, 0);
    };
    var toggleHint = function (event) {
        if (event.key === 'Enter')
            return false;
        setHintVisible(true);
    };
    var deleteController = function (controller) {
        var result = controllers.filter(function (item) { return item !== controller; });
        setControllers(result);
        setTimeout(function () { return trigger('controller'); }, 0);
    };
    var abortRequest = function (event) {
        var _a;
        event.preventDefault();
        (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.cancel();
    };
    return (_jsxs("div", __assign({ className: "relative" }, { children: [_jsxs("form", __assign({ style: { maxWidth: "1200px" }, onSubmit: handleSubmit(onSubmit), className: "flex flex-column align-items-start justify-content-center" }, { children: [_jsx("article", __assign({ className: "border-round w-full flex gap-6 justify-content-center" }, { children: groupsAvailability() ? (_jsxs("section", __assign({ className: "w-full" }, { children: [_jsx(UserGroupField, { groupsAvailability: groupsAvailability, register: register, errors: errors, setValue: setValue, trigger: trigger, getValues: getValues, groups: groups }), _jsx(SupervisorGroupField, { groupsAvailability: groupsAvailability, register: register, errors: errors, trigger: trigger, setValue: setValue, getValues: getValues, groups: groups }), _jsx(AdminGroupField, { groupsAvailability: groupsAvailability, register: register, errors: errors, trigger: trigger, setValue: setValue, getValues: getValues, groups: groups })] }))) : (_jsxs("section", __assign({ style: { width: "100%" } }, { children: [_jsx(ControllersField, { register: register, errors: errors, onClickHandler: onClickHandler, onKeyDownHandler: onKeyDownHandler, controllers: controllers }), controllers.map(function (controller, index) {
                                    return _jsxs("div", __assign({ className: "flex mb-3 p-2 p-inputtext p-component ".concat(failed.find(function (item) { return item === controller; }) ? 'border-red-500' : '') }, { children: [_jsxs("div", __assign({ className: "text-sm", style: { width: "100%" } }, { children: [index + 1, ". ldap://", controller] })), _jsx("span", { onClick: function () { return deleteController(controller); }, className: "p-button-icon p-c pi pi-times cursor-pointer text-xs flex align-items-center justify-content-center" })] }), controller);
                                }), _jsx(BaseDNField, { register: register, errors: errors }), _jsx(LoginField, { register: register, errors: errors }), _jsx(PasswordField, { register: register, errors: errors })] }))) })), _jsxs("div", __assign({ className: "flex w-full" }, { children: [_jsx(DialogWindow, __assign({ hintVisible: hintVisible, setHintVisible: setHintVisible }, { children: translater("groupTabPageDialogWindowContent") })), _jsx(GroupInfoWindow, { reload: clearData, hideModal: function () { return setVisibleUsersModal(false); }, amount: amount, hintVisible: visibleUsersModal }), _jsx("div", __assign({ className: "w-full flex justify-content-end gap-2" }, { children: loading ? (_jsx(Button, { label: translater("cancelConnection").toString(), icon: "pi pi-times", type: "button", onClick: abortRequest, className: "w-full p-button p-button-warning" })) : (_jsx(Button, { label: groupsAvailability()
                                        ? translater("groupTabPageSaveActiveDirectoryButton").toString()
                                        : translater("groupTabPageTestConnectButton").toString(), disabled: submitCount > 0 && !isValid, icon: "pi pi-check", className: "w-full p-button-outlined text-green-600 border-green-600", loading: loading })) }))] }))] })), _jsx(UserImportModal, { updSettings: updSettings, visible: userImportModalVisible, hideModal: function () { return setUserImportModalVisible(false); } })] })));
};
