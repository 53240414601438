var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { translater } from "../../../../../../utils/localization/localization";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import { CreateContext } from "./create-modal/CreateContext";
export var CreateContextButton = function (_a) {
    var loadData = _a.loadData;
    var _b = __read(useState(false), 2), dialogVisible = _b[0], setDialogVisible = _b[1];
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsxs("span", __assign({ "data-pr-tooltip": translater("contextPageAddButton"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-add-context button-mini" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-plus", className: "p-button-success", onClick: function () { return setDialogVisible(true); } }), _jsx(Tooltip, { target: ".custom-target-icon-add-context" })] })), _jsx(Button, { type: "button", icon: "pi pi-plus", label: translater('contextPageAddButton').toString(), className: "min-w-max full-button p-button-success", onClick: function () { return setDialogVisible(true); } }), _jsx(CreateContext, { loadData: loadData, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible })] })));
};
