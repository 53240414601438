import dagre from 'dagre';
import { store } from '../../../../redux/store';
// Инициализация графа (преобразование координат nodes и edges)
export var getLayoutedElements = function (nodes, edges, direction) {
    if (direction === void 0) { direction = 'TB'; }
    var _a = store.getState().builder, marginX = _a.marginX, marginY = _a.marginY;
    var dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(function () { return ({}); });
    dagreGraph.setDefaultNodeLabel(function () { return ({}); });
    dagreGraph.setGraph({ rankdir: direction });
    // Генерация Node
    nodes.forEach(function (node) {
        dagreGraph.setNode(node.id, { width: marginX, height: marginY });
    });
    // Генерация Edges
    edges.forEach(function (edge) {
        dagreGraph.setEdge(edge.source, edge.target);
    });
    dagre.layout(dagreGraph);
    nodes.forEach(function (node) {
        var nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = 'top';
        node.sourcePosition = 'bottom';
        node.position = {
            x: nodeWithPosition.x - marginX / 2,
            y: nodeWithPosition.y - marginY / 2,
        };
        return node;
    });
    return { nodes: nodes, edges: edges };
};
