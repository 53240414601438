import { titleColumn } from "./components/title";
import { supervisorsColumn } from "./components/supervisors";
import { usersAmountColumn } from "./components/usersAmount";
import { descriptionColumn } from "./components/description";
import { dateCreateColumn } from "./components/dateCreate";
import { dateUpdateColumn } from "./components/dateUpdate";
export var dynamicColumns = [
    titleColumn,
    supervisorsColumn,
    usersAmountColumn,
    descriptionColumn,
    dateCreateColumn,
    dateUpdateColumn
];
