var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import { translater } from "../../../../../../../utils/localization/localization";
export var EditorCreateFileButton = function () {
    var navigate = useNavigate();
    return (_jsx("div", __assign({ className: "min-w-max" }, { children: _jsxs("span", __assign({ "data-pr-tooltip": translater("editorPageItemsLabelCreate"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-create-editor-file" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-plus", className: "p-button-success", onClick: function () {
                        navigate('/editor/create-file');
                    } }), _jsx(Tooltip, { target: ".custom-target-icon-create-editor-file" })] })) })));
};
