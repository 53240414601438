var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from "primereact/tooltip";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import { API } from "../../../../../../api";
import { translater } from "../../../../../../utils/localization/localization";
import { EntityDependencyErrorArea } from "../../../../../components/particals/error/EntityDependencyErrorArea";
export var DeleteRulesButton = function (_a) {
    var loadData = _a.loadData, selectedRules = _a.selectedRules, clearSelectedRules = _a.clearSelectedRules;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState([]), 2), dependencyErrors = _c[0], setDependencyErrors = _c[1];
    var _d = __read(useState(false), 2), dependencyModalVisible = _d[0], setDependencyModalVisible = _d[1];
    var _e = __read(useState([]), 2), removableCandidates = _e[0], setRemovableCandidates = _e[1];
    var accept = function (ids) {
        setLoading(true);
        API.removeRules(ids)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            clearSelectedRules();
            // Перезагружаем данные после удаления
            loadData();
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var confirm = function (selectedGroups) {
        var titles = selectedGroups.map(function (rule) { return rule.name; });
        var ids = selectedGroups.map(function (rule) { return rule._id; });
        setRemovableCandidates(ids);
        confirmDialog({
            message: translater("rulesPageHeaderConfirmDialogMessage").toString()
                + " ".concat(titles.join(', '), " ?"),
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(ids); },
            reject: function () { }
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkRulesBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeRules(removableCandidates)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                // Перезагружаем данные после удаления
                loadData();
                setDependencyErrors([]);
                setDependencyModalVisible(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": translater("deleteBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", type: "button", icon: "pi pi-trash", disabled: (selectedRules === null || selectedRules.length === 0) || loading, className: "delete-rules-button bg-red-500 border-red-500", onClick: function () { return confirm(selectedRules); } }), _jsx(Tooltip, { target: ".delete-rules-button" }), _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] }));
};
