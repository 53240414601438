var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { API } from "../../../api";
import { translater } from "../../../utils/localization/localization";
import { dynamicColumns } from "./components/columns/columns";
import { Header } from "./components/header/Header";
import { TableConversion } from "./components/header/TableConversion";
import { localStorageVariables } from "../../../utils/localStorage/variables";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
var defaultLazyState = { first: 0, page: 1 };
export var Rules = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/rules' }]);
    }, []);
    var navigate = useNavigate();
    var _b = __read(useState([]), 2), groups = _b[0], setRules = _b[1];
    var _c = __read(useState(50), 2), limit = _c[0], setLimit = _c[1];
    var _d = __read(useState(0), 2), groupsAmount = _d[0], setRulesAmount = _d[1];
    var _e = __read(useState([]), 2), selectedRules = _e[0], setSelectedRules = _e[1];
    var _f = __read(useState(false), 2), loading = _f[0], setLoading = _f[1];
    var _g = __read(useState(''), 2), globalFinderValue = _g[0], setGlobalFinderValue = _g[1];
    var _h = __read(useState(defaultLazyState), 2), lazyState = _h[0], setlazyState = _h[1];
    var defaultVisibleColumns = dynamicColumns
        .filter(function (col) { return ['pattern', 'name', 'comment'].includes(col.field || ''); })
        .map(function (col) { return col.field || ''; });
    var _j = __read(useState(defaultVisibleColumns), 2), visibleColumns = _j[0], setVisibleColumns = _j[1];
    var changeVisibleColumns = function (columns) {
        setVisibleColumns(columns);
        localStorage.setItem(localStorageVariables.defaultTableColumns.rule, JSON.stringify(columns));
    };
    // Получение имен колонок
    useEffect(function () {
        var columnNames = localStorage.getItem(localStorageVariables.defaultTableColumns.rule) || '';
        if (columnNames) {
            setVisibleColumns(JSON.parse(columnNames));
        }
        else {
            localStorage.setItem(localStorageVariables.defaultTableColumns.rule, JSON.stringify(defaultVisibleColumns));
        }
    }, []);
    var loadData = function () {
        setLoading(true);
        var query = globalFinderValue.trim();
        API.rulesFinder(query, lazyState)
            .then(function (response) {
            var _a = response.data, rules = _a.rules, limit = _a.limit, amount = _a.amount;
            setLimit(limit);
            setRules(rules);
            setRulesAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadData(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    var onPage = function (event) { setlazyState(event); };
    var onChangeGlobalFinderValue = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
    };
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { loadData: loadData, selectedRules: selectedRules, clearSelectedRules: function () { return setSelectedRules([]); } }) })), _jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(TableConversion, { loadData: loadData, globalFinderValue: globalFinderValue, setGlobalFinderValue: onChangeGlobalFinderValue, visibleColumns: visibleColumns, setVisibleColumns: changeVisibleColumns, dynamicColumns: dynamicColumns }) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: groups, loading: loading, rows: limit, first: lazyState.first, totalRecords: groupsAmount, responsiveLayout: "scroll", scrollable: true, selectionMode: "multiple", onPage: onPage, selection: selectedRules, onSelectionChange: function (e) { return setSelectedRules(e.value); }, dataKey: "_id", style: { minWidth: "200px" } }, { children: [_jsx(Column, { className: "flex justify-content-center", selectionMode: "multiple", style: { maxWidth: "90px" }, headerStyle: { width: '6rem', height: "65px" } }, 'selecting'), _jsx(Column, { className: "flex justify-content-center", header: translater("actionsColumn"), style: { maxWidth: "100px" }, body: function (row) {
                                return (_jsx(_Fragment, { children: _jsx(Button, { icon: "pi pi-pencil", className: "p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return navigate('/rule/update/' + row._id); } }) }));
                            } }, 'actions'), dynamicColumns
                            .filter(function (column) {
                            return visibleColumns.find(function (visibleColumn) { return column.field === visibleColumn; });
                        })
                            .map(function (column) {
                            var header = translater(column.columnHeader);
                            return (_jsx(Column, __assign({ className: "justify-content-center px-5", style: column.style || { minWidth: "150px" }, header: header }, column), column.field));
                        })] })) }))] })));
};
