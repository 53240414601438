import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Parser from 'html-react-parser';
import { ErrorsList } from '../error/ErrorsList';
export var Notifications = function () {
    var toast = useRef(null);
    var list = useSelector(function (state) { return state.notifications; }).list;
    useEffect(function () {
        var prepared = list.map(function (notification) {
            notification.detail = Parser(String(notification.detail));
            return notification;
        });
        if (toast.current)
            toast.current.show(prepared);
    }, [list]);
    return (_jsxs(_Fragment, { children: [_jsx(Toast, { className: 'custom-notification', position: "bottom-right", ref: toast }), _jsx(ErrorsList, {})] }));
};
