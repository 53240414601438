var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { getListItemKeyword, translate } from "../FormGenerator";
import { RequiredFieldDesignation } from "../utils/RequiredFieldDesignation";
import { ErrorBlock } from "../../error/ErrorBlock";
import { translater } from "../../../../../utils/localization/localization";
import { Hint } from "../utils/Hint";
import { API } from "../../../../../api";
import { Preloader } from "../../preloader/Preloader";
export var List = function (_a) {
    var _b = _a.form, register = _b.register, getValues = _b.getValues, setValue = _b.setValue, trigger = _b.trigger, setError = _b.setError, clearErrors = _b.clearErrors, _c = _b.formState, errors = _c.errors, isValid = _c.isValid, _d = _a.field, titleTranslaterKey = _d.titleTranslaterKey, field = _d.field, placeholderTranslaterKey = _d.placeholderTranslaterKey, _e = _d.apiDataUrl, apiDataUrl = _e === void 0 ? 'no-key' : _e, staticList = _d.staticList, required = _d.required, defaultValue = _d.defaultValue, hintTranslaterKey = _d.hintTranslaterKey;
    var ref = useRef(null);
    var _f = __read(useState([]), 2), list = _f[0], setList = _f[1];
    var _g = __read(useState(false), 2), loading = _g[0], setLoading = _g[1];
    var rules = {};
    if (required)
        rules.required = translater("Form Generator Required Field").toString();
    var loadList = function () {
        setLoading(true);
        API.getServerData(apiDataUrl)
            .then(function (response) {
            var data = response.data;
            var value = getValues(field);
            if (value) {
                var _a = value.content, id_1 = _a === void 0 ? '' : _a;
                var item = data.find(function (element) { return element.content === id_1; });
                if (item)
                    setValue(field, item);
            }
            setList(data);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        if (apiDataUrl !== 'no-key')
            loadList();
        if (staticList)
            setList(staticList);
    }, []);
    useEffect(function () {
        if (required && !getValues(field)) {
            setError(field, {
                type: 'required',
                message: translater("Form Generator Required Field").toString(),
            });
        }
        else {
            clearErrors(field);
        }
    }, [getValues(field)]);
    return (_jsxs("div", __assign({ className: "w-full flex flex-column align-items-start gap-2" }, { children: [_jsxs("div", __assign({ className: "w-full flex flex-column" }, { children: [_jsxs("span", __assign({ className: "mb-1" }, { children: [hintTranslaterKey && (_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "text-primary p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" })), translate(titleTranslaterKey), required && _jsx(RequiredFieldDesignation, {})] })), loading
                        ? _jsx(Preloader, {})
                        : (_jsx(Dropdown, { placeholder: placeholderTranslaterKey || 'List', options: list, itemTemplate: function (option) { return getListItemKeyword(option); }, value: getListItemKeyword(getValues(field)), optionLabel: "keyword", valueTemplate: getListItemKeyword(getValues(field)), onChange: function (e) {
                                setValue(field, e.value);
                                setTimeout(function () { return trigger(field); }, 0);
                            }, className: "w-full" }))] })), _jsx(ErrorBlock, { errors: errors, keyword: field }), _jsx(Hint, { hint: hintTranslaterKey, reference: ref })] })));
};
