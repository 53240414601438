var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { translater } from "../../../../../../utils/localization/localization";
import { ConfirmModal } from "../../../../users/components/header/components/import/AD/components/ConfirmModal";
export var UserImportModal = function (_a) {
    var visible = _a.visible, hideModal = _a.hideModal, updSettings = _a.updSettings;
    var _b = __read(useState(false), 2), modalVisible = _b[0], setModalVisible = _b[1];
    var importUsers = function () {
        setModalVisible(true);
        hideModal();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, __assign({ visible: visible, onHide: function () { hideModal(); updSettings(); }, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window max-w-17rem', style: { minWidth: '600px' } }, { children: _jsx("div", __assign({ className: "flex flex-column gap-3 align-items-center w-full" }, { children: _jsx("div", __assign({ className: "flex gap-3 align-items-center align-items-end w-full" }, { children: _jsxs("div", __assign({ className: "w-full text-center flex flex-column align-items-center justify-content-center gap-3" }, { children: [_jsx("span", { children: translater('settingsPageActiveDirectoryUserImportLabel') }), _jsx(Button, { onClick: function () { return importUsers(); }, label: translater('settingsPageActiveDirectoryUserImportButton').toString(), className: "w-full p-button-outlined text-green-600 border-green-600" })] })) })) })) })), _jsx(ConfirmModal, { loadData: function () { return hideModal(); }, updSettings: function () { return updSettings(); }, hideModal: function () { return setModalVisible(false); }, visible: modalVisible })] }));
};
