var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FileEditor } from "./components/code-editor/CodeEditor";
import { Header } from "./components/header/Header";
import { NoData } from "./components/NoData";
import { useSelector } from "react-redux";
import { FileList } from "./components/FileList";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
export var Editor = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/editor' }]);
    }, []);
    var currentFileName = useSelector(function (state) { return state.editor; }).currentFileName;
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx(Header, {}), _jsx(FileList, {}), currentFileName
                ? _jsx(FileEditor, {})
                : _jsx(NoData, {})] })));
};
