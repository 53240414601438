var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { translater } from "../../../../../utils/localization/localization";
import { changeSupportConnection } from "../../../../../redux/actions/supportActions";
export var Header = function () {
    var _a = useSelector(function (state) { return state; }).support, isConnected = _a.isConnected, isFileLoaded = _a.isFileLoaded;
    var toggleConnection = function () {
        // if (isConnected) {
        // }
        changeSupportConnection();
    };
    return (_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: isConnected ? (_jsx(Button, { type: "button", disabled: !isFileLoaded, label: translater('supportPageDisconnectionButtonLabel').toString(), icon: "pi pi-desktop", className: "p-button-danger", onClick: function () { return toggleConnection(); } })) : (_jsx(Button, { type: "button", disabled: !isFileLoaded, label: translater('supportPageConnectionButtonLabel').toString(), icon: "pi pi-desktop", className: "p-button-success", onClick: function () { return toggleConnection(); } })) })));
};
