import { HIDE_PLAYER, SET_AUDIO_LIST, SET_CURRENT_AUDIO_OBJECT, SET_DEFAULT_POSITION, SET_POSITION, SHOW_PLAYER } from '../reducers/audioPlayerReducer';
import { store } from '../store';
import { infoNotification } from './notificationsActions';
export var showAudioPlayer = function () {
    return store.dispatch({ type: SHOW_PLAYER, payload: true });
};
var removeCurrentAudioObject = function () {
    store.dispatch({
        type: SET_CURRENT_AUDIO_OBJECT,
        payload: null
    });
};
export var hideAudioPlayer = function () {
    removeCurrentAudioObject();
    setDefaultPosition();
    return store.dispatch({ type: HIDE_PLAYER, payload: false });
};
export var setAudioList = function (list) {
    store.dispatch({
        type: SET_AUDIO_LIST,
        payload: list
    });
};
export var clearAudioPlayer = function () {
    hideAudioPlayer();
    setAudioList([]);
};
export var setCurrentAudioObject = function (audioObject) {
    var audioList = store.getState().audioPlayer.audioList;
    // Показываем плеер
    showAudioPlayer();
    // Задаем позицию текущего аудио относительно всех элементов audioList
    var position = audioList
        .findIndex(function (audio) { return audio.id === audioObject.id; });
    setPosition(position);
    return store.dispatch({
        type: SET_CURRENT_AUDIO_OBJECT,
        payload: audioList[position]
    });
};
export var setPosition = function (position) { return store.dispatch({
    type: SET_POSITION,
    payload: position
}); };
export var setDefaultPosition = function () {
    return store.dispatch({ type: SET_DEFAULT_POSITION, payload: 0 });
};
export var nextAudio = function () {
    var _a = store.getState().audioPlayer, currentPosition = _a.position, audioList = _a.audioList;
    var position = (currentPosition + 1) >= audioList.length ? 0 : currentPosition + 1;
    setPosition(position);
    setCurrentAudioObject(audioList[position]);
};
export var previousAudio = function () {
    var _a = store.getState().audioPlayer, currentPosition = _a.position, audioList = _a.audioList;
    var position = ((currentPosition - 1) >= 0) ? currentPosition - 1 : audioList.length - 1;
    setPosition(position);
    setCurrentAudioObject(audioList[position]);
};
export var downloadAudio = function (url) {
    if (url) {
        infoNotification('Try to load. Please wait.');
        var fileName_1 = "audio.wav";
        var bearerToken = localStorage.getItem('token');
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': "Bearer ".concat(bearerToken) }
        })
            .then(function (response) { return response.blob(); })
            .then(function (blob) {
            var audioBlob = new Blob([blob], { type: "audio/wav" });
            var urlObjecct = URL.createObjectURL(audioBlob);
            var link = document.createElement("a");
            link.href = urlObjecct;
            link.download = fileName_1;
            link.click();
        });
    }
    else {
        infoNotification('Invalid audio url to download');
    }
};
