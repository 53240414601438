import { store } from '../store';
import { adminPermission, superviorPermission } from './authActions';
import { HIDE_HEADER, HIDE_SIDEBAR, SET_APPLICATION_VERSION, SET_APPLY_LOGS_VISIBLE, SET_BREADCRUMBS, SET_COMPANY_NAME, SET_HOME_DATA, SET_LAST_VISIT_URL, SET_SIDEBAR_TABS, SHOW_HEADER, SHOW_SIDEBAR } from '../reducers/appReducer';
import { localStorageVariables } from '../../utils/localStorage/variables';
export var getSidebarItems = function () {
    var sidebarItems = store.getState().app.sidebarItems;
    switch (true) {
        case adminPermission():
            return sidebarItems.filter(function (item) { return item.access.includes('Administrator'); });
        case superviorPermission():
            return sidebarItems.filter(function (item) { return item.access.includes('Supervisor'); });
        default:
            return sidebarItems.filter(function (item) { return item.access.includes('User'); });
    }
};
export var getCurrentHomeData = function () {
    var homeData = store.getState().app.homeData;
    return JSON.parse(JSON.stringify(homeData));
};
export var setCompanyName = function (name) {
    var action = {
        type: SET_COMPANY_NAME,
        payload: name
    };
    return store.dispatch(action);
};
export var setApplicationVersion = function (version) {
    var action = {
        type: SET_APPLICATION_VERSION,
        payload: version
    };
    return store.dispatch(action);
};
export var setBreadCrumbs = function (breadCrumbs) {
    var action = {
        type: SET_BREADCRUMBS,
        payload: breadCrumbs
    };
    return store.dispatch(action);
};
export var getAdminSettingsItems = function () {
    var adminSettingsItems = store.getState().app.adminSettingsItems;
    var isAdmin = adminPermission();
    return isAdmin
        ? adminSettingsItems
        : adminSettingsItems.filter(function (item) { return item.access.includes('Administrator'); });
};
export var getSystemSettingsItems = function () {
    var systemSettingsItems = store.getState().app.systemSettingsItems;
    var isAdmin = adminPermission();
    return isAdmin
        ? systemSettingsItems
        : systemSettingsItems.filter(function (item) { return item.access.includes('Administrator'); });
};
export var getPBXSettingsItems = function () {
    var pbxSettingsItems = store.getState().app.pbxSettingsItems;
    var isAdmin = adminPermission();
    return isAdmin
        ? pbxSettingsItems
        : pbxSettingsItems.filter(function (item) { return item.access.includes('Administrator'); });
};
export var getSecuritySettingsItems = function () {
    var securitySettingsItems = store.getState().app.securitySettingsItems;
    var isAdmin = adminPermission();
    return isAdmin
        ? securitySettingsItems
        : securitySettingsItems.filter(function (item) { return item.access.includes('Administrator'); });
};
export var getUserSettingsItems = function () {
    var userSettingsItems = store.getState().app.userSettingsItems;
    var isAdmin = adminPermission();
    return isAdmin ? [] : userSettingsItems.filter(function (item) { return item.access.includes('User'); });
};
export var showHeader = function () {
    return store.dispatch({ type: SHOW_HEADER });
};
export var hideHeader = function () {
    return store.dispatch({ type: HIDE_HEADER });
};
export var showSidebar = function () {
    return store.dispatch({ type: SHOW_SIDEBAR });
};
export var hideSidebar = function () {
    return store.dispatch({ type: HIDE_SIDEBAR });
};
export var setHomeData = function (data) {
    return store.dispatch({ type: SET_HOME_DATA, payload: data });
};
export var setApplyLogsVisible = function (mode) {
    var action = {
        type: SET_APPLY_LOGS_VISIBLE,
        payload: mode
    };
    localStorage.setItem(localStorageVariables.app.applyLogsVisible, String(mode));
    return store.dispatch(action);
};
export var setDefaultHomeData = function () {
    var data = {
        data: null,
        phones: [],
        mainPhone: null,
        userInfo: {
            // missingCallsExistence: false,
            messagesAmount: 0,
            faxAmount: 0
        }
    };
    return store.dispatch({ type: SET_HOME_DATA, payload: data });
};
export var changeSidebarTabs = function (tabs) {
    // Записываем данные sidebar tabs в localStorage
    var json = JSON.stringify(tabs);
    localStorage.setItem(localStorageVariables.sidebarTabs, json);
    return store.dispatch({ type: SET_SIDEBAR_TABS, payload: tabs });
};
export var changeLastVisitUrl = function (url) {
    localStorage.setItem(localStorageVariables.lastVisitUrl, url);
    return store.dispatch({ type: SET_LAST_VISIT_URL, payload: url });
};
