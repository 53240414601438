var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomAudioPlayer } from '../../components/particals/custom-audio-player/CustomAudioPlayer';
import { Header } from './components/Header';
import { clearAudioPlayer, setAudioList } from '../../../redux/actions/audioPlayerActions';
import { API } from "../../../api";
import { translater } from "../../../utils/localization/localization";
import { dynamicColumns } from "./components/columns/columns";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
var defaultLazyState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    globalFinderValue: '',
    filters: {
        type: { value: null, matchMode: FilterMatchMode.IN },
        date: { value: null, matchMode: FilterMatchMode.IN },
    }
};
export var Calls = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/calls' }]);
    }, []);
    var location = useLocation();
    var navigate = useNavigate();
    var phones = useSelector(function (state) { return state; }).app.homeData.phones;
    var currentAudioObject = useSelector(function (state) { return state.audioPlayer; }).currentAudioObject;
    var _b = __read(useState({ phones: [], mainPhone: null }), 2), userPhones = _b[0], setUserPhones = _b[1];
    var _c = __read(useState(''), 2), globalFinderValue = _c[0], setGlobalFinderValue = _c[1];
    var _d = __read(useState(undefined), 2), timer = _d[0], setTimer = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(useState(0), 2), totalRecords = _f[0], setTotalRecords = _f[1];
    var _g = __read(useState(50), 2), limit = _g[0], setLimit = _g[1];
    var _h = __read(useState([]), 2), records = _h[0], setRecords = _h[1];
    var _j = __read(useState(defaultLazyState), 2), lazyState = _j[0], setlazyState = _j[1];
    var _k = __read(useState(0), 2), callsRequestCounter = _k[0], setCallsRequestCounter = _k[1];
    // Набор телефонов по умолчанию
    var _l = __read(useState([]), 2), selectedPhoneNumbers = _l[0], setSelectedPhoneNumbers = _l[1];
    var clearData = function () {
        setGlobalFinderValue('');
        setlazyState(defaultLazyState);
        navigate('/calls');
    };
    var onChangeGlobalFinderValue = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
        clearTimeout(timer);
        var newTimer = setTimeout(function () {
            setlazyState(function (prev) { return (__assign(__assign({}, prev), { globalFinderValue: value })); });
        }, 500);
        setTimer(newTimer);
    };
    var onFilter = function (event) {
        event['first'] = 0;
        setlazyState(event);
    };
    var onPage = function (event) { setlazyState(event); };
    var onSort = function (event) { setlazyState(event); };
    var resetData = function () {
        setRecords([]);
        setTotalRecords(0);
        setAudioList([]);
    };
    var loadCalls = function (phones, filters) {
        if (phones.length > 0) {
            setLoading(true);
            API.getCalls(phones, __assign(__assign({}, filters), { globalFinderValue: globalFinderValue }))
                .then(function (response) {
                var _a = response.data, calls = _a.calls, amount = _a.amount, limit = _a.limit;
                setRecords(calls);
                setTotalRecords(amount);
                setLoading(false);
                setAudioList(calls);
                setLimit(limit);
            })
                .catch(function (error) {
                setLoading(false);
            })
                .finally(function () {
                setLoading(false);
                setCallsRequestCounter(callsRequestCounter + 1);
            });
        }
        else
            resetData();
    };
    useEffect(function () {
        setUserPhones(function (prev) { return (__assign(__assign({}, prev), { phones: phones })); });
    }, [phones]);
    useEffect(function () {
        setLoading(true);
        if (callsRequestCounter === 0) {
            if (location.search.includes('all-missing')) {
                API.getMissingCallsPeriod()
                    .then(function (response) {
                    var period = response.data.period;
                    var filtersClone = JSON.parse(JSON.stringify(lazyState));
                    filtersClone.filters.type.value = ['missing'];
                    filtersClone.filters.date.value = [new Date(period.start), new Date(period.end)];
                    setlazyState(filtersClone);
                    setLoading(true);
                    API.getPhones()
                        .then(function (response) {
                        var _a = response.data, phones = _a.phones, mainPhone = _a.mainPhone;
                        setUserPhones({ phones: phones, mainPhone: mainPhone });
                        var selected = location.search.includes('all-missing')
                            ? phones
                            : (mainPhone ? [mainPhone] : []);
                        setSelectedPhoneNumbers(selected);
                        loadCalls(selected, filtersClone);
                    })
                        .finally(function () { return setLoading(false); })
                        .catch(function () { return setLoading(false); });
                })
                    .finally(function () { return setLoading(false); })
                    .catch(function () { return setLoading(false); });
            }
            else {
                API.getPhones()
                    .then(function (response) {
                    var _a = response.data, phones = _a.phones, mainPhone = _a.mainPhone;
                    setUserPhones({ phones: phones, mainPhone: mainPhone });
                    var selected = location.search.includes('all-missing')
                        ? phones
                        : (mainPhone ? [mainPhone] : []);
                    setSelectedPhoneNumbers(selected);
                    loadCalls(selected, lazyState);
                })
                    .finally(function () { return setLoading(false); })
                    .catch(function () { return setLoading(false); });
            }
        }
    }, []);
    useEffect(function () {
        if (callsRequestCounter !== 0)
            loadCalls(selectedPhoneNumbers, lazyState);
    }, [lazyState, selectedPhoneNumbers]);
    // Обнуляем аудио-плеер после закрытия страницы
    useEffect(function () { return function () { return clearAudioPlayer(); }; }, []);
    var columns = dynamicColumns(setRecords).map(function (column) {
        var header = translater(column.columnHeader).toString();
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    return (_jsxs("main", __assign({ className: "calls-page max-w-full m-3 mt-0 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "surface-card shadow-2 border-round overflow-hidden mt-2" }, { children: _jsx(CustomAudioPlayer, {}) })), _jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { clearFilter: clearData, setGlobalFinderValue: onChangeGlobalFinderValue, selectedPhoneNumbers: selectedPhoneNumbers, setSelectedPhoneNumbers: setSelectedPhoneNumbers, phoneNumbers: userPhones.phones, globalFinderValue: globalFinderValue }) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: _jsx(DataTable, __assign({ value: records, lazy: true, paginator: true, first: lazyState.first, rows: limit, totalRecords: totalRecords, onPage: onPage, onSort: onSort, onFilter: onFilter, filters: lazyState.filters, sortField: lazyState.sortField, sortOrder: lazyState.sortOrder, loading: loading, selectionMode: "single", selection: currentAudioObject, responsiveLayout: "scroll", scrollable: true, style: { minWidth: "200px" } }, { children: columns })) }))] })));
};
