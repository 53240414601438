var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from "primereact/dropdown";
import { translater } from "../../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../../components/particals/error/ErrorBlock";
export var SupervisorGroupField = function (_a) {
    var groups = _a.groups, getValues = _a.getValues, setValue = _a.setValue, groupsAvailability = _a.groupsAvailability, register = _a.register, trigger = _a.trigger, errors = _a.errors;
    var namesPrompt = useRef(null);
    var validations = {
        validate: function (value) {
            if (groupsAvailability()) {
                if (!value)
                    return translater("groupTabPageValidateSupervisorGroupField").toString();
            }
            return true;
        }
    };
    var select = function (item) {
        setValue('supervisor', item);
        trigger('supervisor');
    };
    return (_jsxs(_Fragment, { children: [_jsxs("label", __assign({ htmlFor: "supervisor", className: "block text-900 font-medium mb-2" }, { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = namesPrompt.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("groupTabPageSupervisorGroupField")] })), _jsx(Dropdown, { onChange: function (e) { return select(e.value); }, options: groups, value: getValues('supervisor'), filter: true, placeholder: translater("groupTabPagePlaceholderSupervisorGroupField").toString(), className: "w-full mb-3" }), _jsx(InputText, __assign({}, register('supervisor', validations), { id: "supervisor", type: "text", name: "supervisor", placeholder: translater("groupTabPagePlaceholderSupervisorGroupField").toString(), className: "w-full mb-3 hidden" })), _jsx(ErrorBlock, { errors: errors, keyword: "supervisor" }), _jsx(OverlayPanel, __assign({ ref: namesPrompt }, { children: _jsx("p", { children: translater("groupTabPageGroupFieldOverlayPanel") }) }))] }));
};
