var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../../../utils/localization/localization";
import { TooltipRevealer } from "../../../../../components/particals/tooltip/TooltipRevealer";
import { infoNotification } from "../../../../../../redux/actions/notificationsActions";
export var DisplayField = function (_a) {
    var header = _a.header, _b = _a.value, value = _b === void 0 ? '' : _b;
    var copy = function (data) {
        navigator.clipboard.writeText(data);
        infoNotification(translater("successfullyCopied").toString());
    };
    return (_jsxs("div", { children: [_jsx("label", __assign({ className: "" }, { children: translater(header) })), _jsxs("div", __assign({ className: "mt-1 flex align-items-center gap-1" }, { children: [_jsx(InputText, { value: value, type: "text", disabled: true, className: "w-full w-23rem" }), _jsx(TooltipRevealer, __assign({ hint: translater("сopiedMessage").toString() }, { children: _jsx(Button, { icon: "pi pi-copy", 
                            // label={translater("settingsPageUpdateCompanyNameButton").toString()}
                            onClick: function () { return copy(value); }, className: "p-button p-component p-button-outlined p-button-secondary w-3rem" }) }))] }))] }));
};
