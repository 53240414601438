var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { API } from "../../../../../api";
import { MenuItems } from "./components/MenuItems";
import { useLocation } from "react-router-dom";
var menuItems = [
    {
        title: 'settingsPageApplicationInfoHeader',
        picture: 'pi pi-info-circle',
        type: 'application-info'
    },
    {
        title: 'settingsPageCompanyNameHeader',
        picture: 'pi pi-star',
        type: 'company-name'
    },
    {
        title: 'settingsPageActiveDirectoryHeader',
        picture: 'pi pi-users',
        type: 'active-directory'
    },
    {
        title: 'settingsPageEncryptionKeyHeader',
        picture: 'pi pi-key',
        type: 'encryption-key'
    },
    {
        title: 'settingsPageCDRHeader',
        picture: 'pi pi-phone',
        type: 'cdr'
    },
    // {
    //   title: 'settingsPageConnectionHeader',
    //   picture: 'pi pi-map',
    //   type: 'pbx-connection'
    // },
    {
        title: 'settingsPageAppDataHeader',
        picture: 'pi pi-database',
        type: 'database'
    }
];
var defaultSettings = {
    version: null,
    developer: null,
    activeDirectory: null,
    encryptionKeyExistence: false,
    serverIP: null,
    cdrDataBase: '',
    clientUrl: '',
    databaseBackup: null
};
export var Menu = function (_a) {
    var breadcrumbs = _a.breadcrumbs;
    var location = useLocation();
    var _b = __read(useState(menuItems[0]), 2), selectedMenuItem = _b[0], setSelectedMenuItem = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(defaultSettings), 2), settings = _d[0], setSettings = _d[1];
    // Выбираем текущую настройку
    useEffect(function () {
        var pathname = location.pathname;
        var pathPart = pathname.split('/').at(2);
        var menuItem = menuItems.find(function (item) { return item.type === pathPart; });
        if (menuItem)
            setSelectedMenuItem(menuItem);
    }, [location]);
    // Загружаем настройки
    useEffect(function () {
        setLoading(true);
        API.getSettingsData()
            .then(function (response) {
            if (response) {
                var data = response.data;
                setSettings(data);
                setLoading(false);
            }
        })
            .catch(function (err) {
            setLoading(false);
        });
    }, []);
    return (_jsx("main", __assign({ className: 'settings-page flex align-items-start gap-2' }, { children: _jsx(MenuItems, { item: selectedMenuItem, settings: settings, setSettings: setSettings, breadcrumbs: breadcrumbs }) })));
};
