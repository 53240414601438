var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chart } from 'primereact/chart';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { translater } from "../../../../../../../utils/localization/localization";
import { AnimatedNumber } from '../../../../../../components/particals/animation/animated-number.tsx/AnimatedNumber';
export var IncomingMissingChart = function (_a) {
    var data = _a.data, currentPeriod = _a.currentPeriod;
    var callsPerHour = new Array(24).fill(0);
    var missedCallsPerHour = new Array(24).fill(0);
    var incomingCallsPerHour = new Array(24).fill(0);
    var isDarkMode = useSelector(function (state) { return state; }).theme.isDarkMode;
    if (data) {
        data.all.calls.forEach(function (call) {
            var startHour = new Date(call.start).getHours();
            var endHour = new Date(call.end).getHours();
            // С 0 утра по 23 вечера
            if (startHour >= 0 && endHour <= 23) {
                if (startHour === endHour) {
                    callsPerHour[startHour - 0]++;
                }
                else {
                    for (var i = Math.max(startHour, 0); i <= Math.min(endHour, 23); i++) {
                        callsPerHour[i - 0]++;
                    }
                }
            }
        });
        data.missing.calls.forEach(function (call) {
            var startHour = new Date(call.start).getHours();
            var endHour = new Date(call.end).getHours();
            // С 0 утра по 23 вечера
            if (startHour >= 0 && endHour <= 23) {
                if (startHour === endHour) {
                    missedCallsPerHour[startHour - 0]++;
                }
                else {
                    for (var i = Math.max(startHour, 0); i <= Math.min(endHour, 23); i++) {
                        missedCallsPerHour[i - 0]++;
                    }
                }
            }
        });
        data.incomming.calls.forEach(function (call) {
            var startHour = new Date(call.start).getHours();
            var endHour = new Date(call.end).getHours();
            // С 0 утра по 23 вечера
            if (startHour >= 0 && endHour <= 23) {
                if (startHour === endHour) {
                    incomingCallsPerHour[startHour - 0]++;
                }
                else {
                    for (var i = Math.max(startHour, 0); i <= Math.min(endHour, 23); i++) {
                        incomingCallsPerHour[i - 0]++;
                    }
                }
            }
        });
    }
    var commonIncomingCallsPerHour = incomingCallsPerHour.map(function (amount, index) {
        return amount + missedCallsPerHour[index];
    });
    var chartData = {
        labels: __spreadArray([], __read(Array(24).keys()), false).map(function (i) { return "".concat(i, ":00"); }),
        datasets: [
            {
                label: translater("homePageIncomingCalls").toString(),
                data: incomingCallsPerHour,
                fill: false,
                borderColor: '#F59C12',
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 2,
            },
            {
                label: translater("homePageCommonIncomingCalls").toString(),
                data: commonIncomingCallsPerHour,
                fill: false,
                borderColor: '#326fd1',
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 2,
            },
            {
                label: translater("homePageMissingCalls").toString(),
                data: missedCallsPerHour,
                fill: false,
                borderColor: '#F04143',
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 2,
            },
        ]
    };
    var chartOptionsY = {
        title: {
            display: true,
            text: translater("userDashboardChartOptionsY"),
            color: '#ccc'
        },
        ticks: {
            stepSize: 1,
            color: '#9e9e9e'
        },
        grid: {
            display: false
        }
    };
    if (callsPerHour.every(function (item) { return item === 0; }))
        chartOptionsY.min = 0;
    var chartOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || '';
                        var value = context.parsed.y;
                        return "".concat(label, " ").concat(value);
                    }
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: translater("userDashboardChartOptionsX"),
                    color: '#ccc'
                },
                ticks: {
                    color: '#9e9e9e'
                },
                grid: {
                    display: false
                }
            },
            y: {
                title: {
                    display: true,
                    text: translater("userDashboardChartOptionsY"),
                    color: '#ccc'
                },
                ticks: {
                    stepSize: 1,
                    color: '#9e9e9e'
                },
                grid: {
                    display: false
                },
                beginAtZero: true
            }
        }
    };
    var kpiValue = 0;
    if (data) {
        var incommingAmount = data.incomming.amount, missingAmount = data.missing.amount;
        if (incommingAmount > 0 && incommingAmount >= missingAmount)
            kpiValue = missingAmount / (incommingAmount + missingAmount) * 100;
    }
    return (_jsxs("div", __assign({ className: 'home-statistics flex gap-2 mx-3 mt-2 mb-0' }, { children: [_jsx("div", __assign({ className: clsx("home-statistics-block special flex flex-column justify-content-center w-full gap-3 py-4 border-round-md shadow-2 relative", {
                    "bg-white": !isDarkMode,
                    "surface-card": isDarkMode
                }) }, { children: _jsx("div", __assign({ className: 'flex flex-column' }, { children: _jsx(Chart, { style: { height: '150px' }, type: "line", className: 'mt-1 px-3', data: chartData, options: chartOptions }) })) })), _jsxs("div", __assign({ className: 'home-statistics-info w-full flex gap-2' }, { children: [_jsxs("div", __assign({ className: clsx("home-statistics-block flex flex-column w-full gap-3 p-4 border-round-md shadow-2", {
                            "bg-white": !isDarkMode,
                            "surface-card": isDarkMode
                        }) }, { children: [_jsxs("div", __assign({ className: 'flex flex-column' }, { children: [_jsx("h3", __assign({ style: { color: '#F59C12' }, className: 'm-0 text-4xl' }, { children: _jsx(AnimatedNumber, { value: data ? data.incomming.amount : 0 }) })), _jsx("span", __assign({ className: 'text-sm mt-1 text-500' }, { children: "\n                  ".concat(translater("homePageIncomingCallsBlock").toString(), " \n                  ").concat(translater("homePagePerPeriod").toString(), " \n                  \"").concat(currentPeriod, "\"\n                ") }))] })), _jsxs("div", __assign({ className: 'flex flex-column ' }, { children: [_jsxs("h3", __assign({ style: { color: '#F59C12' }, className: 'm-0 text-3xl' }, { children: [_jsx(AnimatedNumber, { value: data ? data.incomming.averageDuration : 0 }), " ", translater("homePageUnitSecond")] })), _jsx("span", __assign({ className: 'text-sm mt-1 text-500' }, { children: translater("homePageAverageDurationCallsBlock") }))] }))] })), _jsxs("div", __assign({ className: clsx("home-statistics-block flex flex-column w-full gap-3 p-4 border-round-md shadow-2", {
                            "bg-white": !isDarkMode,
                            "surface-card": isDarkMode
                        }) }, { children: [_jsxs("div", __assign({ className: 'flex flex-column' }, { children: [_jsx("h3", __assign({ style: { color: '#F04143' }, className: 'm-0 text-4xl' }, { children: _jsx(AnimatedNumber, { value: data ? data.missing.amount : 0 }) })), _jsx("span", __assign({ className: 'flex text-sm mt-1 text-500' }, { children: "\n                  ".concat(translater("homePageMissingCallsBlock").toString(), " \n                  ").concat(translater("homePagePerPeriod").toString(), " \n                  \"").concat(currentPeriod, "\"\n                ") }))] })), _jsxs("div", __assign({ className: 'flex flex-column' }, { children: [_jsxs("h3", __assign({ style: { color: '#6366f1' }, className: 'm-0 text-3xl' }, { children: [_jsx(AnimatedNumber, { value: kpiValue }), " %"] })), _jsx("span", __assign({ className: 'text-sm mt-1 text-500' }, { children: translater("homePageIncomingMissingCallsKPI") }))] }))] }))] }))] })));
};
