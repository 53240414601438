import { v4 as uuidv4 } from 'uuid';
import { ADD_TREE_HISTORY, CLEAN_TREE_HISTORY, HIDE_FORM, HIDE_MINIMAP, RETURN_PREVIOUS_TREE, SET_DRAGGING_MODE, SET_FORM_DATA, SET_FORM_LOADER, SET_MOVING_MODE, SET_MOVING_NODE, SET_TREE_BLOCKS, SHOW_FORM, SHOW_MINIMAP, UPDATE_TREE } from "../reducers/builderReducer";
import { store } from "../store";
import { clone, findParentElementById, findTreeElementById, replaceElementById } from "../../shared/pages/builder/utils/tree";
export var getCurrentTree = function () { return clone(store.getState().builder.tree); };
export var showForm = function () {
    return store.dispatch({ type: SHOW_FORM });
};
export var hideForm = function () {
    return store.dispatch({ type: HIDE_FORM });
};
export var showMinimap = function () {
    return store.dispatch({ type: SHOW_MINIMAP });
};
export var hideMinimap = function () {
    return store.dispatch({ type: HIDE_MINIMAP });
};
export var setFormLoaderMode = function (mode) {
    return store.dispatch({ type: SET_FORM_LOADER, payload: mode });
};
export var setFormData = function (data) {
    return store.dispatch({ type: SET_FORM_DATA, payload: data });
};
export var setDraggingMode = function (mode) {
    return store.dispatch({ type: SET_DRAGGING_MODE, payload: mode });
};
export var setMovingMode = function (mode) {
    return store.dispatch({ type: SET_MOVING_MODE, payload: mode });
};
export var setMovingNode = function (node) {
    return store.dispatch({ type: SET_MOVING_NODE, payload: node });
};
export var setBuilderBlocks = function (blocks) {
    return store.dispatch({ type: SET_TREE_BLOCKS, payload: blocks });
};
export var addTreeHistory = function (tree) {
    // const { builder: { treeHistory } }: RootState = store.getState()
    return store.dispatch({ type: ADD_TREE_HISTORY, payload: clone(tree) });
};
export var returnPreviousTree = function () {
    var treeHistory = store.getState().builder.treeHistory;
    if (treeHistory.length > 0) {
        var tree = treeHistory[treeHistory.length - 1];
        return store.dispatch({ type: RETURN_PREVIOUS_TREE, payload: clone(tree) });
    }
};
export var cleanTreeHistory = function () {
    return store.dispatch({ type: CLEAN_TREE_HISTORY });
};
export var updateTreeWithoutHistorySaving = function (updTree) {
    return store.dispatch({ type: UPDATE_TREE, payload: updTree });
};
export var updateTree = function (updTree) {
    var tree = getCurrentTree();
    addTreeHistory(tree);
    return store.dispatch({ type: UPDATE_TREE, payload: updTree });
};
export var addChild = function (child, parent, branch) {
    var tree = getCurrentTree();
    var element = clone(parent);
    if (branch)
        child.branch = branch;
    child.id = uuidv4();
    element.children.push(child);
    if (branch) {
        var sorted = element.children.sort(function (a, b) { return a.branch - b.branch; });
        element.children = clone(sorted);
    }
    var updTree = replaceElementById(tree, parent.id, element);
    updateTree(clone(updTree));
};
export var removeNodeById = function (id) {
    var currentTree = store.getState().builder.tree;
    var parent = findParentElementById(currentTree, id);
    if (parent) {
        parent.children.length = 0;
        var tree = replaceElementById(currentTree, parent.id, parent);
        updateTree(clone(tree));
    }
};
export var moveNodes = function (movedNode, droppedNode, callback, branch) {
    var currentTree = store.getState().builder.tree;
    var tree = clone(currentTree);
    var parent = findParentElementById(tree, movedNode.id);
    // Если просто меняется позиция элемента внутри множественного родителя
    if (droppedNode.id === parent.id) {
        parent.children = parent.children.map(function (child) {
            if (child.id === movedNode.id)
                child.branch = branch;
            return child;
        });
        parent.children = parent.children.sort(function (a, b) { return a.branch - b.branch; });
        tree = replaceElementById(tree, parent.id, parent);
        return updateTree(tree);
    }
    parent.children = parent.children
        .filter(function (child) { return child.id !== movedNode.id; });
    tree = replaceElementById(tree, parent.id, parent);
    callback();
    // Одиночный на одиночный 
    if (!movedNode.isChildrenList && !droppedNode.isChildrenList) {
        movedNode.branch = '';
        droppedNode.children.push(movedNode);
        tree = replaceElementById(tree, droppedNode.id, droppedNode);
        return updateTree(tree);
    }
    // Одиночный на множественный 
    if (!movedNode.isChildrenList && droppedNode.isChildrenList) {
        movedNode.branch = branch;
        if (parent.isChildrenList) {
            parent.children = parent.children
                .filter(function (child) { return child.id !== movedNode.id; });
            tree = replaceElementById(tree, parent.id, parent);
        }
        droppedNode.children.push(movedNode);
        droppedNode.children = droppedNode.children.sort(function (a, b) { return a.branch - b.branch; });
        tree = replaceElementById(tree, droppedNode.id, droppedNode);
        var parentClone = findTreeElementById(tree, parent.id);
        parentClone.children = parentClone.children
            .filter(function (child) { return child.id !== movedNode.id; });
        tree = replaceElementById(tree, parentClone.id, parentClone);
        return updateTree(tree);
    }
    // Множественный на одиночный
    if (movedNode.isChildrenList && !droppedNode.isChildrenList) {
        movedNode.branch = '';
        droppedNode.children.push(movedNode);
        tree = replaceElementById(tree, droppedNode.id, droppedNode);
        return updateTree(tree);
    }
    // Множественный на множественный
    if (movedNode.isChildrenList && droppedNode.isChildrenList) {
        callback();
        movedNode.branch = branch;
        droppedNode.children.push(movedNode);
        droppedNode.children = droppedNode.children.sort(function (a, b) { return a.branch - b.branch; });
        tree = replaceElementById(tree, droppedNode.id, droppedNode);
        parent.children = parent.children
            .filter(function (child) { return child.id !== movedNode.id; });
        tree = replaceElementById(tree, parent.id, parent);
        return updateTree(tree);
    }
};
