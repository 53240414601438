// import { surnameColumn } from "./columns/surname"
// import { nameColumn } from "./columns/name"
// import { secnameColumn } from "./columns/secname"
// import { mainPhoneColumn } from "./columns/mainPhone"
import { loginColumn } from "./columns/login";
import { baseDN } from "./columns/baseDN";
import { emailColumn } from "./columns/email";
import { rolesColumn } from "./columns/roles";
import { phonesColumn } from "./columns/phones";
import { groupsColumn } from "./columns/groups";
import { typeColumn } from "./columns/type";
import { fullnameColumn } from "./columns/fullname";
export var dynamicColumns = [
    loginColumn,
    fullnameColumn,
    rolesColumn,
    phonesColumn,
    emailColumn,
    groupsColumn,
    typeColumn,
    baseDN
];
