var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import { translater } from "../../../../../../utils/localization/localization";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
export var compareChildrenContext = function (prevChildren, newChildren) {
    var prevChildrenIds = prevChildren.map(function (item) { return item._id; }).join(' ');
    var newChildrenIds = newChildren.map(function (item) { return item._id; }).join(' ');
    return prevChildrenIds === newChildrenIds;
};
export var SaveOrderButton = function (_a) {
    var id = _a.id, loadData = _a.loadData, contextChildren = _a.contextChildren, contextChildrenHistory = _a.contextChildrenHistory;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var saveOrder = function () {
        setLoading(true);
        var children = contextChildren.map(function (child) {
            return {
                id: child._id,
                type: child.type
            };
        });
        API.contextsSaveOptions(id, children)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            loadData();
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("span", __assign({ "data-pr-tooltip": translater("contextPageHeaderSaveOrderBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-return-back button-mini" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-save", loading: loading, disabled: compareChildrenContext(contextChildrenHistory, contextChildren), className: "min-w-max text-green-600 border-green-600 p-button-outlined", onClick: function () { return saveOrder(); } }), _jsx(Tooltip, { target: ".custom-target-icon-return-back" })] })), _jsx(Button, { type: "button", icon: "pi pi-save", loading: loading, disabled: compareChildrenContext(contextChildrenHistory, contextChildren), label: translater('contextPageHeaderSaveOrderBtn').toString(), className: "min-w-max full-button text-green-600 border-green-600 p-button-outlined", onClick: function () { return saveOrder(); } })] }));
};
