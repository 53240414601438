var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useController } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
import { useState } from "react";
export var PhonesField = function (_a) {
    var _b = _a.form, control = _b.control, trigger = _b.trigger, getValues = _b.getValues, setValue = _b.setValue;
    var _c = __read(useState(''), 2), currentPhone = _c[0], setCurrentPhone = _c[1];
    var _d = useController({
        control: control,
        name: "phones",
        rules: {
            validate: function (phones) {
                if (currentPhone && phones.find(function (p) { return p.toLocaleLowerCase() === currentPhone.trim().toLocaleLowerCase(); })) {
                    return translater("usersPageCreateUserValidatePhonesPhoneNumber").toString();
                }
                if (!phones.length)
                    return translater("usersPageCreateUserValidatePhonesField").toString();
                if ((!currentPhone) && phones.length > 0)
                    return true;
                return true;
            }
        }
    }), field = _d.field, fieldState = _d.fieldState;
    var onChangeHandler = function (phone) {
        //  Удаление всех символов, кроме цифр
        setCurrentPhone(phone.replace(/\D/g, ''));
        setTimeout(function () { trigger('phones'); }, 0);
    };
    var addPhone = function (value) {
        // Поле не может быть пустым
        if (!value.trim())
            return false;
        if (+value && +value <= 0)
            return false;
        // Проверяем совпадение номеров
        var diff = field.value.find(function (p) { return p.toLocaleLowerCase() === value.trim().toLocaleLowerCase(); });
        if (diff)
            return false;
        field.onChange(__spreadArray(__spreadArray([], __read(field.value), false), [value], false));
        // Чистим поле
        setCurrentPhone('');
        setTimeout(function () { trigger('phones'); }, 0);
    };
    var onKeyDownHandler = function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            var value = event.target.value;
            if (+value && +value > 0)
                addPhone(value);
        }
    };
    var onClickHandler = function (value) { return addPhone(value); };
    var deletePhone = function (phone) {
        var result = field.value.filter(function (item) { return item !== phone; });
        // Чистим главный телефон, если он был удален из списка номеров
        if (getValues('mainPhone') && getValues('mainPhone') === phone) {
            setValue('mainPhone', '');
        }
        field.onChange(result);
        setTimeout(function () { trigger('phones'); }, 0);
    };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ className: "block text-900 font-medium mb-2" }, { children: translater("usersPageCreateUserPhones") })), _jsx("div", __assign({ className: "field mb-2 col-12 p-0" }, { children: _jsxs("div", __assign({ className: "p-inputgroup relative" }, { children: [_jsx(InputText, { value: currentPhone, id: "phones", onChange: function (e) { return onChangeHandler(e.target.value); }, onKeyDown: onKeyDownHandler, placeholder: translater("usersPageCreateUserPhonesPlaceholder").toString(), className: "w-full", style: { borderTopRightRadius: "6px", borderBottomRightRadius: "6px" } }), _jsx("i", { className: "pi pi-reply absolute cursor-pointer z-2", onClick: function () { return onClickHandler(currentPhone); }, style: {
                                transform: "rotate(180deg)",
                                right: "6px", bottom: "6px",
                                padding: "7px",
                                border: "1px solid #ccc",
                                backgroundColor: "#fff",
                                borderRadius: "6px"
                            } })] })) })), _jsx(ErrorBlock, { errors: fieldState, keyword: "error" }), _jsx("div", __assign({ className: "w-full flex flex-column gap-2" }, { children: field.value.map(function (phone) {
                    return _jsxs("div", __assign({ className: "flex p-2 p-inputtext p-component" }, { children: [_jsxs("div", __assign({ className: "w-full flex align-items-center gap-2 text-sm" }, { children: [_jsx("i", { className: "text-sm pi pi-phone" }), phone] })), _jsx("span", { onClick: function () { return deletePhone(phone); }, className: "p-button-icon p-c pi pi-times cursor-pointer text-xs flex align-items-center justify-content-center" })] }), phone);
                }) }))] }));
};
