var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { translater } from "../../../../../../../utils/localization/localization";
import { API } from "../../../../../../../api";
import { SecretKeyField } from "./fields/SecretKeyField";
import { CertificateField } from "./fields/CertificateField";
import { Preview } from "./components/Preview";
import { useForm } from "react-hook-form";
var defaultCertificate = {
    subject: '',
    subjectAltName: '',
    issuer: '',
    validFrom: String(new Date()),
    validTo: String(new Date()),
};
export var Form = function (_a) {
    var loadData = _a.loadData, hideModal = _a.hideModal;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), modalVisible = _c[0], setModalVisible = _c[1];
    var _d = __read(useState(defaultCertificate), 2), preview = _d[0], setPreview = _d[1];
    var checkCertificate = function (_a) {
        var certificate = _a.certificate;
        setLoading(true);
        API.getMeta({ certificate: certificate })
            .then(function (response) {
            var data = response.data;
            setPreview(data);
            setModalVisible(true);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var form = useForm({ mode: 'all' });
    var setValue = form.setValue, handleSubmit = form.handleSubmit, trigger = form.trigger, _e = form.formState, errors = _e.errors, isValid = _e.isValid, submitCount = _e.submitCount;
    var onSubmit = function (data) {
        checkCertificate(data);
    };
    var setCertificate = function (content) {
        setValue('certificate', content.trim().replace(/^\s+|\s+$/g, ''));
        trigger('certificate');
    };
    var setPrivateKey = function (content) {
        setValue('key', content.trim().replace(/^\s+|\s+$/g, ''));
        trigger('key');
    };
    return (_jsx(_Fragment, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsxs("div", __assign({ className: "w-full flex justify-content-between mt-2 gap-3" }, { children: [_jsx("div", __assign({ className: "w-full flex surface-section p-4 shadow-2 border-round" }, { children: _jsx(CertificateField, { form: form, setCertificate: setCertificate }) })), _jsx("div", __assign({ className: "w-full flex surface-section p-4 shadow-2 border-round" }, { children: _jsx(SecretKeyField, { form: form, setPrivateKey: setPrivateKey }) }))] })), _jsxs("div", __assign({ className: "flex justify-content-end gap-3 mt-4" }, { children: [_jsx(Button, { label: translater("certificateTabPageCertificateInformationModalCancelButton").toString(), disabled: loading, className: 'border-orange-400 text-orange-400 p-button-outlined', onClick: function () { return hideModal(); } }), _jsx(Button, { label: translater("certificateTabPageCheckButton").toString(), icon: "pi pi-check", className: "p-button-success", loading: loading, disabled: submitCount > 0 && !isValid })] })), _jsx(Preview, { loadData: loadData, hideModal: hideModal, modalVisible: modalVisible, form: form, setModalVisible: setModalVisible, information: preview })] })) }));
};
