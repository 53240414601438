import { jsx as _jsx } from "react/jsx-runtime";
export var Preloader = function (_a) {
    var _b = _a.height, height = _b === void 0 ? '50px' : _b, _c = _a.width, width = _c === void 0 ? '100%' : _c, _d = _a.radius, radius = _d === void 0 ? '4px' : _d;
    var styles = {
        width: width,
        minWidth: width,
        height: height,
        borderRadius: radius
    };
    return _jsx("div", { style: styles, className: "preloader-component" });
};
