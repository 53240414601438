var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Button } from "primereact/button";
import { DeleteGroupsButton } from "./components/DeleteGroupsButton";
import { translater } from "../../../../../utils/localization/localization";
import { CreateGroup } from "./components/create-modal/CreateGroup";
export var Header = function (_a) {
    var selectedGroups = _a.selectedGroups, clearSelectedGroups = _a.clearSelectedGroups, loadData = _a.loadData;
    var _b = __read(useState(false), 2), dialogVisible = _b[0], setDialogVisible = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "groups-header-blocks w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsx("div", __assign({ className: "users-create-header-block flex gap-2" }, { children: _jsx(Button, { type: "button", icon: "pi pi-plus", label: translater('groupsPageAddGroupButton').toString(), className: "min-w-max p-button-success", onClick: function () { return setDialogVisible(true); } }) })), _jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(DeleteGroupsButton, { loadData: loadData, selectedGroups: selectedGroups, clearSelectedGroups: clearSelectedGroups }) }))] })), _jsx(CreateGroup, { loadData: loadData, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible })] }));
};
