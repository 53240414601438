var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../../../../../api";
import { getCurrentHomeData, setHomeData } from "../../../../../../redux/actions/appActions";
import { dateAdapter, getPeriods } from "../../../../../../utils/localization/time";
export var SecurityDashboard = function () {
    var _a = useSelector(function (state) { return state; }).app.homeData, homeDataDetails = _a.data, userPhones = _a.phones;
    var periods = getPeriods();
    var _b = __read(useState(periods[0]), 2), period = _b[0], setPeriod = _b[1];
    var _c = __read(useState(true), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(null), 2), data = _d[0], setData = _d[1];
    var changePeriod = function (phones, period) {
        setPeriod(period);
        loadPhonesStatistics(phones, period);
    };
    // Загружаем данные для домашней страницы
    var loadPhonesStatistics = function (phones, period) {
        setLoading(true);
        API.getPhonesStatistics(phones, dateAdapter(period.content))
            .then(function (response) {
            var information = response.data.information;
            if (information)
                setData(information);
            var data = getCurrentHomeData();
            setHomeData(__assign(__assign({}, data), { data: information }));
            setLoading(false);
        })
            .catch(function () { return setLoading(false); })
            .finally(function () { return setLoading(false); });
    };
    // Загружаем номера телефонов
    var loadPhones = function () {
        setLoading(true);
        API.getPhones()
            .then(function (response) {
            var _a = response.data, phones = _a.phones, mainPhone = _a.mainPhone;
            var currentHomeData = getCurrentHomeData();
            setHomeData(__assign(__assign({}, currentHomeData), { data: null, phones: phones, mainPhone: mainPhone }));
            loadPhonesStatistics(phones, period);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        var homeData = getCurrentHomeData();
        if (homeData.data) {
            var data_1 = homeData.data;
            setData(data_1);
            setLoading(false);
        }
        else {
            loadPhones();
        }
    }, [homeDataDetails]);
    return (_jsx(_Fragment, {}));
};
