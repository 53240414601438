var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
var template = function (_a) {
    var phones = _a.phones, mainPhone = _a.mainPhone;
    return (_jsx("div", __assign({ className: "flex flex-wrap align-items-center justify-content-center gap-1" }, { children: phones.map(function (phone) { return (_jsx("span", __assign({ className: clsx("mr-1 px-2 py-1 border-indigo-200 border-round-xs border-1", {
                "border-red-200": phone === mainPhone
            }) }, { children: phone }), phone)); }) })));
};
export var phonesColumn = {
    columnHeader: 'usersPagePhonesColumn',
    field: 'phones',
    style: {
        minWidth: "300px"
    },
    body: template
};
