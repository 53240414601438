import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useSelector } from 'react-redux';
import { hideMinimap, showMinimap } from '../../../../../../../redux/actions/builderAction';
import { translater } from '../../../../../../../utils/localization/localization';
export var MiniMapToggle = function () {
    var minimapVisible = useSelector(function (state) { return state.builder; }).minimapVisible;
    var minimapToggle = function () { return minimapVisible ? hideMinimap() : showMinimap(); };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": translater("builderMiniMapToggle"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", onClick: function () { return minimapToggle(); }, className: 'mini-map-button p-button-outlined p-button-icon-only p-button-secondary', disabled: !minimapVisible, icon: "pi pi-map" }), _jsx(Tooltip, { target: ".mini-map-button" })] }));
};
