var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { setEnglishLanguage, setRussianLanguage } from "../../../redux/actions/languageActions";
export var Translater = function (_a) {
    var style = _a.style, className = _a.className;
    var lang = useSelector(function (state) { return state.language; }).lang;
    var changeLanguage = function () {
        if (lang === 'ru')
            return setEnglishLanguage();
        return setRussianLanguage();
    };
    return (_jsx("button", __assign({ type: "button", onClick: changeLanguage, className: "cursor-pointer flex align-items-center justify-content-center p-3 uppercase text-indigo-500 border-1 border-circle border-indigo-500 font-bold select-none ".concat(className), style: __assign({ background: 'none', width: '24px', height: '16px' }, style) }, { children: lang })));
};
