var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { Header } from "./component/header/Header";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
import { FileUpdoad } from "./component/file-updoad/FileUpdoad";
import { ConnectionCard } from "./component/connection-card/ConnectionCard";
import { API } from "../../../api";
import { setFileLoadedStatus } from "../../../redux/actions/supportActions";
import { Skeleton } from "primereact/skeleton";
export var Support = function (_a) {
    var title = _a.title;
    var isFileLoaded = useSelector(function (state) { return state; }).support.isFileLoaded;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        var checkFileExistence = function () {
            setLoading(true);
            API.vpnFileExistence()
                .then(function (response) {
                var fileExistence = response.data.fileExistence;
                if (fileExistence)
                    setFileLoadedStatus(true);
                setLoading(false);
            })
                .catch(function (error) {
                setLoading(false);
            });
        };
        checkFileExistence();
    }, []);
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/interprise-support' }]);
    }, []);
    if (loading) {
        return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx(Skeleton, { height: "4rem", width: "100%" }), _jsx("br", {}), _jsx(Skeleton, { height: "3rem", width: "100%" }), _jsx(Skeleton, { height: "3rem", width: "100%" }), _jsx(Skeleton, { height: "3rem", width: "100%" }), _jsx(Skeleton, { height: "3rem", width: "100%" }), _jsx(Skeleton, { height: "3rem", width: "100%" })] })));
    }
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx(Header, {}), isFileLoaded === false ? _jsx(FileUpdoad, {}) : _jsx(ConnectionCard, {})] })));
};
