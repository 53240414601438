var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { DeleteRulesButton } from "./components/DeleteRulesButton";
import { translater } from "../../../../../utils/localization/localization";
import { useNavigate } from "react-router-dom";
export var Header = function (_a) {
    var selectedRules = _a.selectedRules, clearSelectedRules = _a.clearSelectedRules, loadData = _a.loadData;
    var navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "groups-header-blocks w-full flex gap-4 justify-content-between align-items-center" }, { children: [_jsx("div", __assign({ className: "users-create-header-block flex gap-2" }, { children: _jsx(Button, { type: "button", icon: "pi pi-plus", label: translater('rulesPageAddRuleButton').toString(), className: "min-w-max p-button-success", onClick: function () { return navigate('/rule/create'); } }) })), _jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(DeleteRulesButton, { loadData: loadData, selectedRules: selectedRules, clearSelectedRules: clearSelectedRules }) }))] })) }));
};
