var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
export var template = function (_a) {
    var roles = _a.roles;
    return (roles.map(function (role) {
        var isAdmin = role.title === 'Administrator';
        var isUser = role.title === 'User';
        var isSupervisor = role.title === 'Supervisor';
        var isSecurity = role.title === 'Security';
        return (_jsx("span", __assign({ className: clsx({
                'mr-1 px-2 py-1 font-medium': true,
                'text-indigo-500': isAdmin,
                'text-orange-500': isSupervisor,
                'text-green-500': isUser,
                'text-600': isSecurity,
            }) }, { children: role.title }), role.title));
    }));
};
export var rolesColumn = {
    columnHeader: 'usersPageRolesColumn',
    field: 'roles',
    body: template
};
