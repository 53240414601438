var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import { API } from '../../../../../../../../api';
import { successNotification } from '../../../../../../../../redux/actions/notificationsActions';
import { translater } from '../../../../../../../../utils/localization/localization';
var defaultAudioData = {
    name: '',
    file: null,
};
export var CreateModal = function (_a) {
    var hide = _a.hide, setFinder = _a.setFinder, refresh = _a.refresh;
    var _b = __read(useState(defaultAudioData), 2), audio = _b[0], setAudio = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var ref = useRef(null);
    var nameRef = useRef(null);
    var create = function () {
        if (!audio.file)
            return;
        setLoading(true);
        var formData = new FormData();
        formData.append('name', audio.name);
        formData.append('file', audio.file);
        API.createAWSAudio(formData)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            hide();
            refresh();
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var validation = function () {
        if (audio.name.trim() === '')
            return true;
        if (!audio.file)
            return true;
        return false;
    };
    var clear = function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.clear();
        setAudio(function (prev) { return (__assign(__assign({}, prev), { file: null })); });
    };
    var uploader = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, file;
        return __generator(this, function (_b) {
            _a = __read(event.files, 1), file = _a[0];
            if (file) {
                // Сохраняем файл напрямую
                setAudio(function (prev) { return (__assign(__assign({}, prev), { file: file })); });
            }
            return [2 /*return*/];
        });
    }); };
    var handleKeyDown = function (event) {
        console.log(event);
        if (event.key === "Enter")
            return false;
    };
    // useEffect(() => {
    //   window.addEventListener("keydown", handleKeyDown)
    //   return () => {
    //     window.removeEventListener("keydown", handleKeyDown)
    //   }
    // }, [])
    return (_jsxs(Dialog, __assign({ visible: true, header: translater("awsAudioItemCreateModalHeader"), style: { width: '50vw' }, onHide: function () { return hide(); } }, { children: [_jsx(OverlayPanel, __assign({ ref: nameRef }, { children: translater("awsAudioItemCreateModalNameLabelHint") })), _jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsxs("div", __assign({ className: "flex flex-column gap-1" }, { children: [_jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = nameRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("awsAudioItemCreateModalNameLabel")] }), _jsx(InputText, { value: audio.name, placeholder: translater("awsAudioItemCreateModalNamePlaceholder").toString(), onChange: function (e) {
                                    return setAudio(function (prev) { return (__assign(__assign({}, prev), { name: e.target.value })); });
                                } })] })), _jsxs("div", __assign({ className: "flex flex-column gap-1" }, { children: [_jsx("label", { children: translater("awsAudioItemCreateModalAudioFileLabel") }), _jsxs("div", __assign({ className: "flex gap-2 w-full" }, { children: [audio.file ? (_jsx("span", __assign({ className: 'w-full p-2 pl-3 flex align-items-center border-1 border-300 border-round-sm' }, { children: translater("awsAudioItemUpdateModalAudioFileLoaded") }))) : (_jsx(FileUpload, { ref: ref, mode: "basic", id: 'audio-loader', chooseLabel: translater("awsAudioItemCreateModalAudioFileChooseLabel").toString(), accept: "audio/wav", className: "w-full audio-area-select-button", customUpload: true, onSelect: function (event) { return uploader(event); } })), _jsx(Button, { disabled: !audio.file, type: "button", icon: "pi pi-trash", onClick: clear, className: "p-button-danger p-button-outlined" })] }))] })), _jsx(Button, { disabled: validation(), loading: loading, onClick: create, label: translater("awsAudioItemCreateModalButton").toString(), className: "w-full p-button-success" })] }))] })));
};
