var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { Terminal as TerminalComponent } from '@xterm/xterm';
import { FitAddon } from '@xterm/addon-fit';
import { ClipboardAddon } from '@xterm/addon-clipboard';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import io from 'socket.io-client';
import { useDocumentTitle } from '../../../utils/routes/routes';
import { setBreadCrumbs } from '../../../redux/actions/appActions';
import { getWebsiteUrl } from '../../../api';
import { FontSizeArea } from '../../components/particals/editor/components/FontSizeArea';
import { FullScreenToggle } from '../builder/components/header/components/buttons/FullScreenToggle';
import '@xterm/xterm/css/xterm.css';
export var Terminal = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    var _b = useSelector(function (state) { return state; }), isDarkMode = _b.theme.isDarkMode, _c = _b.editor, isSyntaxRegistered = _c.isSyntaxRegistered, fontSize = _c.fontSize, programmingLanguage = _c.programmingLanguage;
    var terminalRef = useRef(null);
    var termRef = useRef(null);
    var location = useLocation();
    var _d = __read(useSearchParams(), 1), searchParams = _d[0];
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/terminal' }]);
    }, []);
    useEffect(function () {
        var term = new TerminalComponent({
            fontFamily: 'monospace',
            fontSize: fontSize,
            cursorBlink: true
        });
        termRef.current = term;
        var domain = getWebsiteUrl().domain;
        var socket = io("ws://".concat(domain));
        socket.on('connect', function () {
            socket.emit('admin-authenticate', { token: localStorage.getItem('token') });
        });
        socket.on('admin-authenticate', function (data) {
            if (data.success) {
                socket.emit('terminal-connection');
            }
            else {
                console.error('Authentication failed:', data.error);
            }
        });
        socket.on('auth_error', function (data) {
            console.error('Authentication error:', data.error);
        });
        socket.on('message', function (event) {
            var data = JSON.parse(event);
            if (data.type === "terminal-data")
                term.write(data.data);
        });
        if (terminalRef.current) {
            // Модуль для адаптации размеров консоли по родителькому блоку
            var fitAddon = new FitAddon();
            var clipboardAddon = new ClipboardAddon();
            term.loadAddon(fitAddon);
            term.loadAddon(clipboardAddon);
            term.attachCustomKeyEventHandler(function (event) {
                // Проверка на Windows (Ctrl) и macOS (Cmd/Meta)
                var isPasteShortcut = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'v';
                var clearAll = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'k';
                if (isPasteShortcut) {
                    navigator.clipboard.readText()
                        .then(function (text) {
                        var e_1, _a;
                        if (text) {
                            try {
                                for (var text_1 = __values(text), text_1_1 = text_1.next(); !text_1_1.done; text_1_1 = text_1.next()) {
                                    var char = text_1_1.value;
                                    term._core._onData.fire(char);
                                    // Отправляем символы на сервер через WebSocket
                                    socket.send(JSON.stringify({
                                        type: "terminal-command",
                                        command: char, // Отправляем символ серверу
                                    }));
                                }
                            }
                            catch (e_1_1) { e_1 = { error: e_1_1 }; }
                            finally {
                                try {
                                    if (text_1_1 && !text_1_1.done && (_a = text_1.return)) _a.call(text_1);
                                }
                                finally { if (e_1) throw e_1.error; }
                            }
                        }
                    })
                        .catch(function (err) {
                        console.error('Ошибка вставки текста из буфера обмена:', err);
                    });
                    return false; // Предотвращает дефолтное поведение браузера
                }
                // Очистка всего
                if (clearAll) {
                    term.clear();
                    return false;
                }
                return true;
            });
            term.open(terminalRef.current);
            fitAddon.fit();
        }
        term.onKey(function (e) {
            socket.send(JSON.stringify({
                type: "terminal-command",
                command: e.key,
            }));
        });
        if (location.search) {
            var name_1 = searchParams.get('name');
            var hostname = searchParams.get('hostname');
            var user = searchParams.get('user');
            if (name_1 && hostname && user) {
                var cmd = "ssh ".concat(user, "@").concat(hostname);
                setTimeout(function () { document.title = name_1; }, 1000);
                socket.send(JSON.stringify({
                    type: "terminal-command",
                    command: "".concat(cmd, "\r"), // добавляем \r для эмуляции нажатия Enter
                }));
            }
        }
        socket.on('disconnect', function () {
            console.log('Connection closed!');
        });
        return function () {
            socket.close();
        };
    }, []);
    useEffect(function () {
        if (termRef.current) {
            termRef.current.options.fontSize = fontSize;
        }
    }, [fontSize]);
    return (_jsxs("div", __assign({ className: 'flex flex-column p-3 gap-2 h-screen' }, { children: [_jsxs("div", __assign({ className: "w-full flex align-items-center justify-content-start gap-2 surface-card shadow-2 border-round p-3" }, { children: [_jsx(FullScreenToggle, {}), _jsx(FontSizeArea, { hiddenFields: ['font-family'], styling: 'border-1 p-3 border-round-md button-size flex align-items-center justify-content-center' })] })), _jsx("div", __assign({ style: { background: '#000' }, className: "max-w-full h-full shadow-2 p-3 border-round" }, { children: _jsx("div", { className: 'h-full', ref: terminalRef }) }))] })));
};
