import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { setFormData, showForm } from "../../../../../../../redux/actions/builderAction";
import { findTreeElementById } from "../../../../utils/tree";
import { useSelector } from "react-redux";
export var SettingsButton = function (_a) {
    var id = _a.id, form = _a.form;
    var tree = useSelector(function (state) { return state.builder; }).tree;
    var showSettings = function () {
        var node = findTreeElementById(tree, id);
        var generatedForm = Object.assign({}, form, { nodeId: id, settings: node.settings });
        setFormData(generatedForm);
        showForm();
    };
    return (_jsx("i", { onClick: showSettings, className: clsx("pi pi-cog", "node-button"), style: { fontSize: '0.6rem', color: '#787878' } }));
};
