var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import Parser from "html-react-parser";
import { Button } from "primereact/button";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../utils/localization/localization";
export var EntityDependencyErrorArea = function (_a) {
    var errors = _a.errors, visible = _a.visible, hide = _a.hide, refresh = _a.refresh, remove = _a.remove, loading = _a.loading;
    var _b = __read(useState(''), 2), finder = _b[0], setFinder = _b[1];
    var resolveError = function (path) {
        window.open("".concat(window.location.origin).concat(path));
    };
    return (_jsxs(Dialog, __assign({ header: translater('entityDependencyErrorAreaReadyModalHeader'), visible: visible, style: { width: '50vw', maxHeight: '40vw' }, footer: errors.length > 0 ? (_jsx(Button, { loading: loading, onClick: function () { return refresh(); }, label: translater('entityDependencyErrorAreaCheckAgainBtn').toString(), className: "w-full mt-2 p-button-outlined" })) : (_jsx(Button, { loading: loading, onClick: function () { return remove(); }, label: translater('entityDependencyErrorAreaReadyToRemoveBtn').toString(), className: "w-full mt-2 p-button-outlined border-green-600 text-green-600" })), onHide: function () { return hide(); } }, { children: [errors.length > 1 ? (_jsx(InputText, { placeholder: translater('entityDependencyErrorAreaReadyModalPlaceholder').toString(), className: "mt-1 mb-2 w-full", value: finder, onChange: function (e) { return setFinder(e.target.value); } })) : '', errors.length > 0 ? (_jsx("div", __assign({ className: "flex flex-column gap-2" }, { children: errors
                    .filter(function (error) {
                    return error.message.toUpperCase().includes(finder.toUpperCase());
                })
                    .map(function (error, index) {
                    var _a = error.message, message = _a === void 0 ? '' : _a, _b = error.resolvePath, resolvePath = _b === void 0 ? '' : _b;
                    return (_jsx(Message, { style: {
                            border: 'solid #ff5757',
                            borderWidth: '0 0 0 6px',
                            color: '#ff5757'
                        }, className: "w-full justify-content-start flex flex-column align-items-start", severity: "error", content: _jsxs("div", __assign({ className: "ml-2 flex flex-column gap-1" }, { children: [_jsx("span", { children: Parser(message) }), resolvePath ? (_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("i", { className: "pi pi-external-link app-link-color text-sm" }), _jsx("span", __assign({ className: "app-link-color no-underline cursor-pointer", onClick: function () { return resolveError(resolvePath); } }, { children: "Resolve dependency" }))] }))) : ''] })) }, index));
                }) }))) : (_jsxs("div", __assign({ className: "w-full flex align-items-center justify-content-center gap-2" }, { children: [_jsx("i", { className: "pi pi-check text-green-600" }), _jsx("span", __assign({ className: "text-green-600" }, { children: translater('entityDependencyErrorAreaSuccessDependencyResolved') }))] })))] })));
};
