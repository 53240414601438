var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
var validate = function (value) {
    if (!value)
        return translater("cdrTabPageValidateDomain").toString();
    return true;
};
export var DomainField = function (_a) {
    var register = _a.register, errors = _a.errors;
    var ref = useRef(null);
    var validations = { validate: validate };
    return (_jsxs(_Fragment, { children: [_jsxs("label", __assign({ htmlFor: "domain", className: "block text-900 font-medium mb-2" }, { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("cdrTabPageDomainField")] })), _jsx(InputText, __assign({}, register('domain', validations), { id: "domain", type: "text", placeholder: translater("cdrTabPagePlaceholderDomainField").toString(), className: "w-full mb-3" })), _jsx(OverlayPanel, __assign({ ref: ref }, { children: translater("cdrTabPageDomainFieldOverlayPanel") })), _jsx(ErrorBlock, { errors: errors, keyword: "domain" })] }));
};
