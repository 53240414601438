var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from 'primereact/skeleton';
export var Preloader = function () {
    return (_jsxs("div", __assign({ className: "flex flex-column flex-auto" }, { children: [_jsxs("div", __assign({ className: "mt-2 mx-3 flex flex-column gap-2 flex-wrap" }, { children: [_jsx("div", __assign({ className: "home-statistics-preloader flex gap-2 w-full" }, { children: _jsx(Skeleton, { height: "5rem", className: 'w-full' }) })), _jsxs("div", __assign({ className: "flex gap-2 justify-content-between w-full" }, { children: [_jsx(Skeleton, { height: "13rem" }), _jsxs("div", __assign({ className: 'flex gap-2 justify-content-between w-full' }, { children: [_jsx(Skeleton, { height: "13rem" }), _jsx(Skeleton, { height: "13rem" })] }))] }))] })), _jsxs("div", __assign({ className: "mt-2 mx-3 flex flex-column gap-2 flex-wrap" }, { children: [_jsxs("div", __assign({ className: "flex gap-2 justify-content-between w-full mb-2" }, { children: [_jsx(Skeleton, { height: "13rem" }), _jsxs("div", __assign({ className: 'flex gap-2 justify-content-between w-full' }, { children: [_jsx(Skeleton, { height: "13rem" }), _jsx(Skeleton, { height: "13rem" })] }))] })), _jsx("div", __assign({ className: "home-statistics-preloader flex gap-2 w-full" }, { children: _jsx(Skeleton, { height: "5rem", className: 'w-full' }) }))] }))] })));
};
