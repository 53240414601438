var formatTime = function (date) {
    return new Date(date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};
var timeBodyTemplate = function (_a) {
    var date = _a.date;
    return formatTime(date);
};
export var timeColumn = {
    columnHeader: 'callsTime',
    field: 'time',
    body: timeBodyTemplate
};
