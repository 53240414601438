var formatTime = function (seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var secs = Math.floor(seconds % 60);
    var paddedHours = hours.toString().padStart(2, '0');
    var paddedMinutes = minutes.toString().padStart(2, '0');
    var paddedSecs = secs.toString().padStart(2, '0');
    return "".concat(paddedHours, ":").concat(paddedMinutes, ":").concat(paddedSecs);
};
var durationBodyTemplate = function (_a) {
    var duration = _a.duration, billsec = _a.billsec;
    return formatTime(billsec);
};
export var durationColumn = {
    columnHeader: 'callsDuration',
    field: 'billsec',
    sortable: true,
    style: {
        minWidth: "200px"
    },
    body: durationBodyTemplate,
};
