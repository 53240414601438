var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { useSelector } from "react-redux";
import { Message } from 'primereact/message';
import { Dropdown } from "primereact/dropdown";
import clsx from "clsx";
import { SettingsButton } from "./buttons/SettingsButton";
import { RemoveButton } from "./buttons/RemoveButton";
import { addChild } from "../../../../../../redux/actions/builderAction";
import { clone } from "../../../utils/tree";
import { VisibleButton } from "./buttons/VisibleButton";
import { API } from "../../../../../../api";
import { Preloader } from "../../../../../components/particals/preloader/Preloader";
import { getFormSettings } from "../../../../../components/particals/form-generator/FormGenerator";
var defaultModalSetting = {
    header: '',
    description: '',
    visible: false,
    branches: [],
    selectedBranches: [],
    callback: function () { }
};
var defaultModalBlockCreating = {
    visible: false,
    blockNames: [],
    selectedBlock: null,
    blocksLoader: true,
    blockType: '',
    callback: function () { }
};
export var NodeLayout = function (_a) {
    var node = _a.node, rest = _a.rest, _b = _a.node, label = _b.data.label, icon = _b.icon, canHaveChildren = _b.canHaveChildren, type = _a.type;
    var isDragging = useSelector(function (state) { return state.builder; }).isDragging;
    var _c = __read(useState(defaultModalSetting), 2), modalSettings = _c[0], setModalSettings = _c[1];
    var _d = __read(useState(defaultModalBlockCreating), 2), modalBlockCreating = _d[0], setModalBlockCreating = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var loadBlockNames = function (blockType) {
        setModalBlockCreating(__assign(__assign({}, modalBlockCreating), { blocksLoader: true }));
        API.getBlocksNames(blockType)
            .then(function (response) {
            var blocks = response.data;
            setModalBlockCreating(__assign(__assign({}, modalBlockCreating), { blockNames: blocks, blocksLoader: false }));
        })
            .finally(function () { return setModalBlockCreating(function (prev) { return (__assign(__assign({}, prev), { blocksLoader: false })); }); });
    };
    var hideCreateBlockModal = function () {
        setModalBlockCreating(defaultModalBlockCreating);
        // Удаляем стили при наведении
        document.querySelectorAll('.droppable-area').forEach(function (el) { return el.classList.remove('droppable-area'); });
    };
    var beforeDrop = function (_droppedItem, _node) {
        var droppedItem = clone(_droppedItem);
        var node = clone(_node);
        // Если элемент не может иметь children
        if (!node.canHaveChildren)
            return;
        // (1)
        // Обычное поведение элементов
        // У одиночных элементов – друг под друга
        // У множественных элементов – с выбором позиции
        // (1) Для одиночных элементов у которых нет после себя children
        if (!node.isChildrenList && node.children.length === 0)
            return addChild(clone(droppedItem), clone(node));
        // (1) Для множественных элементов, 
        // у которых используются еще не все children
        if (node.isChildrenList && node.children.length !== node.childrenTemplate.length) {
            // Если остался один элемент для выбора - выбирать автоматически
            if (node.childrenTemplate.length - node.children.length === 1) {
                var _a = __read(node.childrenTemplate
                    .filter(function (branch) {
                    var branches = node.children.map(function (child) { return child.branch; });
                    // Отфильтровываем уже выбранные элементы
                    return !branches.includes(branch);
                }), 1), branch = _a[0];
                if (branch)
                    addChild(clone(droppedItem), clone(node), branch);
                return false;
            }
            var addBranchChild_1 = function (branch) { return addChild(clone(droppedItem), clone(node), branch); };
            setModalSettings({
                header: 'Select your branch',
                visible: true,
                branches: node.childrenTemplate,
                selectedBranches: node.children ? node.children.map(function (child) { return child.branch; }) : [],
                callback: function (branch) { return addBranchChild_1(branch); }
            });
        }
        // (2)
        // Кастомное поведение элементов
        // У одиночных элементов – натаскивать друг на друга
        // У множественных элементов – с выбором позиции при натаскивании
        // (2) Для одиночных
        if (!node.isChildrenList && node.children.length > 0) {
            // Поведение: перетаскивание одиночного элемента на одиночный элемент, 
            // где одиночный элемент содержит одного child
            if (!droppedItem.childrenTemplate) {
                // Логика для элементов, которые не могут содержать после себя child
                if (!droppedItem.canHaveChildren) {
                    var callback_1 = function (branch) {
                        if (branch) {
                            node.children.length = 0;
                            addChild(clone(droppedItem), clone(node));
                        }
                    };
                    setModalSettings({
                        header: "".concat(droppedItem.data.label, " dropping"),
                        description: "After this action action you will remove all children of ".concat(node.data.label, "!"),
                        visible: true,
                        branches: ['Agree!'],
                        selectedBranches: [],
                        callback: function (branch) { return callback_1(branch); }
                    });
                }
                else {
                    // Логика для элементов, которые могут содержать после себя child
                    var _b = __read(node.children, 1), nodeChild = _b[0];
                    droppedItem.children.push(clone(nodeChild));
                    node.children.length = 0;
                    return addChild(clone(droppedItem), clone(node));
                }
            }
            // Поведение: перетаскивание множественного элемента
            if (droppedItem.childrenTemplate && droppedItem.childrenTemplate.length > 1) {
                var callback_2 = function (branch) {
                    var _a = __read(node.children, 1), nodeChild = _a[0];
                    nodeChild.branch = branch;
                    droppedItem.children.push(clone(nodeChild));
                    node.children.length = 0;
                    addChild(clone(droppedItem), clone(node));
                };
                setModalSettings({
                    header: 'Select the branch to move the children',
                    visible: true,
                    branches: droppedItem.childrenTemplate,
                    selectedBranches: droppedItem.children ? droppedItem.children.map(function (child) { return child.branch || ''; }) : [],
                    callback: function (branch) { return callback_2(branch); }
                });
            }
        }
        // Поведение: перетаскивание одиночного элемента на множественный элемент, 
        // у которого все chilred заняты
        if ((node.isChildrenList && node.children.length === node.childrenTemplate.length) && !droppedItem.childrenTemplate) {
            // Перетаскиваем одиночный элемент который после себя не может иметь детей
            if (!droppedItem.canHaveChildren) {
                var callback_3 = function (branch) {
                    var agreementCallback = function (agreement) {
                        if (agreement) {
                            node.children = node.children.filter(function (child) { return child.branch !== branch; });
                            return addChild(clone(droppedItem), clone(node), branch);
                        }
                    };
                    setTimeout(function () {
                        setModalSettings({
                            header: "".concat(droppedItem.data.label, " dropping"),
                            description: "After this action action you will remove all children of '".concat(branch, "' branch and paste ").concat(droppedItem.data.label, "!"),
                            visible: true,
                            branches: ['Agree!'],
                            selectedBranches: [],
                            callback: function (branch) { return agreementCallback(branch); }
                        });
                    }, 100);
                };
                setModalSettings({
                    header: 'Select the branch',
                    description: 'Select the branch to paste the item as a first item in the branch',
                    visible: true,
                    branches: node.childrenTemplate,
                    selectedBranches: [],
                    callback: function (branch) { return callback_3(branch); }
                });
            }
            else {
                // Перетаскиваем одиночный элемент который после может иметь детей (не Hang Up и тп.)
                var callback_4 = function (branch) {
                    if (branch) {
                        var child = node.children.find(function (child) { return child.branch === branch; });
                        node.children = node.children.filter(function (child) { return child.branch !== branch; });
                        delete child.branch;
                        droppedItem.children = [child];
                        return addChild(clone(droppedItem), clone(node), branch);
                    }
                };
                setModalSettings({
                    header: 'Select the branch',
                    description: 'Select the branch to paste the item as a first item in the branch',
                    visible: true,
                    branches: node.childrenTemplate,
                    selectedBranches: [],
                    callback: function (branch) { return callback_4(branch); }
                });
            }
        }
        // Поведение: перетаскивание множественного элемента на множественный элемент, 
        // у которого все chilred заняты
        if ((node.isChildrenList && node.children.length === node.childrenTemplate.length) && droppedItem.childrenTemplate) {
            var callback_5 = function (branch) {
                var agreementCallback = function (agreement) {
                    var child = node.children.find(function (child) { return child.branch === branch; });
                    node.children = node.children.filter(function (child) { return child.branch !== branch; });
                    child.branch = agreement;
                    droppedItem.children = [child];
                    return addChild(clone(droppedItem), clone(node), branch);
                };
                setTimeout(function () {
                    setModalSettings({
                        header: "".concat(droppedItem.data.label, " dropping"),
                        description: "Select branch of '".concat(droppedItem.data.label, "' to move the children!"),
                        visible: true,
                        branches: droppedItem.childrenTemplate,
                        selectedBranches: [],
                        callback: function (branch) { return agreementCallback(branch); }
                    });
                }, 100);
            };
            setModalSettings({
                header: 'Select the branch',
                description: 'Select the branch to paste the item as a first item in the branch',
                visible: true,
                branches: node.childrenTemplate,
                selectedBranches: [],
                callback: function (branch) { return callback_5(branch); }
            });
        }
    };
    var drop = function (event, node) { return __awaiter(void 0, void 0, void 0, function () {
        var block, callback;
        return __generator(this, function (_a) {
            event.preventDefault();
            block = JSON.parse(event.dataTransfer.getData('application/reactflow'));
            callback = function (selectedBlock, selectedBlockType) { return __awaiter(void 0, void 0, void 0, function () {
                var blockItem, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            setLoading(true);
                            return [4 /*yield*/, API.getBlock(block.formName, selectedBlock === null || selectedBlock === void 0 ? void 0 : selectedBlock._id)
                                // Добавляем default values тем элементам, которые берем как новые
                            ];
                        case 1:
                            blockItem = (_a.sent()).data;
                            // Добавляем default values тем элементам, которые берем как новые
                            if (selectedBlockType === 'new') {
                                blockItem.settings = getFormSettings(JSON.parse(blockItem.formJSON));
                            }
                            setLoading(false);
                            beforeDrop(blockItem, node);
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            console.log(error_1);
                            setLoading(false);
                            return [3 /*break*/, 3];
                        case 3:
                            event.dataTransfer.clearData('application/reactflow');
                            // Удаляем стили при наведении
                            document.querySelectorAll('.droppable-area').forEach(function (el) { return el.classList.remove('droppable-area'); });
                            setModalBlockCreating(defaultModalBlockCreating);
                            return [2 /*return*/];
                    }
                });
            }); };
            setModalBlockCreating(function (prev) { return (__assign(__assign({}, prev), { visible: true, callback: callback, blockType: block.formName })); });
            return [2 /*return*/];
        });
    }); };
    // Событие когда курсор расположен над элементом для сброса
    var dragOver = function (event, node) {
        event.preventDefault();
        // Не разукрашивать зону, если элемент не может иметь children
        if (!node.canHaveChildren)
            return;
        // Очистка стилей
        event.currentTarget.parentElement
            && event.currentTarget.parentElement.classList.add('droppable-area');
    };
    // Событие, когда курсор уходит из зоны сбора
    var dragLeave = function (event) {
        event.preventDefault();
        // Очистка стилей
        event.currentTarget.parentElement
            && event.currentTarget.parentElement.classList.remove('droppable-area');
    };
    var addBranchChild = function (branch) {
        modalSettings.callback(branch);
        setTimeout(function () { setModalSettings(defaultModalSetting); }, 0);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ onDrop: function (event) { return drop(event, node); }, onDragOver: function (event) { return dragOver(event, node); }, onDragLeave: dragLeave, className: clsx('flex align-items-center gap-2 node-nav justify-content-between', {
                    // 'justify-content-between': type !== 'input',
                    // 'justify-content-center': type === 'input',
                    'drag-permition': isDragging && canHaveChildren
                }) }, { children: [_jsxs("span", __assign({ className: "flex align-items-center" }, { children: [icon
                                ? _jsx("i", { className: "pi " + icon || '', style: { fontSize: '0.6rem', marginRight: '5px' } })
                                : '', _jsx("input", { type: "text", value: node.settings && node.settings.name || label, className: "node-label-input", readOnly: true })] })), _jsxs("div", __assign({ className: 'flex gap-1' }, { children: [type !== 'output' && type !== 'input'
                                ? _jsx(VisibleButton, { node: node, rest: rest })
                                : '', node.formJSON
                                ? _jsx(SettingsButton, { id: node.id, form: JSON.parse(node.formJSON) })
                                : '', type !== 'input'
                                ? _jsx(RemoveButton, { node: node, setModalSettings: setModalSettings })
                                : ''] }))] })), _jsxs(Dialog, __assign({ header: modalSettings.header, visible: modalSettings.visible, resizable: false, onHide: function () { return setModalSettings(defaultModalSetting); }, style: { width: '65vw' }, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: [modalSettings.description
                        && _jsx(Message, { className: "w-full mb-3", severity: "warn", text: modalSettings.description }), _jsx("div", __assign({ className: "flex justify-content-center gap-2" }, { children: modalSettings.branches
                            .filter(function (branch) {
                            var branches = modalSettings.selectedBranches;
                            // Отфильтровываем уже выбранные элементы
                            return !branches.includes(branch);
                        })
                            .map(function (branch) {
                            return _jsx(Button, { onClick: function () { return addBranchChild(branch); }, className: "p-button-outlined w-full", label: branch }, branch);
                        }) }))] })), _jsx(Dialog, __assign({ header: 'Выбор блока', visible: modalBlockCreating.visible, resizable: false, onShow: function () { return loadBlockNames(modalBlockCreating.blockType); }, onHide: function () { return hideCreateBlockModal(); }, style: { width: '40vw' }, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: _jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "flex align-items-center justify-content-center gap-2  mt-1" }, { children: modalBlockCreating.blocksLoader ? (_jsxs(_Fragment, { children: [_jsx(Preloader, { width: "calc(100% - 80px)" }), _jsx(Preloader, { width: "70px" })] })) : (_jsxs(_Fragment, { children: [_jsx(Dropdown, { value: modalBlockCreating.selectedBlock, onChange: function (e) { return setModalBlockCreating(__assign(__assign({}, modalBlockCreating), { selectedBlock: e.value })); }, options: modalBlockCreating.blockNames, placeholder: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0431\u043B\u043E\u043A", optionLabel: "name", filter: true, className: "w-full" }), _jsx(Button, { onClick: function () { return modalBlockCreating.callback(modalBlockCreating.selectedBlock, 'prepared'); }, label: 'Выбрать', style: { width: '14vw' }, className: "p-button-success", disabled: !modalBlockCreating.selectedBlock || loading })] })) })), _jsx(Button, { label: 'Создать новый блок', disabled: loading, icon: "pi pi-plus", onClick: function () { return modalBlockCreating.callback(modalBlockCreating.selectedBlock, 'new'); }, className: "w-full p-button-outlined" })] })) }))] }));
};
