var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddItemsButton } from "./components/AddItemsButton";
import { SaveOrderButton } from "./components/SaveOrderButton";
import { UndoButton } from "./components/UndoButton";
import { ReturnBackButton } from "./components/ReturnBackButton";
export var Header = function (_a) {
    var loadData = _a.loadData, id = _a.id, contextChildren = _a.contextChildren, contextChildrenHistory = _a.contextChildrenHistory;
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(ReturnBackButton, {}), _jsx(AddItemsButton, { loadData: loadData, id: id })] })), _jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(UndoButton, { contextChildren: contextChildren, contextChildrenHistory: contextChildrenHistory, loadData: loadData, id: id }), _jsx(SaveOrderButton, { contextChildren: contextChildren, contextChildrenHistory: contextChildrenHistory, loadData: loadData, id: id })] }))] })) }));
};
