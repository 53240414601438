var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { successNotification } from "../../../../../../../../redux/actions/notificationsActions";
import { translater } from "../../../../../../../../utils/localization/localization";
import { API } from "../../../../../../../../api";
import { NameField } from "./fields/NameField";
import { CommentField } from "./fields/CommentField";
var defaultFormValues = {
    name: '',
    comment: ''
};
export var Form = function (_a) {
    var hideModal = _a.hideModal, loadData = _a.loadData;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var sendRequest = function (data) {
        setLoading(true);
        API.createContext(data)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            hideModal();
            loadData();
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var form = useForm({
        mode: 'all',
        defaultValues: defaultFormValues
    });
    var handleSubmit = form.handleSubmit, _c = form.formState, isValid = _c.isValid, submitCount = _c.submitCount;
    var onSubmit = function (data) {
        var name = data.name, comment = data.comment;
        var values = {
            name: name,
            comment: comment
        };
        sendRequest(values);
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsxs("div", __assign({ className: "surface-card mt-2 p-4 shadow-2 border-round w-full" }, { children: [_jsx(NameField, { form: form }), _jsx(CommentField, { form: form })] })), _jsx("div", __assign({ className: "w-full flex justify-content-end gap-4 mt-4" }, { children: _jsx(Button, { className: "w-full p-button-success", label: translater("contextPageAddButton").toString(), icon: "pi pi-check", loading: loading, disabled: submitCount > 0 && !isValid }) }))] })));
};
