var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from "primereact/tooltip";
import { useState, useEffect } from "react";
var generateId = function () { return Math.random().toString(36).slice(2, 10) + Math.random().toString(36).slice(2, 10); };
export var TooltipRevealer = function (_a) {
    var hint = _a.hint, children = _a.children, _b = _a.className, className = _b === void 0 ? "" : _b;
    var _c = __read(useState(null), 2), tooltipUniqueId = _c[0], setTooltipUniqueId = _c[1];
    useEffect(function () {
        var id = generateId();
        setTooltipUniqueId("text-truncator-".concat(id));
    }, []);
    // Если ID еще не был сгенерирован, не рендерим Tooltip
    if (!tooltipUniqueId) {
        return null;
    }
    return (_jsxs("div", __assign({ "data-pr-tooltip": hint, "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "".concat(tooltipUniqueId, " ").concat(className) }, { children: [children, _jsx(Tooltip, { target: ".".concat(tooltipUniqueId) })] })));
};
