var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { translater } from "../../../../../../utils/localization/localization";
import { API } from "../../../../../../api";
import { Button } from "primereact/button";
import { TimedProgressBar } from "./TimedProgressBar";
import { showCustomOverlay } from "../../../../../../utils/app/native";
export var ApplicationAutoUpdate = function () {
    var _a = __read(useState(true), 2), checking = _a[0], setChecking = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(!false), 2), isLastVersion = _c[0], setLastVersionMode = _c[1];
    var _d = __read(useState(!navigator.onLine), 2), offline = _d[0], setOffline = _d[1];
    var time = 20000;
    var checkUpdates = function () {
        if (!navigator.onLine) {
            setOffline(true);
            return;
        }
        setOffline(false);
        setChecking(true);
        API.checkApplicatoinUpdates()
            .then(function (response) {
            var hasUpdates = response.data.hasUpdates;
            setLastVersionMode(!hasUpdates);
            setChecking(false);
        })
            .catch(function (error) {
            setChecking(false);
        });
    };
    var applyUpdates = function () {
        if (!navigator.onLine) {
            setOffline(true);
            return;
        }
        setOffline(false);
        setLoading(true);
        showCustomOverlay();
        setTimeout(function () {
            location.reload();
        }, time);
        API.applyRemoteApplicationUpdates()
            .then(function (response) {
            var message = response.data.message;
        })
            .catch(function (error) {
            console.log(error);
            // setLoading(false)
            // hideCustomOverlay()
        });
    };
    useEffect(function () {
        checkUpdates();
    }, []);
    useEffect(function () {
        var handleOnlineStatus = function () { return setOffline(!navigator.onLine); };
        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);
        return function () {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsx("label", { children: translater('settingsPageApplicationInfoUpdatesLabel') }), offline ? (_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("i", { className: "pi pi-exclamation-triangle text-red-500" }), _jsx("span", { children: translater('settingsPageApplicationInfoOfflineLabel') })] }))) : checking ? (_jsxs("div", __assign({ className: "flex align-items-center gap-3" }, { children: [_jsx("i", { className: "pi pi-sync pi-spin text-lg" }), _jsx("span", { children: translater('settingsPageApplicationInfoCheckUpdatesLabel') })] }))) : (isLastVersion ? (_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("i", { className: "pi pi-check text-green-500" }), _jsxs("span", { children: [translater('settingsPageApplicationInfoNoUpdatesLabel'), " ", _jsx("b", __assign({ className: "cursor-pointer text-primary-500", onClick: function () { return checkUpdates(); } }, { children: translater('settingsPageApplicationInfoCheckUpdatesRefreshBtn') }))] })] }))) : (loading ? (_jsx(TimedProgressBar, { time: time })) : (_jsx(Button, { loading: loading, label: translater('settingsPageApplicationInfoUpdatesButton').toString(), onClick: function () { return applyUpdates(); }, className: "p-button p-component p-button-outlined text-green-600 border-green-600" }))))] })));
};
