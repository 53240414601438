var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import { Column } from "primereact/column";
import { API } from "../../../../../../../api";
import { CreateModal } from "../header/components/CreateModal";
import { UpdateModal } from "../header/components/UpdateModal";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { translater } from "../../../../../../../utils/localization/localization";
import { ViewModal } from "../header/components/ViewModal";
import { EntityDependencyErrorArea } from "../../../../../../components/particals/error/EntityDependencyErrorArea";
import { dynamicColumns } from "./components/columns/columns";
import { TooltipRevealer } from "../../../../../../components/particals/tooltip/TooltipRevealer";
export var defaultAWSAudioLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var GroupsList = function (_a) {
    var setActiveIndex = _a.setActiveIndex;
    var _b = __read(useState([]), 2), groups = _b[0], setGroups = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(false), 2), createModalVisible = _d[0], setCreateModalVisible = _d[1];
    var _e = __read(useState(null), 2), modifiedGroup = _e[0], setModifiedGroup = _e[1];
    var _f = __read(useState(null), 2), viewGroup = _f[0], setViewGroup = _f[1];
    var _g = __read(useState(false), 2), checkURL = _g[0], setCheckURL = _g[1];
    var _h = __read(useState(50), 2), limit = _h[0], setLimit = _h[1];
    var _j = __read(useState(0), 2), tableItemsAmount = _j[0], setTableItemsAmount = _j[1];
    var _k = __read(useState(defaultAWSAudioLazyState), 2), lazyState = _k[0], setLazyState = _k[1];
    var _l = __read(useState(''), 2), globalFinderValue = _l[0], setGlobalFinderValue = _l[1];
    var _m = __read(useState([]), 2), dependencyErrors = _m[0], setDependencyErrors = _m[1];
    var _o = __read(useState(false), 2), dependencyModalVisible = _o[0], setDependencyModalVisible = _o[1];
    var _p = __read(useState([]), 2), removableCandidates = _p[0], setRemovableCandidates = _p[1];
    var loadGroups = function (cb) {
        setLoading(true);
        API.finderAWSGroup(globalFinderValue, lazyState)
            .then(function (response) {
            var _a = response.data, limit = _a.limit, amount = _a.amount, groups = _a.groups;
            setGroups(groups);
            setLimit(limit);
            setTableItemsAmount(amount);
            if (cb && checkURL === false)
                setTimeout(function () { cb(groups); }, 0);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var handleSearch = function (value) {
        setGlobalFinderValue(value);
    };
    var accept = function (id) {
        API.removeAWSGroup([id])
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadGroups();
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var confirm = function (item) {
        setRemovableCandidates([String(item._id)]);
        confirmDialog({
            message: translater("awsGroupsItemСonfirmDialogButtonMessage"),
            header: translater("awsGroupsItemСonfirmDialogButtonHeader"),
            icon: 'pi pi-info-circle',
            position: 'top',
            accept: function () { return accept(item._id || ''); },
            reject: function () { }
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkGroupsBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeAWSGroup(removableCandidates)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadGroups();
            setDependencyErrors([]);
            setDependencyModalVisible(false);
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    var columns = dynamicColumns.map(function (column) {
        var header = translater(column.columnHeader);
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, headerClassName: "text-center", style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    var onPage = function (event) { setLazyState(event); };
    var onFilter = function (event) {
        event['first'] = 0;
        setLazyState(event);
    };
    var cb = function (groups) {
        var params = new URLSearchParams(location.search);
        var groupId = params.get('group');
        var modal = params.get('modal');
        setCheckURL(true);
        if (groupId) {
            var group = groups.find(function (group) { return group._id === groupId; });
            if (group && modal === 'open') {
                setModifiedGroup(group);
            }
        }
    };
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadGroups(cb); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    return (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx(InputText, { value: globalFinderValue, className: "w-full", placeholder: translater("awsGroupsListSearchPlaceholder").toString(), onChange: function (e) { return handleSearch(e.target.value); } }), _jsx(TooltipRevealer, __assign({ hint: translater("createButton").toString() }, { children: _jsx(Button, { onClick: function () { return setCreateModalVisible(true); }, icon: "pi pi-plus", className: 'p-button-success' }) }))] })), _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: groups, loading: loading, rows: limit, first: lazyState.first, totalRecords: tableItemsAmount, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, responsiveLayout: "scroll", scrollable: true, className: "border-1 border-300 border-round-md overflow-hidden", dataKey: "_id" }, { children: [columns, _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), body: function (row) {
                            return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(TooltipRevealer, __assign({ hint: translater("viewBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-eye", className: "p-button p-component p-button-icon-only p-button-outlined border-circle  p-button-warning", style: { width: "50px", height: "50px" }, onClick: function () { return setViewGroup(row); } }) })), _jsx(TooltipRevealer, __assign({ hint: translater("updateBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-pencil", className: "p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return setModifiedGroup(row); } }) })), _jsx(TooltipRevealer, __assign({ hint: translater("deleteBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-trash", className: "p-button p-component p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }) }))] })));
                        } }, 'actions')] })), createModalVisible ? (_jsx(CreateModal, { refresh: function () { return loadGroups(); }, setFinder: handleSearch, hide: function () { return setCreateModalVisible(false); } })) : '', modifiedGroup ? (_jsx(UpdateModal, { modifiedGroup: modifiedGroup, refresh: function () { return loadGroups(); }, setFinder: handleSearch, hide: function () { return setModifiedGroup(null); } })) : '', viewGroup ? (_jsx(ViewModal, { viewGroup: viewGroup, refresh: function () { return loadGroups(); }, setFinder: handleSearch, hide: function () { return setViewGroup(null); } })) : '', _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] })));
};
