import jwt_decode from 'jwt-decode';
import { SET_CURRENT_USER } from '../reducers/authReducer';
import { successNotification } from './notificationsActions';
import { store } from '../store';
import { setAuthToken } from '../../utils/axios/token';
import { API } from '../../api';
import { setDefaultHomeData } from './appActions';
// Логин - получение токена пользователя
export var login = function (data) {
    return API.login(data)
        .then(function (res) {
        // Заносим токен в localStorage
        var _a = res.data, token = _a.token, message = _a.message;
        localStorage.setItem('token', token);
        // Присваиваем токен в Auth header
        setAuthToken(token);
        // Парсим токен и получаем пользователя
        var decoded = jwt_decode(token);
        store.dispatch(setCurrentUser(decoded));
        successNotification(message);
    });
};
// Логин через active directory - получение токена пользователя
export var activeDirectoryLogin = function (data) {
    return API.activeDirectoryLogin(data)
        .then(function (res) {
        // Заносим токен в localStorage
        var _a = res.data, token = _a.token, message = _a.message;
        localStorage.setItem('token', token);
        // Присваиваем токен в Auth header
        setAuthToken(token);
        // Парсим токен и получаем пользователя
        var decoded = jwt_decode(token);
        store.dispatch(setCurrentUser(decoded));
        successNotification(message);
    });
};
// Вернет true, если роли совпали
export var compareRoles = function (rolesList) {
    var user = store.getState().auth.user;
    // // Ищем пользователя, если нет его в локальном хранилище
    // if (!user) {
    //   const token = localStorage.getItem('token')
    //   // Парсим токен и получаем пользователя
    //   user = jwt_decode(token || '')
    // }
    if (user) {
        var roles = user.roles;
        if (user.roles.length === 0)
            return false;
        var _loop_1 = function (i) {
            var element = rolesList[i];
            if (roles.find(function (role) { return element === role.title; }))
                return { value: true };
        };
        for (var i = 0; i < rolesList.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    }
    // Если роли не совпали
    return false;
};
export var adminPermission = function () {
    var roles = ['Administrator'];
    return compareRoles(roles);
};
export var superviorPermission = function () {
    var roles = ['Supervisor'];
    return compareRoles(roles);
};
export var securityPermission = function () {
    var roles = ['Security'];
    return compareRoles(roles);
};
export var getUserRoles = function () {
    var user = store.getState().auth.user;
    return user ? user.roles : [];
};
// Присвоение пользователя
export var setCurrentUser = function (decoded) {
    return ({ type: SET_CURRENT_USER, payload: decoded });
};
// Выход из системы
export var logout = function () {
    localStorage.removeItem('token');
    // Удаление auth header в последующих запросах
    setAuthToken('');
    setDefaultHomeData();
    store.dispatch(setCurrentUser(null));
};
