var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Divider } from "primereact/divider";
import { translater } from "../../../../../utils/localization/localization";
import { Form } from "./components/Form";
import { DisplayField } from "./components/DisplayField";
import { GreenMessage } from "../../../../components/particals/green-message/GreenMessage";
export var ActiveDirectory = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var settings = _a.settings, setSettings = _a.setSettings;
    var _j = __read(useState(false), 2), editingMode = _j[0], setEditingMode = _j[1];
    var fields = [
        {
            header: 'settingsPageActiveDirectoryControllersLabel',
            value: (_b = settings.activeDirectory) === null || _b === void 0 ? void 0 : _b.urls,
        },
        {
            header: 'settingsPageActiveDirectoryLoginLabel',
            value: (_c = settings.activeDirectory) === null || _c === void 0 ? void 0 : _c.login,
        },
        {
            header: 'settingsPageActiveDirectoryPasswordLabel',
            value: (_d = settings.activeDirectory) === null || _d === void 0 ? void 0 : _d.password,
        },
        {
            header: 'settingsPageActiveDirectoryBaseDNLabel',
            value: (_e = settings.activeDirectory) === null || _e === void 0 ? void 0 : _e.baseDN,
        },
        {
            header: 'settingsPageActiveDirectoryUserGroupLabel',
            value: (_f = settings.activeDirectory) === null || _f === void 0 ? void 0 : _f.user,
        },
        {
            header: 'settingsPageActiveDirectorySupervisorGroupLabel',
            value: (_g = settings.activeDirectory) === null || _g === void 0 ? void 0 : _g.supervisor,
        },
        {
            header: 'settingsPageActiveDirectoryAdminGroupLabel',
            value: (_h = settings.activeDirectory) === null || _h === void 0 ? void 0 : _h.admin,
        }
    ];
    return (_jsxs("div", __assign({ className: "w-full card flex flex-column gap-2 surface-card shadow-2 border-round p-3" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center justify-content-between" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center gap-3" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: translater('settingsPageActiveDirectoryHeader') })), settings.activeDirectory ? _jsx(GreenMessage, { message: "settingsPageActiveDirectoryGreenMessage" }) : ''] })), _jsx("div", { children: settings.activeDirectory ? (editingMode ? (_jsx("i", { onClick: function () { return setEditingMode(false); }, className: "pi pi-times text-sm cursor-pointer" })) : (_jsx("i", { onClick: function () { return setEditingMode(true); }, className: "pi pi-pencil text-sm cursor-pointer" }))) : '' })] })), _jsx(Divider, { className: "my-1" }), !settings.activeDirectory ? (_jsx(Form, { setSettings: setSettings })) : (editingMode ? (_jsx(Form, { setSettings: setSettings })) : (settings.activeDirectory ? (_jsx("div", __assign({ className: "flex align-items-start gap-5" }, { children: _jsx("div", __assign({ className: "flex flex-column gap-2" }, { children: fields.map(function (field, index) {
                        return (_jsx(DisplayField, { header: field.header, value: field.value || '' }, index));
                    }) })) }))) : ''))] })));
};
