var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { closeEditorFileByName, recognizeProgrammingLanguageByExtension, setCurrentEditorFile } from "../../../../redux/actions/editorAction";
export var FileList = function () {
    var _a = useSelector(function (state) { return state.editor; }), currentFileName = _a.currentFileName, files = _a.files;
    var selectedFile = files.find(function (file) { return file.name === currentFileName; });
    var closeFile = function (event, fileName) {
        event.preventDefault();
        event.stopPropagation();
        closeEditorFileByName(fileName);
        setCurrentEditorFile(null);
    };
    var openFile = function (fileName) {
        var _a = __read(fileName.split('.'), 2), name = _a[0], ext = _a[1];
        recognizeProgrammingLanguageByExtension(ext);
        setCurrentEditorFile(fileName);
    };
    return (_jsx(_Fragment, { children: files.length ? (_jsx("div", __assign({ className: "surface-section p-3 shadow-2 border-round flex gap-3 align-items-center flex-wrap" }, { children: files.map(function (file, index) {
                return (_jsxs("div", __assign({ className: clsx('flex gap-2 p-2 cursor-pointer align-items-center border-1 border-round-sm border-300 surface-300', {
                        'border-primary bg-primary': (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name) === file.name
                    }), onClick: function () { return openFile(file.name); } }, { children: [_jsxs("span", __assign({ className: "flex align-items-center" }, { children: [file.name, _jsx("i", { className: clsx('pi pi-pencil w-1 ml-2 text-xs', {
                                        'hidden text-primary': file.content === file.prevContent,
                                        'text-300': file.name === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name)
                                    }), onClick: function (e) { return closeFile(e, file.name); } })] })), _jsx("i", { className: "pi pi-times", onClick: function (e) { return closeFile(e, file.name); } })] }), index));
            }) }))) : '' }));
};
