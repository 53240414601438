var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { translater } from "../../../../../utils/localization/localization";
export var TableConversion = function (_a) {
    var globalFinderValue = _a.globalFinderValue, setGlobalFinderValue = _a.setGlobalFinderValue, visibleColumns = _a.visibleColumns, setVisibleColumns = _a.setVisibleColumns, dynamicColumns = _a.dynamicColumns;
    var onColumnToggle = function (event) {
        var selectedColumns = event.value;
        setVisibleColumns(selectedColumns);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsx("div", __assign({ className: "w-full" }, { children: _jsxs("span", __assign({ className: "w-full p-input-icon-left" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: globalFinderValue, onChange: function (event) { return setGlobalFinderValue(event); }, className: "w-full", placeholder: translater('usersPageSearchPlaceholder').toString() })] })) })), _jsx("div", { children: _jsx(MultiSelect, { value: visibleColumns, optionLabel: "content", optionValue: "field", options: dynamicColumns.map(function (col) { return (__assign(__assign({}, col), { content: translater(col.columnHeader) })); }), onChange: onColumnToggle, className: "columns-multi-select", style: { maxWidth: "300px" }, display: "chip" }) })] })) }));
};
