var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Preview } from './components/Preview';
import { CreateRedirectInterface } from './components/interfaces/create/Create';
import { UpdateRedirectInterface } from './components/interfaces/update/Update';
import { RedirectsPreloader } from './components/RedirectsPreloader';
import { API } from '../../../api';
import { translater } from '../../../utils/localization/localization';
import { useDocumentTitle } from '../../../utils/routes/routes';
import { setBreadCrumbs } from '../../../redux/actions/appActions';
export var Redirects = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/redirects' }]);
    }, []);
    var _b = __read(useState(true), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(null), 2), editibleRedirect = _c[0], setEditibleRedirect = _c[1];
    var _d = __read(useState(false), 2), displayCreateInterface = _d[0], setDisplayCreateInterface = _d[1];
    var _e = __read(useState({ phones: [], mainPhone: null }), 2), userPhones = _e[0], setUserPhones = _e[1];
    var _f = __read(useState(userPhones.mainPhone || null), 2), selectedPhone = _f[0], setSelectedPhone = _f[1];
    var _g = __read(useState([]), 2), redirects = _g[0], setRedirects = _g[1];
    var loadRedirects = function () {
        setLoading(true);
        API.getRedirects()
            .then(function (response) {
            var redirects = response.data.redirects;
            setRedirects(redirects);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    // Загружаем номера телефонов
    var loadPhones = function () {
        setLoading(true);
        API.getPhones()
            .then(function (response) {
            var _a = response.data, phones = _a.phones, mainPhone = _a.mainPhone;
            setUserPhones({ phones: phones, mainPhone: mainPhone });
            setSelectedPhone(mainPhone || null);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); });
    };
    useEffect(function () {
        loadRedirects();
        loadPhones();
    }, []);
    useEffect(function () {
        setDisplayCreateInterface(false);
        if (selectedPhone) {
            var candidate = redirects
                .find(function (redirect) { return +redirect.extension === +selectedPhone; });
            setEditibleRedirect(candidate || null);
        }
    }, [selectedPhone, redirects]);
    return _jsx(_Fragment, { children: _jsx("main", __assign({ className: 'redirects-page' }, { children: _jsx("div", __assign({ className: "min-h-screen w-full p-3" }, { children: loading ? (_jsx(RedirectsPreloader, {})) : (userPhones.phones.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'max-w-full card-position-center flex flex-column gap-2 mb-2 py-3 px-4 surface-card shadow-2 border-round' }, { children: [_jsx("label", __assign({ className: 'card-position-center font-medium' }, { children: translater('redirectsRedirectNumber') })), _jsx(Dropdown, { value: selectedPhone, onChange: function (e) { return setSelectedPhone(e.value); }, options: userPhones.phones, placeholder: translater('redirectsRedirectNumberDropdownPlaceholder').toString(), className: "card-position-center w-full max-w-20rem p-dropdown" })] })), editibleRedirect ? (_jsx(UpdateRedirectInterface, { refresh: loadRedirects, redirect: editibleRedirect, selectedPhone: selectedPhone })) : (displayCreateInterface ? (_jsx(CreateRedirectInterface, { refresh: loadRedirects, selectedPhone: selectedPhone })) : (_jsx(Preview, { showCreateInterface: function () { return setDisplayCreateInterface(true); }, selectedPhone: selectedPhone })))] })) : (_jsx(Fieldset, __assign({ legend: translater('redirectsFieldsetLegend') }, { children: _jsx("p", __assign({ className: "m-0" }, { children: translater('redirectsFieldsetMessage') })) })))) })) })) });
};
