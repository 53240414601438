var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor } from '@monaco-editor/react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { changeEditorFileContent, changeEditorFilePrevContent, registerCustomEditorSyntax } from '../../../../../redux/actions/editorAction';
import { API } from '../../../../../api';
import { successNotification } from '../../../../../redux/actions/notificationsActions';
import { store } from '../../../../../redux/store';
import { registerIniCustomSyntax } from '../../../../../utils/editor/syntax';
import { translater } from '../../../../../utils/localization/localization';
export var FileEditor = function () {
    var _a = useSelector(function (state) { return state; }), isDarkMode = _a.theme.isDarkMode, _b = _a.editor, currentFileName = _b.currentFileName, files = _b.files, fontSize = _b.fontSize, programmingLanguage = _b.programmingLanguage, isSyntaxRegistered = _b.isSyntaxRegistered;
    var currentFile = files.find(function (file) { return file.name === currentFileName; });
    var onChangeHandler = function (value) {
        if (value && currentFile) {
            return changeEditorFileContent(currentFile.name, value);
        }
        if (currentFile) {
            changeEditorFileContent(currentFile.name, '');
        }
    };
    var saveContent = function () {
        var _a = store.getState().editor, editorFiles = _a.files, editorCurrentFilesFolderPath = _a.currentFilesFolderPath, editorCurrentFileName = _a.currentFileName;
        var file = editorFiles.find(function (file) { return file.name === editorCurrentFileName; });
        if (file) {
            var folderPath = "".concat(editorCurrentFilesFolderPath, "/").concat(file.name);
            API.updateConfigurationFileContent(folderPath, file.content)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                changeEditorFilePrevContent(file.name, file.content);
            });
        }
    };
    var reloadFileContent = function () {
        var _a = store.getState().editor, currentFilesFolderPath = _a.currentFilesFolderPath, currentFileName = _a.currentFileName;
        if (currentFileName) {
            var folderPath = "".concat(currentFilesFolderPath, "/").concat(currentFileName);
            API.getConfigurationFile(folderPath)
                .then(function (response) {
                var content = response.data;
                changeEditorFilePrevContent(currentFileName, content);
                changeEditorFileContent(currentFileName, content);
                successNotification(translater('editorFileReloadSuccessfullyNotification').toString());
            });
        }
    };
    var bindHotKeys = function (event) {
        // Command (Ctrl) + S
        if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            saveContent();
        }
        // Command (Ctrl) + R
        if (event.key === 'r' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            reloadFileContent();
        }
    };
    useEffect(function () {
        document.addEventListener('keydown', bindHotKeys);
        return function () { return document.removeEventListener('keydown', bindHotKeys); };
    }, []);
    return (_jsxs("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round relative" }, { children: [Boolean(currentFile === null || currentFile === void 0 ? void 0 : currentFile.readOnly) ? (_jsx("div", { className: 'absolute w-full h-full z-2 surface-400 opacity-20 top-0 left-0' })) : '', _jsx(Editor, { value: currentFile === null || currentFile === void 0 ? void 0 : currentFile.content, options: {
                    folding: true,
                    foldingHighlight: true,
                    foldingStrategy: 'auto',
                    fontSize: fontSize
                }, onMount: function (editor, monaco) {
                    if (!isSyntaxRegistered) {
                        registerCustomEditorSyntax();
                        registerIniCustomSyntax(monaco);
                    }
                }, height: "70vh", theme: isDarkMode ? 'hc-black' : 'vs', onChange: onChangeHandler, language: programmingLanguage })] })));
};
