var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Builder } from "../../../../builder/Builder";
import { store } from "../../../../../../redux/store";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import { useDocumentTitle } from "../../../../../../utils/routes/routes";
import { setBreadCrumbs } from "../../../../../../redux/actions/appActions";
var defaultSettings = {
    saveButtonTranslaterKey: 'builderSaveTreeButton',
    callback: function () { },
    isUpdateMode: false,
    callbackLoading: false
};
export var CreateRule = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/rules' }, { title: 'rulesPageCreateRuleBreadCrumbLabel', path: '/rule/create' }]);
    }, []);
    var navigate = useNavigate();
    var _b = __read(useState(defaultSettings), 2), settings = _b[0], setSettings = _b[1];
    var setLoading = function (mode) {
        setSettings(function (prev) { return (__assign(__assign({}, prev), { callbackLoading: mode })); });
    };
    var createRule = function () {
        var tree = store.getState().builder.tree;
        setLoading(true);
        var rule = {
            name: tree.settings.name,
            pattern: tree.settings.pattern,
            comment: tree.settings.comment,
            formJSON: JSON.stringify(tree)
        };
        API.createRule(rule)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            navigate('/rules');
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        setSettings(function (prev) { return (__assign(__assign({}, prev), { callback: createRule })); });
    }, []);
    return (_jsx(_Fragment, { children: _jsx(Builder, { settings: settings, rule: undefined }) }));
};
