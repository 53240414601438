var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
export var NoInternet = function () {
    var reload = function () { return window.location.replace('/'); };
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "surface-section px-4 py-8 md:px-6 lg:px-8 h-screen" }, { children: [_jsx("div", __assign({ style: {
                        background: "radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%)",
                    }, className: "text-center" }, { children: _jsx("span", __assign({ className: "bg-white text-pink-500 font-bold text-2xl inline-block px-3" }, { children: "Unstable connection to the server" })) })), _jsx("p", __assign({ className: "text-700 text-xl m-3 text-center" }, { children: "Please wait or contact the administrator." })), _jsx("div", __assign({ className: "text-center" }, { children: _jsx(Button, { onClick: reload, label: 'Reload the page', icon: "pi pi-replay" }) }))] })) });
};
