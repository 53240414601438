var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var initialState = {
    isHidden: true,
    audioList: [],
    currentAudioObject: null,
    position: 0
};
export var SHOW_PLAYER = 'SHOW_PLAYER';
export var HIDE_PLAYER = 'HIDE_PLAYER';
export var SET_POSITION = 'SET_POSITION';
export var SET_AUDIO_LIST = 'SET_AUDIO_LIST';
export var SET_CURRENT_AUDIO_OBJECT = 'SET_CURRENT_AUDIO_OBJECT';
export var DICREMENT_POSITION = 'DICREMENT_POSITION';
export var INCREMENT_POSITION = 'INCREMENT_POSITION';
export var SET_DEFAULT_POSITION = 'SET_DEFAULT_POSITION';
export var audioPlayerReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SHOW_PLAYER:
            return __assign(__assign({}, state), { isHidden: false });
        case HIDE_PLAYER:
            return __assign(__assign({}, state), { isHidden: true });
        case SET_AUDIO_LIST:
            return __assign(__assign({}, state), { audioList: action.payload });
        case SET_CURRENT_AUDIO_OBJECT:
            return __assign(__assign({}, state), { currentAudioObject: action.payload });
        case SET_DEFAULT_POSITION:
            return __assign(__assign({}, state), { position: 0 });
        case SET_POSITION:
            return __assign(__assign({}, state), { position: action.payload });
        case INCREMENT_POSITION:
            return __assign(__assign({}, state), { position: state.position + 1 });
        case DICREMENT_POSITION:
            return __assign(__assign({}, state), { position: state.position - 1 });
        default:
            return state;
    }
};
