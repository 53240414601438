var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog } from "primereact/confirmdialog";
import { translater } from "../../../../../../../utils/localization/localization";
import { API } from "../../../../../../../api";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { closeEditorFileByName, setCurrentEditorFile } from "../../../../../../../redux/actions/editorAction";
export var DeleteFileButton = function (_a) {
    var loading = _a.loading, setLoading = _a.setLoading, setFileNames = _a.setFileNames, fileNames = _a.fileNames;
    var _b = useSelector(function (state) { return state.editor; }), currentFileName = _b.currentFileName, files = _b.files, currentFilesFolderPath = _b.currentFilesFolderPath;
    var currentFile = files.find(function (file) { return file.name === currentFileName; });
    var accept = function () {
        setLoading(true);
        var folderPath = "".concat(currentFilesFolderPath, "/").concat(currentFileName);
        API.removeCofigurationFile(folderPath)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setFileNames(fileNames.filter(function (file) { return file.fileName !== currentFileName; }));
            setLoading(false);
            setCurrentEditorFile(null);
            closeEditorFileByName(currentFileName);
        })
            .catch(function () { return setLoading(false); });
    };
    var confirm = function () {
        var title = currentFileName;
        confirmDialog({
            message: translater("editorPageHeaderConfirmDialogMessage").toString()
                + " ".concat(title, "?"),
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(); },
            reject: function () { }
        });
    };
    return (_jsx("div", __assign({ className: "min-w-max" }, { children: _jsxs("span", __assign({ "data-pr-tooltip": translater("editorPageItemsLabelDelete"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-delete-file" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-trash", className: "p-button-danger", disabled: loading || (currentFile === null || currentFile === void 0 ? void 0 : currentFile.readOnly) || !Boolean(currentFile), onClick: function () { return confirm(); } }), _jsx(Tooltip, { target: ".custom-target-icon-delete-file" })] })) })));
};
