var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
export var CurrentTime = function () {
    var _a = __read(useState(new Date()), 2), currentTime = _a[0], setCurrentTime = _a[1];
    useEffect(function () {
        var timerId = setInterval(function () { return setCurrentTime(new Date()); }, 1000);
        return function () { return clearInterval(timerId); };
    }, []);
    var formatTime = function (date) {
        var options = {
            hour: '2-digit', minute: '2-digit', second: '2-digit'
        };
        return date.toLocaleTimeString('ru', options);
    };
    useEffect(function () {
        setCurrentTime(new Date());
    }, []);
    return (_jsx("span", __assign({ className: 'header-role flex justify-content-start text-600 p-2' }, { children: formatTime(currentTime) })));
};
