var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../../../../../api';
import { successNotification } from '../../../../../redux/actions/notificationsActions';
import { translater } from '../../../../../utils/localization/localization';
export var CreateButton = function () {
    var navigate = useNavigate();
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var create = function () {
        setLoading(true);
        API.createMongoDatabase()
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            navigate('/initialization');
            setLoading(false);
        })
            .catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Button, { loading: loading, onClick: function () { return create(); }, label: translater('databasePageExportButton').toString(), className: "w-full max-w-19rem" }) }));
};
