var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Image } from 'primereact/image';
import { useNavigate } from "react-router-dom";
import { Translater } from "../../components/particals/Translater";
import { infoNotification, successNotification } from "../../../redux/actions/notificationsActions";
import { API } from "../../../api";
import { CreateButton } from "./components/create/CreateButton";
import { ImportButton } from "./components/import/ImportButton";
import { images } from "../../../assets/images/index";
import { translater } from "../../../utils/localization/localization";
export var Database = function () {
    var navigate = useNavigate();
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    useEffect(function () {
        setLoading(true);
        var validate = function () {
            API.databaseVerification()
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                navigate('/initialization');
                setLoading(false);
            })
                .catch(function (error) {
                var _a;
                var message = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message;
                if (message)
                    infoNotification(message);
                setLoading(false);
            });
        };
        validate();
    }, []);
    return (_jsxs("main", __assign({ className: "relative surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center" }, { children: [_jsx(Translater, { className: "absolute", style: { top: '30px', right: '30px' } }), _jsxs("div", __assign({ className: "surface-card p-6 shadow-1 border-round flex flex-column gap-3 align-items-center" }, { children: [_jsx("img", { src: images.common.logo, alt: "logo", className: "mb-4 w-8rem" }), _jsxs("div", __assign({ className: "flex gap-5 align-items-end" }, { children: [_jsxs("div", __assign({ className: "w-full w-20rem text-center flex flex-column justify-content-center gap-3" }, { children: [_jsx(Image, { src: images.database.server, alt: "Image", width: "100" }), _jsx("span", { children: translater('databasePageExportDescription') }), _jsx(CreateButton, {})] })), _jsxs("div", __assign({ className: "w-full w-20rem text-center flex flex-column justify-content-center gap-3" }, { children: [_jsx(Image, { src: images.database.zip, alt: "Image", width: "100" }), _jsx("span", { children: translater('databasePageImportDescription') }), _jsx(ImportButton, {})] }))] }))] }))] })));
};
