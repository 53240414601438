var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { returnPreviousTree } from '../../../../../../../redux/actions/builderAction';
import { translater } from '../../../../../../../utils/localization/localization';
export var ReturnPreviousTree = function () {
    var treeHistory = useSelector(function (state) { return state.builder; }).treeHistory;
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ "data-pr-tooltip": translater("builderReturnPreviousTreeButton"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", onClick: function () { return returnPreviousTree(); }, className: 'return-previous-tree-button p-button-outlined p-button-icon-only p-button-secondary return-prev-history relative', disabled: treeHistory.length === 0, icon: "pi pi-undo" }, { children: _jsx("span", __assign({ className: clsx('history-length absolute', {
                        'hidden': treeHistory.length === 0
                    }) }, { children: treeHistory.length })) })), _jsx(Tooltip, { target: ".return-previous-tree-button" })] }));
};
