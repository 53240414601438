var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useSelector } from "react-redux";
import { translater } from "../../../../../../../utils/localization/localization";
import { API } from "../../../../../../../api";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { changeEditorFilePrevContent } from "../../../../../../../redux/actions/editorAction";
export var SaveButton = function (_a) {
    var loading = _a.loading, setLoading = _a.setLoading;
    var _b = useSelector(function (state) { return state.editor; }), currentFileName = _b.currentFileName, files = _b.files, currentFilesFolderPath = _b.currentFilesFolderPath;
    var selectedFile = files.find(function (file) { return file.name === currentFileName; });
    var saveContent = function () {
        setLoading(true);
        if (selectedFile) {
            var folderPath = "".concat(currentFilesFolderPath, "/").concat(selectedFile.name);
            API.updateConfigurationFileContent(folderPath, selectedFile.content)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                changeEditorFilePrevContent(selectedFile.name, selectedFile.content);
                setLoading(false);
            })
                .finally(function () { return setLoading(false); });
        }
    };
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsxs("span", __assign({ "data-pr-tooltip": translater("editorPageBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-save-file button-mini" }, { children: [_jsx(Button, { type: "button", icon: "pi pi-save", className: "bg-white border-green-600 text-green-600", loading: loading, disabled: ((selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.content) === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.prevContent))
                            || (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.readOnly)
                            || (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.content.trim()) === '', onClick: function () { return saveContent(); } }), _jsx(Tooltip, { target: ".custom-target-icon-save-file" })] })), _jsx(Button, { type: "button", icon: "pi pi-save", loading: loading, disabled: ((selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.content) === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.prevContent)) || (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.readOnly), label: translater("editorPageBtn").toString(), className: "full-button bg-white border-green-600 text-green-600", onClick: function () { return saveContent(); } })] })));
};
