import defaultImage from './defaultImage.png';
import outcoming from './pages/calls/out_call.png';
import incoming from './pages/calls/in_call.png';
import missing from './pages/calls/miss_call.png';
import unanswered from './pages/calls/unanswered.png';
import viewed from './pages/calls/viewed.png';
import logo from './common/logo.png';
import logoMini from './common/logo-min.png';
import sidebarIcon from './common/sidebarIcon.png';
import zip from './pages/database/zip.png';
import server from './pages/database/server.png';
export var images = {
    defaultImage: defaultImage,
    pages: {
        calls: {
            outcoming: outcoming,
            incoming: incoming,
            missing: missing,
            unanswered: unanswered,
            viewed: viewed
        }
    },
    common: {
        logo: logo,
        logoMini: logoMini,
        sidebarIcon: sidebarIcon
    },
    database: {
        zip: zip,
        server: server
    }
};
