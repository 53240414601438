var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import jwt_decode from 'jwt-decode';
import { translater } from "../../../../../../../utils/localization/localization";
import { API } from "../../../../../../../api";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { setAuthToken } from "../../../../../../../utils/axios/token";
import { store } from "../../../../../../../redux/store";
import { setCurrentUser } from "../../../../../../../redux/actions/authActions";
import { getCurrentHomeData, setHomeData } from "../../../../../../../redux/actions/appActions";
export var RolesToggle = function () {
    var _a = __read(useState([]), 2), roles = _a[0], setRoles = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), tokenPreparing = _c[0], setTokenPreparing = _c[1];
    var rolesToggleBtn = useRef(null);
    var checkRole = function (userRoles, currentUserRoleTitle) {
        return userRoles.find(function (role) { return currentUserRoleTitle === role.title; });
    };
    var user = useSelector(function (state) { return state; }).auth.user;
    useEffect(function () {
        var loadRoles = function () {
            setLoading(true);
            var id = user ? user.id : '';
            API.getUserRoles(id)
                .then(function (response) {
                var roles = response.data;
                setRoles(roles);
                setLoading(false);
            })
                .finally(function () { return setLoading(false); });
        };
        loadRoles();
    }, []);
    var changeAccount = function (role) {
        if (checkRole(user ? user.roles : [], role))
            return false;
        if (user && !tokenPreparing) {
            setTokenPreparing(true);
            var userID = user ? user.id : '';
            API.getUserRoleToken({ id: userID, role: role })
                .then(function (response) {
                if (response) {
                    var _a = response.data, token = _a.token, message = _a.message;
                    var decoded = jwt_decode(token);
                    if (decoded) {
                        localStorage.setItem('token', token);
                        // // Присваиваем токен в Auth header
                        setAuthToken(token);
                        // Парсим токен и получаем пользователя
                        store.dispatch(setCurrentUser(decoded));
                        successNotification(message);
                        // Очищаем данные для пользователя на главной страниы
                        var homeData = getCurrentHomeData();
                        setHomeData(__assign(__assign({}, homeData), { data: null }));
                        // Отправляем на главную страницу
                        // navigate('/')
                        window.location.href = '/';
                    }
                }
                setTokenPreparing(false);
            })
                .finally(function () { return setTokenPreparing(false); });
        }
    };
    if (roles.length <= 1)
        return _jsx(_Fragment, {});
    return (_jsx("ul", __assign({ className: "list-none border-top-1 surface-border p-0 m-0 mt-1" }, { children: _jsxs("li", __assign({ className: "mt-1" }, { children: [_jsx(StyleClass, __assign({ nodeRef: rolesToggleBtn, selector: "@next", enterClassName: "hidden", leaveToClassName: "hidden" }, { children: _jsxs("a", __assign({ ref: rolesToggleBtn, className: "p-ripple flex align-items-center cursor-pointer p-3 text-800 hover:surface-100 border-round transition-colors transition-duration-150 w-full" }, { children: [_jsx("i", { className: "pi pi-users mr-2" }), _jsx("span", __assign({ className: "font-medium" }, { children: translater("slideOverSettingsTabRolesToggleTitle") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })), _jsx("ul", __assign({ className: "m-0 list-none mt-1" }, { children: loading ? (_jsxs(_Fragment, { children: [_jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px", className: "mt-1" }), _jsx(Skeleton, { height: "50px", className: "mt-1" })] })) : (roles.map(function (_a, index) {
                        var title = _a.title, _id = _a._id;
                        return (_jsx("li", __assign({ onClick: function () { return changeAccount(title); }, className: clsx("navbar-custom-button mt-1 flex align-items-center cursor-pointer p-3 border-round text-700 font-medium hover:surface-100 transition-duration-150 transition-colors w-full", {
                                'active cursor-default color-violet': checkRole(user ? user.roles : [], title),
                            }) }, { children: _jsx("span", { children: title }) }), title + index));
                    })) }))] })) })));
};
