var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { images } from "../images/images";
import { downloadAudio } from '../../../../../redux/actions/audioPlayerActions';
export var DownloadButton = function (_a) {
    var src = _a.src;
    return _jsx(_Fragment, { children: _jsx("button", __assign({ className: "btn-download", onClick: function () { return downloadAudio(src); } }, { children: _jsx("img", { src: images.download, alt: "download-img" }) })) });
};
