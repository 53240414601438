import { SET_LANGUAGE } from "../reducers/languageReducer";
import { store } from "../store";
export var setEnglishLanguage = function () {
    var language = {
        type: SET_LANGUAGE,
        payload: 'en'
    };
    localStorage.setItem('language', 'en');
    return store.dispatch(language);
};
export var setRussianLanguage = function () {
    var language = {
        type: SET_LANGUAGE,
        payload: 'ru'
    };
    localStorage.setItem('language', 'ru');
    return store.dispatch(language);
};
