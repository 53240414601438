var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
export var AnimatedNumber = function (_a) {
    var _b = _a.value, value = _b === void 0 ? 0 : _b, _c = _a.duration, duration = _c === void 0 ? 800 : _c;
    var _d = __read(useState(0), 2), currentValue = _d[0], setCurrentValue = _d[1];
    useEffect(function () {
        var startTimestamp = 0;
        var endValue = Math.floor(value);
        var animate = function (timestamp) {
            if (!startTimestamp)
                startTimestamp = timestamp;
            var progress = Math.min((timestamp - startTimestamp) / duration, 1);
            var newValue = Math.floor(progress * endValue);
            setCurrentValue(newValue);
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };
        var animationId = requestAnimationFrame(animate);
        return function () { return cancelAnimationFrame(animationId); };
    }, [value, duration]);
    return _jsx("span", { children: currentValue });
};
