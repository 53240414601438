var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { IncomingMissingChart } from "./IncomingMissingChart";
import { OutcomingUnansweredChart } from "./OutcomingUnansweredChart";
import { Skeleton } from "primereact/skeleton";
export var Statistics = function (_a) {
    var data = _a.data, currentPeriod = _a.currentPeriod;
    var _b = __read(useState(false), 2), showComponent = _b[0], setShowComponent = _b[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            setShowComponent(true);
        }, 1000);
        return function () { return clearTimeout(timer); };
    }, []);
    return (_jsx(_Fragment, { children: showComponent
            ? _jsxs(_Fragment, { children: [_jsx(IncomingMissingChart, { data: data, currentPeriod: currentPeriod }), _jsx(OutcomingUnansweredChart, { data: data, currentPeriod: currentPeriod })] })
            : (_jsxs("div", __assign({ className: "mt-2 mx-3 flex flex gap-2" }, { children: [_jsx(Skeleton, { className: 'w-full', height: "13rem" }), _jsx(Skeleton, { className: 'w-ful', height: "13rem" }), _jsx(Skeleton, { className: 'w-full', height: "13rem" })] }))) }));
};
