var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { translater } from "../../../../../../utils/localization/localization";
export var DownloadCSV = function () {
    var downloadCsv = function () {
        var csvData = [
            ['001', 'Alexei Morozov', 'Template1', 'Context1', 'Alexei_Morozov', '12345678', '00:3A:2B:3C:7D:9E', 'Panasonic'],
            ['002', 'Nikolai Ivanov', '', '', '', '', '', ''],
            ['003', 'Maria Petrova', 'Template1', 'Context1', '', '', '00:3A:3B:3C:7D:9E', 'Panasonic'],
            ['004', 'Olga Sokolova', '', '', '', '', '00:3A:4B:3C:7D:9E', 'Panasonic'],
            ['005', 'Dmitri Kuznetsov', '', '', 'Dmitri_Kuznetsov', '12345678', '', ''],
        ];
        // Преобразуем данные в CSV строку
        var csvString = csvData.map(function (row) { return row.join(','); }).join('\n');
        // Создаем Blob
        var blob = new Blob([csvString], { type: 'text/csv' });
        // Создаем ссылку и задаем URL для скачивания
        var url = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = 'extensions-example.csv';
        // Клик по ссылке для скачивания
        document.body.appendChild(link);
        link.click();
        // Удаляем ссылку после скачивания
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
    return (_jsx("span", __assign({ onClick: downloadCsv, className: "text-sm cursor-pointer app-link-color" }, { children: translater('extensionsPageInfoModalDownloadCSV') })));
};
