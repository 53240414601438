import { setRootTheme } from "../../utils/themes/themes";
import { SET_DARK_MODE } from "../reducers/themeReducer";
import { store } from "../store";
export var setDarkMode = function (mode) {
    var settings = { type: SET_DARK_MODE, payload: mode };
    localStorage.setItem('asteraid/dark-mode', String(mode));
    changeTheme(mode);
    setRootTheme(mode);
    return store.dispatch(settings);
};
// Меняем цвет в link
export var changeTheme = function (mode) {
    var element = document.getElementById('theme-link');
    if (element) {
        var path = element.href;
        // Меняем значение подключаемого скрипта стилей
        var theme = mode ? path.replace('light', 'dark') : path.replace('dark', 'light');
        element.href = theme;
        setRootTheme(mode);
    }
};
