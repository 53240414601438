var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { useDocumentTitle } from "../../../utils/routes/routes";
import { Header } from './components/header/Header';
import { setBreadCrumbs } from '../../../redux/actions/appActions';
import { API } from '../../../api';
import { FilterMatchMode } from 'primereact/api';
import { translater } from '../../../utils/localization/localization';
import { TooltipRevealer } from '../../components/particals/tooltip/TooltipRevealer';
import { Modal } from './components/header/components/Modal';
import { EntityDependencyErrorArea } from '../../components/particals/error/EntityDependencyErrorArea';
import { successNotification } from '../../../redux/actions/notificationsActions';
export var defaultAWSAudioLazyState = {
    first: 0,
    page: 1,
    sortField: null,
    sortOrder: null,
    globalFinderValue: '',
    filters: {
        type: { value: null, matchMode: FilterMatchMode.IN },
    }
};
export var Queues = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    var _b = __read(useState([]), 2), queues = _b[0], setQueues = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(''), 2), queuesFinder = _d[0], setQueuesFinder = _d[1];
    var _e = __read(useState(defaultAWSAudioLazyState), 2), lazyState = _e[0], setLazyState = _e[1];
    var _f = __read(useState(false), 2), checkURL = _f[0], setCheckURL = _f[1];
    var _g = __read(useState(50), 2), limit = _g[0], setLimit = _g[1];
    var _h = __read(useState(0), 2), amount = _h[0], setAmount = _h[1];
    var _j = __read(useState(false), 2), dialogVisible = _j[0], setDialogVisible = _j[1];
    var _k = __read(useState(null), 2), modifiedQueue = _k[0], setModifiedQueue = _k[1];
    var _l = __read(useState([]), 2), dependencyErrors = _l[0], setDependencyErrors = _l[1];
    var _m = __read(useState(false), 2), dependencyModalVisible = _m[0], setDependencyModalVisible = _m[1];
    var _o = __read(useState([]), 2), removableCandidates = _o[0], setRemovableCandidates = _o[1];
    var loadData = function (cb) {
        setLoading(true);
        var query = queuesFinder.trim();
        API.queuesFinder(query, lazyState)
            .then(function (response) {
            var _a = response.data, serverQueues = _a.queues, limit = _a.limit, amount = _a.amount;
            setLimit(limit);
            setQueues(serverQueues);
            setAmount(amount);
            if (cb && checkURL === false)
                setTimeout(function () { cb(serverQueues); }, 0);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); })
            .finally(function () { return setLoading(false); });
    };
    var onPage = function (event) { setLazyState(event); };
    var accept = function (id) {
        API.removeQueues([id])
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadData();
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var confirm = function (audio) {
        setRemovableCandidates([String(audio._id)]);
        confirmDialog({
            message: translater("queuePageItemСonfirmDialogButtonMessage"),
            header: translater("queuePageItemСonfirmDialogButtonHeader"),
            icon: 'pi pi-info-circle',
            position: 'top',
            accept: function () { return accept(audio._id || ''); },
            reject: function () { }
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkQueuesBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeQueues(removableCandidates)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadData();
            setDependencyErrors([]);
            setDependencyModalVisible(false);
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    var cb = function (serverQueues) {
        var params = new URLSearchParams(location.search);
        var id = params.get('queue');
        var modal = params.get('modal');
        setCheckURL(true);
        if (id) {
            var queue = serverQueues.find(function (serverQueue) { return serverQueue._id === id; });
            if (queue && modal === 'open') {
                setModifiedQueue(queue);
                setDialogVisible(true);
            }
        }
    };
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/queues' }]);
    }, []);
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadData(cb); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [queuesFinder]);
    return (_jsxs("main", __assign({ className: "max-w-full m-3 mt-3 flex flex-column gap-2 h-full" }, { children: [_jsx(Header, { finder: setQueuesFinder, refresh: function () { return loadData(); }, finderValue: queuesFinder }), _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: queues, loading: loading, rows: limit, first: lazyState.first, totalRecords: amount, onPage: onPage, 
                // onFilter={onFilter}
                // onSort={onSort}
                sortField: lazyState.sortField, sortOrder: lazyState.sortOrder, filters: lazyState.filters, responsiveLayout: "scroll", scrollable: true, className: "overflow-hidden surface-card shadow-1 border-round", dataKey: "_id" }, { children: [_jsx(Column, { field: "name", className: "justify-content-center px-5", header: translater('queuePageTableNameColumn') }), _jsx(Column, { field: "templates", body: function (row) {
                            return row.templates
                                .map(function (template) { return template.name; })
                                .join(', ');
                        }, className: "justify-content-center px-5 text-center", header: translater('queuePageTableTemplateColumn') }), _jsx(Column, { field: "extensions", body: function (row) {
                            return row.extensions.length;
                        }, className: "justify-content-center px-5", header: translater('queuePageTableExtensionsAmountColumn') }), _jsx(Column, { bodyStyle: { minWidth: "195px" }, headerStyle: { minWidth: "195px" }, className: "justify-content-center", header: translater("actionsColumn"), body: function (row) {
                            return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(TooltipRevealer, __assign({ hint: translater("updateBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-pencil", className: "p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () {
                                                setModifiedQueue(row);
                                                setDialogVisible(true);
                                            } }) })), _jsx(TooltipRevealer, __assign({ hint: translater("deleteBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-trash", className: "p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }) }))] })));
                        } }, 'actions')] })), modifiedQueue ? (_jsx(Modal, { modified: modifiedQueue, refresh: function () { loadData(); }, setDialogVisible: function (param) { setDialogVisible(param); setModifiedQueue(null); }, dialogVisible: dialogVisible })) : '', _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] })));
};
