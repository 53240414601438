var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { API } from '../../../../../../api';
import { translater } from '../../../../../../utils/localization/localization';
export var GroupsList = function (_a) {
    var selectedUsers = _a.selectedUsers, clearSelectedUsers = _a.clearSelectedUsers, loadData = _a.loadData;
    var _b = __read(useState([]), 2), groups = _b[0], setGroups = _b[1];
    var _c = __read(useState([]), 2), selectedGroups = _c[0], setSelectedGroups = _c[1];
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(useState(undefined), 2), timeout = _e[0], setTimeOut = _e[1];
    var multiSelect = useRef(null);
    var loadGroups = function (query) {
        API.groupsFinder(query, { first: 0 })
            .then(function (response) {
            var groups = response.data.groups;
            setGroups(groups);
        });
    };
    var searchGroups = function (_a) {
        var filter = _a.filter;
        clearTimeout(timeout);
        var newTimeout = setTimeout(function () { return loadGroups(filter); }, 600);
        setTimeOut(newTimeout);
    };
    var hideList = function () {
        setGroups([]);
        setSelectedGroups([]);
    };
    var saveGroupsUsers = function () {
        setLoading(true);
        var ids = selectedGroups.map(function (group) { return group._id; });
        var users = selectedUsers.map(function (user) { return user._id; });
        API.addGroupsUsers(ids, users)
            .then(function (response) {
            var _a;
            var message = response.data.message;
            successNotification(message);
            clearSelectedUsers();
            setLoading(false);
            (_a = multiSelect.current) === null || _a === void 0 ? void 0 : _a.hide();
            loadData();
        })
            .catch(function () { return setLoading(false); });
    };
    // const deleteGroupsUsers = () => {
    //   setLoading(true)
    //   const ids = selectedGroups.map(group => group._id)
    //   const users = selectedUsers.map(user => user._id)
    //   API.removeGroupsUsers(ids, users)
    //     .then(response => {
    //       const { message } = response.data
    //       successNotification(message)
    //       setLoading(false)
    //       multiSelect.current.hide()
    //       loadData()
    //     })
    //     .catch(() => setLoading(false))
    // }
    var panelFooterTemplate = function () {
        var groupsLength = selectedGroups ? selectedGroups.length : 0;
        var usersLength = selectedUsers ? selectedUsers.length : 0;
        return (_jsxs("div", __assign({ className: 'flex flex-column gap-3 p-3 pt-1' }, { children: [_jsxs("div", { children: [_jsx("b", { children: usersLength }), " ", translater("usersPageGroupsListSelectedUsers")] }), _jsxs("div", { children: [_jsx("b", { children: groupsLength }), " ", translater("usersPageGroupsListSelectedGroups")] }), _jsx("div", __assign({ className: 'w-full flex gap-2' }, { children: _jsx(Button, { onClick: saveGroupsUsers, className: 'w-full p-button-success', disabled: loading, label: translater("usersPageGroupsListAddButton").toString() }) }))] })));
    };
    return (_jsx(_Fragment, { children: _jsx(MultiSelect, { filterPlaceholder: translater("usersPageGroupsListSearch").toString(), onFilter: searchGroups, onShow: function () { return loadGroups(''); }, onHide: function () { return hideList(); }, filter: true, ref: multiSelect, value: selectedGroups, className: "md:w-15rem", onChange: function (e) { return setSelectedGroups(e.value); }, panelFooterTemplate: panelFooterTemplate, options: groups, optionLabel: "title", disabled: selectedUsers === null || selectedUsers.length === 0, placeholder: translater("usersPageСhooseButton").toString(), display: "chip" }) }));
};
