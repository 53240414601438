import { actionColumn } from "./components/action";
import { dateColumn } from "./components/date";
import { destinationColumn } from "./components/destination";
import { durationColumn } from "./components/duration";
import { sourceColumn } from "./components/source";
import { timeColumn } from "./components/time";
import { typeColumn } from "./components/type";
export var dynamicColumns = function (setRecords) { return [
    typeColumn(setRecords),
    dateColumn,
    timeColumn,
    durationColumn,
    sourceColumn,
    destinationColumn,
    actionColumn,
]; };
