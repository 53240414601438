var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { translater } from "../../../../../../../utils/localization/localization";
import { EditorSettingsDialog } from "./EditorSettingsDialog";
export var EditorSettingsButton = function (_a) {
    var dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible;
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsx(Button, { "data-pr-tooltip": translater("editorPageSettings"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-cog", className: "custom-target-icon-settings p-button-outlined p-button-secondary", onClick: function () { return setDialogVisible(true); } }), _jsx(Tooltip, { target: ".custom-target-icon-settings" }), _jsx(EditorSettingsDialog, { dialogVisible: dialogVisible, setDialogVisible: setDialogVisible })] })));
};
