var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ExpertTab, preparedExpertOptions } from "../../../components/particals/form-generator/components/ExpertTab";
import { getDefaultValues, getStructureCommentedFields } from "../../../components/particals/form-generator/utils/form";
export var PreviewDialog = function (_a) {
    var modifiedExtension = _a.modifiedExtension, hideDialog = _a.hideDialog;
    var _b = __read(useState(modifiedExtension ? preparedExpertOptions(modifiedExtension.expertConfig) : ""), 1), expertCode = _b[0];
    var _c = __read(useState(null), 2), modifiedExtensionStructure = _c[0], setForm = _c[1];
    var form = useForm({
        mode: "all",
        defaultValues: (modifiedExtension === null || modifiedExtension === void 0 ? void 0 : modifiedExtension.config) || getDefaultValues(modifiedExtension === null || modifiedExtension === void 0 ? void 0 : modifiedExtension.config)
    });
    var commentedFields = getStructureCommentedFields(modifiedExtension === null || modifiedExtension === void 0 ? void 0 : modifiedExtension.structure);
    useEffect(function () {
        // Открываем элемент
        if (modifiedExtension) {
            var form_1 = JSON.parse(modifiedExtension.structure);
            setForm(form_1);
        }
    }, [modifiedExtension]);
    return (_jsx("div", __assign({ className: "card" }, { children: _jsxs(Dialog, __assign({ visible: true, modal: true, draggable: false, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: "form-dialog-window relative", contentStyle: { minHeight: "400px" }, style: { minWidth: "800px" }, onHide: function () { return hideDialog(); } }, { children: [_jsx("div", { style: {
                        width: '100%',
                        height: 'calc(100% - 80px)',
                        left: 0,
                        bottom: 'auto',
                        zIndex: 1
                    }, className: "card-cover absolute" }), _jsx(ExpertTab, { expertCode: expertCode, onExpertCode: function () { }, form: form, isActive: true, readOnly: true, commentedFields: commentedFields, tabs: modifiedExtensionStructure ? modifiedExtensionStructure.tabs : [] })] })) })));
};
