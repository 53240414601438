import { dateFromColumn } from "./components/dateFrom";
import { issuerColumn } from "./components/issuer";
import { subjectColumn } from "./components/subject";
import { validColumn } from "./components/valid";
import { dateToColumn } from "./components/dateTo";
export var dynamicColumns = [
    issuerColumn,
    subjectColumn,
    validColumn,
    dateFromColumn,
    dateToColumn,
];
