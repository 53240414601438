var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { translater } from "../../../../../../../utils/localization/localization";
import { setEnglishLanguage, setRussianLanguage } from "../../../../../../../redux/actions/languageActions";
import { ToggleModeButton } from "./ToggleModeButton";
import { SignOutButton } from "./SignOutButton";
import { RolesToggle } from "./RolesToggle";
export var SettingsTabPanel = function () {
    var _a = __read(useState(true), 2), isLangListOpen = _a[0], setLangListOpen = _a[1];
    var lang = useSelector(function (state) { return state; }).language.lang;
    var btnRef3 = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsxs(_Fragment, { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", leaveToClassName: "hidden" }, { children: _jsxs("a", __assign({ onClick: function () { return setLangListOpen(!isLangListOpen); }, ref: btnRef3, className: "p-ripple flex align-items-center cursor-pointer p-3 text-800 hover:surface-100 border-round transition-colors transition-duration-150 w-full" }, { children: [_jsx("i", { className: "pi pi-comments mr-2" }), _jsx("span", __assign({ className: "font-medium" }, { children: translater("languagesSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })), _jsxs("ul", __assign({ className: "m-0 list-none mt-1" }, { children: [_jsx("li", __assign({ onClick: setEnglishLanguage, className: clsx("navbar-custom-button mt-1 flex align-items-center cursor-pointer p-3 border-round text-700 font-medium hover:surface-100 transition-duration-150 transition-colors w-full", {
                                    'active color-violet': lang === 'en'
                                }) }, { children: _jsx("span", { children: translater('englishLanguage') }) })), _jsx("li", __assign({ onClick: setRussianLanguage, className: clsx("navbar-custom-button mt-1 flex align-items-center cursor-pointer p-3 border-round text-700 font-medium hover:surface-100 transition-duration-150 transition-colors w-full", {
                                    'active color-violet': lang === 'ru'
                                }) }, { children: _jsx("span", { children: translater('russianLanguage') }) }))] }))] }), _jsx(RolesToggle, {}), _jsx("ul", __assign({ className: "list-none border-top-1 surface-border p-0 m-0 mt-1" }, { children: _jsx("li", __assign({ className: "mt-1" }, { children: _jsx(ToggleModeButton, {}) })) })), _jsx("ul", __assign({ className: "list-none border-top-1 surface-border p-0 m-0 mt-1" }, { children: _jsx("li", __assign({ className: "mt-1" }, { children: _jsx(SignOutButton, {}) })) }))] }));
};
