var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { OverlayPanel } from "primereact/overlaypanel";
import { useSelector } from "react-redux";
import { translater } from "../../../../../../utils/localization/localization";
import { TooltipRevealer } from "../../../../../components/particals/tooltip/TooltipRevealer";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
export var UpdateComanyName = function (_a) {
    var settings = _a.settings;
    var companyName = useSelector(function (state) { return state; }).app.companyName;
    var nameRef = useRef(null);
    var clientUrlRef = useRef(null);
    var _b = __read(useState(''), 2), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = __read(useState(''), 2), name = _c[0], setName = _c[1];
    var _d = __read(useState(settings.clientUrl), 2), url = _d[0], setUrl = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    useEffect(function () { setUrl(settings.clientUrl); }, [settings.clientUrl]);
    // Грузим первоначальные данные
    useEffect(function () { setName(companyName); }, []);
    var updateName = function () {
        if (name.trim() === '')
            return false;
        setLoading(true);
        API.updateCompanyName(name)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                // Вручную меняем название вкладки
                var title = document.title;
                var _a = __read(title.split('–'), 2), page = _a[1];
                document.title = "".concat(name, " \u2013 ").concat(page);
            }
        })
            .catch(function (err) { setLoading(false); })
            .finally(function () { setLoading(false); });
    };
    var updateClientUrl = function () {
        if (url.trim() === '')
            return false;
        setLoading(true);
        API.updateClientUrl(url)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
            }
        })
            .catch(function () { setLoading(false); })
            .finally(function () { setLoading(false); });
    };
    var handleNameChange = function (e) {
        var value = e.target.value;
        setName(value);
        if (e.target.value.trim() === '') {
            setErrorMessage(translater("settingsPageUpdateCompanyNameInputEmptyFieldValidation").toString());
            return false;
        }
        setErrorMessage('');
        return true;
    };
    return (_jsxs("div", __assign({ className: "w-full card  flex flex-column align-items-start gap-2 surface-card shadow-2 border-round p-3" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: translater('settingsPageCompanyNameHeader') })), _jsx(Divider, { className: "my-1" }), _jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = nameRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("settingsPageUpdateCompanyNameLabel")] }), _jsx(OverlayPanel, __assign({ ref: nameRef }, { children: translater("settingsPageUpdateCompanyNameLabelHint") })), _jsxs("div", __assign({ className: "mb-2 flex align-items-center gap-1" }, { children: [_jsx(InputText, { id: "company-name", value: name, type: "text", onChange: handleNameChange, placeholder: translater("settingsPageUpdateCompanyNameInputLabel").toString(), className: "w-full w-23rem" }), _jsx(TooltipRevealer, __assign({ hint: translater("saveChanges").toString() }, { children: _jsx(Button, { icon: "pi pi-save", disabled: name.trim() === '', loading: loading, 
                            // label={translater("settingsPageUpdateCompanyNameButton").toString()}
                            onClick: function () { return updateName(); }, className: "p-button p-component p-button-outlined text-green-600 border-green-600 w-3rem" }) }))] })), errorMessage && _jsx("small", __assign({ className: "p-error text-base" }, { children: errorMessage })), _jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = clientUrlRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("settingsPageUpdateClientUrlLabel")] }), _jsx(OverlayPanel, __assign({ ref: clientUrlRef }, { children: translater("settingsPageUpdateClientUrlLabelHint") })), _jsxs("div", __assign({ className: "mb-2 flex align-items-center gap-1" }, { children: [_jsx(InputText, { id: "url", value: url, type: "text", onChange: function (e) { return setUrl(e.target.value); }, placeholder: translater("settingsPageUpdateClientUrlInputPlanceholder").toString(), className: "w-full w-23rem" }), _jsx(TooltipRevealer, __assign({ hint: translater("saveChanges").toString() }, { children: _jsx(Button, { icon: "pi pi-save", disabled: url.trim() === '', loading: loading, 
                            // label={translater("settingsPageUpdateCompanyNameButton").toString()}
                            onClick: function () { return updateClientUrl(); }, className: "p-button p-component p-button-outlined text-green-600 border-green-600 w-3rem" }) }))] })), errorMessage && _jsx("small", __assign({ className: "p-error text-base" }, { children: errorMessage }))] })));
};
