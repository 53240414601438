var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { translater } from "../../../../../../../utils/localization/localization";
import { showHeader, showSidebar } from "../../../../../../../redux/actions/appActions";
import { TooltipRevealer } from "../../../../../../components/particals/tooltip/TooltipRevealer";
export var ReturnBackButton = function () {
    var navigate = useNavigate();
    var returnBack = function () {
        navigate('/rules');
        showSidebar();
        showHeader();
    };
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsx(TooltipRevealer, __assign({ hint: translater("returnBack").toString() }, { children: _jsx("span", __assign({ className: "button-mini" }, { children: _jsx(Button, { onClick: function () { return returnBack(); }, className: "p-button-outlined p-button-secondary", icon: "pi pi-sign-out" }) })) })), _jsx(Button, { onClick: function () { return returnBack(); }, label: translater('returnBack').toString(), className: clsx('p-button-outlined p-button-secondary full-button'), icon: "pi pi-sign-out" })] })));
};
