var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { localStorageVariables } from "../../utils/localStorage/variables";
import { ADD_EDITOR_FILE, CHANGE_EDITOR_FILE_CONTENT, CHANGE_EDITOR_FILE_PREV_CONTENT, CLOSE_EDITOR_FILE_BY_NAME, SET_CURRENT_EDITOR_FILE, SET_EDITOR_TEXT_SIZE, SET_PROGRAMMING_LANGUAGE, SET_CURRENT_EDITOR_FOLDER_PATH, ADD_FILE_PATH_HISTORY, REMOVE_FILE_PATH_HISTORY, defaultFilesHistory, MAX_HISTORY_FILES_PATH_AMOUNT, REGISTER_CUSTOM_SYNTAX } from "../reducers/editorReducer";
import { store } from "../store";
export var setCurrentEditorFile = function (fileName) {
    var action = {
        type: SET_CURRENT_EDITOR_FILE,
        payload: fileName
    };
    return store.dispatch(action);
};
export var clearCurrentEditorFile = function () { return setCurrentEditorFile(null); };
export var addEditorFile = function (file) {
    var action = {
        type: ADD_EDITOR_FILE,
        payload: file
    };
    return store.dispatch(action);
};
export var changeEditorFilePrevContent = function (fileName, updContent) {
    var action = {
        type: CHANGE_EDITOR_FILE_PREV_CONTENT,
        payload: { fileName: fileName, updContent: updContent }
    };
    return store.dispatch(action);
};
export var changeEditorFileContent = function (fileName, updContent) {
    var action = {
        type: CHANGE_EDITOR_FILE_CONTENT,
        payload: { fileName: fileName, updContent: updContent }
    };
    return store.dispatch(action);
};
export var closeEditorFileByName = function (fileName) {
    var action = {
        type: CLOSE_EDITOR_FILE_BY_NAME,
        payload: fileName
    };
    return store.dispatch(action);
};
export var setEditorProgrammingLanguage = function (language) {
    var action = {
        type: SET_PROGRAMMING_LANGUAGE,
        payload: language
    };
    localStorage.setItem(localStorageVariables.editor.programmingLanguage, language);
    return store.dispatch(action);
};
export var setEditorFontSize = function (size) {
    var aciton = {
        type: SET_EDITOR_TEXT_SIZE,
        payload: size
    };
    localStorage.setItem(localStorageVariables.editor.fontSize, String(size));
    return store.dispatch(aciton);
};
export var setEditorFolderPath = function (path) {
    var aciton = {
        type: SET_CURRENT_EDITOR_FOLDER_PATH,
        payload: path
    };
    localStorage.setItem(localStorageVariables.editor.folderPath, path);
    return store.dispatch(aciton);
};
export var addHistoryFilePath = function (path) {
    var filesPathHistory = store.getState().editor.filesPathHistory;
    var candidate = filesPathHistory.find(function (currentPath) { return [path, path + '/'].includes(currentPath); });
    var deafultCandidate = defaultFilesHistory.find(function (currentPath) { return [currentPath, currentPath + '/'].includes(path); });
    if (candidate || deafultCandidate)
        return false;
    var updFilesPathHistory = filesPathHistory.length >= MAX_HISTORY_FILES_PATH_AMOUNT
        ? __spreadArray(__spreadArray([], __read(filesPathHistory.slice(-(MAX_HISTORY_FILES_PATH_AMOUNT - 1))), false), [path], false) : __spreadArray(__spreadArray([], __read(filesPathHistory), false), [path], false);
    localStorage.setItem(localStorageVariables.editor.filesPathHistory, JSON.stringify(updFilesPathHistory));
    var aciton = {
        type: ADD_FILE_PATH_HISTORY,
        payload: updFilesPathHistory
    };
    return store.dispatch(aciton);
};
export var removeHistoryFilePath = function (pathToRemove) {
    var filesPathHistory = store.getState().editor.filesPathHistory;
    var updFilesPathHistory = filesPathHistory.filter(function (path) { return path !== pathToRemove; });
    localStorage.setItem(localStorageVariables.editor.filesPathHistory, JSON.stringify(updFilesPathHistory));
    var aciton = {
        type: REMOVE_FILE_PATH_HISTORY,
        payload: updFilesPathHistory
    };
    return store.dispatch(aciton);
};
export var registerCustomEditorSyntax = function () {
    var aciton = {
        type: REGISTER_CUSTOM_SYNTAX,
        payload: true
    };
    return store.dispatch(aciton);
};
export var recognizeProgrammingLanguageByExtension = function (ext) {
    switch (ext) {
        case 'json':
            return setEditorProgrammingLanguage('json');
        case 'py':
            return setEditorProgrammingLanguage('python');
        case 'js':
            return setEditorProgrammingLanguage('javascript');
        default:
            return setEditorProgrammingLanguage('ini');
    }
};
