export var localStorageVariables = {
    lastVisitUrl: 'app-last-url',
    sidebarTabs: 'app-sidebar-tabs',
    defaultTableColumns: {
        user: 'users-app-default-table-columns',
        group: 'gruops-app-default-table-columns',
        certificate: 'certificates-app-default-table-columns',
        rule: 'rules-app-default-table-columns',
        list: 'lists-app-default-table-columns',
        context: 'context-app-default-table-columns'
    },
    editor: {
        fontSize: 'editor-font-size',
        programmingLanguage: 'editor-programming-language',
        folderPath: 'editor-folder-path',
        filesPathHistory: 'editor-files-path-history'
    },
    app: {
        applyLogsVisible: 'app-apply-logs-visible-mode'
    },
    support: {
        isConnected: 'tunnel-is-connected'
    }
};
