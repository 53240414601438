var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { translater } from "../../../utils/localization/localization";
export var NotFound = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    return _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "surface-section px-4 py-8 md:px-6 lg:px-8 h-screen" }, { children: [_jsx("div", __assign({ style: {
                        background: "radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%)",
                    }, className: "text-center" }, { children: _jsx("span", __assign({ className: "bg-white text-pink-500 font-bold text-2xl inline-block px-3" }, { children: translater("notFoundPageErrorCode") })) })), _jsx("div", __assign({ className: "mt-6 mb-5 font-bold text-6xl text-900 text-center" }, { children: translater("notFoundPageError") })), _jsx("p", __assign({ className: "text-700 text-3xl mt-0 mb-6 text-center" }, { children: translater("notFoundPageErrorMessage") })), _jsx("div", __assign({ className: "text-center" }, { children: _jsx(Link, __assign({ className: "no-underline", to: '/' }, { children: _jsx(Button, { label: translater("notFoundPageButtonLabel").toString(), icon: "pi pi-home" }) })) }))] })) });
};
