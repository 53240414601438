var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from "primereact/tooltip";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import { API } from "../../../../../../api";
import { translater } from "../../../../../../utils/localization/localization";
export var DeleteUsersButton = function (_a) {
    var loadData = _a.loadData, selectedUsers = _a.selectedUsers, clearSelectedUsers = _a.clearSelectedUsers;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var accept = function (ids) {
        setLoading(true);
        API.removeUsers(ids)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            clearSelectedUsers();
            // Перезагружаем данные после удаления
            loadData();
        })
            .catch(function () { return setLoading(false); });
    };
    var confirm = function (selectedUsers) {
        var logins = selectedUsers.map(function (user) { return user.login; });
        var ids = selectedUsers.map(function (user) { return user._id; });
        var message = " ".concat(logins.join(', '), "?");
        confirmDialog({
            message: translater("usersPageHeaderConfirmDialogMessage").toString() + message,
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(ids); },
            reject: function () { }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": translater("deleteBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", type: "button", icon: "pi pi-trash", disabled: (selectedUsers === null || selectedUsers.length === 0) || loading, className: "custom-target-icon-delete-user bg-red-500 border-red-500", onClick: function () { return confirm(selectedUsers); } }), _jsx(Tooltip, { target: ".custom-target-icon-delete-user" })] }));
};
