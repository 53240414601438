import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import clsx from "clsx";
import { translater } from "../../../../../../utils/localization/localization";
import { compareChildrenContext } from "./SaveOrderButton";
export var UndoButton = function (_a) {
    var loadData = _a.loadData, contextChildren = _a.contextChildren, contextChildrenHistory = _a.contextChildrenHistory;
    var undo = function () {
        loadData();
    };
    return (_jsx(Button, { type: "button", disabled: compareChildrenContext(contextChildrenHistory, contextChildren), label: translater('contextPageHeaderCancelNewOrderState').toString(), className: clsx('p-button-outlined p-button-secondary'), onClick: function () { return undo(); } }));
};
