import { nameColumn } from "./components/name";
import { commentColumn } from "./components/comment";
import { patternColumn } from "./components/pattern";
import { typeColumn } from "./components/type";
export var dynamicColumns = [
    nameColumn,
    typeColumn,
    patternColumn,
    commentColumn
];
