var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { translater } from "../../../../utils/localization/localization";
export var DialogWindow = function (_a) {
    var hintVisible = _a.hintVisible, setHintVisible = _a.setHintVisible, children = _a.children;
    var navigate = useNavigate();
    var logOut = function () {
        navigate('/login');
    };
    return (_jsxs(Dialog, __assign({ onHide: function () { }, visible: hintVisible, modal: true, resizable: false, breakpoints: { '960px': '75vw', '640px': '100vw' }, style: { width: '40vw' }, closable: false, showHeader: false, className: "overflow-hidden", footer: _jsxs("div", __assign({ className: "flex justify-content-center surface-border py-2 mb-2 flex" }, { children: [_jsx(Button, { icon: "pi pi-times", onClick: function () { return setHintVisible(false); }, label: translater("groupTabPageDialogWindowButtonCancel").toString(), className: "p-button-outlined w-5 mr-2" }), _jsx(Button, { icon: "pi pi-sign-out", onClick: logOut, label: translater("groupTabPageDialogWindowButtonExit").toString(), className: "w-5 ml-2" })] })) }, { children: [_jsxs("div", __assign({ className: "flex flex-column align-items-center mt-5" }, { children: [_jsx("span", __assign({ className: "flex align-items-center justify-content-center bg-indigo-500 text-50 mr-3 border-circle mb-3", style: { width: '70px', height: '70px' } }, { children: _jsx("i", { className: "pi pi-bell text-5xl" }) })), _jsx("div", __assign({ className: "font-medium text-xl text-900 text-center" }, { children: translater("groupTabPageDialogWindowHeader") }))] })), _jsx("div", __assign({ className: "flex flex-column align-items-center mt-2" }, { children: _jsx("p", __assign({ className: "line-height-3 p-0 m-0 text-center text-base" }, { children: children })) }))] })));
};
