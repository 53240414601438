var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { LoginByPassword } from "./components/LoginByPassword";
import { activeDirectoryLogin, login } from "../../../redux/actions/authActions";
import { images } from "../../../assets/images";
import { Translater } from "../../components/particals/Translater";
import { translater } from "../../../utils/localization/localization";
import { useForm } from "react-hook-form";
import { API } from "../../../api";
import { useNavigate } from "react-router-dom";
export var Login = function () {
    var navigation = useNavigate();
    var _a = __read(useState(false), 2), isLoginProcess = _a[0], setLoginProcess = _a[1];
    var isActiveDirectory = function (login) {
        return /[a-zA-Z0-9][@|\/][a-zA-Z0-9]/i.test(login.trim());
    };
    var signIn = function (loginData) {
        setLoginProcess(true);
        if (isActiveDirectory(loginData.login)) {
            var data = {
                username: loginData.login,
                password: loginData.password
            };
            activeDirectoryLogin(data)
                .then(function () { return setLoginProcess(false); })
                .catch(function () { return setLoginProcess(false); });
        }
        else {
            login(loginData)
                .then(function () { return setLoginProcess(false); })
                .catch(function () { return setLoginProcess(false); });
        }
    };
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit;
    var onSubmit = function (data) {
        if (!isLoginProcess)
            signIn(data);
    };
    useEffect(function () {
        var validate = function () {
            API.databaseVerification()
                .catch(function (error) {
                navigation('/database');
            });
        };
        validate();
    }, []);
    return (_jsxs("main", __assign({ className: "relative surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center" }, { children: [_jsx(Translater, { className: "absolute", style: { top: '30px', right: '30px' } }), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "surface-card p-4 shadow-2 border-round w-full lg:w-6 max-w-30rem" }, { children: [_jsxs("div", __assign({ className: "text-center mb-5" }, { children: [_jsx("img", { src: images.common.logo, alt: "logo", className: "mb-3 w-8rem" }), _jsx("div", __assign({ className: "text-900 text-3xl font-medium mb-3" }, { children: translater("welcomeBack") }))] })), _jsx(LoginByPassword, { register: register }), _jsx(Button, { loading: isLoginProcess, label: translater("signInButton").toString(), icon: "pi pi-user", className: "w-full" })] }))] })));
};
