var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "../../../../../../../api";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { login } from "../../../../../../../redux/actions/authActions";
import { LoginField } from "./fields/LoginField";
import { PasswordField } from "./fields/PasswordField";
import { ConfirmPasswordField } from "./fields/ConfirmPasswordField";
import { Button } from "primereact/button";
import { translater } from "../../../../../../../utils/localization/localization";
import { CompanyName } from "./fields/CompanyName";
var defaultAdminData = {
    name: 'admin',
    surname: 'admin',
    secname: 'admin',
    email: 'admin@mail.ru',
    role: 'Administrator'
};
export var Form = function (_a) {
    var reloadTabs = _a.reloadTabs;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var systemLogin = function (data) {
        setLoading(true);
        login(data)
            .then(function () {
            setLoading(false);
            reloadTabs();
        })
            .catch(function () { return setLoading(false); });
    };
    // const updateCompanyName = () => {
    //   API.getCompanyName()
    //     .then((response: AxiosResponse<{ name: string }>) => {
    //       const { name } = response.data
    //       setCompanyName(name)
    //     })
    // }
    var createAdmin = function (data) {
        setLoading(true);
        API.adminInitialization(data)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            var password = data.password, login = data.login;
            systemLogin({ login: login, password: password });
        })
            .catch(function () { return setLoading(false); });
    };
    var _c = useForm({ mode: 'all' }), register = _c.register, handleSubmit = _c.handleSubmit, getValues = _c.getValues, _d = _c.formState, errors = _d.errors, isValid = _d.isValid, submitCount = _d.submitCount;
    var onSubmit = function (data) {
        var cloned = Object.assign(defaultAdminData, data);
        createAdmin(cloned);
    };
    return (_jsx("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: _jsxs("div", __assign({ className: "surface-card p-4 shadow-2 border-round w-full" }, { children: [_jsx(LoginField, { register: register, errors: errors }), _jsx(PasswordField, { register: register, errors: errors }), _jsx(ConfirmPasswordField, { register: register, errors: errors, getValues: getValues }), _jsx(CompanyName, { register: register, errors: errors }), _jsx(Button, { label: translater("adminAccountTabPageCreateAdminButton").toString(), disabled: submitCount > 0 && !isValid, icon: "pi pi-check", className: "p-button p-component p-button-outlined text-green-600 border-green-600 w-full", loading: loading })] })) })));
};
