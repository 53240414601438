var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog } from 'primereact/dialog';
import { Form } from './components/Form';
import { translater } from '../../../../../../../utils/localization/localization';
export var UpdateGroup = function (_a) {
    var modifiedGroup = _a.modifiedGroup, dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible, loadData = _a.loadData;
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx(Dialog, __assign({ header: translater("groupsUpdateGroupDialogHeader"), visible: dialogVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window', contentStyle: { minHeight: '400px' }, style: { minWidth: '500px' }, onHide: function () { return setDialogVisible(false); } }, { children: _jsx(Form, { modifiedGroup: modifiedGroup, loadData: loadData, hideModal: function () { return setDialogVisible(false); } }) })) })));
};
