var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var initialState = {
    list: [],
    errors: [],
    history: []
};
// Значения по умолчанию
var notifications = localStorage.getItem('notifications');
if (notifications)
    initialState.history = JSON.parse(notifications);
else
    localStorage.setItem('notifications', JSON.stringify([]));
export var SET_NOTIFICATION = "SET_NOTIFICATION";
export var SET_HISTORY_NOTIFICATION = "SET_HISTORY_NOTIFICATION";
export var CLEAR_HISTORY_NOTIFICATIONS = "CLEAR_HISTORY_NOTIFICATIONS";
export var REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export var SET_ERRORS = "SET_ERRORS";
export var REMOVE_ERRORS = "REMOVE_ERRORS";
export var notificationsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_NOTIFICATION:
            return __assign(__assign({}, state), { list: action.payload ? __spreadArray(__spreadArray([], __read(state.list), false), [action.payload], false) : state.list });
        case SET_HISTORY_NOTIFICATION:
            return __assign(__assign({}, state), { history: action.payload ? __spreadArray([action.payload], __read(state.history), false) : state.history });
        case CLEAR_HISTORY_NOTIFICATIONS:
            localStorage.setItem('notifications', JSON.stringify([]));
            state.history.length = 0;
            return __assign({}, state);
        case REMOVE_NOTIFICATION:
            return __assign(__assign({}, state), { list: state.list.slice(0, -1) });
        case SET_ERRORS:
            return __assign(__assign({}, state), { errors: action.payload ? __spreadArray(__spreadArray([], __read(state.errors), false), __read(action.payload), false) : state.errors });
        case REMOVE_ERRORS:
            return __assign(__assign({}, state), { errors: [] });
        default:
            return state;
    }
};
