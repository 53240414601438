var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { FilterMatchMode } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import { Tooltip } from "primereact/tooltip";
import { translater } from "../../../utils/localization/localization";
import { API } from "../../../api";
import { successNotification } from "../../../redux/actions/notificationsActions";
import '../.././../assets/scss/pages/lists.scss';
import { dynamicColumns } from "./components/columns/columns";
import { UpdateContext } from "./components/header/components/update-modal/UpdateContext";
import { Header } from "./components/header/Header";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { EntityDependencyErrorArea } from "../../components/particals/error/EntityDependencyErrorArea";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
var defaultLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var Contexts = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/contexts' }]);
    }, []);
    var _b = __read(useState([]), 2), contexts = _b[0], setContexts = _b[1];
    var _c = __read(useState(50), 2), limit = _c[0], setLimit = _c[1];
    var _d = __read(useState(0), 2), contextsAmount = _d[0], setContextsAmount = _d[1];
    var _e = __read(useState([]), 2), dependencyErrors = _e[0], setDependencyErrors = _e[1];
    var _f = __read(useState(false), 2), dependencyModalVisible = _f[0], setDependencyModalVisible = _f[1];
    var _g = __read(useState([]), 2), removableCandidates = _g[0], setRemovableCandidates = _g[1];
    var _h = __read(useState([]), 2), selectedContexts = _h[0], setSelectedContexts = _h[1];
    var _j = __read(useState(false), 2), loading = _j[0], setLoading = _j[1];
    var _k = __read(useState(''), 2), globalFinderValue = _k[0], setGlobalFinderValue = _k[1];
    var _l = __read(useState(false), 2), dialogVisible = _l[0], setDialogVisible = _l[1];
    var _m = __read(useState(null), 2), modifiedContext = _m[0], setModifiedContext = _m[1];
    var _o = __read(useState(defaultLazyState), 2), lazyState = _o[0], setlazyState = _o[1];
    var navigate = useNavigate();
    var loadData = function () {
        setLoading(true);
        var query = globalFinderValue.trim();
        API.contextsFinder(query, lazyState)
            .then(function (response) {
            var _a = response.data, contexts = _a.contexts, limit = _a.limit, amount = _a.amount;
            setLimit(limit);
            setContexts(contexts);
            setContextsAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var onFilter = function (event) {
        event['first'] = 0;
        setlazyState(event);
    };
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadData(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    var onPage = function (event) { setlazyState(event); };
    var onChangeGlobalFinderValue = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
    };
    var accept = function (ids) {
        setLoading(true);
        API.removeContexts(ids)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                setSelectedContexts([]);
                // Перезагружаем данные после удаления
                loadData();
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkContextsBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeContexts(removableCandidates)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                setSelectedContexts([]);
                // Перезагружаем данные после удаления
                loadData();
                setDependencyErrors([]);
                setDependencyModalVisible(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    var confirm = function (row) {
        var title = row.name;
        var ids = [row._id];
        setRemovableCandidates(ids);
        confirmDialog({
            message: translater("contextPageHeaderConfirmDialogMessage").toString()
                + " ".concat(title, "?"),
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(ids); },
            reject: function () { }
        });
    };
    var columns = dynamicColumns.map(function (column) {
        var header = translater(column.columnHeader);
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { loadData: loadData, globalFinderValue: globalFinderValue, setGlobalFinderValue: onChangeGlobalFinderValue }) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: contexts, loading: loading, rows: limit, first: lazyState.first, totalRecords: contextsAmount, responsiveLayout: "scroll", scrollable: true, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, selection: selectedContexts, onSelectionChange: function (e) { return setSelectedContexts(e.value); }, dataKey: "_id", style: { minWidth: "200px" } }, { children: [columns, _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), body: function (row) {
                                if (row.location === 'file')
                                    return '–';
                                return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(Button, { "data-pr-tooltip": translater("openBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-sign-in", className: "custom-target-icon-open-context-".concat(row._id, " p-button p-component  p-button-icon-only p-button-outlined p-button-warning border-circle"), style: { width: "50px", height: "50px" }, onClick: function () { return navigate('/contexts/' + row._id); } }), _jsx(Tooltip, { target: "custom-target-icon-open-context-".concat(row._id) }), _jsx(Button, { "data-pr-tooltip": translater("updateBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-pencil", className: "custom-target-icon-update-context-".concat(row._id, " p-button p-component p-button-icon-only p-button-outlined border-circle"), style: { width: "50px", height: "50px" }, onClick: function () {
                                                setDialogVisible(true);
                                                setModifiedContext(row);
                                            } }), _jsx(Tooltip, { target: "custom-target-icon-update-context-".concat(row._id) }), _jsx(Button, { "data-pr-tooltip": translater("deleteBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-trash", className: "custom-target-icon-delete-context-".concat(row._id, " p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle"), style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }), _jsx(Tooltip, { target: "custom-target-icon-delete-context-".concat(row._id) })] })));
                            } }, 'actions')] })) })), _jsx(UpdateContext, { modifiedContext: modifiedContext, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible, loadData: loadData }), _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] })));
};
