var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Modal } from "./Modal";
import { Button } from "primereact/button";
import { translater } from "../../../../../../utils/localization/localization";
var defaultQueue = {
    name: '',
    templates: [],
    extensions: []
};
export var CreateButton = function (_a) {
    var refresh = _a.refresh, modified = _a.modified;
    var _b = __read(useState(false), 2), dialogVisible = _b[0], setDialogVisible = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx(Button, { icon: "pi pi-plus", label: translater('queuesPagesCreateButton').toString(), className: "p-button p-component p-button-success white-space-nowrap min-w-max", onClick: function () { return setDialogVisible(true); } }), dialogVisible ? (_jsx(Modal, { refresh: refresh, setDialogVisible: setDialogVisible, dialogVisible: dialogVisible })) : ''] }));
};
