import { patternColumn } from "./components/pattern";
import { commentColumn } from "./components/comment";
import { nameColumn } from "./components/name";
import { dateCreateColumn } from "./components/dateCreate";
import { dateUpdateColumn } from "./components/dateUpdate";
export var dynamicColumns = [
    nameColumn,
    patternColumn,
    commentColumn,
    dateCreateColumn,
    dateUpdateColumn
];
