var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { API } from "../../../../../../api";
import { translater } from "../../../../../../utils/localization/localization";
import { OdbcConnectionString } from "./fields/OdbcConnectionString";
export var Form = function (_a) {
    var setSettings = _a.setSettings, settings = _a.settings;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), hintVisible = _c[0], setHintVisible = _c[1];
    var cancelToken = useRef(null);
    var testConnection = function (data) {
        setLoading(true);
        cancelToken.current = axios.CancelToken.source();
        if (cancelToken)
            API.testCDRDatabaseConnection(data, cancelToken)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                setSettings(function (prev) { return (__assign(__assign({}, prev), { cdrDataBase: data.odbc })); });
                // reset()
            })
                .catch(function () { return setLoading(false); });
    };
    var abortRequest = function (event) {
        var _a;
        event.preventDefault();
        (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.cancel();
        setLoading(false);
    };
    var formData = { mode: 'all' };
    var _d = useForm({ mode: 'all', defaultValues: { odbc: (settings === null || settings === void 0 ? void 0 : settings.cdrDataBase) || '' } }), register = _d.register, handleSubmit = _d.handleSubmit, reset = _d.reset, trigger = _d.trigger, getValues = _d.getValues, setValue = _d.setValue, _e = _d.formState, errors = _e.errors, isValid = _e.isValid, submitCount = _e.submitCount;
    useEffect(function () {
        // Когда settings обновляются, сбрасываем форму с новыми значениями
        reset({ odbc: settings.cdrDataBase });
    }, [settings, reset]);
    var onSubmit = function (data) {
        testConnection(data);
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsx("div", __assign({ className: "w-full w-23rem" }, { children: _jsx(OdbcConnectionString, { register: register, errors: errors }) })), _jsx("div", __assign({ className: "flex justify-content-start gap-2 w-23rem" }, { children: loading ? (_jsx(Button, { label: translater("cancel").toString(), icon: "pi pi-times", type: "button", onClick: abortRequest, className: "p-button p-button-warning w-full p-button-outlined" })) : (_jsx(Button, { label: translater("settingsPageCDRFormButton").toString(), icon: "pi pi-check", className: "w-full p-button-outlined text-green-600 border-green-600", loading: loading, disabled: submitCount > 0 && !isValid })) }))] })));
};
