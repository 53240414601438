var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { FullSideBar } from "./components/FullSideBar";
import { MiniSideBar } from "./components/MiniSideBar";
import { getPBXSettingsItems, getSecuritySettingsItems, getSidebarItems, getSystemSettingsItems, getUserSettingsItems } from "../../../../redux/actions/appActions";
export var SideBar = function () {
    var defaultSideBarState = localStorage.getItem('isFullSideBar');
    var _a = __read(useState(!defaultSideBarState), 2), isFullSideBar = _a[0], setFullSideBarFlag = _a[1];
    var setSideBarState = function (mode) {
        if (mode) {
            localStorage.removeItem('isFullSideBar');
            return setFullSideBarFlag(true);
        }
        localStorage.setItem('isFullSideBar', 'true');
        return setFullSideBarFlag(false);
    };
    var links = getSidebarItems();
    var systemSettingsLinks = getSystemSettingsItems();
    var pbxSettingsLinks = getPBXSettingsItems();
    var securitySettingsLinks = getSecuritySettingsItems();
    var userLinks = getUserSettingsItems();
    return isFullSideBar ? (_jsx(FullSideBar, { setFullSideBarFlag: setSideBarState, links: links.sort(function (a, b) { return a.order - b.order; }), systemSettingsLinks: systemSettingsLinks.sort(function (a, b) { return a.order - b.order; }), pbxSettingsLinks: pbxSettingsLinks.sort(function (a, b) { return a.order - b.order; }), securitySettingsLinks: securitySettingsLinks.sort(function (a, b) { return a.order - b.order; }), userLinks: userLinks.sort(function (a, b) { return a.order - b.order; }) })) : (_jsx(MiniSideBar, { setFullSideBarFlag: setSideBarState, links: links.sort(function (a, b) { return a.order - b.order; }), systemSettingsLinks: systemSettingsLinks.sort(function (a, b) { return a.order - b.order; }), pbxSettingsLinks: pbxSettingsLinks.sort(function (a, b) { return a.order - b.order; }), securitySettingsLinks: securitySettingsLinks.sort(function (a, b) { return a.order - b.order; }), userLinks: userLinks.sort(function (a, b) { return a.order - b.order; }) }));
};
