var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog } from 'primereact/dialog';
import { translater } from '../../../../../../utils/localization/localization';
import { Form } from './components/Form';
export var CreateStation = function (_a) {
    var dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible, loadData = _a.loadData;
    var closeDialog = function () {
        setDialogVisible(false);
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "card" }, { children: _jsx(Dialog, __assign({ header: translater("stationsPageCreateDialogTitle"), visible: dialogVisible, modal: true, draggable: false, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window', style: { minWidth: '800px' }, onHide: function () { return closeDialog(); } }, { children: _jsx("div", __assign({ className: "surface-card mt-2 p-4 shadow-2 border-round w-full" }, { children: _jsx(Form, { loadData: loadData, closeDialog: function () { return setDialogVisible(false); } }) })) })) })) }));
};
