import { localStorageVariables } from "../../utils/localStorage/variables";
import { SET_CONNECTION, SET_LOADED_FILE_STATUS } from "../reducers/supportReducer";
import { store } from "../store";
export var changeSupportConnection = function () {
    var isConnected = store.getState().support.isConnected;
    var mode = !isConnected;
    var settings = { type: SET_CONNECTION, payload: mode };
    localStorage.setItem(localStorageVariables.support.isConnected, String(mode));
    return store.dispatch(settings);
};
export var setFileLoadedStatus = function (status) {
    return store.dispatch({ type: SET_LOADED_FILE_STATUS, payload: status });
};
