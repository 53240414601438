var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { Translater } from "../../components/particals/Translater";
import { translater } from "../../../utils/localization/localization";
export var Tabs = function (_a) {
    var active = _a.active, setActive = _a.setActive, failedTabs = _a.failedTabs;
    var isReady = function (mode) {
        if (active === 'finished')
            return 'text-green-500';
        return failedTabs.includes(mode) ? '' : 'text-green-500';
    };
    var clickPermission = function (mode) {
        if (!['groups', 'certificates', 'cdr'].includes(active))
            return false;
        // Давать возможность переключить на двух поледних табах: AD и сертификаты
        return failedTabs.includes(mode);
    };
    return (_jsxs("ul", __assign({ className: "relative surface-card p-0 m-0 list-none flex select-none" }, { children: [_jsx("li", __assign({ className: "flex-1" }, { children: _jsxs("a", __assign({ className: classNames('p-ripple px-4 py-3 flex align-items-center justify-content-center border-bottom-2 transition-colors transition-duration-150 ' + isReady('admin'), { 'border-blue-500 text-blue-500 hover:border-blue-500': active === 'admin', 'text-400 border-transparent': active !== 'admin' }) }, { children: [_jsx("i", { className: "pi pi-user mr-2" }), _jsx("span", __assign({ className: "font-medium text-sm" }, { children: translater("tabsCreateTabName") })), _jsx(Ripple, {})] })) })), _jsx(Translater, { className: "absolute", style: {
                    bottom: '-43px',
                    right: '34px'
                } })] })));
};
