var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Ripple } from "primereact/ripple";
import { logout } from "../../../../../../../redux/actions/authActions";
import { translater } from "../../../../../../../utils/localization/localization";
export var SignOutButton = function () {
    var navigate = useNavigate();
    var logOut = function () {
        logout();
        navigate('/login');
    };
    return (_jsx(_Fragment, { children: _jsxs("span", __assign({ onClick: logOut, className: "flex align-items-center cursor-pointer p-3 border-round text-800 hover:surface-100 transition-duration-150 transition-colors w-full no-underline" }, { children: [_jsx("i", { className: "pi pi-sign-out text-base mr-2" }), _jsx("span", __assign({ className: "block font-medium" }, { children: translater("LogOutButton") })), _jsx(Ripple, {})] })) }));
};
