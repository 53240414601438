var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { translate } from "../FormGenerator";
import { InputTextarea } from 'primereact/inputtextarea';
import { RequiredFieldDesignation } from "../utils/RequiredFieldDesignation";
import { ErrorBlock } from "../../error/ErrorBlock";
import { translater } from "../../../../../utils/localization/localization";
import { Hint } from "../utils/Hint";
export var Textarea = function (_a) {
    var _b = _a.form, register = _b.register, errors = _b.formState.errors, _c = _a.field, titleTranslaterKey = _c.titleTranslaterKey, field = _c.field, placeholderTranslaterKey = _c.placeholderTranslaterKey, defaultValue = _c.defaultValue, required = _c.required, hintTranslaterKey = _c.hintTranslaterKey;
    var ref = useRef(null);
    var rules = {};
    if (required)
        rules.required = translater("Form Generator Required Field").toString();
    var params = {};
    if (placeholderTranslaterKey)
        params.placeholder = translate(placeholderTranslaterKey).toString();
    if (defaultValue)
        params.defaultValue = translate(String(defaultValue)).toString();
    return (_jsxs("div", __assign({ className: "w-full flex flex-column align-items-start gap-2" }, { children: [_jsxs("div", __assign({ className: "w-full flex flex-column" }, { children: [_jsxs("span", __assign({ className: "mb-1" }, { children: [hintTranslaterKey && (_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "text-primary p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" })), translate(titleTranslaterKey), required && _jsx(RequiredFieldDesignation, {})] })), _jsx(InputTextarea, __assign({}, register(field, rules), params, { className: "w-full" }))] })), _jsx(ErrorBlock, { errors: errors, keyword: field }), _jsx(Hint, { hint: hintTranslaterKey, reference: ref })] })));
};
