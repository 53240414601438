import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { downloadAudio, setCurrentAudioObject } from '../../../../../../redux/actions/audioPlayerActions';
import { translater } from '../../../../../../utils/localization/localization';
var actionBodyTemplate = function (row) {
    var voice_message = row.voice_message, type = row.type;
    if (voice_message && ['incoming', 'outcoming'].includes(type))
        return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": translater("listenBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-play", className: "calls-play-icon-".concat(row.id, " p-button p-component mr-2 p-button-icon-only p-button-outlined p-button-rounded"), style: { width: "50px", height: "50px" }, onClick: function () { return setCurrentAudioObject(row); } }), _jsx(Tooltip, { target: ".calls-play-icon-".concat(row.id) }), _jsx(Button, { "data-pr-tooltip": translater("downloadBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-download", className: "calls-download-icon-".concat(row.id, " p-button p-component p-button-icon-only p-button-outlined p-button-rounded p-button-warning"), style: { width: "50px", height: "50px" }, onClick: function () { return downloadAudio(voice_message); } }), _jsx(Tooltip, { target: ".calls-download-icon-".concat(row.id) })] }));
};
export var actionColumn = {
    columnHeader: 'callsAction',
    field: 'action',
    style: { minWidth: "180px" },
    body: actionBodyTemplate
};
