var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { translater } from "../../../../../../utils/localization/localization";
export var GroupInfoWindow = function (_a) {
    var hintVisible = _a.hintVisible, amount = _a.amount, reload = _a.reload, hideModal = _a.hideModal;
    var _b = amount || {}, _c = _b.users, users = _c === void 0 ? 0 : _c, _d = _b.supervisors, supervisors = _d === void 0 ? 0 : _d, _e = _b.admins, admins = _e === void 0 ? 0 : _e;
    return (_jsx(Dialog, __assign({ onHide: function () { }, visible: hintVisible, modal: true, resizable: false, breakpoints: { '960px': '75vw', '640px': '100vw' }, style: { width: '40vw' }, closable: false, showHeader: false, className: "overflow-hidden", footer: _jsx("div", __assign({ className: "flex justify-content-center surface-border mb-3 flex" }, { children: _jsx(Button, { onClick: function () { reload(); hideModal(); }, icon: "pi pi-check-circle", label: translater("groupTabPageGroupInfoWindowButton").toString(), className: "p-button-outlined w-3 mr-2" }) })) }, { children: _jsxs("div", __assign({ className: "flex flex-column gap-3 align-items-center mt-5" }, { children: [_jsxs("p", __assign({ className: "line-height-3 p-0 m-0 text-center text-base" }, { children: [translater("groupTabPageGroupInfoWindowUsersInfo"), " ", users] })), _jsxs("p", __assign({ className: "line-height-3 p-0 m-0 text-center text-base" }, { children: [translater("groupTabPageGroupInfoWindowSupervisorsInfo"), " ", supervisors] })), _jsxs("p", __assign({ className: "line-height-3 p-0 m-0 text-center text-base" }, { children: [translater("groupTabPageGroupInfoWindowAdminsInfo"), " ", admins] }))] })) })));
};
