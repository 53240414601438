import { translater } from "../../../../../../utils/localization/localization";
export var transfortDuration = function (duration) {
    if (isNaN(duration)) {
        return duration;
    }
    return Number(duration).toFixed(1);
};
var template = function (_a) {
    var duration = _a.duration;
    return transfortDuration(duration) + ' ' + translater('awsAudioListSeconds');
};
export var durationColumn = {
    columnHeader: 'awsPageAudioDurationColumn',
    field: 'duration',
    sortable: true,
    body: template,
};
