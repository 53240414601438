var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "../../../../../../../api";
import { infoNotification, successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { translater } from "../../../../../../../utils/localization/localization";
import { HostnameField } from "./fields/HostnameField";
import { NameField } from "./fields/NameField";
import { LocationField } from "./fields/LocationField";
import { PasswordField } from "./fields/PasswordField";
import { LoginField } from "./fields/LoginField";
import { SshKeyField } from "./fields/SshKeyField";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { Link } from "react-router-dom";
export var Form = function (_a) {
    var loadData = _a.loadData, closeDialog = _a.closeDialog;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), modalVisible = _c[0], setModalVisible = _c[1];
    var _d = __read(useState(''), 2), sshResult = _d[0], setSshResult = _d[1];
    var options = ['Login & Password', 'SSH key'];
    var _e = __read(useState(options[0]), 2), optionValue = _e[0], setOptionValue = _e[1];
    var sendData = function (data) {
        var checkField = function (field) { return field && field.trim(); };
        // Если не заполненно ни одно поле: login, password, ssh
        if (!checkField(data.login) && !checkField(data.password) && !checkField(data.sshKey)) {
            infoNotification(translater('stationsPageEnterDataHint').toString());
            return false;
        }
        setLoading(true);
        setModalVisible(false);
        API.createStation(data)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            closeDialog();
            loadData();
        })
            .catch(function () {
            setLoading(false);
        });
    };
    var checkSSHData = function (data) {
        setLoading(true);
        API.sshCopyId(data)
            .then(function (response) {
            var result = response.data.result;
            setSshResult(result);
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
        });
    };
    var _f = useForm({ mode: 'all' }), register = _f.register, handleSubmit = _f.handleSubmit, getValues = _f.getValues, _g = _f.formState, errors = _g.errors, isValid = _g.isValid, submitCount = _g.submitCount;
    var onSubmit = function (data) {
        // sendData(data)
        setModalVisible(true);
        checkSSHData(data);
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsxs("div", __assign({ className: "w-full" }, { children: [_jsx(HostnameField, { register: register, errors: errors }), _jsx(NameField, { register: register, errors: errors }), _jsx(LocationField, { register: register, errors: errors }), optionValue === 'Login & Password' ? (_jsxs(_Fragment, { children: [_jsx(LoginField, { register: register, errors: errors }), _jsx(PasswordField, { register: register, errors: errors })] })) : (_jsx(SshKeyField, { register: register, errors: errors }))] })), _jsx(Button, { label: translater("stationsPageAddButton").toString(), className: "w-full p-button-success", loading: loading, disabled: submitCount > 0 && !isValid }), _jsxs(Dialog, __assign({ visible: modalVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window max-w-25rem flex flex-column', style: { minWidth: '600px' }, onHide: function () { setModalVisible(false); setSshResult(''); }, footer: _jsx("div", __assign({ className: "flex align-items-center gap-1" }, { children: _jsx(Button, { type: "submit", className: "w-full p-button-success", disabled: submitCount > 0 && !isValid, loading: loading, onClick: function () { return sendData(getValues()); }, label: translater('modalExecCommandModalSaveStationBtn').toString() }) })) }, { children: [_jsx("b", { children: translater('modalExecCommandHeaderMessage') }), _jsxs("p", __assign({ style: { fontFamily: 'monospace' }, className: "text-sm" }, { children: [translater('modalExecCommandText'), getValues('hostname')] })), loading ? (_jsxs("div", __assign({ className: "flex flex-column gap-1" }, { children: [_jsx(Skeleton, {}), _jsx(Skeleton, {}), _jsx(Skeleton, {})] }))) : (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsx("b", { children: translater('modalExecCommandResultMessage') }), _jsx("pre", __assign({ style: {
                                    minWidth: '500px',
                                    overflow: 'auto',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    margin: 0
                                }, className: "text-sm" }, { children: sshResult })), _jsx(Link, __assign({ to: '/terminal', target: "_blank", className: "app-link-color no-underline" }, { children: translater('modalOpenTerminalMessage') }))] })))] }))] })));
};
