import axios from "axios";
import { errorNotification, infoNotification } from "../../redux/actions/notificationsActions";
import { logout } from "../../redux/actions/authActions";
import { translater } from "../localization/localization";
export var initInterceptor = function () {
    axios.interceptors.response.use(
    // При успешном response
    function (response) { return response; }, 
    // При response с ошибкой
    function (error) {
        var _a, _b, _c, _d;
        var status = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status;
        var message = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data.message;
        var forcedPath = (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.forcedPath;
        var invalidServer = translater("interceptorErrorNotificationServer").toString();
        var accessDenied = translater("interceptorErrorNotification").toString();
        var notFound = translater("interceptorErrorNotification404").toString();
        var cancelRequest = translater("interceptorCancelRequest").toString();
        var errorNetwork = 'Please check the network.';
        // При указании forcedPath
        if (forcedPath) {
            return window.location.replace(forcedPath);
        }
        // Обработка отмены запроса
        if (error.code && error.code === 'ERR_CANCELED')
            return infoNotification(cancelRequest);
        // Обработка отмены запроса
        if (error.code && error.code === 'ERR_NETWORK')
            return infoNotification(errorNetwork);
        // Доступ закрыт
        if (status && [403].includes(status)) {
            var msg = message || accessDenied;
            errorNotification(msg);
            return setTimeout(function () { return window.location.replace("/"); }, 1000);
        }
        // Выйти из системы если вышла сессия
        if (status && [401].includes(status)) {
            errorNotification(message || accessDenied);
            logout();
            return setTimeout(function () { return window.location.replace("/"); }, 0);
        }
        if (status && [400].includes(status))
            return errorNotification(message || notFound);
        // Страница не существует
        if (status && [404].includes(status))
            return errorNotification(message || notFound);
        if (status && [500].includes(status)) {
            return Promise.reject(error);
        }
        // Показываем ошибку
        errorNotification(message ? message : invalidServer);
        return Promise.reject(error);
    });
};
