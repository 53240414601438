var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Menu } from './components/menu/Menu';
import { useDocumentTitle } from '../../../utils/routes/routes';
import { setBreadCrumbs } from '../../../redux/actions/appActions';
export var Settings = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/settings' }]);
    }, []);
    return (_jsx("main", __assign({ className: 'settings-page flex flex-column gap-2 m-3' }, { children: _jsx(Menu, { breadcrumbs: [{ title: title, path: '/settings' }] }) })));
};
