var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { OverlayPanel } from "primereact/overlaypanel";
import { classNames } from "primereact/utils";
import { translater } from "../../../../../../utils/localization/localization";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import { GreenMessage } from "../../../../../components/particals/green-message/GreenMessage";
import { Calendar } from "primereact/calendar";
var defaultIntervals = [
    '1d',
    '3d',
    '1w',
    '2w',
    '1m'
];
export var DatabaseBackup = function (_a) {
    var settings = _a.settings, setSettings = _a.setSettings;
    var _b = __read(useState(defaultIntervals), 2), backUpintervals = _b[0], setBackUpInterval = _b[1];
    var _c = __read(useState(null), 2), selectedInterval = _c[0], setSelectedInterval = _c[1];
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var intervalRef = useRef(null);
    var pathRef = useRef(null);
    var _e = __read(useState('/opt/backup'), 2), path = _e[0], setPath = _e[1];
    var _f = __read(useState(new Date(new Date().setMinutes(45))), 2), date = _f[0], setDate = _f[1];
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if ((_a = settings.databaseBackup) === null || _a === void 0 ? void 0 : _a.interval) {
            setSelectedInterval((_b = settings.databaseBackup) === null || _b === void 0 ? void 0 : _b.interval);
        }
        if ((_c = settings.databaseBackup) === null || _c === void 0 ? void 0 : _c.path) {
            setPath((_d = settings.databaseBackup) === null || _d === void 0 ? void 0 : _d.path);
        }
        if ((_e = settings.databaseBackup) === null || _e === void 0 ? void 0 : _e.path) {
            setDate(prepareTime());
        }
    }, [settings.databaseBackup]);
    function prepareTime() {
        if (settings.databaseBackup && settings.databaseBackup.time) {
            var _a = __read(settings.databaseBackup.time.split(':'), 2), hours = _a[0], minutes = _a[1];
            var _date = new Date();
            _date.setMinutes(Number(minutes));
            _date.setHours(Number(hours));
            return _date;
        }
        return new Date(new Date().setMinutes(45));
    }
    var addBackup = function () {
        if (!selectedInterval)
            return false;
        setLoading(true);
        var currentDate = new Date(date);
        var time = "".concat(currentDate.getHours(), ":").concat(currentDate.getMinutes());
        API.resaveBackupProcess(selectedInterval, path, time)
            .then(function (response) {
            if (response) {
                var message = response.data.message;
                successNotification(message);
                setSettings(function (settings) { return (__assign(__assign({}, settings), { databaseBackup: {
                        path: path,
                        crone: '',
                        time: time,
                        interval: selectedInterval
                    } })); });
            }
        })
            .catch(function (err) { setLoading(false); })
            .finally(function () { setLoading(false); });
    };
    var isValidFolderPath = function (path) {
        return Boolean(/^(\/|(\.\/|\.\.\/)*)([a-zA-Z0-9_.-]+(\/)?)+$/.test(path.trim()));
    };
    return (_jsxs("div", __assign({ className: "relative w-full card flex flex-column align-items-start gap-2 surface-card shadow-2 border-round p-3" }, { children: [!settings.encryptionKeyExistence ? (_jsxs("div", __assign({ className: "backup-warning-modal flex-column gap-2" }, { children: [_jsx("span", { children: translater('settingsPageBackupNoEncryptionKeyModalLabel') }), _jsx("i", { className: "pi pi-arrow-down" })] }))) : '', _jsxs("div", __assign({ className: "flex align-items-center gap-3" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: translater('settingsPageBackup') })), settings.databaseBackup ? _jsx(GreenMessage, { message: "settingsPageBackupGreenMessage" }) : ''] })), _jsx(Divider, { className: "my-1" }), _jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = intervalRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("settingsPageBackupIntervalLabel")] }), _jsx(OverlayPanel, __assign({ ref: intervalRef }, { children: translater("settingsPageBackupIntervalLabelHint") })), _jsx("div", __assign({ className: "mb-2 flex align-items-center gap-2" }, { children: backUpintervals.map(function (interval) {
                    return (_jsx("div", __assign({ style: { borderColor: '#ced4da' }, onClick: function () { return setSelectedInterval(interval); }, className: classNames({
                            'cursor-default border-green-600 text-green-600': interval === selectedInterval
                        }, "border-1 p-2 select-none cursor-pointer border-round-sm") }, { children: translater('settingsPageBackupInterval' + interval) })));
                }) })), _jsx("label", { children: translater("settingsPageBackupTimeLabel") }), _jsx("div", __assign({ className: "mb-2 flex align-items-center gap-2" }, { children: _jsx(Calendar, { value: date, onChange: function (e) { return setDate(e.value); }, showIcon: true, timeOnly: true, icon: function () { return _jsx("i", { className: "pi pi-clock" }); }, className: "back-up-time", stepMinute: 15 }) })), _jsxs("label", { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = pathRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("settingsPageBackupPathLabel")] }), _jsx(OverlayPanel, __assign({ ref: pathRef }, { children: translater("settingsPageBackupPathLabelHint") })), _jsx("div", __assign({ className: "mb-2 flex flex-column align-items-center gap-1" }, { children: _jsx(InputText, { id: "path", disabled: true, value: path, type: "text", onChange: function (e) { return setPath(e.target.value); }, placeholder: translater("settingsPageBackupPathPlaceholder").toString(), className: "w-full w-23rem" }) })), !settings.databaseBackup ? (_jsx(Button, { loading: loading, disabled: isValidFolderPath(path) === false || selectedInterval === null, label: translater("settingsPageBackupCreateButton").toString(), onClick: function () { return addBackup(); }, className: "p-button p-component p-button-outlined text-green-600 border-green-600 w-23rem" })) : (_jsx(Button, { loading: loading, label: translater("settingsPageBackupUpdateButton").toString(), onClick: function () { return addBackup(); }, className: "p-button p-component p-button-outlined text-green-600 border-green-600w-23rem" }))] })));
};
