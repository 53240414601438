var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { translater } from "../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
export var ControllersField = function (_a) {
    var onKeyDownHandler = _a.onKeyDownHandler, onClickHandler = _a.onClickHandler, controllers = _a.controllers, register = _a.register, errors = _a.errors;
    var ref = useRef(null);
    var validations = {
        validate: function (value) {
            if (value && controllers.find(function (c) { return c.toLocaleLowerCase() === value.trim().toLocaleLowerCase(); })) {
                return (translater("groupTabPageValidateControllerNameControllersField").toString());
            }
            if (!controllers.length) {
                return (translater("groupTabPageValidateControllersField").toString());
            }
            if ((!value) && controllers.length > 0)
                return true;
            return true;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("label", __assign({ htmlFor: "controller", className: "block text-900 font-medium mb-2" }, { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("groupTabPageControllersField")] })), _jsx("div", __assign({ className: "field mb-3 col-12 p-0" }, { children: _jsxs("div", __assign({ className: "p-inputgroup relative" }, { children: [_jsx("span", __assign({ className: "p-inputgroup-addon" }, { children: "ldap://" })), _jsx(InputText, __assign({}, register('controller', validations), { id: "controller", onKeyDown: function (event) { return onKeyDownHandler(event); }, type: "text", name: "controller", placeholder: translater("groupTabPagePlaceholderControllersField").toString(), className: "w-full", style: { borderTopRightRadius: "6px", borderBottomRightRadius: "6px" } })), _jsx("i", { className: "pi pi-reply absolute cursor-pointer z-2", onClick: function () { return onClickHandler(); }, style: { transform: "rotate(180deg)", right: "6px", bottom: "6px", padding: "7px", border: "1px solid #ccc", backgroundColor: "#fff", borderRadius: "6px" } })] })) })), _jsx(ErrorBlock, { errors: errors, keyword: "controller" }), _jsx(OverlayPanel, __assign({ ref: ref }, { children: translater("groupTabPageControllersFieldOverlayPanel") }))] }));
};
