var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Ripple } from "primereact/ripple";
export var InformationTabPanel = function () {
    var user = useSelector(function (state) { return state.auth; }).user;
    var username = user ? user.username : 'null';
    var login = user ? user.login : 'null';
    return (_jsx(_Fragment, { children: _jsx("ul", __assign({ className: "m-0 p-0 list-none" }, { children: _jsx("li", { children: _jsxs("a", __assign({ className: "p-ripple flex p-3 align-items-center font-medium border-round\n              transition-duration-150 transition-colors w-full" }, { children: [_jsx("span", __assign({ className: "flex align-items-center justify-content-center mr-3 border-circle border-indigo-500 bg-indigo-500 text-white text-2xl", style: { width: '40px', height: '40px' } }, { children: username.slice(0, 1).toUpperCase() })), _jsxs("div", __assign({ className: "flex flex-column justify-content-center gap-1" }, { children: [_jsx("div", __assign({ className: "text-800 font-medium" }, { children: _jsx("span", __assign({ className: "ml-1" }, { children: username })) })), _jsx("div", __assign({ className: "text-800 font-medium" }, { children: _jsx("span", __assign({ className: "text-700 font-normal ml-1" }, { children: user === null || user === void 0 ? void 0 : user.roles.map(function (role) {
                                            return _jsx("span", { children: role.title }, role + '-role');
                                        }) })) }))] })), _jsx(Ripple, {})] })) }) })) }));
};
