import axios from 'axios';
export var setAuthToken = function (token) {
    if (token) {
        // Применить токен авторизации к каждому запросу, если авторизован
        axios.defaults.headers.common['Authorization'] = "Bearer ".concat(token);
    }
    else {
        // Удаляем header
        delete axios.defaults.headers.common['Authorization'];
    }
};
