import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from "primereact/tag";
var typeBodyTemplate = function (_a) {
    var status = _a.status;
    var severity = status.includes('Asterisk') ? 'success' : 'danger';
    return _jsx(Tag, { className: "text-sm", value: status, severity: severity });
};
export var statusColumn = {
    columnHeader: 'stationsPageStatusColumn',
    field: 'status',
    body: typeBodyTemplate
};
