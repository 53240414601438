var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { CertificateField } from "./fields/CertificateField";
import { SecretKeyField } from "./fields/SecretKeyField";
import { CertificateInformationModal } from "./CertificateInformationModal";
import { API } from "../../../../../../api";
import { DialogWindow } from "../../DialogWindow";
import { translater } from "../../../../../../utils/localization/localization";
import { useForm } from "react-hook-form";
var defaultCertificate = {
    subject: '',
    subjectAltName: '',
    issuer: '',
    validFrom: '',
    validTo: '',
};
export var Form = function (_a) {
    var reloadTabs = _a.reloadTabs;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), hintVisible = _c[0], setHintVisible = _c[1];
    var _d = __read(useState(false), 2), modalVisible = _d[0], setModalVisible = _d[1];
    var _e = __read(useState(defaultCertificate), 2), certificateInformation = _e[0], setCertificateInformation = _e[1];
    var checkCertificate = function (_a) {
        var certificate = _a.certificate;
        setLoading(true);
        API.getMeta({ certificate: certificate })
            .then(function (response) {
            var data = response.data;
            setCertificateInformation(data);
            setModalVisible(true);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var _f = useForm({ mode: 'all' }), register = _f.register, setValue = _f.setValue, getValues = _f.getValues, handleSubmit = _f.handleSubmit, trigger = _f.trigger, _g = _f.formState, errors = _g.errors, isValid = _g.isValid, submitCount = _g.submitCount;
    var onSubmit = function (data) {
        checkCertificate(data);
    };
    var setCertificate = function (content) {
        setValue('certificate', content.trim().replace(/^\s+|\s+$/g, ''));
        trigger('certificate');
    };
    var setPrivateKey = function (content) {
        setValue('key', content.trim().replace(/^\s+|\s+$/g, ''));
        trigger('key');
    };
    var toggleHint = function (event) {
        if (event.key === 'Enter')
            return false;
        setHintVisible(true);
    };
    return (_jsx("div", __assign({ className: "relative" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsxs("div", __assign({ className: "surface-card p-4 shadow-2 border-round w-full" }, { children: [_jsx(CertificateField, { errors: errors, register: register, setCertificate: setCertificate }), _jsx(SecretKeyField, { errors: errors, register: register, setPrivateKey: setPrivateKey })] })), _jsx(CertificateInformationModal, { reloadTabs: reloadTabs, modalVisible: modalVisible, getValues: getValues, setModalVisible: setModalVisible, information: certificateInformation }), _jsxs("div", __assign({ className: "flex justify-content-end gap-4 my-2" }, { children: [_jsx(DialogWindow, __assign({ hintVisible: hintVisible, setHintVisible: setHintVisible }, { children: translater("certificateTabPageDialogWindowContent") })), _jsxs("div", __assign({ className: "flex justify-content-end gap-2 mt-4" }, { children: [_jsx(Button, { label: translater("groupTabPageTestLaterButton").toString(), icon: "pi pi-times", type: "button", onClick: toggleHint, className: "p-button-outlined" }), _jsx(Button, { disabled: submitCount > 0 && !isValid, label: translater("certificateTabPageCheckButton").toString(), icon: "pi pi-check", className: "p-button-success", loading: loading })] }))] }))] })) })));
};
