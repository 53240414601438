var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import io from 'socket.io-client';
import { securityPermission } from "../../../../../../redux/actions/authActions";
import { TooltipRevealer } from "../../../../../components/particals/tooltip/TooltipRevealer";
import { translater } from "../../../../../../utils/localization/localization";
import { API, getWebsiteUrl } from "../../../../../../api";
export var UserInfo = function () {
    var navigate = useNavigate();
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState(false), 2), missingCallsExistence = _b[0], setMissingCallsExistence = _b[1];
    var user = useSelector(function (state) { return state; }).auth.user;
    var username = user ? (user.username || '') : '';
    useEffect(function () {
        if (!securityPermission()) {
            setLoading(true);
            API.getPhones()
                .then(function (response) {
                var phones = response.data.phones;
                API.checkMissingCallsExistence(phones)
                    .then(function (response) {
                    var existence = response.data.existence;
                    setMissingCallsExistence(existence);
                })
                    .catch(function () { })
                    .finally(function () { return setLoading(false); });
            })
                .catch(function () { return setLoading(false); });
        }
    }, [user === null || user === void 0 ? void 0 : user.roles]);
    useEffect(function () {
        var domain = getWebsiteUrl().domain;
        var socket = io("ws://".concat(domain));
        socket.on('connect', function () {
            socket.emit('authenticate', { token: localStorage.getItem('token') });
        });
        socket.on('authenticate', function (data) {
            if (data.success) {
                socket.emit('update-missing-calls');
            }
            else {
                console.error('Authentication failed:', data.error);
            }
        });
        // Обработка ошибки в случае Invalid Authentication
        socket.on('auth_error', function (data) {
            console.error('Authentication error:', data.error);
        });
        socket.on('update-missing-calls', function (data) {
            try {
                var existence = data.existence;
                setMissingCallsExistence(existence);
            }
            catch (error) {
                console.error('Error processing missing calls data:', error);
            }
        });
        socket.on('disconnect', function () {
            console.log('Connection closed!');
        });
        return function () {
            console.log('Cleaning up on component unmount');
            socket.close();
        };
    }, []);
    var showMissingCalls = function () {
        navigate({ pathname: '/calls', search: "?type=all-missing" });
    };
    return (_jsxs("div", __assign({ className: "col-12 md:col-4 lg:col-3 xl:col-3 flex flex-column justify-content-center gap-2" }, { children: [_jsx("span", __assign({ className: "flex align-items-center justify-content-center border-circle border-indigo-500 bg-indigo-500 text-white text-4xl", style: { width: '75px', height: '75px', alignSelf: 'center' } }, { children: username.slice(0, 1).toUpperCase() })), _jsx("span", __assign({ className: "header-username text-xl text-700 font-medium" }, { children: username })), securityPermission() ? '' : (_jsxs("div", __assign({ className: "text-500 mb-4 flex justify-content-center gap-4" }, { children: [loading ? _jsx(Skeleton, { size: "1rem" }) : missingCallsExistence ? (_jsx(TooltipRevealer, __assign({ hint: translater("homePageUserInfoTooltipRevealer").toString() }, { children: _jsxs("div", __assign({ onClick: showMissingCalls, className: 'relative cursor-pointer' }, { children: [_jsx("i", { className: 'pi pi-phone' }), _jsx("i", { className: 'missing-calls-red-circle absolute' })] })) }))) : (_jsxs("div", __assign({ className: 'relative' }, { children: [_jsx("i", { className: 'pi pi-phone' }), _jsx("i", { className: 'disactive absolute' })] }))), _jsx("i", { className: 'pi pi-envelope' }), _jsx("i", { className: 'pi pi-print' })] })))] })));
};
