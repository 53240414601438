var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
export var DrapAndDropConfirm = function (_a) {
    var modalSettings = _a.modalSettings, clear = _a.clear;
    return (_jsxs(Dialog, __assign({ header: modalSettings.header, visible: modalSettings.visible, resizable: false, onHide: function () { return clear(); }, style: { width: '65vw' }, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: [modalSettings.description
                && _jsx(Message, { className: "w-full mb-3", severity: "warn", text: modalSettings.description }), _jsx("div", __assign({ className: "flex justify-content-center gap-2" }, { children: modalSettings.branches
                    .filter(function (branch) {
                    var branches = modalSettings.selectedBranches;
                    // Отфильтровываем уже выбранные элементы
                    return !branches.includes(branch);
                })
                    .map(function (branch) {
                    return _jsx(Button, { onClick: function () { return modalSettings.callback(branch); }, className: "p-button-outlined w-full", label: branch }, branch);
                }) }))] })));
};
