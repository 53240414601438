var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";
import { translater } from "../../../../../../../utils/localization/localization";
import { API } from "../../../../../../../api";
import { changeEditorFileContent, changeEditorFilePrevContent } from "../../../../../../../redux/actions/editorAction";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
export var EditorFileReloadButton = function (_a) {
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = useSelector(function (state) { return state.editor; }), currentFileName = _c.currentFileName, currentFilesFolderPath = _c.currentFilesFolderPath;
    var reloadFileContent = function () {
        setLoading(true);
        var folderPath = "".concat(currentFilesFolderPath, "/").concat(currentFileName);
        API.getConfigurationFile(folderPath)
            .then(function (response) {
            var content = response.data;
            if (currentFileName) {
                changeEditorFilePrevContent(currentFileName, content);
                changeEditorFileContent(currentFileName, content);
            }
            setLoading(false);
            successNotification(translater('editorFileReloadSuccessfullyNotification').toString());
        })
            .finally(function () { return setLoading(false); });
    };
    var confirm = function () {
        confirmDialog({
            message: translater("editorFileReloadConfirmMessage"),
            header: translater("editorFileReloadConfirmHeader"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return reloadFileContent(); },
            reject: function () { }
        });
    };
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsx(Button, { "data-pr-tooltip": translater("editorFileReloadButton"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-refresh", disabled: !currentFileName || loading, className: "custom-target-icon-file-reload p-button-outlined p-button-secondary", onClick: function () { return confirm(); } }), _jsx(Tooltip, { target: ".custom-target-icon-file-reload" })] })));
};
