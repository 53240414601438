import { nameColumn } from "./components/name";
import { locationColumn } from "./components/location";
import { hostnameColumn } from "./components/hostname";
import { statusColumn } from "./components/status";
export var dynamicColumns = [
    nameColumn,
    locationColumn,
    hostnameColumn,
    statusColumn
];
