var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { MultiSelect } from 'primereact/multiselect';
import { useState } from "react";
import { API } from "../../../../../../../../../api";
import { translater } from "../../../../../../../../../utils/localization/localization";
export var UsersField = function (_a) {
    var control = _a.form.control;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState([]), 2), users = _c[0], setUsers = _c[1];
    var _d = __read(useState(undefined), 2), timeout = _d[0], setTimeOut = _d[1];
    var field = useController({ control: control, name: "users" }).field;
    var usersFinder = function (query) {
        setLoading(true);
        API.usersFinder(query, { first: 0 })
            .then(function (response) {
            var users = response.data.users;
            setUsers(users);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var searchUsers = function (event) {
        clearTimeout(timeout);
        var newTimeout = setTimeout(function () { return usersFinder(event.filter); }, 600);
        setTimeOut(newTimeout);
    };
    var onChangeHandler = function (users) { return field.onChange(users); };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "users", className: "block text-900 font-medium mb-2" }, { children: translater("groupsPageUsersField") })), _jsx(MultiSelect, { onShow: function () { usersFinder(''); }, onChange: function (e) { return onChangeHandler(e.value); }, value: field.value, options: users, optionLabel: "login", placeholder: translater("groupsPageUsersFieldPlaceholder").toString(), filter: true, onFilter: searchUsers, className: "w-full mb-3" })] }));
};
