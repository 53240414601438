import { jsx as _jsx } from "react/jsx-runtime";
import { Calendar } from 'primereact/calendar';
import { translater } from '../../../../../../../../utils/localization/localization';
var formatDate = function (value) {
    var date = new Date(value);
    return date.toLocaleDateString();
};
var dateBodyTemplate = function (_a) {
    var date = _a.date;
    return formatDate(date);
};
// const formatTime = (date: Date): string => {
//   const options: Intl.DateTimeFormatOptions = {
//     hour: '2-digit', minute: '2-digit', second: '2-digit'
//   }
//   return date.toLocaleTimeString('ru', options)
// }
// const prepareDate = (date: Date): string => {
//   const { language: { lang } }: RootState = store.getState()
//   const options: Intl.DateTimeFormatOptions = {
//     year: 'numeric',
//     month: 'short',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: false // 24-часовой формат
//   }
//   return date.toLocaleDateString(lang, options)
// }
// const prepareInterval = (interval: any) => {
//   if (interval === null) return 'Выберете дату начала.'
//   if (interval[1] === null) return `Выберете дату конца`
//   return `${prepareDate(interval[0])} – ${prepareDate(interval[1])}`
// }
var dateFilterTemplate = function (options) {
    var today = new Date();
    return (_jsx("div", { children: _jsx(Calendar, { value: options.value || null, readOnlyInput: true, maxDate: today, onChange: function (e) {
                options.filterCallback(e.value, options.index); // Передаём как есть
            }, selectionMode: "range", dateFormat: "dd-mm-yy", showTime: true, 
            // mask="99/99/9999"
            className: "w-16rem", placeholder: translater("callsPageDateColumnCalendarPlaceholder").toString() }) }));
};
export var dateColumn = {
    columnHeader: 'callsDate',
    field: 'date',
    filterField: 'date',
    showFilterMatchModes: false,
    // filter: true,
    body: dateBodyTemplate,
    filterElement: dateFilterTemplate,
};
