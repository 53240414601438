var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { changeTheme } from "../actions/themeActions";
var initialState = {
    isDarkMode: false
};
export var SET_DARK_MODE = 'SET_DARK_MODE';
// Устанавливаем тему по умолчанию
var theme = localStorage.getItem('asteraid/dark-mode');
if (theme) {
    initialState.isDarkMode = theme === 'true';
}
else
    localStorage.setItem('asteraid/dark-mode', String(initialState.isDarkMode) === 'true' ? 'true' : 'false');
// Меняем цвет в link
changeTheme(initialState.isDarkMode);
export var themesReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_DARK_MODE:
            return __assign(__assign({}, state), { isDarkMode: action.payload });
        default:
            return state;
    }
};
