var template = function (_a) {
    var comment = _a.comment;
    var strLength = 150;
    return comment.length > strLength
        ? comment.slice(0, strLength) + '...'
        : comment;
};
export var commentColumn = {
    columnHeader: 'contextPageCommentColumn',
    field: 'comment',
    style: {
        textAlign: "justify"
    },
    body: template,
};
