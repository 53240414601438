var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import { Tooltip } from 'primereact/tooltip';
import { StyleClass } from "primereact/styleclass";
import { images } from "../../../../../assets/images";
import { translater } from "../../../../../utils/localization/localization";
import { adminPermission } from "../../../../../redux/actions/authActions";
import { CustomImage } from "../../custom-image/CustomImage";
import { changeTabs } from "./FullSideBar";
export var MiniSideBar = function (_a) {
    var setFullSideBarFlag = _a.setFullSideBarFlag, links = _a.links, systemSettingsLinks = _a.systemSettingsLinks, pbxSettingsLinks = _a.pbxSettingsLinks, securitySettingsLinks = _a.securitySettingsLinks, userLinks = _a.userLinks;
    var _b = useSelector(function (state) { return state; }), isConnected = _b.support.isConnected, _c = _b.app.sidebarTabs, isSettingsOpen = _c.isSettingsOpen, isSystemSettingsOpen = _c.isSystemSettingsOpen, isPbxSettingsOpen = _c.isPbxSettingsOpen, isUserSettingsOpen = _c.isUserSettingsOpen, isSecuritySettingsOpen = _c.isSecuritySettingsOpen;
    var btnRef1 = useRef(null);
    var btnRef2 = useRef(null);
    var btnRef3 = useRef(null);
    return (_jsx("div", __assign({ className: "min-h-screen flex relative lg:static surface-ground" }, { children: _jsx("div", __assign({ id: "app-sidebar-4", className: "surface-section h-full lg:h-auto hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none bg-white" }, { children: _jsxs("div", __assign({ className: "flex flex-column h-full" }, { children: [_jsx("div", __assign({ className: "flex align-items-center px-3 flex-shrink-0 justify-content-center flex-column", style: { height: "60px" } }, { children: _jsx(Link, __assign({ to: "/" }, { children: _jsx("img", { src: images.common.logoMini, alt: "logo-mini", className: "mt-2 w-3rem" }) })) })), _jsxs("div", __assign({ className: "flex flex-column align-items-center justify-content-center mt-3 select-none" }, { children: [_jsxs("span", __assign({ "data-pr-tooltip": translater('sideBarHintExpand'), "data-pr-position": "right", "data-pr-my": "left+8 center-2", className: "cursor-pointer custom-target-icon-show-toggle", onClick: function () { return setFullSideBarFlag(true); } }, { children: [_jsx(CustomImage, { src: images.common.sidebarIcon, width: "20px" }), _jsx(Tooltip, { target: ".custom-target-icon-show-toggle" })] })), _jsxs("ul", __assign({ className: "list-none p-3 lg:py-3 lg:px-2 m-0 flex flex-column gap-1" }, { children: [links.map(function (link) {
                                        return (_jsx("li", { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                    ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }, link.order));
                                    }), _jsxs("li", { children: [(userLinks.length > 0 || (systemSettingsLinks.length > 0 && adminPermission()))
                                                ? _jsx(_Fragment, { children: _jsx(StyleClass, __assign({ nodeRef: btnRef1, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isSettingsOpen'); }, ref: btnRef1, className: "settings-side-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1", "data-pr-tooltip": translater("settingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-cog", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".settings-side-bar" })] })) })) })
                                                : '', adminPermission() ?
                                                _jsxs(_Fragment, { children: [_jsxs("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: [_jsxs("li", { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isPbxSettingsOpen'); }, ref: btnRef2, className: "admin-settings-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1 mb-1", "data-pr-tooltip": translater("pbxSettingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-unlock", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".admin-settings-bar" })] })) })), _jsx("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isPbxSettingsOpen }) }, { children: pbxSettingsLinks.map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                                            ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] }), _jsxs("li", { children: [userLinks.length > 0
                                                                            ? _jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ ref: btnRef3, onClick: function () { return changeTabs('isUserSettingsOpen'); }, className: "user-settings-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1", "data-pr-tooltip": translater("userSettingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-user", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".user-settings-bar" })] })) }))
                                                                            : '', _jsx("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isUserSettingsOpen }) }, { children: userLinks.map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                                            ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] })] })), _jsxs("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: [_jsxs("li", { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isSystemSettingsOpen'); }, ref: btnRef2, className: "admin-settings-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1 mb-1", "data-pr-tooltip": translater("systemSettingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-unlock", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".admin-settings-bar" })] })) })), _jsx("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSystemSettingsOpen }) }, { children: systemSettingsLinks.map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: classNames('custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                                            ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full', {
                                                                                            'red-connection-globe red-connection-globe-mini': link.path === '/interprise-support' && isConnected
                                                                                        }) }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] }), _jsxs("li", { children: [userLinks.length > 0
                                                                            ? _jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ ref: btnRef3, onClick: function () { return changeTabs('isUserSettingsOpen'); }, className: "user-settings-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1", "data-pr-tooltip": translater("userSettingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-user", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".user-settings-bar" })] })) }))
                                                                            : '', _jsx("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isUserSettingsOpen }) }, { children: userLinks.map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                                            ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] })] })), _jsxs("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: [_jsxs("li", { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isSecuritySettingsOpen'); }, ref: btnRef2, className: "admin-settings-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1 mb-1", "data-pr-tooltip": translater("securitySettingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-unlock", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".admin-settings-bar" })] })) })), _jsx("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSecuritySettingsOpen }) }, { children: securitySettingsLinks.map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                                            ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] }), _jsxs("li", { children: [userLinks.length > 0
                                                                            ? _jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ ref: btnRef3, onClick: function () { return changeTabs('isUserSettingsOpen'); }, className: "user-settings-bar p-ripple flex flex-column align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1", "data-pr-tooltip": translater("userSettingsSideBar"), "data-pr-position": "right", "data-pr-my": "left+8 center-2" }, { children: [_jsx("i", { className: "pi pi-user", style: { width: '16px', height: '16px' } }), _jsx("i", { className: "pi pi-chevron-down mt-1", style: { fontSize: '7px' } }), _jsx(Ripple, {}), _jsx(Tooltip, { target: ".user-settings-bar" })] })) }))
                                                                            : '', _jsx("ul", __assign({ className: clsx("list-none p-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isUserSettingsOpen }) }, { children: userLinks.map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                                            ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] })] }))] })
                                                :
                                                    _jsx("ul", __assign({ className: "list-none p-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out" }, { children: userLinks.map(function (link) {
                                                            return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ "data-pr-tooltip": translater(link.name), "data-pr-position": "right", "data-pr-my": "left+8 center-2", to: link.path, className: 'custom-target-sidebar-icon' + link.order + '-' + link.name +
                                                                        ' mini-nav no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer justify-content-center p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full' }, { children: [_jsx("i", { className: link.classes + ' mr-0' }), _jsx(Tooltip, { target: '.custom-target-sidebar-icon' + link.order + '-' + link.name }), _jsx(Ripple, {})] })) }), link.order));
                                                        }) }))] })] }))] }))] })) })) })));
};
