var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dynamicColumns } from '../../../../users/components/list/components/columns/columns';
import { API } from '../../../../../../../../api';
import { SimplePlayer } from '../../../../audio/components/player/SimplePlayer';
import { translater } from '../../../../../../../../utils/localization/localization';
import { transfortDuration } from '../../../../audio/components/list/components/columns/components/duration';
import { defaultAWSAudioLazyState } from '../../../../audio/components/list/AudioList';
var defaultUsersLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var ViewModal = function (_a) {
    var hide = _a.hide, setFinder = _a.setFinder, refresh = _a.refresh, viewGroup = _a.viewGroup;
    var _b = __read(useState(viewGroup), 2), group = _b[0], setGroup = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    // Users 
    var _d = __read(useState(viewGroup.users), 2), users = _d[0], setUsers = _d[1];
    var _e = __read(useState([]), 2), filteredUsers = _e[0], setFilteredUsers = _e[1];
    var _f = __read(useState({}), 2), expandedUsersGroups = _f[0], setExpandedUsersGroups = _f[1];
    var _g = __read(useState(''), 2), userFinder = _g[0], setUserFinder = _g[1];
    var _h = __read(useState(viewGroup.users.map(function (user) { return String(user._id); })), 2), selectedUsers = _h[0], setSelectedUsers = _h[1];
    var _j = __read(useState(50), 2), usersLimit = _j[0], setUsersLimit = _j[1];
    var _k = __read(useState(0), 2), usersTableItemsAmount = _k[0], setUsersTableItemsAmount = _k[1];
    var _l = __read(useState(defaultUsersLazyState), 2), lazyState = _l[0], setLazyState = _l[1];
    var _m = __read(useState(''), 2), globalUsersFinderValue = _m[0], setGlobalUsersFinderValue = _m[1];
    var toggleUsersGroups = function (event, userName) {
        event.preventDefault();
        event.stopPropagation();
        setExpandedUsersGroups(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[userName] = !prevState[userName], _a)));
        });
    };
    var onPage = function (event) { setLazyState(event); };
    var onFilter = function (event) {
        event['first'] = 0;
        setLazyState(event);
    };
    var handleUserSearch = function (value) {
        setGlobalUsersFinderValue(value);
    };
    // const groupsTemplate = (user: AWSUser) => {
    //   const showAllGroups = expandedUsersGroups[user.name]
    //   return user.groups?.length ? (
    //     <div className="w-full flex align-items-center justify-content-center gap-2 flex-wrap">
    //       {showAllGroups
    //         ? user.groups.map((group, index) => (
    //           <span key={group.name + index} className="border-1 p-1 border-round-sm border-300 text-600 text-sm">
    //             {group.name}
    //           </span>
    //         ))
    //         : user.groups.slice(0, 3).map((group, index) => (
    //           <span key={group.name + index} className="border-1 p-1 border-round-sm border-300 text-600 text-sm">
    //             {group.name}
    //           </span>
    //         ))}
    //       {user.groups.length > 3 && (
    //         <span
    //           className="p-1 border-round-sm border-300 text-600 text-sm border-1"
    //           onClick={(e) => toggleUsersGroups(e, user.name)}
    //         >
    //           {showAllGroups ?
    //             <i className="pi pi-times text-600 text-sm"></i>
    //             : '...'}
    //         </span>
    //       )}
    //     </div>
    //   ) : (
    //     ""
    //   )
    // }
    var changeFiltetedUser = function () {
        var filtered = users.filter(function (user) {
            if (user.name.toLowerCase().includes(globalUsersFinderValue.toLowerCase()))
                return true;
            if (user.phone.toLowerCase().includes(globalUsersFinderValue.toLowerCase()))
                return true;
            return false;
        });
        setFilteredUsers(filtered);
    };
    var columns = dynamicColumns
        .filter(function (column) {
        return column.field !== 'groups';
    })
        .map(function (column) {
        var header = translater(column.columnHeader);
        // if (column.field === 'groups') {
        //   return <Column
        //     body={groupsTemplate}
        //     className="justify-content-center px-5"
        //     header={header}
        //     key={column.field}
        //     style={column.style || { minWidth: "100px" }}
        //     {...column} />
        // }
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    var setCurrentAudio = function () { return __awaiter(void 0, void 0, void 0, function () {
        var audios, audio_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.finderAWSAudio('', defaultAWSAudioLazyState)];
                case 1:
                    audios = _a.sent();
                    audio_1 = audios.data.audios.find(function (audio) { return viewGroup.audio._id === audio._id; });
                    setGroup(function (prev) { return (__assign(__assign({}, prev), { audio: audio_1 })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setGroup(function (prev) { return (__assign(__assign({}, prev), { audio: null })); });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var timeOut = setTimeout(function () { return changeFiltetedUser(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalUsersFinderValue, lazyState]);
    useEffect(function () {
        setCurrentAudio();
    }, []);
    return (_jsx(Dialog, __assign({ visible: true, header: translater("awsGroupsItemDialogHeader"), style: { width: '50vw', height: '100%' }, onHide: function () { return hide(); } }, { children: _jsxs("div", __assign({ className: 'flex flex-column gap-3' }, { children: [_jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsx("label", { children: translater("awsGroupsItemNameField") }), _jsx(InputText, { disabled: true, value: group.name, placeholder: translater("awsGroupsItemNameFieldPlaceholder").toString() })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsx("label", { children: translater("awsGroupsItemSystemNameField") }), _jsx(InputText, { disabled: true, value: group.globalName, placeholder: translater("awsGroupsItemSystemNameFieldPlaceholder").toString() })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-1' }, { children: [_jsx("label", { children: translater("awsGroupsItemViewModalInitiatorsField") }), _jsx(Chips, { className: 'w-full', readOnly: true, value: group.initiators })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", { children: translater("awsGroupsItemUsersField") }), _jsx("div", __assign({ className: "flex align-items-center gap-2" }, { children: users.filter(function (user, index) {
                                return selectedUsers.includes(String(user._id));
                            }).length === 0 ? (_jsx("span", __assign({ className: 'p-2 border-1 border-300 border-round-sm w-full' }, { children: translater("awsGroupsItemUsersFieldNoUserSelected") }))) : (_jsx(InputText, { value: globalUsersFinderValue, className: "w-full", placeholder: translater("awsGroupsItemSearchByUsers").toString(), onChange: function (e) { return handleUserSearch(e.target.value); } })) })), _jsx(DataTable, __assign({ lazy: true, paginator: true, value: filteredUsers, loading: loading, rows: usersLimit, first: lazyState.first, totalRecords: usersTableItemsAmount, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, responsiveLayout: "scroll", scrollable: true, className: "border-1 border-300 border-round-md overflow-hidden", dataKey: "_id" }, { children: columns }))] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", { children: translater("awsGroupsItemCreateModalContextField") }), _jsx(InputText, { disabled: true, value: group.context ? group.context.name : 'null', className: "w-full", placeholder: 'null' })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", { children: translater("awsGroupsItemAudioField") }), _jsxs("span", __assign({ className: 'flex align-item-center justify-content-between gap-2 p-2 border-1 border-300 border-round-sm w-full' }, { children: [translater("awsGroupsItemAudioFieldAudioUploaded"), _jsxs("div", __assign({ className: 'flex align-items-center gap-2' }, { children: [_jsxs("span", __assign({ className: "text-600 text-sm" }, { children: [transfortDuration(group.audio.duration), translater('awsAudioListSeconds')] })), _jsx(SimplePlayer, { audioId: String(group.audio._id) })] }))] }))] }))] })) })));
};
