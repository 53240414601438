var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Editor } from '@monaco-editor/react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { API } from '../../../../../../api';
import { translater } from '../../../../../../utils/localization/localization';
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { registerCustomEditorSyntax } from '../../../../../../redux/actions/editorAction';
import { registerIniCustomSyntax } from '../../../../../../utils/editor/syntax';
import { FontSizeArea } from '../../../../../components/particals/editor/components/FontSizeArea';
export var CreateFile = function (_a) {
    var dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible, setSelectedFile = _a.setSelectedFile, setFiles = _a.setFiles, folders = _a.folders, selectedFolder = _a.selectedFolder;
    var _b = useSelector(function (state) { return state; }), isDarkMode = _b.theme.isDarkMode, _c = _b.editor, isSyntaxRegistered = _c.isSyntaxRegistered, fontSize = _c.fontSize, programmingLanguage = _c.programmingLanguage;
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(useState(''), 2), name = _e[0], setName = _e[1];
    var _f = __read(useState(''), 2), content = _f[0], setContent = _f[1];
    var _g = __read(useState(selectedFolder), 2), folder = _g[0], setFolder = _g[1];
    var createFile = function () {
        setLoading(true);
        API.createFile({ name: name, content: content, parent: folder })
            .then(function (response) {
            var _a = response.data, message = _a.message, id = _a.id;
            successNotification(message);
            setLoading(false);
            setName('');
            setContent('');
            var file = {
                _id: id,
                name: name,
                parent: folder,
                prevContent: content,
                currentContent: content,
                type: 'client'
            };
            setFiles(function (files) {
                return __spreadArray([file], __read(files), false);
            });
            setDialogVisible(false);
        })
            .catch(function () {
            setLoading(false);
        });
    };
    var onChangeHandler = function (value) {
        if (value)
            setContent(value);
    };
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "card" }, { children: _jsxs(Dialog, __assign({ header: translater("filesPageCreateDialogTitle"), visible: dialogVisible, modal: true, draggable: false, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window', style: { minWidth: '800px' }, onHide: function () { return setDialogVisible(false); } }, { children: [_jsx(FontSizeArea, {}), _jsxs("div", __assign({ className: 'flex flex-column gap-2 mt-1' }, { children: [_jsxs("div", __assign({ className: 'flex flex-column gap-2 surface-card shadow-2 py-3 px-4 border-round' }, { children: [_jsx("label", { children: translater('filesPageCreateFolderLabel') }), _jsx(Dropdown, { value: folder, onChange: function (e) { return setFolder(e.value); }, options: folders, placeholder: "Select a folder", className: "w-full" }), _jsx("label", { children: translater('filesPageCreateFileLabel') }), _jsxs("div", __assign({ className: 'flex align-items-center gap-2 w-full' }, { children: [_jsx(InputText, { value: name, onChange: function (event) { return setName(event.target.value); }, className: "w-full", placeholder: translater('filesPageCreateDialoCreateInputPlaceholder').toString() }), _jsx(Button, { type: "button", disabled: loading, loading: loading, label: translater('filesPageCreateDialoCreateButton').toString(), className: "p-button-success w-15rem", onClick: function () { return createFile(); } })] }))] })), _jsx("div", __assign({ className: 'surface-card shadow-2 py-3 px-4 border-round' }, { children: _jsx(Editor, { value: content, options: {
                                        folding: true,
                                        foldingHighlight: true,
                                        foldingStrategy: 'auto',
                                        fontSize: fontSize
                                    }, onMount: function (editor, monaco) {
                                        if (!isSyntaxRegistered) {
                                            registerCustomEditorSyntax();
                                            registerIniCustomSyntax(monaco);
                                        }
                                    }, height: "70vh", theme: isDarkMode ? 'hc-black' : 'vs', onChange: onChangeHandler, language: programmingLanguage }) }))] }))] })) })) }));
};
