import { nameColumn } from "./components/name";
import { typeColumn } from "./components/type";
import { durationColumn } from "./components/duration";
import { idColumn } from "./components/id";
export var dynamicColumns = [
    nameColumn,
    durationColumn,
    typeColumn,
    idColumn
];
