var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Statistics } from "./components/Statistics";
import { translater } from "../../../../../../utils/localization/localization";
import { API } from "../../../../../../api";
import { getCurrentHomeData, setHomeData } from "../../../../../../redux/actions/appActions";
import { Preloader } from "./components/Preloader";
import { dateAdapter, getPeriods } from "../../../../../../utils/localization/time";
export var UserDashboard = function () {
    var _a = useSelector(function (state) { return state; }).app.homeData, homeDataDetails = _a.data, userPhones = _a.phones;
    var periods = getPeriods();
    var today = new Date();
    var _b = __read(useState(periods[0]), 2), period = _b[0], setPeriod = _b[1];
    var _c = __read(useState(true), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(null), 2), data = _d[0], setData = _d[1];
    var _e = __read(useState([]), 2), dates = _e[0], setDates = _e[1];
    var _f = __read(useState(false), 2), isCalendarRangeMode = _f[0], setCalendarRangeMode = _f[1];
    var calendarRef = useRef(null);
    var changeCalendarDateRange = function (e) {
        var range = e.value;
        if (Array.isArray(range)
            && (range.length > 1 && range.every(function (_) { return _; }))) {
            // Добавляем 23 часа и 59 минут к дате
            var _a = __read(range, 2), first = _a[0], last = _a[1];
            last.setHours(23, 59, 59, 999);
            var data_1 = {
                keyword: '',
                content: { start: new Date(first), end: new Date(last) }
            };
            // Скрываем выпадающее окно календаря после выбора двух дат
            if (calendarRef.current) {
                calendarRef.current.hide();
            }
            loadPhonesStatistics(userPhones, data_1);
        }
        setDates(e.value);
    };
    var changePeriod = function (phones, period) {
        setPeriod(period);
        // Очищаем календарь
        setDates([]);
        loadPhonesStatistics(phones, period);
    };
    // Загружаем данные для домашней страницы
    var loadPhonesStatistics = function (phones, period) {
        setLoading(true);
        API.getPhonesStatistics(phones, dateAdapter(period.content))
            .then(function (response) {
            var information = response.data.information;
            if (information)
                setData(information);
            var data = getCurrentHomeData();
            setHomeData(__assign(__assign({}, data), { data: information }));
            setLoading(false);
        })
            .catch(function () { return setLoading(false); })
            .finally(function () { return setLoading(false); });
    };
    // Загружаем номера телефонов
    var loadPhones = function () {
        setLoading(true);
        API.getPhones()
            .then(function (response) {
            var _a = response.data, phones = _a.phones, mainPhone = _a.mainPhone;
            var currentHomeData = getCurrentHomeData();
            setHomeData(__assign(__assign({}, currentHomeData), { data: null, phones: phones, mainPhone: mainPhone }));
            loadPhonesStatistics(phones, period);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        var homeData = getCurrentHomeData();
        if (homeData.data) {
            var data_2 = homeData.data;
            setData(data_2);
            setLoading(false);
        }
        else {
            loadPhones();
        }
    }, [homeDataDetails]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(Preloader, {})) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex gap-2 flex-column max-w-full surface-card shadow-2 py-3 px-4 border-round mt-2 mx-3" }, { children: [_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(Dropdown, { disabled: isCalendarRangeMode, value: period, onChange: function (e) { return changePeriod(userPhones, e.value); }, optionLabel: "keyword", options: periods, filter: true, placeholder: translater("userDashboardDropdownPeriod").toString(), className: "w-full" }), _jsx(Calendar, { disabled: !isCalendarRangeMode, value: dates, ref: calendarRef, inputId: "birth_date", onChange: function (e) { return changeCalendarDateRange(e); }, selectionMode: "range", readOnlyInput: true, inputClassName: "w-14rem", showIcon: true, maxDate: today, placeholder: translater("dashboardCalendarRangePlaceholder").toString() })] })), _jsxs("div", __assign({ className: "flex gap-2 align-items-center" }, { children: [_jsx(InputSwitch, { inputId: "calendar-switch", checked: isCalendarRangeMode, onChange: function (e) { return setCalendarRangeMode(e.value); } }), _jsx("label", __assign({ htmlFor: "calendar-switch" }, { children: translater("dashboardInputSwitchLabel") }))] }))] })), data ? (_jsx("div", __assign({ className: 'flex flex-column' }, { children: _jsx(Statistics, { data: data, currentPeriod: period.keyword }) }))) : ''] })) }));
};
