import axios from 'axios';
import { store } from '../../redux/store';
export var setLanguage = function () {
    // Устанавливаем язык по умолчанию
    var lang = store.getState().language.lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    // Подписываемся на изменение языка
    setLanguageSubscribe();
};
function setLanguageSubscribe() {
    store.subscribe(function () {
        var lang = store.getState().language.lang;
        axios.defaults.headers.common['Accept-Language'] = lang;
    });
}
