var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import { MultiSelect } from 'primereact/multiselect';
import { getListItemKeyword, translate } from "../FormGenerator";
import { RequiredFieldDesignation } from "../utils/RequiredFieldDesignation";
import { ErrorBlock } from "../../error/ErrorBlock";
import { translater } from "../../../../../utils/localization/localization";
import { Hint } from "../utils/Hint";
import { Preloader } from "../../preloader/Preloader";
import { API } from "../../../../../api";
var getMultiselectItemKeyword = function (listItem) {
    return listItem ? "".concat(translater(listItem.keyword), ", ") || "".concat(listItem.keyword, ", ") : '';
};
export var Multiselect = function (_a) {
    var _b = _a.form, control = _b.control, setValue = _b.setValue, _c = _a.field, titleTranslaterKey = _c.titleTranslaterKey, defaultSelectAll = _c.defaultSelectAll, field_name = _c.field, staticList = _c.staticList, placeholderTranslaterKey = _c.placeholderTranslaterKey, _d = _c.apiDataUrl, apiDataUrl = _d === void 0 ? 'no-key' : _d, required = _c.required, hintTranslaterKey = _c.hintTranslaterKey;
    var ref = useRef(null);
    var _e = __read(useState([]), 2), list = _e[0], setList = _e[1];
    var _f = __read(useState(!true), 2), loading = _f[0], setLoading = _f[1];
    var settings = {
        control: control,
        name: field_name,
        rules: {}
    };
    if (required && settings.rules) {
        settings.rules.required = translater("Form Generator Required Field").toString();
    }
    var _g = useController(settings), field = _g.field, fieldState = _g.fieldState;
    var params = {};
    var onChangeHandler = function (e) {
        field.onChange(e.value);
    };
    var loadList = function () {
        setLoading(true);
        API.getServerData(apiDataUrl)
            .then(function (response) {
            var data = response.data;
            var value = field.value;
            if (value && Array.isArray(value)) {
                var items = data.filter(function (element) { return value.find(function (item) { return item.content === element.content; }); });
                if (items.length)
                    setValue(field.name, items);
            }
            setList(data);
            if (defaultSelectAll && defaultSelectAll === true) {
                console.log(field.name, data);
                setValue(field.name, data);
            }
            setLoading(false);
        })
            .finally(function () { return setLoading(false); })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        if (apiDataUrl !== 'no-key')
            loadList();
        if (staticList) {
            setList(staticList);
            if (defaultSelectAll && defaultSelectAll === true) {
                setValue(field.name, staticList);
            }
        }
    }, []);
    return (_jsxs("div", __assign({ className: "w-full flex flex-column align-items-start gap-2" }, { children: [_jsxs("div", __assign({ className: "w-full flex flex-column" }, { children: [_jsxs("span", __assign({ className: "mb-1" }, { children: [hintTranslaterKey && (_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "text-primary p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" })), translate(titleTranslaterKey), required && _jsx(RequiredFieldDesignation, {})] })), loading
                        ? _jsx(Preloader, {})
                        : (_jsx(MultiSelect, __assign({}, params, { placeholder: placeholderTranslaterKey || 'Mutiselect', options: list, value: field.value, optionLabel: "keyword", selectedItemTemplate: function (option) { return getMultiselectItemKeyword(option); }, onChange: function (e) { return onChangeHandler(e); }, className: "w-full", itemTemplate: function (option) { return getListItemKeyword(option); }, multiple: true })))] })), _jsx(ErrorBlock, { errors: fieldState, keyword: "error" }), _jsx(Hint, { hint: hintTranslaterKey, reference: ref })] })));
};
