var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import clsx from 'clsx';
import { translater } from '../../../../../utils/localization/localization';
import { API } from '../../../../../api';
import { store } from '../../../../../redux/store';
import { TooltipRevealer } from '../../../../components/particals/tooltip/TooltipRevealer';
import { CurrentTime } from './components/CurrentTime';
import { UserInfo } from './components/UserInfo';
var getCurrentDate = function () {
    var lang = store.getState().language.lang;
    var now = new Date();
    var locale = lang === 'ru' ? 'ru-RU' : 'en-EN';
    var formattedDate = new Intl.DateTimeFormat(locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    }).format(now);
    var date = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    var hours = ('0' + now.getHours()).slice(-2);
    var minutes = ('0' + now.getMinutes()).slice(-2);
    var seconds = ('0' + now.getSeconds()).slice(-2);
    var time = "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    return { date: date, time: time };
};
export var HomeHeader = function () {
    var date = getCurrentDate().date;
    var DISPLAYED_PHONES_LENGTH = 4;
    var _a = __read(useState([]), 2), roles = _a[0], setRoles = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), phonesListVisible = _c[0], setPhonesListVisible = _c[1];
    var _d = __read(useState(''), 2), phonesFinder = _d[0], setPhonesFinder = _d[1];
    var _e = __read(useState(date), 2), currentDate = _e[0], setCurrentDate = _e[1];
    var _f = useSelector(function (state) { return state; }), user = _f.auth.user, _g = _f.app.homeData, phones = _g.phones, mainPhone = _g.mainPhone, lang = _f.language.lang;
    var checkRole = function (userRoles, currentUserRoleTitle) {
        return userRoles.find(function (role) { return currentUserRoleTitle === role.title; });
    };
    useEffect(function () {
        var loadRoles = function () {
            setLoading(true);
            var id = user ? user.id : '';
            API.getUserRoles(id)
                .then(function (response) {
                var roles = response.data;
                setRoles(roles);
                setLoading(false);
            })
                .finally(function () { return setLoading(false); });
        };
        loadRoles();
    }, []);
    useEffect(function () {
        var date = getCurrentDate().date;
        setCurrentDate(date);
    }, [lang]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "surface-section p-0 pt-5 mx-3 mt-3 shadow-2 border-round" }, { children: _jsxs("div", __assign({ className: "header-info-blocks grid text-center mb-3" }, { children: [_jsx(UserInfo, {}), _jsxs("div", __assign({ className: "header-info-block col-12 sm:col-11 md:col-7 lg:col-5 xl:col-4 flex flex-column align-items-start justify-content-start gap-3" }, { children: [_jsxs("div", __assign({ className: 'w-full flex flex-column gap-2 text-800' }, { children: [_jsx("span", __assign({ className: 'flex align-items-center p-2 px-3 border-round-sm surface-100 text-sm font-medium' }, { children: translater("homePageInfoBlockRolesTitle").toString().toUpperCase() })), _jsx("div", __assign({ className: "w-full m-0 flex flex-wrap gap-2 align-items-center" }, { children: loading ? (_jsxs("div", __assign({ className: 'flex flex-row w-full flex-nowrap gap-2' }, { children: [_jsx(Skeleton, { height: "35px" }), _jsx(Skeleton, { height: "35px" }), _jsx(Skeleton, { height: "35px" })] }))) : (roles.map(function (_a, index) {
                                            var title = _a.title;
                                            return (_jsx("div", __assign({ className: clsx("navbar-custom-button flex flex-row cursor-default p-1 px-2 border-round text-700 font-medium m-0", {
                                                    'active p-2 px-3 color-violet': checkRole(user ? user.roles : [], title),
                                                }) }, { children: _jsx("span", { children: title }) }), title + index));
                                        })) }))] })), _jsxs("div", __assign({ className: 'w-full flex flex-column gap-2 text-800' }, { children: [_jsx("span", __assign({ className: 'flex align-items-center p-2 px-3 border-round-sm surface-100 text-sm font-medium' }, { children: translater("homePageInfoBlockPhoneNumbersTitle").toString().toUpperCase() })), _jsxs("div", __assign({ className: "text-700 flex align-items-center flex-wrap gap-1" }, { children: [mainPhone ? (_jsx(TooltipRevealer, __assign({ hint: translater('homePageMainPhone').toString() }, { children: _jsxs("span", __assign({ className: "flex align-items-center p-1 px-2 font-medium border-indigo-500 navbar-custom-button border-round select-none color-violet active p-2 px-3" }, { children: [_jsx("i", { className: "pi pi-phone mr-2 text-sm text-500 color-violet" }), mainPhone] })) }))) : '', phones.length > 0 ? (_jsx(_Fragment, { children: phones
                                                    .filter(function (phone) { return phone !== mainPhone; })
                                                    .map(function (phone) { return (_jsxs("span", __assign({ className: clsx('p-1 px-2 font-medium border-indigo-500 navbar-custom-button border-round select-none') }, { children: [_jsx("i", { className: clsx('pi pi-phone mr-2 text-sm text-500', {
                                                                'color-violet': phone === mainPhone
                                                            }) }), phone] }), phone)); })
                                                    .slice(0, DISPLAYED_PHONES_LENGTH) })) : (_jsx("span", { children: translater("homePageEmptyPhoneNumbers") })), phones.length > DISPLAYED_PHONES_LENGTH ? (_jsxs(_Fragment, { children: [_jsx(TooltipRevealer, __assign({ hint: translater('homePageShowAllPhonesButtonHint').toString() }, { children: _jsx("span", __assign({ onClick: function () { return setPhonesListVisible(true); }, className: clsx('p-1 px-2 font-medium border-indigo-500 navbar-custom-button border-round select-none cursor-pointer') }, { children: _jsx("i", { className: "pi pi-ellipsis-h mr-2 text-sm text-500" }) })) })), _jsxs(Dialog, __assign({ header: translater("homePageInfoBlockPhoneNumbersTitle").toString().toUpperCase(), visible: phonesListVisible, style: { width: '30vw', height: '15vw' }, onHide: function () { return setPhonesListVisible(false); } }, { children: [_jsxs("span", __assign({ className: "p-input-icon-left w-full" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: phonesFinder, placeholder: translater("searchInputText").toString(), className: "w-full my-2", onChange: function (e) { return setPhonesFinder(e.target.value); } })] })), _jsx("div", __assign({ className: "text-700 flex align-items-center flex-wrap gap-1 mt-2" }, { children: phones
                                                                    .filter(function (phone) { return phone.includes(phonesFinder); })
                                                                    .map(function (phone) { return (_jsxs("span", __assign({ className: clsx('p-1 px-2 font-medium border-indigo-500 navbar-custom-button border-round select-none', {
                                                                        'color-violet active p-2 px-3': phone === mainPhone
                                                                    }) }, { children: [_jsx("i", { className: clsx('pi pi-phone mr-2 text-sm text-500', {
                                                                                'color-violet': phone === mainPhone
                                                                            }) }), phone] }), phone)); }) })), phones
                                                                .filter(function (phone) { return phone.includes(phonesFinder); })
                                                                .length === 0
                                                                ? _jsx("span", __assign({ className: 'flex justify-content-center' }, { children: translater("resultNotFound") }))
                                                                : ''] }))] })) : ''] }))] }))] })), _jsxs("div", __assign({ className: "header-info-block col-12 sm:col-11 md:col-7 lg:col-5 xl:col-4 flex flex-column align-items-start justify-content-start gap-3" }, { children: [_jsxs("div", __assign({ className: 'w-full flex flex-column gap-2 text-800' }, { children: [_jsx("span", __assign({ className: 'flex align-items-center p-2 px-3 border-round-sm surface-100 text-sm font-medium' }, { children: translater("homePageInfoBlockDateTitle").toString().toUpperCase() })), _jsx("span", __assign({ className: 'header-role flex justify-content-start text-600 p-2' }, { children: currentDate }))] })), _jsxs("div", __assign({ className: 'w-full flex flex-column gap-2 text-800' }, { children: [_jsx("span", __assign({ className: 'flex align-items-center p-2 px-3 border-round-sm surface-100 text-sm font-medium' }, { children: translater("homePageInfoBlockTimeTitle").toString().toUpperCase() })), _jsx(CurrentTime, {})] }))] }))] })) })) }));
};
