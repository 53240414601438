var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TabPages from "./components/tab-pages/TabPages";
import { Tabs } from './components/Tabs';
import { successNotification } from "../../redux/actions/notificationsActions";
import { API } from "../../api";
export var Initialization = function () {
    var navigate = useNavigate();
    var _a = __read(useState('loading'), 2), active = _a[0], setActive = _a[1];
    var _b = __read(useState([]), 2), failedTabs = _b[0], setFailedTabs = _b[1];
    var loadStatus = function () {
        API.initializationProccess()
            .then(function (response) {
            if (response && typeof response === 'object') {
                var _a = response.data, _b = _a.status, status_1 = _b === void 0 ? 'loading' : _b, failed = _a.failedTabs, _c = _a.message, message = _c === void 0 ? '' : _c;
                // Если инициализация завершена
                if (status_1 === 'finished') {
                    successNotification(message);
                    navigate('/login');
                }
                setActive(status_1);
                setFailedTabs(failed);
            }
        });
    };
    useEffect(function () {
        loadStatus();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Tabs, { active: active, setActive: setActive, failedTabs: failedTabs }), _jsx("div", __assign({ className: "p-0", style: { maxWidth: "800px", margin: '35px auto' } }, { children: _jsx(TabPages, { reloadTabs: loadStatus, active: active }) }))] }));
};
