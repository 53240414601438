import jwt_decode from 'jwt-decode';
import { logout, setCurrentUser } from '../../redux/actions/authActions';
import { setAuthToken } from '../axios/token';
import { store } from '../../redux/store';
export var authInit = function () {
    var token = localStorage.getItem('token');
    if (token) {
        // Присваиваем token в auth хедер
        setAuthToken(token);
        // Парсим токен пользователя
        var decoded = jwt_decode(token);
        // Авторизируем пользователя
        store.dispatch(setCurrentUser(decoded));
        // Получаем время в секундах
        var currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            // Выбрасываем пользователя из системы
            logout();
            // Редирект на логин
            window.location.href = "./login";
        }
    }
};
