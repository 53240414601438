var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileUpload } from 'primereact/fileupload';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { API } from '../../../../../api';
import { successNotification } from '../../../../../redux/actions/notificationsActions';
import { translater } from '../../../../../utils/localization/localization';
export var ImportButton = function () {
    var navigate = useNavigate();
    var ref = useRef(null);
    var overlayPanelRef = useRef(null);
    var _a = __read(useState(null), 2), data = _a[0], setData = _a[1];
    var _b = __read(useState(false), 2), dialogVisible = _b[0], setDialogVisible = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(''), 2), encryptionKey = _d[0], setEncryptionKey = _d[1];
    var handleFileUpload = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, file;
        return __generator(this, function (_b) {
            _a = __read(event.files, 1), file = _a[0];
            if (!file)
                return [2 /*return*/];
            setData(file);
            setDialogVisible(true);
            return [2 /*return*/];
        });
    }); };
    var clear = function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.clear();
        setData(null);
    };
    var importDatabase = function () {
        setLoading(true);
        if (data) {
            var formData = new FormData();
            formData.append('file', data);
            formData.append('keys', encryptionKey);
            API.databaseImport(formData)
                .then(function (response) {
                if (response) {
                    var message = response.data.message;
                    successNotification(message);
                    navigate('/login');
                    setLoading(false);
                }
            })
                .catch(function (error) {
                console.log(error);
                setData(null);
                setLoading(false);
            })
                .finally(function () {
                setLoading(false);
            });
        }
    };
    return (_jsxs("div", __assign({ className: 'flex gap-2 align-items-center justify-content-center' }, { children: [_jsx(FileUpload, { mode: "basic", accept: ".zip", customUpload: true, chooseLabel: translater('databasePageImportButton').toString(), className: 'w-full database-zip-upload max-w-19rem', ref: ref, onClear: function () { }, onSelect: handleFileUpload }), _jsx(Button, { onClick: function () { return clear(); }, style: { minWidth: '45px' }, className: classNames('p-button-outlined p-button-secondary', {
                    'hidden': !data
                }), icon: "pi pi-trash" }), _jsxs(Dialog, __assign({ header: translater("databasePageModalHeader"), visible: dialogVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window max-w-20rem', style: { minWidth: '500px' }, onHide: function () { setDialogVisible(false); setEncryptionKey(''); } }, { children: [_jsxs("label", __assign({ htmlFor: "encryption-key", className: "block text-900 font-medium mb-2" }, { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = overlayPanelRef.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("databasePageModalInputLabel")] })), _jsx(InputText, { id: "encryption-key", value: encryptionKey, onChange: function (e) { return setEncryptionKey(e.target.value); }, type: "text", placeholder: translater("databasePageModalInputPlaceholder").toString(), className: "w-full mb-2" }), _jsx(Button, { loading: loading, disabled: encryptionKey.trim() === '', onClick: function () { return importDatabase(); }, label: translater('databasePageModalButton').toString(), className: "w-full" }), _jsx(OverlayPanel, __assign({ ref: overlayPanelRef }, { children: translater("databasePageModalInputLabelHintMessage") }))] }))] })));
};
