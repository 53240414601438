var formatDate = function (value) {
    var date = new Date(value);
    return date.toLocaleDateString();
};
var dateBodyTemplate = function (_a) {
    var dateCreate = _a.dateCreate;
    return formatDate(dateCreate);
};
export var dateCreateColumn = {
    columnHeader: 'groupsPageDateCreateColumn',
    field: 'dateCreate',
    style: {
        minWidth: "250px"
    },
    body: dateBodyTemplate,
};
