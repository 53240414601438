var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImportADButton } from "./components/import/AD/ImportADButton";
import { DeleteUsersButton } from "./components/DeleteUsersButton";
import { GroupsList } from "./components/GroupsList";
import { CreateUserButton } from "./components/CreateUserButton";
export var Header = function (_a) {
    var selectedUsers = _a.selectedUsers, clearSelectedUsers = _a.clearSelectedUsers, loadData = _a.loadData;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "users-create-header-blocks w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsxs("div", __assign({ className: "users-create-header-block flex gap-2" }, { children: [_jsx(CreateUserButton, { loadData: loadData }), _jsx(ImportADButton, { loadData: loadData })] })), _jsxs("div", __assign({ className: "users-create-header-block flex gap-2" }, { children: [_jsx(GroupsList, { loadData: loadData, selectedUsers: selectedUsers, clearSelectedUsers: clearSelectedUsers }), _jsx(DeleteUsersButton, { loadData: loadData, selectedUsers: selectedUsers, clearSelectedUsers: clearSelectedUsers })] }))] })) }));
};
