var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ProgressBar } from 'primereact/progressbar';
export function TimedProgressBar(_a) {
    var time = _a.time;
    var _b = __read(useState(0), 2), value = _b[0], setValue = _b[1];
    var duration = time; // Продолжительность в миллисекундах (20 секунд)
    var interval = 1000; // Интервал обновления (каждые 100 миллисекунд)
    useEffect(function () {
        var increment = 100 / (duration / interval); // Расчет прироста значения
        var progressInterval = setInterval(function () {
            setValue(function (prevValue) {
                if (prevValue + increment >= 100) {
                    clearInterval(progressInterval);
                    return 100;
                }
                return prevValue + increment;
            });
        }, interval);
        // Очистка таймера при размонтировании компонента
        return function () { return clearInterval(progressInterval); };
    }, []);
    return _jsx(ProgressBar, { className: "w-20rem", value: value });
}
