export var getDefaultValues = function (json) {
    var formData = JSON.parse(json);
    var values = {};
    for (var i = 0; i < formData.tabs.length; i++) {
        var fields = formData.tabs[i].fields;
        if (fields) {
            for (var i_1 = 0; i_1 < fields.length; i_1++) {
                var field = fields[i_1];
                // Text fields
                values[field.field] = 'defaultValue' in field ? field.defaultValue : '';
                // Array fields
                if (['multiselect'].includes(field.type))
                    values[field.field] = 'defaultValue' in field ? field.defaultValue : [];
            }
        }
    }
    return values;
};
export var orderValues = function (values, fields) {
    // Создаем пустой объект, в который будем добавлять свойства в нужном порядке.
    var orderedValues = {};
    // Проходимся по каждому элементу в массиве fields.
    fields.forEach(function (item) {
        // Для каждого элемента проверяем, существует ли соответствующее свойство в объекте values.
        if (values.hasOwnProperty(item.field)) {
            // Если существует, добавляем это свойство в новый объект.
            orderedValues[item.field] = values[item.field];
        }
    });
    // Возвращаем новый объект с правильно упорядоченными свойствами.
    return orderedValues;
};
export var recognizeConfigField = function (configField) {
    // Если массив
    if (Array.isArray(configField))
        return configField.map(function (item) { return item.keyword; }).join(', ');
    // Если объект
    if (typeof configField === 'object') {
        return configField.keyword;
    }
    return configField;
};
export var getStructureCommentedFields = function (json) {
    if (!json)
        return [];
    var commentedList = [];
    var formData = JSON.parse(json);
    for (var i = 0; i < formData.tabs.length; i++) {
        var fields = formData.tabs[i].fields;
        if (fields) {
            for (var i_2 = 0; i_2 < fields.length; i_2++) {
                var field = fields[i_2];
                if (field.isCommented)
                    commentedList.push(field.field);
            }
        }
    }
    return commentedList;
};
// export const getColumnHeaderByConfigField = (structure: string, configField: string) => {
//   const form: Form = JSON.parse(structure)
//   const { tabs } = form
//   const field = tabs
//     .reduce((accum: FormField[], item) => {
//       return item.fields ? [...accum, ...item.fields] : [...accum]
//     }, [])
//     .find(field => field.field === configField)
//   return field?.titleTranslaterKey || ''
// }
