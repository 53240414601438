var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { store } from "../store";
import { CLEAR_HISTORY_NOTIFICATIONS, REMOVE_ERRORS, REMOVE_NOTIFICATION, SET_ERRORS, SET_HISTORY_NOTIFICATION, SET_NOTIFICATION } from "../reducers/notificationsReducer";
var notificationLife = 4000;
export var setNotification = function (params) {
    var notification = {
        type: SET_NOTIFICATION,
        payload: params
    };
    // Чистим предыдущие уведомления
    removeNotification();
    return store.dispatch(notification);
};
export var removeNotification = function () {
    var notification = {
        type: REMOVE_NOTIFICATION
    };
    return store.dispatch(notification);
};
export var clearNotificationsHistory = function () {
    var notification = {
        type: CLEAR_HISTORY_NOTIFICATIONS
    };
    return store.dispatch(notification);
};
export var addHistoryNotification = function (n) {
    var notification = {
        type: SET_HISTORY_NOTIFICATION,
        payload: n
    };
    var prevHistory = JSON.parse(localStorage.getItem('notifications') || '');
    localStorage.setItem('notifications', JSON.stringify(__spreadArray([n], __read(prevHistory), false)));
    return store.dispatch(notification);
};
export var successNotification = function (message) {
    var user = store.getState().auth.user;
    var params = {
        severity: 'success',
        summary: 'Success',
        detail: message,
        life: notificationLife,
        date: new Date().toLocaleString()
    };
    addHistoryNotification(__assign(__assign({}, params), { userId: user === null || user === void 0 ? void 0 : user.id }));
    setNotification(params);
};
export var errorNotification = function (message) {
    var user = store.getState().auth.user;
    var params = {
        severity: 'error',
        summary: 'Error',
        detail: message,
        life: notificationLife,
        date: new Date().toLocaleString()
    };
    addHistoryNotification(__assign(__assign({}, params), { userId: user === null || user === void 0 ? void 0 : user.id }));
    setNotification(params);
};
export var infoNotification = function (message) {
    var params = {
        severity: 'info',
        summary: 'Info',
        detail: message,
        life: notificationLife,
        date: new Date().toLocaleString()
    };
    // addHistoryNotification(params)
    setNotification(params);
};
export var setErrors = function (errors) {
    var notification = {
        type: SET_ERRORS,
        payload: errors
    };
    return store.dispatch(notification);
};
export var removeErrors = function () {
    return store.dispatch({ type: REMOVE_ERRORS });
};
