var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MultiSelect } from "primereact/multiselect";
import { translater } from "../../../../utils/localization/localization";
import { CreateCertificateButton } from "./header-components/CreateCestificatesButton";
export var Header = function (_a) {
    var loadData = _a.loadData, visibleColumns = _a.visibleColumns, setVisibleColumns = _a.setVisibleColumns, dynamicColumns = _a.dynamicColumns;
    var onColumnToggle = function (event) {
        var selectedColumns = event.value;
        setVisibleColumns(selectedColumns);
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsx(CreateCertificateButton, { loadData: loadData }), _jsx(MultiSelect, { value: visibleColumns, optionLabel: "content", optionValue: "field", options: dynamicColumns.map(function (col) { return (__assign(__assign({}, col), { content: translater(col.columnHeader) })); }), onChange: onColumnToggle, className: "columns-multi-select columns-multi-select-certificates w-full", display: "chip" })] })) }));
};
