var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import clsx from 'clsx';
import { hideHeader, hideSidebar, showHeader, showSidebar } from '../../../../../../../redux/actions/appActions';
import { translater } from '../../../../../../../utils/localization/localization';
export var FullScreenToggle = function (_a) {
    var classNames = _a.classNames;
    var _b = __read(useState(false), 2), fullScreenMode = _b[0], setFullScreenMode = _b[1];
    var toggle = function () {
        if (fullScreenMode) {
            showSidebar();
            showHeader();
        }
        else {
            hideSidebar();
            hideHeader();
        }
        setFullScreenMode(!fullScreenMode);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": fullScreenMode ? translater("collapseButton") : translater("expandButton"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", onClick: function () { return toggle(); }, className: 'full-screen-button p-button-outlined p-button-icon-only p-button-secondary' + ' ' + classNames, icon: clsx('pi', {
                    'pi-window-maximize': !fullScreenMode,
                    'pi-window-minimize': fullScreenMode
                }) }), _jsx(Tooltip, { target: ".full-screen-button" })] }));
};
