var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from 'primereact/checkbox';
import { getPBXSettingsItems, getSecuritySettingsItems, getSidebarItems, getSystemSettingsItems, getUserSettingsItems } from "../../../../../../../redux/actions/appActions";
export var PagesHidder = function () {
    var links = getSidebarItems();
    var systemSettingsLinks = getSystemSettingsItems();
    var pbxSettingsLinks = getPBXSettingsItems();
    var securitySettingsLinks = getSecuritySettingsItems();
    var userLinks = getUserSettingsItems();
    return (_jsx("div", __assign({ className: "flex" }, { children: _jsxs("label", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx(Checkbox, { onChange: function (e) { }, checked: false }), "PBX tab"] })) })));
};
