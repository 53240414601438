import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { clone, findTreeElementById, replaceElementById } from "../../../../utils/tree";
import { updateTree } from "../../../../../../../redux/actions/builderAction";
export var VisibleButton = function (_a) {
    var node = _a.node, rest = _a.rest;
    var currentTree = useSelector(function (state) { return state.builder; }).tree;
    var visibilityToggle = function (event) {
        var element = findTreeElementById(currentTree, node.id);
        element.isHidden = !element.isHidden;
        var tree = replaceElementById(currentTree, element.id, element);
        updateTree(clone(tree));
    };
    return (_jsx("i", { onClick: visibilityToggle, className: clsx("pi node-button", {
            'pi-eye': !node.isHidden,
            'pi-eye-slash': node.isHidden,
        }), style: { fontSize: '0.6rem', color: '#787878' } }));
};
