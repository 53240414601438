import { dateColumn } from "./components/date";
import { destinationColumn } from "./components/destination";
import { durationColumn } from "./components/duration";
import { sourceColumn } from "./components/source";
import { timeColumn } from "./components/time";
import { dispositionColumn } from "./components/disposition";
export var dynamicColumns = function (setRecords) { return [
    dateColumn,
    timeColumn,
    durationColumn,
    sourceColumn,
    destinationColumn,
    dispositionColumn,
]; };
