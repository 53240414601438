var formatDate = function (value) {
    var date = new Date(value);
    return date.toLocaleDateString();
};
var dateBodyTemplate = function (_a) {
    var dateUpdate = _a.dateUpdate;
    return formatDate(dateUpdate);
};
export var dateUpdateColumn = {
    columnHeader: 'rulesPageDateUpdateColumn',
    field: 'dateUpdate',
    style: {
        minWidth: "250px"
    },
    body: dateBodyTemplate,
};
