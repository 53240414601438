var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactFlow, { addEdge, ConnectionLineType, useNodesState, useEdgesState, MiniMap, } from 'reactflow';
import { useSelector } from 'react-redux';
import { getLayoutedElements } from '../utils/inialization';
import { treeSplitting } from '../utils/transform';
import { cleanTreeHistory, moveNodes, returnPreviousTree, setMovingNode, updateTreeWithoutHistorySaving } from '../../../../redux/actions/builderAction';
import { clone, findAllChildrenIds, findDroppableNodes, findTreeElementById } from '../utils/tree';
import { DrapAndDropConfirm } from './components/modals/DrapAndDropConfirm';
import { API } from '../../../../api';
import { getFormSettings } from '../../../components/particals/form-generator/FormGenerator';
var defaultModalSetting = {
    header: '',
    description: '',
    visible: false,
    branches: [],
    selectedBranches: [],
    callback: function () { }
};
export var FlowArea = function (_a) {
    var settings = _a.settings, rule = _a.rule;
    var _b = useSelector(function (state) { return state.builder; }), tree = _b.tree, minimapVisible = _b.minimapVisible, movingNode = _b.movingNode, marginX = _b.marginX, marginY = _b.marginY;
    var _c = __read(useState([]), 2), droppableNodes = _c[0], setDroppableNodes = _c[1];
    var _d = __read(useState(null), 2), droppableCandidate = _d[0], setDroppableCandidate = _d[1];
    var _e = __read(useState(null), 2), tempNodes = _e[0], setTempNodes = _e[1];
    var _f = __read(useState(defaultModalSetting), 2), modalSettings = _f[0], setModalSettings = _f[1];
    var _g = __read(useNodesState([]), 3), nodes = _g[0], setNodes = _g[1], onNodesChange = _g[2];
    var _h = __read(useEdgesState([]), 3), edges = _h[0], setEdges = _h[1], onEdgesChange = _h[2];
    useEffect(function () {
        // console.log(tree);
        var _a = treeSplitting(tree), initialNodes = _a.nodes, initialEdges = _a.edges;
        var _b = getLayoutedElements(initialNodes, initialEdges), layoutedNodes = _b.nodes, layoutedEdges = _b.edges;
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
        // console.log(JSON.stringify(tree));
        // console.log(JSON.stringify(tree, null, 2));
    }, [tree]);
    // Bind событий для shortcut
    useEffect(function () {
        var handleKeyDown = function (event) {
            // Комбинации клавиш cmd + z или ctrl + Z
            if ((event.metaKey || event.ctrlKey) && ['Z', 'z'].includes(event.key)) {
                returnPreviousTree();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    var onConnect = useCallback(function (params) {
        return setEdges(function (eds) {
            return addEdge(__assign(__assign({}, params), { type: ConnectionLineType.SmoothStep, animated: true }), eds);
        });
    }, []);
    // const onLayout = useCallback(
    //   (direction: any) => {
    //     const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    //       nodes,
    //       edges,
    //       direction
    //     )
    //     setNodes([...layoutedNodes])
    //     setEdges([...layoutedEdges])
    //   },
    //   [nodes, edges]
    // )
    var regenerateTree = function () {
        var _a = treeSplitting(tree), initialNodes = _a.nodes, initialEdges = _a.edges;
        var _b = getLayoutedElements(initialNodes, initialEdges), layoutedNodes = _b.nodes, layoutedEdges = _b.edges;
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    };
    var onNodeDragStart = function (event, node) {
        var clonedNodes = clone(nodes);
        // Скрываем детей перетягиваемого элемента
        var childrenIds = findAllChildrenIds(tree, node.id);
        clonedNodes = clonedNodes.filter(function (node) { return !childrenIds.includes(node.id); });
        // Скрываем edge, который ведет к элементу, который перетягиваем
        var updEdges = edges.filter(function (edge) { return edge.target !== node.id; });
        setEdges(updEdges);
        // Задаем класс для элемента, который перетаскиваем
        var nodeIndex = clonedNodes.findIndex(function (_node) { return node.id === _node.id; });
        clonedNodes[nodeIndex].className = 'moving-node';
        setMovingNode(clonedNodes[nodeIndex]);
        var outputsIds = findDroppableNodes(tree);
        // Задаем класс для элементов, на которые можем перетаскивать
        var droppable = [];
        clonedNodes = clonedNodes.map(function (_node) {
            if (outputsIds.includes(_node.id) && _node.id !== node.id) {
                droppable.push({
                    id: _node.id,
                    x: _node.position.x,
                    y: _node.position.y,
                });
                _node.className = 'moving-droppable-node';
            }
            return _node;
        });
        setDroppableNodes(droppable);
        setNodes(clonedNodes);
        setTempNodes(clonedNodes);
    };
    var clearDragAndDropModal = function () {
        regenerateTree();
        setModalSettings(defaultModalSetting);
    };
    var areRectanglesNonIntersecting = function (rect1, rect2) {
        // Разделяющая ось по оси X
        if (rect1.x2 < rect2.x1 || rect2.x2 < rect1.x1) {
            return false;
        }
        // Разделяющая ось по оси Y
        if (rect1.y2 < rect2.y1 || rect2.y2 < rect1.y1) {
            return false;
        }
        return true;
    };
    var findIntersectionNode = function (node) {
        var e_1, _a;
        try {
            for (var droppableNodes_1 = __values(droppableNodes), droppableNodes_1_1 = droppableNodes_1.next(); !droppableNodes_1_1.done; droppableNodes_1_1 = droppableNodes_1.next()) {
                var droppableNode = droppableNodes_1_1.value;
                var intersection = areRectanglesNonIntersecting({
                    x1: node.position.x,
                    y1: node.position.y,
                    x2: node.position.x + marginX,
                    y2: node.position.y + marginY
                }, {
                    x1: droppableNode.x,
                    y1: droppableNode.y,
                    x2: droppableNode.x + marginX,
                    y2: droppableNode.y + marginY
                });
                if (intersection)
                    return droppableNode;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (droppableNodes_1_1 && !droppableNodes_1_1.done && (_a = droppableNodes_1.return)) _a.call(droppableNodes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    var removeFlowData = function () {
        setDroppableCandidate(null);
        setMovingNode(null);
        setNodes([]);
        setEdges([]);
    };
    var onNodeDrag = function (event, node) {
        var clonedNodes = clone(tempNodes);
        var intersectionNode = findIntersectionNode(node);
        // Показываем зеленую зону, если прямоугольники (node) пересекаются
        clonedNodes = clonedNodes.map(function (cloneNode) {
            if (cloneNode.id === node.id) {
                cloneNode.position = node.position;
            }
            if (intersectionNode) {
                if (intersectionNode.id === cloneNode.id) {
                    cloneNode.className = cloneNode.className + ' droppable-area';
                }
            }
            else {
                cloneNode.className = cloneNode.className ? cloneNode.className.replace(' droppable-area', '') : cloneNode.className;
            }
            return cloneNode;
        });
        setNodes(clonedNodes);
        setDroppableCandidate(intersectionNode || null);
    };
    var onNodeDragEnd = function (event) {
        if (!droppableCandidate)
            return regenerateTree();
        var droppedNode = findTreeElementById(tree, droppableCandidate.id);
        var movedNode = findTreeElementById(tree, movingNode && movingNode.id || '');
        // Если натащили на элемент со множественными выходами
        if (droppedNode.isChildrenList) {
            // Если свободны более одного элемента
            if (droppedNode.childrenTemplate.length - droppedNode.children.length !== 1) {
                var callback_1 = function (branch) {
                    moveNodes(movedNode, droppedNode, removeFlowData, branch);
                    setModalSettings(defaultModalSetting);
                };
                return setModalSettings({
                    header: 'Select a branch',
                    visible: true,
                    branches: droppedNode.childrenTemplate,
                    selectedBranches: droppedNode.children ? droppedNode.children.map(function (child) { return child.branch; }) : [],
                    callback: function (branch) { return callback_1(branch); }
                });
            }
            else {
                // Если остался только один элемент – перекидываем автоматом
                var _a = __read(droppedNode.childrenTemplate
                    .filter(function (branch) {
                    var branches = droppedNode.children.map(function (child) { return child.branch; });
                    // Отфильтровываем уже выбранные элементы
                    return !branches.includes(branch);
                }), 1), branch = _a[0];
                return moveNodes(movedNode, droppedNode, removeFlowData, branch);
            }
        }
        else {
            // Если натащили на элемент с единым выходом
            return moveNodes(movedNode, droppedNode, removeFlowData);
        }
    };
    // Загружаем root block
    useEffect(function () {
        // Ставим default root если сраница create и ранее на ней не работали
        // if ((tree as any).children.length === 0 && !settings.isUpdateMode) {
        if (!settings.isUpdateMode) {
            cleanTreeHistory();
            API.getBlock('root')
                .then(function (response) {
                if (response) {
                    var root = response.data;
                    root.id = uuidv4();
                    root.settings = getFormSettings(JSON.parse(root.formJSON));
                    updateTreeWithoutHistorySaving(root);
                }
            });
        }
    }, []);
    useEffect(function () {
        // Вставка уже созданного правила
        if (rule) {
            cleanTreeHistory();
            var tree_1 = JSON.parse(rule.formJSON);
            var _a = treeSplitting(tree_1), initialNodes = _a.nodes, initialEdges = _a.edges;
            var _b = getLayoutedElements(initialNodes, initialEdges), layoutedNodes = _b.nodes, layoutedEdges = _b.edges;
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
            updateTreeWithoutHistorySaving(tree_1);
        }
    }, [rule]);
    return (_jsxs(_Fragment, { children: [_jsx(ReactFlow, __assign({ nodes: nodes, edges: edges, elementsSelectable: false, nodesDraggable: true, onNodeDragStart: onNodeDragStart, onNodeDrag: onNodeDrag, onNodeDragStop: onNodeDragEnd, nodesConnectable: false, 
                // defaultViewport={{ x: 300, y: 70, zoom: 0.7 }}
                onConnect: onConnect, onEdgesChange: onEdgesChange, onNodesChange: onNodesChange, connectionLineType: ConnectionLineType.SmoothStep, fitView: true }, { children: minimapVisible
                    && _jsx(MiniMap, { zoomable: true, pannable: true }) })), _jsx(DrapAndDropConfirm, { modalSettings: modalSettings, clear: clearDragAndDropModal })] }));
};
