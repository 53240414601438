var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { iniVariables } from "./vocabulary/init/variables";
import { iniFunctoins } from "./vocabulary/init/functions";
var functions = iniFunctoins;
var keywords = iniVariables;
// Определяем функцию для регистрации подсказок
export function registerIniCustomSyntax(monaco) {
    monaco.languages.registerCompletionItemProvider('ini', {
        provideCompletionItems: function (model, position) {
            var word = model.getWordUntilPosition(position);
            var range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endColumn: word.endColumn
            };
            var text = model.getValueInRange(range);
            var regex = /\${([a-zA-Z_][a-zA-Z0-9_]*)}/g;
            var match;
            var suggestions = [];
            while ((match = regex.exec(text)) !== null) {
                var variableName = match[1];
                suggestions.push({
                    label: "${".concat(variableName, "}"),
                    kind: monaco.languages.CompletionItemKind.Variable,
                    insertText: "${".concat(variableName, "}"),
                    range: range,
                    // Добавляем класс 'template-variable'
                    className: 'template-variable'
                });
            }
            var functionSuggestions = functions.map(function (funcName) { return ({
                label: funcName,
                kind: monaco.languages.CompletionItemKind.Function,
                insertText: funcName,
                range: range
            }); });
            var keywordSuggestions = keywords.map(function (keyword) { return ({
                label: keyword,
                kind: monaco.languages.CompletionItemKind.Keyword,
                insertText: keyword,
                range: range
            }); });
            // Объединяем все предложения
            var allSuggestions = __spreadArray(__spreadArray([], __read(functionSuggestions), false), __read(keywordSuggestions), false);
            // Добавляем предложения для ${XXXX}
            allSuggestions.push.apply(allSuggestions, __spreadArray([], __read(suggestions), false));
            return {
                suggestions: allSuggestions,
                incomplete: false
            };
        }
    });
    // Определяем тему оформления для выделения красным цветом `${hostname}`
    monaco.editor.defineTheme('custom-theme', {
        base: 'vs',
        inherit: true,
        rules: [
            { token: 'template-variable', foreground: '#FF0000' } // Используйте цвет в формате CSS
        ],
        colors: {}
    });
    // Устанавливаем созданную тему оформления
    monaco.editor.setTheme('custom-theme');
}
