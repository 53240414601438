var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Preloader } from '../../../../../../../../components/particals/preloader/Preloader';
export var FooterPreloader = function () {
    return (_jsx("div", __assign({ className: "w-full flex flex-column" }, { children: _jsx(Preloader, { height: '40px' }) })));
};
export var ContentPreloader = function () {
    return (_jsx("div", __assign({ className: "w-full flex flex-column gap-3" }, { children: _jsxs("div", __assign({ className: "w-full flex flex-column gap-3 mt-3" }, { children: [_jsxs("div", __assign({ className: "w-full flex gap-2" }, { children: [_jsx(Preloader, { height: '40px', width: '49%' }), _jsx(Preloader, { height: '40px', width: '49%' })] })), _jsxs("div", __assign({ className: "w-full flex gap-2" }, { children: [_jsx(Preloader, { height: '40px', width: '49%' }), _jsx(Preloader, { height: '40px', width: '49%' })] })), _jsxs("div", __assign({ className: "w-full flex gap-2" }, { children: [_jsx(Preloader, { height: '40px', width: '49%' }), _jsx(Preloader, { height: '40px', width: '49%' })] }))] })) })));
};
