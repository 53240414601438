var template = function (_a) {
    var name = _a.name, surname = _a.surname, secname = _a.secname;
    return "".concat(surname, " ").concat(name, " ").concat(secname);
};
export var fullnameColumn = {
    columnHeader: 'usersPageFullNameColumn',
    field: 'name',
    body: template,
    style: {
        textAlign: "center",
        minWidth: "350px"
    }
};
