import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from 'primereact/tag';
import { translater } from '../../../../../../utils/localization/localization';
var template = function (_a) {
    var isValid = _a.isValid;
    return isValid
        ? _jsx(Tag, { value: translater("certificateTabPageCertificateInformationModalSuccessMessage"), severity: 'success' })
        : _jsx(Tag, { value: translater("certificateTabPageCertificateInformationModalWarnMessage"), severity: 'warning' });
};
export var validColumn = {
    columnHeader: 'certificateTableColumnIsValid',
    field: 'isValid',
    style: {
        textAlign: "center",
        minWidth: '10rem'
    },
    body: template
};
