import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from "primereact/tag";
import { MultiSelect } from "primereact/multiselect";
import { translater } from "../../../../../../utils/localization/localization";
var template = function (_a) {
    var type = _a.type;
    if (type === 'music')
        return _jsx(Tag, { severity: "warning", className: "capitalize", value: type });
    return _jsx(Tag, { className: "capitalize", value: type || 'prompt' });
};
var typeItemTemplate = function (option) {
    if (option === 'music')
        return _jsx(Tag, { severity: "warning", className: "capitalize", value: option });
    return _jsx(Tag, { className: "capitalize", value: option || 'prompt' });
};
var typeFilterTemplate = function (options) {
    return (_jsx(MultiSelect, { value: options.value, options: ['prompt', 'music'], itemTemplate: typeItemTemplate, onChange: function (e) { return options.filterCallback(e.value); }, placeholder: translater("callsPageSelectType").toString(), className: "p-column-filter w-16rem" }));
};
export var typeColumn = {
    columnHeader: 'awsPageAudioTypeColumn',
    field: 'type',
    filter: true,
    showFilterMatchModes: false,
    filterElement: typeFilterTemplate,
    body: template,
};
