var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
var template = function (_a) {
    var activeDirectoryDaseDN = _a.activeDirectoryDaseDN;
    var _b = __read(useState(false), 2), showDialog = _b[0], setShowDialog = _b[1];
    var toggleDialog = function () {
        setShowDialog(function (prevState) { return !prevState; });
    };
    if (!activeDirectoryDaseDN)
        return '–';
    return (_jsxs("div", __assign({ className: "flex flex-column justify-content-center align-items-center gap-1" }, { children: [_jsx("span", __assign({ className: "text-sm overflow-hidden" }, { children: activeDirectoryDaseDN.length > 20 ? activeDirectoryDaseDN.slice(0, 18) + '..' : activeDirectoryDaseDN })), _jsx("i", { className: "pi pi-eye", onClick: toggleDialog }), _jsx(Dialog, __assign({ visible: showDialog, onHide: toggleDialog }, { children: _jsx("p", { children: activeDirectoryDaseDN }) }))] })));
};
export var baseDN = {
    columnHeader: 'usersPageBaseDNColumn',
    field: 'activeDirectoryDaseDN',
    body: template
};
