var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { API } from '../../../../../../api';
import { translater } from '../../../../../../utils/localization/localization';
import { MiniWindowRedirectsUpd } from './components/MiniWindowRedirectsUpd';
import { FullWindowRedirectsUpd } from './components/FullWindowRedirectsUpd';
export var UpdateRedirectInterface = function (_a) {
    var selectedPhone = _a.selectedPhone, redirect = _a.redirect, refresh = _a.refresh;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(redirect), 2), editibleRedirect = _c[0], setEditibleRedirect = _c[1];
    var update = function () {
        if (selectedPhone) {
            setLoading(true);
            var redirect_1 = __assign(__assign({}, editibleRedirect), { extension: selectedPhone, id: editibleRedirect._id });
            API.updateRedirect(redirect_1)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                refresh();
                setLoading(false);
            })
                .catch(function () { return setLoading(false); });
        }
    };
    var removeRecord = function () {
        setLoading(true);
        API.removeRedirect(editibleRedirect._id)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            refresh();
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var remove = function () {
        confirmDialog({
            message: translater("redirectsDeleteRedirectConfirmMessage"),
            header: translater("redirectsDeleteRedirectConfirmHeader"),
            icon: 'pi pi-info-circle',
            position: 'top',
            accept: function () { return removeRecord(); },
            reject: function () { }
        });
    };
    useEffect(function () {
        setEditibleRedirect(redirect);
    }, [redirect]);
    return (_jsxs("div", __assign({ className: 'w-full' }, { children: [_jsxs("div", __assign({ className: 'surface-card py-3 px-4 shadow-2 border-round flex flex-column align-items-start gap-3' }, { children: [_jsx(MiniWindowRedirectsUpd, { editibleRedirect: editibleRedirect, setEditibleRedirect: setEditibleRedirect }), _jsx(FullWindowRedirectsUpd, { editibleRedirect: editibleRedirect, setEditibleRedirect: setEditibleRedirect })] })), _jsxs("span", __assign({ className: "max-w-full gap-3 flex justify-content-end mt-2 py-3 px-4 surface-card shadow-2 border-round" }, { children: [_jsx(Button, { disabled: loading, onClick: remove, className: 'p-button-outlined p-button-danger', label: translater('redirectsDeleteRedirectButton').toString(), icon: "pi pi-trash" }), _jsx(Button, { disabled: loading, onClick: update, className: 'btn-success p-button-success', label: translater('redirectsSaveRedirectButton').toString(), icon: "pi pi-check" })] }))] })));
};
