var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { API } from '../../../../../../api';
import { translater } from '../../../../../../utils/localization/localization';
var defaultLazyState = { first: 0 };
var template = function (_a) {
    var _id = _a._id, title = _a.title, usersAmount = _a.usersAmount;
    var _b = __read(useState(50), 2), limit = _b[0], setLimit = _b[1];
    var _c = __read(useState(0), 2), userAmount = _c[0], setUserAmount = _c[1];
    var _d = __read(useState(defaultLazyState), 2), lazyState = _d[0], setlazyState = _d[1];
    var _e = __read(useState(false), 2), loading = _e[0], setLoading = _e[1];
    var _f = __read(useState(''), 2), globalFinderValue = _f[0], setGlobalFinderValue = _f[1];
    var _g = __read(useState(false), 2), dialogVisible = _g[0], setDialogVisible = _g[1];
    var _h = __read(useState([]), 2), groupUsers = _h[0], setGroupUsers = _h[1];
    var _j = __read(useState(undefined), 2), timeout = _j[0], setTimeOut = _j[1];
    var getUsers = function (id, query, event) {
        if (query === void 0) { query = ''; }
        if (event === void 0) { event = lazyState; }
        setLoading(true);
        API.getGroupUsers(id, query, event)
            .then(function (response) {
            var _a = response.data, users = _a.users, limit = _a.limit, amount = _a.amount;
            setGroupUsers(users);
            setLimit(limit);
            setUserAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var onPage = function (event) {
        setlazyState(event);
        getUsers(_id, globalFinderValue, event);
    };
    var onGlobalFinderChange = function (event) {
        var value = event.target.value;
        setGlobalFinderValue(value);
        clearTimeout(timeout);
        var newTimeout = setTimeout(function () {
            getUsers(_id, value);
        }, 600);
        setTimeOut(newTimeout);
    };
    return (_jsxs("div", { children: [_jsx("span", __assign({ "data-pr-tooltip": translater("groupsPageUsersAmountTooltip"), "data-pr-position": "left", "data-pr-at": "left-3 center", className: "custom-target-show-toggle flex-wrap py-2 px-3 border-round bg-indigo-500 text-white font-medium hover:bg-indigo-400", onClick: function (event) {
                    event.stopPropagation();
                    setDialogVisible(true);
                } }, { children: usersAmount })), _jsx(Tooltip, { target: ".custom-target-show-toggle" }), _jsx("div", { children: _jsx(Dialog, __assign({ onShow: function () { getUsers(_id); }, header: "".concat(translater("groupsPageUsersDialogHeader"), " ").concat(title, "."), visible: dialogVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window', contentStyle: { minHeight: '400px' }, style: { minWidth: '800px' }, onHide: function () { return setDialogVisible(false); } }, { children: _jsxs("div", __assign({ className: "surface-card p-4 shadow-2 border-round" }, { children: [_jsxs("span", __assign({ className: 'text-color-secondary font-normal' }, { children: [translater("groupsPageUsersAmountDialog"), _jsx("span", { children: _jsx("b", { children: usersAmount }) }), translater("members")] })), _jsxs("span", __assign({ className: "w-full p-input-icon-left mb-2 mt-4" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: globalFinderValue, onChange: function (event) { return onGlobalFinderChange(event); }, className: 'w-full', placeholder: translater("groupsPageUsersSearch").toString() })] })), _jsx(DataTable, __assign({ lazy: true, value: groupUsers, size: 'small', paginator: true, loading: loading, rows: limit, totalRecords: userAmount, first: lazyState.first, responsiveLayout: "scroll", scrollable: true, onPage: onPage, dataKey: "_id", showGridlines: true }, { children: _jsx(Column, { field: 'login', header: translater("groupsPageDialogUserColumn"), className: "px-5 text-color-secondary" }) }))] })) })) })] }));
};
export var usersAmountColumn = {
    columnHeader: 'usersPageUsersAmountColumn',
    field: 'usersAmount',
    body: template
};
