var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from "primereact/divider";
import { translater } from "../../../../../utils/localization/localization";
import { ExportButton } from "./components/export/ExportButton";
import { ResetButton } from "./components/reset/ResetButton";
export var ResetArea = function (_a) {
    var settings = _a.settings, setSettings = _a.setSettings;
    return (_jsxs("div", __assign({ className: "w-full card  flex flex-column gap-2 surface-card shadow-2 border-round p-3" }, { children: [_jsx("h3", __assign({ className: "m-0" }, { children: translater('settingsPageAppDataHeader') })), _jsx(Divider, { className: "my-1" }), _jsxs("div", __assign({ className: 'flex flex-column gap-2 align-items-start mb-2' }, { children: [_jsx("label", { children: translater('settingsPageExportDBLabel') }), _jsx(ExportButton, { setSettings: setSettings, settings: settings })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2 align-items-start' }, { children: [_jsx("label", { children: translater('settingsPageDropAppLabel') }), _jsx(ResetButton, {})] }))] })));
};
