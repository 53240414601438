var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { useState } from 'react';
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { API } from '../../../../../../api';
import { translater } from '../../../../../../utils/localization/localization';
import { FullWindowRedirectsCreate } from './components/FullWindowRedirectsCreate';
import { MiniWindowRedirectsCreate } from './components/MiniWindowRedirectsCreate';
export var CreateRedirectInterface = function (_a) {
    var selectedPhone = _a.selectedPhone, refresh = _a.refresh;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState({
        CFNA: '',
        CFB: '',
        CFU: '',
        timeout: 30,
        DND: false,
        active: true
    }), 2), redirect = _c[0], setRedirect = _c[1];
    var create = function () {
        if (selectedPhone) {
            setLoading(true);
            API.createRedirect(__assign(__assign({}, redirect), { extension: selectedPhone }))
                .then(function (response) {
                var message = response.data.message;
                refresh();
                successNotification(message);
                setLoading(false);
            })
                .catch(function () { return setLoading(false); });
        }
    };
    return (_jsxs("div", __assign({ className: 'w-full' }, { children: [_jsxs("div", __assign({ className: 'surface-card py-3 px-4 shadow-2 border-round flex flex-column align-items-start gap-3' }, { children: [_jsx(FullWindowRedirectsCreate, { redirect: redirect, setRedirect: setRedirect }), _jsx(MiniWindowRedirectsCreate, { redirect: redirect, setRedirect: setRedirect })] })), _jsx("span", __assign({ className: "max-w-full gap-3 flex justify-content-end mt-2 py-3 px-4 surface-card shadow-2 border-round" }, { children: _jsx(Button, { disabled: loading, onClick: create, className: 'p-button btn-success p-button-success', label: translater('redirectsCreateRedirectButton').toString(), icon: "pi pi-check" }) }))] })));
};
