var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Ripple } from "primereact/ripple";
import { Tooltip } from "primereact/tooltip";
import { Link, NavLink } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { images } from "../../../../../assets/images/index";
import { getTranslations, translater } from "../../../../../utils/localization/localization";
import { adminPermission } from "../../../../../redux/actions/authActions";
import { CustomImage } from "../../custom-image/CustomImage";
import { store } from "../../../../../redux/store";
import { changeSidebarTabs } from "../../../../../redux/actions/appActions";
export var changeTabs = function (tab) {
    var _a;
    var sidebarTabs = store.getState().app.sidebarTabs;
    // Меняем состояние разворачиваемого таба
    changeSidebarTabs(__assign(__assign({}, sidebarTabs), (_a = {}, _a[tab] = !sidebarTabs[tab], _a)));
};
export var FullSideBar = function (_a) {
    var setFullSideBarFlag = _a.setFullSideBarFlag, links = _a.links, systemSettingsLinks = _a.systemSettingsLinks, pbxSettingsLinks = _a.pbxSettingsLinks, securitySettingsLinks = _a.securitySettingsLinks, userLinks = _a.userLinks;
    var _b = __read(useState(''), 2), inputValue = _b[0], setInputText = _b[1];
    var _c = useSelector(function (state) { return state.app; }).sidebarTabs, isSettingsOpen = _c.isSettingsOpen, isSystemSettingsOpen = _c.isSystemSettingsOpen, isPbxSettingsOpen = _c.isPbxSettingsOpen, isSecuritySettingsOpen = _c.isSecuritySettingsOpen, isUserSettingsOpen = _c.isUserSettingsOpen;
    var commonLinks = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(links), false), __read(systemSettingsLinks), false), __read(pbxSettingsLinks), false), __read(userLinks), false);
    var btnRef1 = useRef(null);
    var btnRef2 = useRef(null);
    var btnRef3 = useRef(null);
    return (_jsx("div", __assign({ id: "app-sidebar", className: "surface-section h-full lg:h-auto hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none", style: { width: "280px" } }, { children: _jsxs("div", __assign({ className: "flex flex-column h-full" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center px-5 flex-shrink-0 justify-content-between", style: { height: "60px" } }, { children: [_jsx(Link, __assign({ to: "/" }, { children: _jsx("img", { src: images.common.logo, alt: "hyper-700", className: "w-7rem" }) })), _jsxs("span", __assign({ "data-pr-tooltip": translater('sideBarHintCollapse'), "data-pr-position": "right", "data-pr-my": "left+8 center-2", className: "cursor-pointer custom-target-icon-hide-toggle", onClick: function () { return setFullSideBarFlag(false); } }, { children: [_jsx(CustomImage, { src: images.common.sidebarIcon, width: "20px" }), _jsx(Tooltip, { target: ".custom-target-icon-hide-toggle" })] }))] })), _jsx("div", __assign({ className: "overflow-y-auto" }, { children: _jsxs("ul", __assign({ className: "flex flex-column list-none p-3 m-0 gap-1" }, { children: [_jsx("li", { children: _jsxs("span", __assign({ className: "p-input-icon-left mb-1 w-full" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: inputValue, onChange: function (event) { return setInputText(event.target.value); }, type: "text", className: "border-none w-full", placeholder: translater("searchInputText").toString() })] })) }), inputValue === '' ? (_jsxs(_Fragment, { children: [links
                                        .sort(function (a, b) { return a.order - b.order; })
                                        .map(function (link) {
                                        return (_jsx("li", { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }, link.order));
                                    }), _jsxs("li", { children: [(userLinks.length > 0 || (systemSettingsLinks.length > 0 && adminPermission()))
                                                ? _jsx(_Fragment, { children: _jsx(StyleClass, __assign({ nodeRef: btnRef1, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isSettingsOpen'); }, ref: btnRef1, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("i", { className: "pi pi-cog mr-2" }), _jsx("span", __assign({ className: "font-medium" }, { children: translater("settingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })) })
                                                : '', adminPermission() ?
                                                _jsxs(_Fragment, { children: [_jsxs("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: [_jsxs("li", { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isPbxSettingsOpen'); }, ref: btnRef2, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("span", __assign({ className: "font-medium" }, { children: translater("pbxSettingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })), _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isPbxSettingsOpen }) }, { children: pbxSettingsLinks
                                                                                .sort(function (a, b) { return a.order - b.order; })
                                                                                .map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] }), _jsxs("li", { children: [(userLinks.length > 0)
                                                                            ? (_jsx(_Fragment, { children: _jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isUserSettingsOpen'); }, ref: btnRef3, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("span", __assign({ className: "font-medium" }, { children: translater("userSettingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })) }))
                                                                            : '', _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isUserSettingsOpen }) }, { children: userLinks
                                                                                .sort(function (a, b) { return a.order - b.order; })
                                                                                .map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] })] })), _jsxs("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: [_jsxs("li", { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isSystemSettingsOpen'); }, ref: btnRef2, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("span", __assign({ className: "font-medium" }, { children: translater("systemSettingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })), _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSystemSettingsOpen }) }, { children: systemSettingsLinks
                                                                                .sort(function (a, b) { return a.order - b.order; })
                                                                                .map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] }), _jsxs("li", { children: [(userLinks.length > 0)
                                                                            ? (_jsx(_Fragment, { children: _jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isUserSettingsOpen'); }, ref: btnRef3, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("span", __assign({ className: "font-medium" }, { children: translater("userSettingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })) }))
                                                                            : '', _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isUserSettingsOpen }) }, { children: userLinks
                                                                                .sort(function (a, b) { return a.order - b.order; })
                                                                                .map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] })] })), _jsxs("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: [_jsxs("li", { children: [_jsx(StyleClass, __assign({ nodeRef: btnRef2, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isSecuritySettingsOpen'); }, ref: btnRef2, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("span", __assign({ className: "font-medium" }, { children: translater("securitySettingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })), _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSecuritySettingsOpen }) }, { children: securitySettingsLinks
                                                                                .sort(function (a, b) { return a.order - b.order; })
                                                                                .map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] }), _jsxs("li", { children: [(userLinks.length > 0)
                                                                            ? (_jsx(_Fragment, { children: _jsx(StyleClass, __assign({ nodeRef: btnRef3, selector: "@next", enterClassName: "hidden", enterActiveClassName: "slidedown", leaveToClassName: "hidden", leaveActiveClassName: "slideup" }, { children: _jsxs("a", __assign({ onClick: function () { return changeTabs('isUserSettingsOpen'); }, ref: btnRef3, className: "p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 border-round transition-colors transition-duration-150 w-full mb-1" }, { children: [_jsx("span", __assign({ className: "font-medium" }, { children: translater("userSettingsSideBar") })), _jsx("i", { className: "pi pi-chevron-down ml-auto mr-1" }), _jsx(Ripple, {})] })) })) }))
                                                                            : '', _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isUserSettingsOpen }) }, { children: userLinks
                                                                                .sort(function (a, b) { return a.order - b.order; })
                                                                                .map(function (link) {
                                                                                return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                                            }) }))] })] }))] })
                                                :
                                                    _jsx("ul", __assign({ className: clsx("list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out", { 'hidden': !isSettingsOpen }) }, { children: userLinks
                                                            .sort(function (a, b) { return a.order - b.order; })
                                                            .map(function (link) {
                                                            return (_jsx("li", __assign({ className: "mb-1" }, { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }), link.order));
                                                        }) }))] })] })) : (commonLinks
                                .sort(function (a, b) { return a.order - b.order; })
                                .filter(function (link) { return getTranslations(link.name).join('').toLowerCase().includes(inputValue.toLowerCase()); })
                                .map(function (link) {
                                return (_jsx("li", { children: _jsxs(NavLink, __assign({ to: link.path, className: "no-underline navbar-custom-button p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full" }, { children: [_jsx("i", { className: link.classes + ' mr-2' }), _jsx("span", __assign({ className: "font-medium" }, { children: translater(link.name) })), _jsx(Ripple, {})] })) }, link.order));
                            }))] })) }))] })) })));
};
