var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Menu } from "primereact/menu";
import { translater } from "../../../../../../../utils/localization/localization";
import { EditorSettingsDialog } from "./EditorSettingsDialog";
import { defaultFilesHistory } from "../../../../../../../redux/reducers/editorReducer";
import { removeHistoryFilePath, setEditorFolderPath } from "../../../../../../../redux/actions/editorAction";
export var EditorPathHistoryButton = function (_a) {
    var dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible, loadFileNames = _a.loadFileNames;
    var filesHistoryMenu = useRef(null);
    var filesPathHistory = useSelector(function (state) { return state.editor; }).filesPathHistory;
    var pathHistory = __spreadArray(__spreadArray([], __read(defaultFilesHistory), false), __read((filesPathHistory.slice().reverse())), false).map(function (path) {
        return {
            template: function () {
                return _jsxs("div", __assign({ onClick: function (event) {
                        filesHistoryMenu.current.toggle(event);
                        setEditorFolderPath(path);
                        // Ставим последний выбранный путь на первое место
                        removeHistoryFilePath(path);
                        loadFileNames(path);
                    }, className: "flex gap-2 align-items-center m-2 p-3 py-3 hover:surface-100 transition-duration-150 transition-colors border-round-md cursor-pointer" }, { children: [_jsx("span", __assign({ className: "w-full select-none white-space-nowrap" }, { children: path.length > 60 ? '...' + path.slice(-60) : path })), path !== '/etc/asterisk/' ? (_jsx("i", { onClick: function (event) {
                                event.preventDefault();
                                event.stopPropagation();
                                removeHistoryFilePath(path);
                            }, className: "pi pi-times" })) : ''] }));
            }
        };
    });
    var menuItems = [
        {
            label: translater("editorPageMenuItemsPathHistoryLabel").toString(),
            items: pathHistory
        }
    ];
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsx(Menu, { model: menuItems, popup: true, className: "w-5 mt-2", ref: filesHistoryMenu, id: "popup_menu_right" }), _jsx(Button, { "data-pr-tooltip": translater("editorPageMenuItemsPathHistoryLabel"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-history", className: "custom-target-icon-path-history p-button-outlined p-button-secondary", onClick: function (event) { return filesHistoryMenu.current.toggle(event); }, "aria-controls": "popup_menu_right", "aria-haspopup": true }), _jsx(Tooltip, { target: ".custom-target-icon-path-history" }), _jsx(EditorSettingsDialog, { dialogVisible: dialogVisible, setDialogVisible: setDialogVisible })] })));
};
