var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { MultiSelect } from "primereact/multiselect";
import { translater } from "../../../../../../utils/localization/localization";
import { Tag } from "primereact/tag";
var userTypes = ['ActiveDirectory', 'LocalDataBase'];
var typeItemTemplate = function (option) {
    return (_jsx("div", __assign({ className: "flex align-items-center gap-2" }, { children: _jsx("span", { children: translater('usersPageTypeFilter' + option) }) })));
};
var typeFilterTemplate = function (options) {
    return (_jsx(MultiSelect, { value: options.value, options: userTypes, itemTemplate: typeItemTemplate, onChange: function (e) { return options.filterCallback(e.value); }, placeholder: translater("usersPageSelectDatabase").toString(), className: "p-column-filter" }));
};
var typeBodyTemplate = function (_a) {
    var activeDirectoryId = _a.activeDirectoryId;
    return activeDirectoryId
        ? _jsx(Tag, { value: translater("usersPageTypeFilterActiveDirectory"), severity: 'warning' })
        : _jsx(Tag, { value: translater("usersPageTypeFilterLocalDataBase"), severity: 'success' });
};
export var typeColumn = {
    columnHeader: 'usersPageTypeColumn',
    field: 'activeDirectoryId',
    filter: true,
    filterElement: typeFilterTemplate,
    showFilterMatchModes: false,
    style: { textAlign: "center", minWidth: "20px" },
    body: typeBodyTemplate
};
