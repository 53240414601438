var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlowArea } from './components/FlowArea';
import { Form } from './components/components/form/Form';
import { Header } from './components/header/Header';
import '../../../assets/scss/pages/builder.scss';
import { Sidebar } from './components/sidebar/Sidebar';
export var Builder = function (_a) {
    var settings = _a.settings, rule = _a.rule;
    return (_jsxs("main", __assign({ className: 'builder flex flex-column gap-2 h-full' }, { children: [_jsx(Header, { settings: settings }), _jsxs("article", __assign({ className: 'builder-area flex gap-2' }, { children: [_jsx(Sidebar, {}), _jsx("section", __assign({ className: 'react-flow-area w-full h-full surface-card shadow-2 border-round-sm' }, { children: _jsx(FlowArea, { settings: settings, rule: rule }) }))] })), _jsx(Form, {})] })));
};
