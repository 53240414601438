var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { classNames } from "primereact/utils";
import Parser from 'html-react-parser';
import { clearNotificationsHistory } from "../../../../../../redux/actions/notificationsActions";
import { translater } from "../../../../../../utils/localization/localization";
export var Notifications = function () {
    var history = useSelector(function (state) { return state; }).notifications.history;
    var overlayPanel = useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx("i", __assign({ onClick: function (e) { return overlayPanel.current.toggle(e); }, className: "pi pi-bell ml-2 cursor-pointer app-notifications-history" }, { children: history.length > 0 ? _jsx("span", {}) : '' })), _jsxs(OverlayPanel, __assign({ className: "app-notifications-history-overlay-panel p-0", ref: overlayPanel }, { children: [history.length !== 0
                        ? (_jsx("div", __assign({ className: "w-full flex p-2" }, { children: _jsx("span", __assign({ onClick: function () { return clearNotificationsHistory(); }, className: "w-full text-center cursor-pointer border-1 py-2 border-300 border-round-sm" }, { children: translater('appNotificationModalClearAll') })) })))
                        : '', _jsxs("div", __assign({ className: "w-22rem flex flex-column gap-3 cover" }, { children: [history.map(function (notification, index) {
                                return (_jsxs("div", __assign({ className: classNames({
                                        'error': notification.severity === 'error',
                                        'info': notification.severity === 'info',
                                        'success': notification.severity === 'success'
                                    }, 'app-notifications-history-overlay-panel-item flex gap-2') }, { children: [_jsx("div", { className: "stick" }), _jsxs("div", __assign({ className: "info-area flex flex-column gap-1" }, { children: [_jsx("div", __assign({ className: "flex" }, { children: _jsx("span", __assign({ className: "date text-sm text-500" }, { children: notification.date })) })), _jsx("span", { children: Parser(String(notification.detail)) })] }))] }), index));
                            }), history.length === 0
                                ? _jsx("span", __assign({ className: "text-center w-full" }, { children: translater('appNotificationModalNoNotificationMessage') }))
                                : ''] }))] }))] }));
};
