import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
export function useDocumentTitle(title, prevailOnUnmount) {
    if (prevailOnUnmount === void 0) { prevailOnUnmount = false; }
    var defaultTitle = useRef(document.title);
    var companyName = useSelector(function (state) { return state; }).app.companyName;
    document.title = companyName ? "".concat(companyName, " \u2013 ").concat(title) : title;
    // useEffect(() => {
    //   document.title = companyName ? `${companyName} – ${title}` : title
    //   console.log(title, 2  )
    // }, [title])
    useEffect(function () { return function () {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }; }, []);
}
// const removeLastSlash = (url: string) => 
//   url.endsWith('/') ? url.slice(0, -1) : url
// export function redirectPrevPage() {
//   const { app: { lastVisitUrl } }: RootState = store.getState()
//   if (removeLastSlash(location.href) === removeLastSlash(location.origin + lastVisitUrl))
//     return false
//   location.href = location.origin + lastVisitUrl
// }
