var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { TooltipRevealer } from "../../tooltip/TooltipRevealer";
import { translater } from "../../../../../utils/localization/localization";
export var GlobeConnection = function (_a) {
    var classes = _a.classes;
    var isConnected = useSelector(function (state) { return state; }).support.isConnected;
    return isConnected ? (_jsx(TooltipRevealer, __assign({ hint: translater("customConnectionGlob").toString() }, { children: _jsx("i", { className: "pi pi-globe text-red-600 " + classes }) }))) : _jsx(_Fragment, {});
};
