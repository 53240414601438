var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { translater } from "../../../../../utils/localization/localization";
import { CreateTemplateButton } from "./components/CreateTemplateButton";
export var Header = function (_a) {
    var globalFinderValue = _a.globalFinderValue, setGlobalFinderValue = _a.setGlobalFinderValue, loadData = _a.loadData, visibleColumns = _a.visibleColumns, dynamicColumns = _a.dynamicColumns, setVisibleColumns = _a.setVisibleColumns, templatesColumnNames = _a.templatesColumnNames;
    var onColumnToggle = function (event) {
        var selectedColumns = event.value;
        setVisibleColumns(selectedColumns);
    };
    var templateConfigFields = templatesColumnNames
        .map(function (name) { return ({ columnHeader: name, field: name }); });
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "w-full flex gap-2 justify-content-between align-items-center" }, { children: [_jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(CreateTemplateButton, { loadData: loadData }) })), _jsxs("span", __assign({ className: "w-full p-input-icon-left" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: globalFinderValue, onChange: function (event) { return setGlobalFinderValue(event); }, className: "w-full", placeholder: translater('templatesPageSearchPlaceholder').toString() })] })), _jsx(MultiSelect, { value: visibleColumns, optionLabel: "content", optionValue: "field", options: __spreadArray(__spreadArray([], __read(dynamicColumns
                        .map(function (col) { return (__assign(__assign({}, col), { content: translater(col.columnHeader) })); })), false), __read(templateConfigFields
                        .map(function (col) { return (__assign(__assign({}, col), { content: col.columnHeader })); })), false), onChange: onColumnToggle, className: "columns-multi-select", style: { maxWidth: "300px" }, display: "chip" })] })) }));
};
