var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { API } from "../../../../../api";
import { SaveButton } from "./components/first-header/SaveButton";
import { translater } from "../../../../../utils/localization/localization";
import { addEditorFile, addHistoryFilePath, recognizeProgrammingLanguageByExtension, setCurrentEditorFile, setEditorFolderPath } from "../../../../../redux/actions/editorAction";
import { EditorSettingsButton } from "./components/second-header/EditorSettingsButton";
import { EditorPathHistoryButton } from "./components/second-header/EditorPathHistoryButton";
import { EditorCreateFileButton } from "./components/first-header/EditorCreateFileButton";
import { DeleteFileButton } from "./components/first-header/DeleteFileButton";
import { CancelChangesButton } from "./components/first-header/CancelChangesButton";
import { FullScreenToggle } from "./components/first-header/FullScreenToggle";
import { successNotification } from "../../../../../redux/actions/notificationsActions";
import { EditorFileReloadButton } from "./components/second-header/EditorFileReloadButton";
export var Header = function () {
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState([]), 2), fileNames = _b[0], setFileNames = _b[1];
    var _c = __read(useState(false), 2), dialogVisible = _c[0], setDialogVisible = _c[1];
    var _d = __read(useState(false), 2), folderExistenceErr = _d[0], setFolderExistenceErr = _d[1];
    var _e = useSelector(function (state) { return state.editor; }), currentFileName = _e.currentFileName, files = _e.files, currentFilesFolderPath = _e.currentFilesFolderPath;
    var onKeyDownHandler = function (event) {
        setFolderExistenceErr(false);
        if (event.key === 'Enter') {
            event.preventDefault();
            loadFileNames(currentFilesFolderPath);
        }
    };
    var loadFileNames = function (path) {
        setLoading(true);
        var preparedPath = path[path.length - 1] === '/' ? path : path + '/';
        addHistoryFilePath(preparedPath);
        API.getConfigurationFileNames(preparedPath)
            .then(function (response) {
            if (!response) {
                setLoading(false);
                setFolderExistenceErr(true);
                return setFileNames([]);
            }
            setFileNames(response.data);
            setFolderExistenceErr(false);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); });
    };
    var changeFile = function (fileInfo) {
        var file = files.find(function (file) { return file.name === fileInfo.fileName; });
        if (file) {
            // Нашли файл в памяти
            return setCurrentEditorFile(file.name);
        }
        // Грузим контент файла с сервера
        setCurrentEditorFile(null);
        setLoading(true);
        var folderPath = "".concat(currentFilesFolderPath, "/").concat(fileInfo.fileName);
        if (fileInfo.fileName) {
            API.getConfigurationFile(folderPath)
                .then(function (response) {
                var content = response.data;
                var _a = __read(fileInfo.fileName.split('.'), 2), fileExtension = _a[1];
                // Автоматически меняем синтаксис реадактора по расширению
                recognizeProgrammingLanguageByExtension(fileExtension);
                var file = {
                    name: fileInfo.fileName,
                    content: content,
                    prevContent: content,
                    ext: fileExtension,
                    readOnly: fileInfo.readOnly
                };
                addEditorFile(file);
                setCurrentEditorFile(fileInfo.fileName);
                setLoading(false);
            })
                .finally(function () { return setLoading(false); });
        }
    };
    var createFolder = function (path) {
        setLoading(true);
        API.createFolderThroughEditor(path)
            .then(function (response) {
            successNotification(response.data.message);
            loadFileNames(currentFilesFolderPath);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); });
    };
    useEffect(function () {
        var load = function () {
            loadFileNames(currentFilesFolderPath);
        };
        load();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsxs("div", __assign({ className: "w-full flex justify-content-between align-items-center gap-2" }, { children: [_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(EditorCreateFileButton, {}), _jsx(CancelChangesButton, { loading: loading })] })), _jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(SaveButton, { loading: loading, setLoading: setLoading }), _jsx(DeleteFileButton, { loading: loading, setLoading: setLoading, setFileNames: setFileNames, fileNames: fileNames }), _jsx(FullScreenToggle, {})] }))] })) })), _jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsxs("div", __assign({ className: "w-full flex justify-content-between align-items-center gap-2" }, { children: [_jsx(EditorPathHistoryButton, { loadFileNames: loadFileNames, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible }), _jsxs("div", __assign({ className: "w-full flex gap-2" }, { children: [_jsx(InputText, { value: currentFilesFolderPath, onKeyDown: function (event) { return onKeyDownHandler(event); }, onChange: function (event) { return setEditorFolderPath(event.target.value); }, className: "w-full" }), _jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsx(Button, { "data-pr-tooltip": translater("editorPageButtonFolderLoader"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", onClick: function () { return loadFileNames(currentFilesFolderPath); }, className: "custom-target-icon-folder-loader p-button-outlined p-button-secondary", icon: "pi pi-reply", style: {
                                                transform: "rotate(180deg)",
                                            } }), _jsx(Tooltip, { target: ".custom-target-icon-folder-loader" })] })), loading ? (_jsx(Skeleton, { className: "h-3rem w-16rem" })) : (_jsx(Dropdown, { value: currentFileName, onChange: function (event) { return changeFile(event.value); }, 
                                    // options={fileNames}
                                    options: fileNames.filter(function (fileInfo) { return !files.find(function (file) { return file.name === fileInfo.fileName; }); }), optionLabel: "fileName", filter: true, placeholder: translater("editorPageSelectFile").toString() }))] })), _jsx(EditorFileReloadButton, {}), _jsx(EditorSettingsButton, { dialogVisible: dialogVisible, setDialogVisible: setDialogVisible })] })) })), folderExistenceErr && (_jsx(Message, { className: "w-full shadow-2", severity: "error", text: _jsx(_Fragment, { children: _jsxs("p", __assign({ className: "m-0 p-0" }, { children: [translater("editorPageFolderExistenceErrMessage"), _jsx("i", __assign({ className: "font-bold" }, { children: currentFilesFolderPath })), "!", _jsx("span", __assign({ onClick: function () { return createFolder(currentFilesFolderPath); }, className: "ml-2 underline cursor-pointer font-bold" }, { children: translater("editorPageFolderExistenceErrButton") }))] })) }) }))] }));
};
