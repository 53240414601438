var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translater } from "../../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../../components/particals/error/ErrorBlock";
export var ConfirmPasswordField = function (_a) {
    var register = _a.register, errors = _a.errors, getValues = _a.getValues;
    var validations = {
        validate: function (value) {
            var password = getValues('password');
            if (password !== value)
                return translater("adminAccountTabPageValidateConfirmPassword").toString();
            return true;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "password-confirm", className: "block text-900 font-medium mb-2" }, { children: translater("adminAccountTabPageConfirmPasswordField") })), _jsx("input", __assign({}, register('passwordConfirm', validations), { id: "password-confirm", type: "password", placeholder: translater("adminAccountTabPagePlaceholderConfirmPasswordField").toString(), className: "p-inputtext p-component w-full mb-3" })), _jsx(ErrorBlock, { errors: errors, keyword: "passwordConfirm" })] }));
};
