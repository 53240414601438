var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useRef, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { successNotification } from '../../../../../../redux/actions/notificationsActions';
import { API } from "../../../../../../api";
import { translater } from "../../../../../../utils/localization/localization";
import { DialogWindow } from "../../DialogWindow";
import { ServerField } from "./fields/ServerField";
import { DriverField } from "./fields/DriverField";
import { PasswordField } from "./fields/PasswordField";
import { UIDField } from "./fields/UIDField";
import { DatabaseField } from "./fields/DatabaseField";
import { DomainField } from "./fields/DomainField";
export var Form = function (_a) {
    var reloadTabs = _a.reloadTabs;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), hintVisible = _c[0], setHintVisible = _c[1];
    var cancelToken = useRef(null);
    var testConnection = function (data) {
        setLoading(true);
        cancelToken.current = axios.CancelToken.source();
        if (cancelToken)
            API.testCDRDatabaseConnection(data, cancelToken)
                .then(function (response) {
                var message = response.data.message;
                successNotification(message);
                setLoading(false);
                reloadTabs();
            })
                .catch(function () { return setLoading(false); });
    };
    var abortRequest = function (event) {
        var _a;
        event.preventDefault();
        (_a = cancelToken.current) === null || _a === void 0 ? void 0 : _a.cancel();
        setLoading(false);
    };
    var toggleHint = function (event) {
        if (event.key === 'Enter')
            return false;
        setHintVisible(true);
    };
    var _d = useForm({ mode: 'all' }), register = _d.register, handleSubmit = _d.handleSubmit, _e = _d.formState, errors = _e.errors, isValid = _e.isValid, submitCount = _e.submitCount;
    var onSubmit = function (data) {
        testConnection(data);
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "w-full" }, { children: [_jsxs("div", __assign({ className: "surface-card p-4 shadow-2 border-round w-full" }, { children: [_jsx(DriverField, { register: register, errors: errors }), _jsx(ServerField, { register: register, errors: errors }), _jsx(UIDField, { register: register, errors: errors }), _jsx(PasswordField, { register: register, errors: errors }), _jsx(DatabaseField, { register: register, errors: errors }), _jsx("hr", { className: "my-3 mx-0 border-top-1 border-none surface-border" }), _jsx(DomainField, { register: register, errors: errors })] })), _jsxs("div", __assign({ className: "flex justify-content-end gap-2 mt-4 mb-4" }, { children: [_jsx(Button, { label: translater("groupTabPageTestLaterButton").toString(), icon: "pi pi-times", onClick: toggleHint, type: "button", className: "p-button-outlined" }), loading ? (_jsx(Button, { label: translater("cancel").toString(), icon: "pi pi-times", type: "button", onClick: abortRequest, className: "p-button p-button-warning" })) : (_jsx(Button, { label: translater("ipTabPageTestConnectButton").toString(), icon: "pi pi-check", className: "p-button-success", loading: loading, disabled: submitCount > 0 && !isValid })), _jsx(DialogWindow, __assign({ hintVisible: hintVisible, setHintVisible: setHintVisible }, { children: translater("groupTabPageDialogWindowContent") }))] }))] })));
};
