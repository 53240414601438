var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from "primereact/tooltip";
import { Button } from 'primereact/button';
import { useEffect, useState } from "react";
import { Header } from "./components/Header";
import { successNotification } from '../../../redux/actions/notificationsActions';
import { API } from "../../../api";
import { View } from "./components/View";
import { translater } from "../../../utils/localization/localization";
import { dynamicColumns } from "./components/columns/columns";
import { localStorageVariables } from "../../../utils/localStorage/variables";
import { useDocumentTitle } from "../../../utils/routes/routes";
import { setBreadCrumbs } from "../../../redux/actions/appActions";
export var Certificates = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/certificates' }]);
    }, []);
    var _b = __read(useState([]), 2), certificates = _b[0], setCertificates = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState({ certificate: null, active: false }), 2), certificateView = _d[0], setCertificateView = _d[1];
    var defaultVisibleColumns = dynamicColumns
        .filter(function (col) { return ['subject', 'isValid', 'issuer'].includes(col.field || ''); })
        .map(function (col) { return col.field || ''; });
    var _e = __read(useState(defaultVisibleColumns), 2), visibleColumns = _e[0], setVisibleColumns = _e[1];
    var changeVisibleColumns = function (columns) {
        setVisibleColumns(columns);
        localStorage.setItem(localStorageVariables.defaultTableColumns.certificate, JSON.stringify(columns));
    };
    // Получение имен колонок
    useEffect(function () {
        var columnNames = localStorage.getItem(localStorageVariables.defaultTableColumns.certificate) || '';
        if (columnNames) {
            setVisibleColumns(JSON.parse(columnNames));
        }
        else {
            localStorage.setItem(localStorageVariables.defaultTableColumns.certificate, JSON.stringify(defaultVisibleColumns));
        }
    }, []);
    var loadData = function () {
        setLoading(true);
        API.getCertificates()
            .then(function (response) {
            var certificatesList = response.data.certificatesList;
            setCertificates(certificatesList);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        loadData();
    }, []);
    var accept = function (id) {
        API.removeCertificate(id)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadData();
        })
            .catch(function (e) { return console.error(e); });
    };
    var confirm = function (certificate) {
        console.log(certificate);
        confirmDialog({
            message: translater("certificateСonfirmTableButtonMessage"),
            header: translater("certificateСonfirmTableButtonHeader"),
            icon: 'pi pi-info-circle',
            position: 'top',
            accept: function () { return accept(certificate.id || ''); },
            reject: function () { }
        });
    };
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsx(Header, { loadData: loadData, visibleColumns: visibleColumns, setVisibleColumns: changeVisibleColumns, dynamicColumns: dynamicColumns }) })), _jsxs("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round" }, { children: [_jsxs(DataTable, __assign({ value: certificates, rows: 20, loading: loading, responsiveLayout: "scroll", scrollable: true }, { children: [dynamicColumns
                                .filter(function (column) {
                                return visibleColumns.find(function (visibleColumn) { return column.field === visibleColumn; });
                            })
                                .map(function (column) {
                                var header = translater(column.columnHeader);
                                return (_jsx(Column, __assign({ className: "justify-content-center px-5", style: column.style || { minWidth: "150px" }, header: header }, column), column.field));
                            }), _jsx(Column, { className: "justify-content-center px-5", header: translater("certificateTableColumnActions"), body: function (row) {
                                    return (_jsxs(_Fragment, { children: [_jsx(Button, { "data-pr-tooltip": translater("viewBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-eye", className: "certificate-view-icon p-button p-component mr-2 p-button-icon-only p-button-outlined border-circle p-button-warning", style: { width: "50px", height: "50px" }, onClick: function () { return setCertificateView(function (prev) { return (__assign(__assign({}, prev), { active: true, certificate: row })); }); } }), _jsx(Tooltip, { target: ".certificate-view-icon" }), _jsx(Button, { "data-pr-tooltip": translater("deleteBtn"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", icon: "pi pi-trash", className: "certificate-delete-icon p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }), _jsx(Tooltip, { target: ".certificate-delete-icon" })] }));
                                } }, 'certificate')] })), certificateView.active ? (_jsx(View, { certificate: certificateView.certificate, hideModal: function () { return setCertificateView(function (prev) { return (__assign(__assign({}, prev), { active: false, certificate: null })); }); }, modalVisible: certificateView.active })) : ''] }))] })));
};
