var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown } from "primereact/dropdown";
import { translater } from "../../../../../../../utils/localization/localization";
import { InputText } from "primereact/inputtext";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
var validate = function (value) {
    if (!value)
        return translater("usersPageCreateUserValidateMainPhone").toString();
    return true;
};
export var MainPhoneField = function (_a) {
    var _b = _a.form, register = _b.register, trigger = _b.trigger, getValues = _b.getValues, setValue = _b.setValue, errors = _b.formState.errors;
    var validations = { validate: validate };
    var setMainPhone = function (phone) {
        setValue('mainPhone', phone);
        trigger('mainPhone');
    };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "mainPhone", className: "block text-900 font-medium mb-2" }, { children: translater("usersPageCreateUserMainPhone") })), _jsx(Dropdown, { onChange: function (e) { return setMainPhone(e.value); }, value: getValues('mainPhone'), id: "mainPhone", options: getValues('phones'), placeholder: translater("usersPageCreateUserMainPhonePlaceholder").toString(), className: "w-full mb-3" }), _jsx(InputText, __assign({}, register('mainPhone', validations), { id: "mainPhone", type: "text", className: "w-full mb-3 hidden" })), _jsx(ErrorBlock, { errors: errors, keyword: "mainPhone" })] }));
};
