var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { Message } from "primereact/message";
import Parser from "html-react-parser";
import { Button } from "primereact/button";
import { removeErrors } from "../../../../redux/actions/notificationsActions";
export var ErrorsList = function () {
    var _a = useSelector(function (state) { return state; }).notifications.errors, errors = _a === void 0 ? [] : _a;
    var resolveError = function (path) {
        window.open("".concat(window.location.origin).concat(path));
    };
    return (_jsx(Dialog, __assign({ header: "Notifications", visible: errors.length > 0, style: { width: '50vw', maxHeight: '40vw' }, footer: _jsx(Button, { onClick: function () { return removeErrors(); }, label: "Close window", className: "w-full mt-2 p-button-outlined" }), onHide: function () { return removeErrors(); } }, { children: _jsx("div", __assign({ className: "flex flex-column gap-2" }, { children: errors.map(function (error, index) {
                var _a = error.message, message = _a === void 0 ? '' : _a, _b = error.resolvePath, resolvePath = _b === void 0 ? '' : _b;
                return (_jsx(Message, { style: {
                        border: 'solid #ff5757',
                        borderWidth: '0 0 0 6px',
                        color: '#ff5757'
                    }, className: "w-full justify-content-start flex flex-column align-items-start", severity: "error", content: _jsxs("div", __assign({ className: "ml-2 flex flex-column gap-1" }, { children: [_jsx("span", { children: Parser(message) }), resolvePath ? (_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("i", { className: "pi pi-external-link app-link-color text-sm" }), _jsx("span", __assign({ className: "app-link-color no-underline cursor-pointer", onClick: function () { return resolveError(resolvePath); } }, { children: "Resolve dependency" }))] }))) : ''] })) }, index));
            }) })) })));
};
