var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { API } from '../../../../../../../api';
import { FormGenerator } from '../../../../../../components/particals/form-generator/FormGenerator';
import { successNotification } from '../../../../../../../redux/actions/notificationsActions';
import { translater } from '../../../../../../../utils/localization/localization';
export var CreateTemplate = function (_a) {
    var dialogVisible = _a.dialogVisible, setDialogVisible = _a.setDialogVisible, loadData = _a.loadData;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), buttonLoading = _c[0], setButtonLoading = _c[1];
    var _d = __read(useState(null), 2), form = _d[0], setForm = _d[1];
    var loadTemplate = function () {
        setLoading(true);
        API.getTemplateCofig()
            .then(function (response) {
            setForm(response.data);
            setLoading(false);
        })
            .finally(function () { return setLoading(false); });
    };
    var sendData = function (_a) {
        var data = _a.data, expertCode = _a.expertCode;
        setButtonLoading(true);
        var name = data.name || '';
        var comment = data.comment || '';
        var template = {
            name: name,
            config: data,
            isActive: true,
            expertConfig: expertCode,
            structure: JSON.stringify(form),
            comment: comment
        };
        API.createTemplate(template)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            setButtonLoading(false);
            setDialogVisible(false);
            loadData();
        })
            .catch(function () {
            setLoading(false);
            setButtonLoading(false);
        });
    };
    useEffect(function () {
        loadTemplate();
    }, []);
    var defaultFormValues = form === null || form === void 0 ? void 0 : form.settings;
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx(Dialog, __assign({ header: translater("templatesPageCreateDialogTitle"), visible: dialogVisible, modal: true, resizable: false, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window', contentStyle: { minHeight: '400px' }, style: { minWidth: '800px' }, onHide: function () { return setDialogVisible(false); } }, { children: loading ? (_jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx(Skeleton, { height: '55px' }), _jsx(Skeleton, { height: '55px' }), _jsx(Skeleton, { height: '55px' }), _jsx(Skeleton, { height: '55px' }), _jsx(Skeleton, { height: '55px' })] }))) : (_jsx("div", __assign({ className: "surface-card mt-2 p-4 shadow-2 border-round w-full" }, { children: form
                    ? (_jsx(FormGenerator, { defaultFormValues: defaultFormValues, onSend: sendData, sendButtonLoading: buttonLoading, json: JSON.stringify(form) }))
                    : (_jsx("span", __assign({ className: 'block text-center' }, { children: translater("formDialogNoData") }))) }))) })) })));
};
