var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useDocumentTitle } from "../../../utils/routes/routes";
export var Test = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    // Состояние для хранения данных от AMI
    var _b = __read(useState([]), 2), amiData = _b[0], setAmiData = _b[1];
    // useEffect(() => {
    //   const { domain } = getWebsiteUrl();
    //   // Подключаемся к пространству имен для AMI
    //   const socket = io(`ws://${domain}/ami`);
    //   socket.on('connect', () => {
    //     console.log('Connected to AMI namespace');
    //     socket.emit('authenticate', { token: localStorage.getItem('token') });
    //   });
    //   socket.on('authenticate', (data) => {
    //     if (data.success) {
    //       console.log('Authentication successful');
    //     } else {
    //       console.error('Authentication failed:', data.error);
    //     }
    //   });
    //   // Получаем данные от AMI в реальном времени и обновляем состояние
    //   socket.on('ami-data', (event) => {
    //     console.log('Received AMI Data:', event);
    //     setAmiData((prevData: any) => [...prevData, event]); // Добавляем новые данные в состояние
    //   });
    //   // Обработка ошибки аутентификации
    //   socket.on('socket_auth_error', (data) => {
    //     console.error('Authentication Error:', data.error);
    //   });
    //   // Обработка отключения
    //   socket.on('disconnect', () => {
    //     console.log('Connection closed!');
    //   });
    //   // Обработка ошибок сокета
    //   socket.on('error', (err) => {
    //     console.error('Socket error:', err);
    //   });
    //   return () => {
    //     console.log('Cleaning up on component unmount');
    //     socket.close();
    //   };
    // }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("h2", { children: "AMI Data Output" }), _jsx("ul", { children: amiData.map(function (data, index) { return (_jsx("li", { children: JSON.stringify(data) }, index)); }) })] }) }));
};
