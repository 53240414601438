var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { CreateButton } from "./components/CreateButton";
import { translater } from "../../../../../utils/localization/localization";
export var Header = function (_a) {
    var refresh = _a.refresh, finder = _a.finder, finderValue = _a.finderValue;
    return (_jsxs("div", __assign({ className: "max-w-full surface-card shadow-1 p-3 border-round flex align-items-center justify-content-between gap-2" }, { children: [_jsx(CreateButton, { refresh: refresh }), _jsx(InputText, { placeholder: translater('queuePageModalNameFieldPlaceholder').toString(), value: finderValue, className: "w-full", onChange: function (e) { return finder(e.target.value); } })] })));
};
