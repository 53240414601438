var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { localStorageVariables } from "../../utils/localStorage/variables";
import { clone } from "../../shared/pages/builder/utils/tree";
var initialState = {
    files: [],
    programmingLanguage: 'ini',
    fontSize: 14,
    currentFileName: null,
    currentFilesFolderPath: '/etc/asterisk',
    filesPathHistory: [],
    isSyntaxRegistered: false // Флаг для определения того, зерегистрирован ли новый синтаксис для редактора
};
export var defaultFilesHistory = [
    '/etc/asterisk/'
];
export var programmingLanguages = [
    'ini',
    'javascript',
    'python',
    'json'
];
// Устанавливаем значения редактора по умолчанию
var editorFontSize = localStorage.getItem(localStorageVariables.editor.fontSize);
if (editorFontSize)
    initialState.fontSize = Number(editorFontSize);
else
    localStorage.setItem(localStorageVariables.editor.fontSize, String(initialState.fontSize));
var editorProgrammingLanguage = localStorage.getItem(localStorageVariables.editor.programmingLanguage);
if (editorProgrammingLanguage)
    initialState.programmingLanguage = editorProgrammingLanguage;
else
    localStorage.setItem(localStorageVariables.editor.programmingLanguage, initialState.programmingLanguage);
var editorFolderPath = localStorage.getItem(localStorageVariables.editor.folderPath);
if (editorFolderPath)
    initialState.currentFilesFolderPath = editorFolderPath;
else
    localStorage.setItem(localStorageVariables.editor.folderPath, initialState.currentFilesFolderPath);
var editorFilesHistory = localStorage.getItem(localStorageVariables.editor.filesPathHistory);
if (editorFilesHistory)
    initialState.filesPathHistory = JSON.parse(editorFilesHistory);
else
    localStorage.setItem(localStorageVariables.editor.filesPathHistory, JSON.stringify(initialState.filesPathHistory));
export var SET_PROGRAMMING_LANGUAGE = "SET_PROGRAMMING_LANGUAGE";
export var SET_EDITOR_TEXT_SIZE = "SET_EDITOR_TEXT_SIZE";
export var ADD_EDITOR_FILE = "ADD_EDITOR_FILE";
export var CLOSE_EDITOR_FILE_BY_NAME = "CLOSE_EDITOR_FILE_BY_NAME";
export var SET_CURRENT_EDITOR_FILE = "SET_CURRENT_EDITOR_FILE";
export var CHANGE_EDITOR_FILE_CONTENT = "CHANGE_EDITOR_FILE_CONTENT";
export var CHANGE_EDITOR_FILE_PREV_CONTENT = "CHANGE_EDITOR_FILE_PREV_CONTENT";
export var SET_CURRENT_EDITOR_FOLDER_PATH = "SET_CURRENT_EDITOR_FOLDER_PATH";
export var ADD_FILE_PATH_HISTORY = "ADD_FILE_PATH_HISTORY";
export var REMOVE_FILE_PATH_HISTORY = "REMOVE_FILE_PATH_HISTORY";
export var REGISTER_CUSTOM_SYNTAX = "REGISTER_CUSTOM_SYNTAX";
export var MAX_HISTORY_FILES_PATH_AMOUNT = 7;
export var editorReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SET_PROGRAMMING_LANGUAGE:
            return __assign(__assign({}, state), { programmingLanguage: action.payload });
        case SET_EDITOR_TEXT_SIZE:
            return __assign(__assign({}, state), { fontSize: action.payload });
        case SET_CURRENT_EDITOR_FILE:
            return __assign(__assign({}, state), { currentFileName: action.payload });
        case SET_CURRENT_EDITOR_FOLDER_PATH:
            return __assign(__assign({}, state), { currentFilesFolderPath: action.payload });
        case ADD_EDITOR_FILE:
            return __assign(__assign({}, state), { files: __spreadArray(__spreadArray([], __read(state.files), false), [action.payload], false) });
        case CLOSE_EDITOR_FILE_BY_NAME:
            return __assign(__assign({}, state), { files: state.files.filter(function (file) { return file.name !== action.payload; }) });
        case REGISTER_CUSTOM_SYNTAX:
            return __assign(__assign({}, state), { isSyntaxRegistered: true });
        case CHANGE_EDITOR_FILE_CONTENT:
            return __assign(__assign({}, state), { files: state.files.map(function (file) {
                    if (file.name === action.payload.fileName) {
                        file.content = action.payload.updContent;
                    }
                    return file;
                }) });
        case CHANGE_EDITOR_FILE_PREV_CONTENT:
            return __assign(__assign({}, state), { files: state.files.map(function (file) {
                    if (file.name === action.payload.fileName) {
                        file.prevContent = action.payload.updContent;
                    }
                    return file;
                }) });
        case ADD_FILE_PATH_HISTORY:
            return __assign(__assign({}, state), { filesPathHistory: clone(action.payload) });
        case REMOVE_FILE_PATH_HISTORY:
            return __assign(__assign({}, state), { filesPathHistory: clone(action.payload) });
        default:
            return state;
    }
};
