var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getUserRoles } from "../../../../redux/actions/authActions";
import { AdminDashboard } from "./dashboards/admin/AdminDashboard";
import { SecurityDashboard } from "./dashboards/security/SecurityDashboard";
import { SupervisorDashboard } from "./dashboards/supervisor/SupervisorDashboard";
import { UserDashboard } from "./dashboards/user/UserDashboard";
import { NoData } from "./particals/NoData";
export var Dashboards = function () {
    var _a = __read(getUserRoles(), 1), role = _a[0];
    switch (role.title) {
        case 'Administrator':
            return _jsx(AdminDashboard, {});
        case 'User':
            return _jsx(UserDashboard, {});
        case 'Supervisor':
            return _jsx(SupervisorDashboard, {});
        case 'Security':
            return _jsx(SecurityDashboard, {});
        default:
            return _jsx(NoData, {});
    }
};
