import { jsx as _jsx } from "react/jsx-runtime";
import { AdminAccount } from "./admin-account/AdminAccount";
import { CDRTabPage } from "./cdr-database/CDRTabPage";
import { CertificateTabPage } from "./certificate-page/CertificateTabPage";
import { GroupTabPage } from "./group-tab-pages/GroupTabPage";
import { IpTabPage } from "./ip-tab-page/IpTabPage";
import { TabPreloader } from "./TabPreloader";
var TabPages = function (_a) {
    var active = _a.active, reloadTabs = _a.reloadTabs;
    switch (active) {
        case 'admin':
            return _jsx(AdminAccount, { reloadTabs: reloadTabs });
        case 'connection':
            return _jsx(IpTabPage, { reloadTabs: reloadTabs });
        case 'cdr':
            return _jsx(CDRTabPage, { reloadTabs: reloadTabs });
        case 'groups':
            return _jsx(GroupTabPage, { reloadTabs: reloadTabs });
        case 'certificates':
            return _jsx(CertificateTabPage, { reloadTabs: reloadTabs });
        default:
            return _jsx(TabPreloader, {});
    }
};
export default TabPages;
