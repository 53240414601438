var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NodeLayout } from "../components/components/nodes/NodeLayout";
import { store } from "../../../../redux/store";
// Преобразования дерева в набор Node[] и Edge[]
export var treeSplitting = function (defaultTree) {
    var _a = store.getState().builder, isMoving = _a.isMoving, movingNode = _a.movingNode;
    var nodes = [];
    var edges = [];
    var position = { x: 0, y: 0 };
    var processTree = function (tree, parentId) {
        var e_1, _a;
        var id = tree.id, children = tree.children, isHidden = tree.isHidden, canHaveChildren = tree.canHaveChildren, branch = tree.branch, label = tree.data.label, rest = __rest(tree
        // Устанавливаем тип для блока
        , ["id", "children", "isHidden", "canHaveChildren", "branch", "data"]);
        // Устанавливаем тип для блока
        var type = 'default';
        if (!parentId)
            type = 'input'; // Тип для самого верхнего
        else if (children && children.length > 0)
            type = 'default';
        else
            type = 'output';
        var node = __assign(__assign({ id: id, position: position, type: type }, rest), { isHidden: isHidden, data: {
                label: _jsx(NodeLayout, { node: tree, type: type, rest: rest })
            } });
        nodes.push(node);
        if (parentId) {
            var edge = {
                id: "e".concat(parentId).concat(id),
                source: parentId,
                target: id,
                label: id,
                branch: branch,
                labelBgStyle: {
                    fill: '#FFCC00',
                    color: '#fff',
                    fillOpacity: 0.7,
                },
                // type: edgeType
            };
            edge.label = branch || null;
            edges.push(edge);
        }
        if (children && !isHidden) {
            try {
                for (var children_1 = __values(children), children_1_1 = children_1.next(); !children_1_1.done; children_1_1 = children_1.next()) {
                    var child = children_1_1.value;
                    processTree(child, id);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (children_1_1 && !children_1_1.done && (_a = children_1.return)) _a.call(children_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        // Сортировать childrfen при генерации node 
        // if (children) {
        //   const childrenWithBranch = children.filter((child: TreeBlock) => child.branch !== undefined)
        //   const childrenWithoutBranch = children.filter((child: TreeBlock) => child.branch === undefined)
        //   childrenWithBranch.sort((a: TreeBlock, b: TreeBlock) => (a.branch || '').localeCompare(b.branch || ''))
        //   const sortedChildren = [...childrenWithBranch, ...childrenWithoutBranch]
        //   for (let child of sortedChildren) {
        //     processTree(child, id);
        //   }
        // }
    };
    processTree(defaultTree);
    return {
        nodes: nodes,
        edges: edges
    };
};
