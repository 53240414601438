var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var template = function (_a) {
    var validFrom = _a.validFrom;
    var _b = __read(new Date(validFrom).toLocaleString().split(','), 1), date = _b[0];
    return date;
};
export var dateFromColumn = {
    columnHeader: 'certificateTableColumnDateFrom',
    field: 'validFrom',
    style: { minWidth: '12rem' },
    body: template
};
