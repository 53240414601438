var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { translater } from '../../../utils/localization/localization';
import { useDocumentTitle } from '../../../utils/routes/routes';
import { setBreadCrumbs } from '../../../redux/actions/appActions';
export var CallAccept = function (_a) {
    var title = _a.title;
    useDocumentTitle(title);
    useEffect(function () {
        setBreadCrumbs([{ title: title, path: '/call-accept' }]);
    }, []);
    return (_jsxs("main", __assign({ className: "max-w-full m-3 flex flex-column gap-2" }, { children: [_jsx("div", __assign({ className: "max-w-full surface-card shadow-2 py-3 px-4 border-round" }, { children: _jsxs("div", __assign({ className: 'text-center text-xl flex flex-column gap-3 my-3' }, { children: [_jsx("span", { children: translater("callAcceptPageInfo") }), _jsx("span", __assign({ className: 'font-medium' }, { children: translater("callAcceptPageNumber") }))] })) })), _jsx("div", __assign({ className: "surface-card shadow-2 overflow-hidden border-round py-3 px-4" }, { children: _jsxs("div", __assign({ className: 'flex flex-row gap-4 w-full justify-content-center' }, { children: [_jsx(Link, __assign({ className: "w-2 no-underline", to: '/instruction' }, { children: _jsx(Button, { label: translater("callAcceptPageButtonAccept").toString(), icon: "pi pi-check", className: 'w-full p-button-success' }) })), _jsx(Link, __assign({ className: "w-2 no-underline", to: '/instruction' }, { children: _jsx(Button, { label: translater("callAcceptPageButtonReject").toString(), icon: "pi pi-times", className: 'w-full p-button-danger' }) }))] })) }))] })));
};
