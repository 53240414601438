var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { translater } from "../../../../../../../utils/localization/localization";
import { showHeader, showSidebar } from "../../../../../../../redux/actions/appActions";
export var ReturnBackButton = function () {
    var navigate = useNavigate();
    var returnBack = function () {
        navigate('/rules');
        showSidebar();
        showHeader();
    };
    return (_jsxs("div", __assign({ className: "min-w-max" }, { children: [_jsxs("span", __assign({ "data-pr-tooltip": translater("returnBack"), "data-pr-position": "bottom", "data-pr-at": "center bottom+6", className: "custom-target-icon-return-back button-mini" }, { children: [_jsx(Button, { onClick: function () { return returnBack(); }, className: clsx('p-button-outlined p-button-secondary'), icon: "pi pi-sign-out" }), _jsx(Tooltip, { target: ".custom-target-icon-return-back" })] })), _jsx(Button, { onClick: function () { return returnBack(); }, label: translater('returnBack').toString(), className: clsx('p-button-outlined p-button-secondary full-button'), icon: "pi pi-sign-out" })] })));
};
