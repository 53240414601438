var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../utils/localization/localization";
import { TooltipRevealer } from "../../../components/particals/tooltip/TooltipRevealer";
import { CreateFile } from "../header/components/create-modal/CreateFile";
import { confirmDialog } from "primereact/confirmdialog";
import { API } from "../../../../api";
import { successNotification } from "../../../../redux/actions/notificationsActions";
export var ParentFolder = function (_a) {
    var files = _a.files, tabs = _a.tabs, selectedFile = _a.selectedFile, folder = _a.folder, prepareFileName = _a.prepareFileName, openTab = _a.openTab, hasUnsavedChanges = _a.hasUnsavedChanges, folders = _a.folders, setFiles = _a.setFiles, setTabs = _a.setTabs, setSelectedFile = _a.setSelectedFile;
    var _b = __read(useState(false), 2), isHidden = _b[0], setHiddenMode = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(false), 2), dialogVisible = _d[0], setDialogVisible = _d[1];
    var _e = __read(useState(''), 2), fileFinder = _e[0], setFileFinder = _e[1];
    var _f = __read(useState(folder), 2), selectedFolder = _f[0], setSelectedFolder = _f[1];
    var openCreateModal = function () {
        setSelectedFolder(folder);
        setDialogVisible(true);
    };
    var confirm = function (file) {
        confirmDialog({
            message: translater("filesPageDeleteFileConfirmMessage").toString() + file.name,
            header: translater("confirmDialogConfirmDeletion"),
            icon: 'pi pi-info-circle',
            className: 'max-w-30rem',
            position: 'top',
            accept: function () { return accept(file._id); },
            reject: function () { }
        });
    };
    var accept = function (id) {
        setLoading(true);
        API.removeFiles([id])
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            var currFile = files.find(function (file) { return file._id !== id; });
            // Удаляем файл везде
            setTabs(function (prevFiles) { return prevFiles.filter(function (file) { return file._id !== id; }); });
            setFiles(function (prevFiles) { return prevFiles.filter(function (file) { return file._id !== id; }); });
            setSelectedFile(currFile);
        })
            .catch(function () { return setLoading(false); });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: 'tabs flex flex-column p-0 m-0 surface-card shadow-2 border-round' }, { children: [_jsxs("div", __assign({ className: 'first-child cursor-default tab w-full flex flex-column gap-2' }, { children: [_jsxs("div", __assign({ className: "w-full flex align-items-center justify-content-between" }, { children: [_jsx("span", __assign({ className: 'capitalize font-bold' }, { children: folder })), _jsx("i", { onClick: function () { return setHiddenMode(!isHidden); }, className: classNames({
                                            'pi-chevron-up': isHidden,
                                            'pi-chevron-down': !isHidden
                                        }, 'pi text-sm cursor-pointer') })] })), !isHidden ? (_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx(InputText, { value: fileFinder, onChange: function (e) { return setFileFinder(e.target.value); }, className: classNames({}, 'p-inputtext-sm p-inputtext p-component w-full'), style: { padding: '6px 8px' }, placeholder: translater('editorPageFileFinderPlaceholder').toString() }), _jsx(TooltipRevealer, __assign({ hint: translater('filesPageCreateDialoCreateButton').toString() }, { children: _jsx(Button, { type: "button", icon: "pi pi-plus", className: classNames({}, 'p-button-outlined text-green-600 border-green-600 p-button-sm w-2rem'), style: { padding: '6px' }, onClick: function () { return openCreateModal(); } }) }))] }))) : ''] })), _jsxs("div", __assign({ className: classNames({
                            'hidden': isHidden,
                            'flex': !isHidden,
                        }, 'flex-column w-full files-list') }, { children: [files.filter(function (file) { return file.name.toLowerCase().includes(fileFinder.toLocaleLowerCase()); }).length === 0 ? (_jsx("div", __assign({ className: 'cursor-default tab' }, { children: _jsx("span", { children: translater('editorPageNoMatchesHeaderTitle') }) }))) : '', files
                                .sort(function (a, b) { return a.name.localeCompare(b.name); })
                                .filter(function (file) { return file.parent === folder; })
                                .filter(function (file) { return file.name.toLowerCase().includes(fileFinder.toLocaleLowerCase()); })
                                .map(function (file) { return (_jsxs("div", __assign({ onClick: function () { return openTab(prepareFileName(file.name)); }, className: classNames({
                                    'edited': tabs.find(function (tab) { return tab.name === file.name && hasUnsavedChanges(tab); }),
                                    'active': (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name) === file.name
                                    // 'active': selectedFile?.name.includes(file.name)
                                }, 'cursor-pointer tab flex align-items-center gap-2 justify-content-between') }, { children: [_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx("i", { className: 'pi pi-file' }), file.name] })), _jsx(TooltipRevealer, __assign({ hint: translater('filesPageTrashFileHint').toString() }, { children: _jsx("i", { onClick: function () { return confirm(file); }, className: classNames({
                                                'flex': (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name.includes(file.name)) && (file.type && file.type === 'client')
                                            }, 'pi pi-trash cursor-pointer text-sm hidden text-500') }) }))] }), file.name)); })] }))] })), _jsx(CreateFile, { folders: folders, selectedFolder: selectedFolder, dialogVisible: dialogVisible, setDialogVisible: setDialogVisible, setFiles: setFiles, setSelectedFile: setSelectedFile })] }));
};
