var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { translater } from "../../../../../utils/localization/localization";
import { API } from "../../../../../api";
import { dynamicColumns } from "./components/columns/columns";
import { TooltipRevealer } from "../../../../components/particals/tooltip/TooltipRevealer";
var defaultLazyState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    globalFinderValue: '',
    filters: {
        type: { value: null, matchMode: FilterMatchMode.IN },
        date: { value: getDateInterval(), matchMode: FilterMatchMode.IN },
    }
};
function getDateInterval() {
    var today = new Date();
    var interval = new Date();
    interval.setDate(today.getDate() - 30);
    return [interval, today];
}
export var CSV = function () {
    var lang = useSelector(function (state) { return state.language; }).lang;
    var _a = __read(useState(false), 2), modalVisible = _a[0], setModalVisible = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState([]), 2), records = _c[0], setRecords = _c[1];
    var _d = __read(useState(defaultLazyState), 2), lazyState = _d[0], setlazyState = _d[1];
    var _e = __read(useState(0), 2), totalRecords = _e[0], setTotalRecords = _e[1];
    var _f = __read(useState(50), 2), limit = _f[0], setLimit = _f[1];
    var loadCalls = function (filters) {
        setLoading(true);
        API.getAutoWarningSystemStatistics(__assign({}, filters))
            .then(function (response) {
            var _a = response.data, calls = _a.calls, amount = _a.amount, limit = _a.limit;
            setRecords(calls);
            setTotalRecords(amount);
            setLoading(false);
            setLimit(limit);
        })
            .catch(function (error) {
            setLoading(false);
        });
    };
    useEffect(function () {
        loadCalls(lazyState);
    }, [lazyState]);
    var download = function () {
        API.downloadAutoWarningSystemStatistics(__assign({}, lazyState))
            .then(function (response) {
            var calls = response.data;
            downloadCSV(calls);
        })
            .catch(function (error) {
            setLoading(false);
        });
    };
    var downloadCSV = function (cdr) {
        var csvData = cdr
            // .map((cdrItem: any) => {
            //   cdrItem.start = new Date(cdrItem.start).toLocaleString().replace(',', '') as any
            //   return cdrItem
            // })
            .map(function (cdrItem) {
            return Object.values(cdrItem);
        });
        // Преобразуем данные в CSV строку
        var csvString = csvData.map(function (row) { return row.join(','); }).join('\n');
        // Создаем Blob
        var blob = new Blob([csvString], { type: 'text/csv' });
        // Создаем ссылку и задаем URL для скачивания
        var url = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = 'statistics.csv';
        // Клик по ссылке для скачивания
        document.body.appendChild(link);
        link.click();
        // Удаляем ссылку после скачивания
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
    var columns = dynamicColumns(setRecords).map(function (column) {
        var header = translater(column.columnHeader).toString();
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    var onPage = function (event) { setlazyState(event); };
    var onSort = function (event) { setlazyState(event); };
    var onFilter = function (event) {
        event['first'] = 0;
        setlazyState(event);
    };
    var getLocale = function () {
        switch (lang) {
            case 'en':
                return 'en-US';
            case 'ru':
                return 'ru-RU';
            default:
                return 'ru-RU';
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex gap-2 align-items-center justify-content-between" }, { children: [_jsxs("div", __assign({ className: "flex aling-items-center gap-2" }, { children: [_jsx("div", __assign({ onClick: function () { return setModalVisible(true); }, style: { padding: '0.75rem 1.25rem' }, className: "cursor-pointer flex align-items-center justify-content-center border-1 border-400 text-700 border-round-md" }, { children: !lazyState.filters.date.value ? (_jsx("span", { children: translater('awsCSVStatisticLabelInvalid') })) : (_jsxs("div", { children: [translater('awsCSVStatisticLabel'), ":", " ", _jsx("span", { children: lazyState.filters.date.value
                                                .filter(function (date) {
                                                return date !== null;
                                            })
                                                .map(function (date) {
                                                return new Date(date).toLocaleDateString(getLocale(), {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                });
                                            })
                                                .join(" - ") })] })) })), _jsx(TooltipRevealer, __assign({ hint: translater("calendarBtn").toString() }, { children: _jsx("div", __assign({ onClick: function () { return setModalVisible(true); }, style: { padding: '0.75rem 1.25rem' }, className: "cursor-pointer flex align-items-center justify-content-center border-1 border-400 text-700 border-round-md" }, { children: _jsx("i", { className: "pi pi-calendar" }) })) }))] })), _jsx(Button, { icon: "pi pi-download", disabled: records.length === 0, onClick: function () { return download(); }, label: translater("awsCSVButtonDownloadCSVLabel").toString(), className: 'p-button-outlined p-button-secondary' })] })), _jsxs(Dialog, __assign({ header: translater('awsCalendarModalHeader'), visible: modalVisible, style: { width: '50vw' }, onHide: function () { if (!modalVisible)
                    return; setModalVisible(false); } }, { children: [_jsx(Calendar, { value: lazyState.filters.date.value, readOnlyInput: true, inline: true, onChange: function (e) { return setlazyState(function (prev) {
                            var udp = JSON.parse(JSON.stringify(prev));
                            udp.filters.date.value = e.value;
                            return udp;
                        }); }, maxDate: new Date(), selectionMode: "range", dateFormat: "dd-mm-yy", className: "w-full" }), _jsx(Button, { onClick: function () { return setModalVisible(false); }, label: translater('awsCalendarModalButtonLabel').toString(), className: "w-full mt-2 p-button-success border-green-600" })] })), _jsx(DataTable, __assign({ value: records, lazy: true, paginator: true, first: lazyState.first, rows: limit, totalRecords: totalRecords, onPage: onPage, onSort: onSort, onFilter: onFilter, filters: lazyState.filters, sortField: lazyState.sortField, sortOrder: lazyState.sortOrder, loading: loading, className: "border-1 border-300 border-round-md overflow-hidden mt-2", responsiveLayout: "scroll", scrollable: true, style: { minWidth: "200px" } }, { children: columns }))] }));
};
