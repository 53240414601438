import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from "primereact/tag";
var template = function (_a) {
    var location = _a.location;
    var severity = location ? 'success' : 'success';
    return _jsx(Tag, { className: "text-sm", value: location === 'db' ? 'Database' : 'PBX Configuration', severity: severity });
};
export var locationColumn = {
    columnHeader: 'contextPageLocationColumn',
    field: 'location',
    body: template,
};
