var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { API } from "../../../../../../../api";
import { CreateModal } from "../header/components/CreateModal";
import { UpdateModal } from "../header/components/UpdateModal";
import { successNotification } from "../../../../../../../redux/actions/notificationsActions";
import { translater } from "../../../../../../../utils/localization/localization";
import { SimplePlayer } from "../player/SimplePlayer";
import { EntityDependencyErrorArea } from "../../../../../../components/particals/error/EntityDependencyErrorArea";
import { dynamicColumns } from "./components/columns/columns";
import { TooltipRevealer } from "../../../../../../components/particals/tooltip/TooltipRevealer";
export var defaultAWSAudioLazyState = {
    first: 0,
    page: 1,
    filters: {
        activeDirectoryId: { value: null, matchMode: FilterMatchMode.IN }
    }
};
export var AudioList = function (_a) {
    var setActiveIndex = _a.setActiveIndex;
    var _b = __read(useState([]), 2), Audios = _b[0], setAudios = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState({}), 2), expandedAudioGroups = _d[0], setExpandedAudioGroups = _d[1];
    var _e = __read(useState(false), 2), createAudioModalVisible = _e[0], setCreateAudioModalVisible = _e[1];
    var _f = __read(useState(null), 2), modifiedAudio = _f[0], setModifiedAudio = _f[1];
    var _g = __read(useState(50), 2), limit = _g[0], setLimit = _g[1];
    var _h = __read(useState(0), 2), tableItemsAmount = _h[0], setTableItemsAmount = _h[1];
    var _j = __read(useState(defaultAWSAudioLazyState), 2), lazyState = _j[0], setLazyState = _j[1];
    var _k = __read(useState(''), 2), globalFinderValue = _k[0], setGlobalFinderValue = _k[1];
    var _l = __read(useState([]), 2), dependencyErrors = _l[0], setDependencyErrors = _l[1];
    var _m = __read(useState(false), 2), dependencyModalVisible = _m[0], setDependencyModalVisible = _m[1];
    var _o = __read(useState([]), 2), removableCandidates = _o[0], setRemovableCandidates = _o[1];
    var navigate = useNavigate();
    var loadAudios = function () {
        setLoading(true);
        API.finderAWSAudio(globalFinderValue.trim(), lazyState, true)
            .then(function (response) {
            var _a = response.data, audios = _a.audios, limit = _a.limit, amount = _a.amount;
            setAudios(audios);
            setLimit(limit);
            setTableItemsAmount(amount);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var toggleAudioGroups = function (AudioName) {
        setExpandedAudioGroups(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[AudioName] = !prevState[AudioName], _a)));
        });
    };
    var openGroup = function (group) {
        var params = new URLSearchParams(location.search);
        params.set('group', String(group._id));
        navigate("?".concat(params.toString(), "&modal=open"), { replace: true });
        setActiveIndex(0);
    };
    var handleAudioSearch = function (value) {
        setGlobalFinderValue(value);
    };
    var accept = function (id) {
        API.removeAWSAudio([id])
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadAudios();
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var confirm = function (audio) {
        setRemovableCandidates([String(audio._id)]);
        confirmDialog({
            message: translater("awsAudioItemСonfirmDialogButtonMessage"),
            header: translater("awsAudioItemСonfirmDialogButtonHeader"),
            icon: 'pi pi-info-circle',
            position: 'top',
            accept: function () { return accept(audio._id || ''); },
            reject: function () { }
        });
    };
    var refresh = function () {
        setLoading(true);
        API.checkAudioBeforeRemoving(removableCandidates)
            .then(function (response) {
            if (response) {
                setDependencyErrors([]);
                setLoading(false);
            }
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var remove = function () {
        setLoading(true);
        API.removeAWSAudio(removableCandidates)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            // Перезагружаем данные после удаления
            loadAudios();
            setDependencyErrors([]);
            setDependencyModalVisible(false);
        })
            .catch(function (err) {
            var _a, _b;
            setLoading(false);
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors) {
                setDependencyModalVisible(true);
                setDependencyErrors((_b = err.response) === null || _b === void 0 ? void 0 : _b.data.errors);
            }
        });
    };
    var hide = function () {
        setDependencyErrors([]);
        setDependencyModalVisible(false);
    };
    var groupsTemplate = function (audio) {
        var _a, _b;
        var showAllGroups = expandedAudioGroups[audio.name];
        if (((_a = audio.groups) === null || _a === void 0 ? void 0 : _a.length) === 0)
            return '–';
        return ((_b = audio.groups) === null || _b === void 0 ? void 0 : _b.length) ? (_jsxs("div", __assign({ className: "w-full flex justify-content-center align-items-center gap-2 flex-wrap" }, { children: [showAllGroups
                    ? audio.groups.map(function (group, index) { return (_jsx("span", __assign({ onClick: function () { return openGroup(group); }, className: "border-1 p-1 border-round-sm border-300 text-600 text-sm cursor-pointer" }, { children: group.name }), group.name + index)); })
                    : audio.groups.slice(0, 3).map(function (group, index) { return (_jsx("span", __assign({ onClick: function () { return openGroup(group); }, className: "border-1 p-1 border-round-sm border-300 text-600 text-sm cursor-pointer" }, { children: group.name }), group.name + index)); }), audio.groups.length > 3 && (_jsx("span", __assign({ className: "p-1 border-round-sm border-300 text-600 text-sm cursor-pointer border-1", onClick: function () { return toggleAudioGroups(audio.name); } }, { children: showAllGroups ?
                        _jsx("i", { className: "pi pi-times text-600 text-sm" })
                        : '...' })))] }))) : ("");
    };
    var columns = dynamicColumns.map(function (column) {
        var header = translater(column.columnHeader);
        if (column.field === 'groups') {
            return _jsx(Column, __assign({ body: groupsTemplate, className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field);
        }
        return (_jsx(Column, __assign({ className: "justify-content-center px-5", header: header, style: column.style || { minWidth: "100px" } }, column), column.field));
    });
    var onPage = function (event) { setLazyState(event); };
    var onFilter = function (event) {
        event['first'] = 0;
        setLazyState(event);
    };
    useEffect(function () {
        var timeOut = setTimeout(function () { return loadAudios(); }, 500);
        return function () { return clearTimeout(timeOut); };
    }, [globalFinderValue, lazyState]);
    return (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsxs("div", __assign({ className: "flex align-items-center gap-2" }, { children: [_jsx(InputText, { value: globalFinderValue, className: "w-full", placeholder: translater("awsAudioListSearchPlaceholder").toString(), onChange: function (e) { return handleAudioSearch(e.target.value); } }), _jsx(TooltipRevealer, __assign({ hint: translater("createButton").toString() }, { children: _jsx(Button, { onClick: function () { return setCreateAudioModalVisible(true); }, icon: "pi pi-plus", className: 'p-button-success' }) }))] })), _jsxs(DataTable, __assign({ lazy: true, paginator: true, value: Audios, loading: loading, rows: limit, first: lazyState.first, totalRecords: tableItemsAmount, onPage: onPage, onFilter: onFilter, filters: lazyState.filters, responsiveLayout: "scroll", scrollable: true, className: "border-1 border-300 border-round-md overflow-hidden", dataKey: "_id" }, { children: [columns, _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), body: function (row) {
                            return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(SimplePlayer, { template: "table", audioId: String(row._id) }), _jsx(TooltipRevealer, __assign({ hint: translater("updateBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-pencil", className: "p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () {
                                                setModifiedAudio(row);
                                            } }) })), _jsx(TooltipRevealer, __assign({ hint: translater("deleteBtn").toString() }, { children: _jsx(Button, { icon: "pi pi-trash", className: "p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", style: { width: "50px", height: "50px" }, onClick: function () { return confirm(row); } }) }))] })));
                        } }, 'actions')] })), createAudioModalVisible ? (_jsx(CreateModal, { refresh: function () { return loadAudios(); }, setFinder: handleAudioSearch, hide: function () { return setCreateAudioModalVisible(false); } })) : '', modifiedAudio ? (_jsx(UpdateModal, { modifiedAudio: modifiedAudio, refresh: function () { return loadAudios(); }, setFinder: handleAudioSearch, hide: function () { return setModifiedAudio(null); } })) : '', _jsx(EntityDependencyErrorArea, { hide: function () { return hide(); }, refresh: function () { return refresh(); }, remove: function () { return remove(); }, loading: loading, visible: dependencyModalVisible, errors: dependencyErrors })] })));
};
