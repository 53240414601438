var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
export var EmailField = function (_a) {
    var _b = _a.form, register = _b.register, errors = _b.formState.errors;
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "email", className: "block text-900 font-medium mb-2" }, { children: translater("usersPageCreateUserEmail") })), _jsx(InputText, __assign({}, register('email'), { id: "email", type: "text", placeholder: translater("usersPageCreateUserEmailPlaceholder").toString(), className: "w-full mb-3" })), _jsx(ErrorBlock, { errors: errors, keyword: "email" })] }));
};
