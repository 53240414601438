var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputTextarea } from "primereact/inputtextarea";
import { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { translater } from "../../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../../components/particals/error/ErrorBlock";
var validate = function (value) {
    if (!value)
        return translater("certificateTabPageCertificateFieldValidate").toString();
    if (!value.startsWith('-----'))
        return translater("certificateTabPageFormatValidate").toString();
    if (!value.endsWith('-----'))
        return translater("certificateTabPageFormatValidate").toString();
    return true;
};
export var CertificateField = function (_a) {
    var setCertificate = _a.setCertificate, _b = _a.form, register = _b.register, errors = _b.formState.errors;
    var ref = useRef(null);
    var onUpload = function (event) {
        var fileList = event.target.files;
        if (fileList) {
            var _a = __read(fileList, 1), file = _a[0];
            if (file) {
                var reader_1 = new FileReader();
                reader_1.addEventListener('load', function () {
                    var content = reader_1.result;
                    setCertificate(content);
                });
                reader_1.readAsText(file);
            }
        }
    };
    var validations = { validate: validate };
    return (_jsxs("div", __assign({ className: "w-full flex flex-column align-items-start" }, { children: [_jsxs("label", __assign({ htmlFor: "certificate", className: "block text-900 font-medium mb-2" }, { children: [_jsx("i", { onClick: function (e) { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.toggle(e); }, className: "p-button-icon p-c p-button-icon-left pi pi-info-circle cursor-pointer mr-2" }), translater("certificateTabPageCertificateField")] })), _jsx("label", __assign({ className: "p-button p-component font-bold mb-2", htmlFor: "certificate-upload" }, { children: translater("certificateTabPageSelectButton") })), _jsx(InputText, { id: "certificate-upload", type: "file", className: "hidden", accept: ".crt,.pem", onChange: onUpload, name: "certificate-upload" }), _jsx(InputTextarea, __assign({}, register('certificate', validations), { id: "certificate", name: "certificate", autoResize: false, style: { minHeight: '50px' }, placeholder: translater("certificateTabPageCertificateFieldPlaceholder").toString(), className: "w-full mb-3 no-resize" })), _jsx(ErrorBlock, { errors: errors, keyword: "certificate" }), _jsx(OverlayPanel, __assign({ ref: ref }, { children: translater("certificateTabPageCertificateFieldOverlayPanel") }))] })));
};
