var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import clsx from "clsx";
import { setBuilderBlocks, setDraggingMode } from "../../../../../redux/actions/builderAction";
import { API } from "../../../../../api";
import { Skeleton } from "primereact/skeleton";
export var Sidebar = function () {
    var _a = useSelector(function (state) { return state.builder; }), blocks = _a.blocks, isDragging = _a.isDragging, isMoving = _a.isMoving, tree = _a.tree;
    var _b = __read(useState(false), 2), hintVisible = _b[0], setHintVisible = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    // Загружаем блоки
    useEffect(function () {
        setLoading(true);
        API.getBlocks()
            .then(function (response) {
            var data = response.data;
            var serverBlocks = data
                .filter(function (block) { return block.data.label.toLowerCase() !== 'root'; });
            setBuilderBlocks(serverBlocks.sort(function (a, b) { return a.data.label.localeCompare(b.data.label); }));
            setLoading(false);
        })
            .finally(function () { return setLoading(false); });
    }, []);
    var onDragStart = function (event, block) {
        event.dataTransfer.setData('application/reactflow', JSON.stringify(block));
        event.dataTransfer.effectAllowed = 'move';
        setDraggingMode(true);
    };
    useEffect(function () {
        // Рисуем зеленую зону над элементами, на которые можно перетянуть
        if (isDragging) {
            var nodes = Array.from(document
                .querySelectorAll('.drag-permition'))
                .map(function (element) { return element.parentElement; });
            nodes.forEach(function (node) { return node === null || node === void 0 ? void 0 : node.classList.add('green-drag-permission-area'); });
        }
        else {
            var nodes = Array.from(document
                .querySelectorAll('.react-flow__node'));
            nodes.forEach(function (node) { return node === null || node === void 0 ? void 0 : node.classList.remove('green-drag-permission-area'); });
        }
    }, [isDragging]);
    return (_jsxs(_Fragment, { children: [_jsx("section", __assign({ className: 'sidebar flex flex-column gap-2 surface-card shadow-2 border-round-sm p-2' }, { children: loading
                    ? (_jsxs("div", __assign({ className: "flex flex-column gap-2" }, { children: [_jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px" }), _jsx(Skeleton, { height: "50px" })] })))
                    : (_jsx(_Fragment, { children: blocks.map(function (block, index) {
                            return (_jsxs("div", __assign({ onDragStart: function (event) { return onDragStart(event, block); }, onDragEnd: function () { return setDraggingMode(false); }, draggable: true, className: "tree-block flex gap-2 p-2" }, { children: [_jsx("i", { className: clsx('pi', block.icon) }), _jsx("span", { children: block.data.label })] }), index));
                        }) })) })), _jsx(Dialog, __assign({ visible: hintVisible, onHide: function () { return setHintVisible(false); }, style: { width: '50vw' }, resizable: false, breakpoints: { '960px': '75vw', '641px': '100vw' } }, { children: _jsx("p", { children: "hint content" }) }))] }));
};
