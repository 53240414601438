var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translater } from "../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
var validate = function (value) {
    if (!value)
        return translater("usersPageCreateUserValidatePassword").toString();
    if (!(/^[^\s]{6,}$/.test(value)))
        return translater("usersPageUserValidatePasswordLength").toString();
    return true;
};
export var PasswordField = function (_a) {
    var _b = _a.form, register = _b.register, errors = _b.formState.errors;
    var validations = { validate: validate };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "password", className: "block text-900 font-medium mb-2" }, { children: translater("usersPageCreateUserPassword") })), _jsx("input", __assign({}, register('password', validations), { id: "password", type: "password", placeholder: translater("usersPageCreateUserPasswordPlaceholder").toString(), className: "p-inputtext p-component w-full mb-3" })), _jsx(ErrorBlock, { errors: errors, keyword: "password" })] }));
};
